<template>
  <div id="whatsAppEditorPage">
    <el-row :gutter="20">
      <!-- Left Side Form -->
      <el-col class="leftSideForm" :span="14" v-bind:class="{ readOnly: readOnly === true }">
        <!-- Form  -->
        <el-form ref="whatsAppInputForm" v-bind:class="{ readOnly: readOnly === true }" label-position="top" :model="whatsAppForm" :rules="whatsAppFormRules">
          <!-- Provider Dropdown -->
          <el-row :gutter="20" class="providerRow">
            <el-col :span="12">
              <el-form-item class="providerField" label="WhatsApp Provider" prop="selectedProvider">
                <el-select :disabled="readOnly" v-model="whatsAppForm.selectedProvider" @change="onProviderSelected" placeholder="Select" style="width: 100%">
                  <el-option v-for="provider in providerList" :key="provider.id" :label="provider.provider_name" :value="provider.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!-- Template Selector Logic -->
            <el-col :span="12" v-if="selectedProviderObj && showTemplateInputFor.includes(selectedProviderObj.provider_type)">
              <!-- Template Name -->
              <template v-if="['whatsapp_gupshup_v1', 'whatsapp_gupshup_enterprise', 'whatsapp_dialog360', 'whatsapp_cloud_api'].indexOf(selectedProviderObj.provider_type) >= 0">
                <!-- Show WhatsApp Template Popup -->
                <el-form-item class="providerField" label="WhatsApp Template">
                  <span>{{ selectedWhatsApptemplate ? selectedWhatsApptemplate.name : 'No template selected.' }}</span>
                  <el-button v-if="!readOnly" class="float-right" size="small" @click="showTemplateSelectPopup" type="primary">Browse Templates</el-button>
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item class="providerField" label="WhatsApp Template ID" prop="selectedProvider">
                  <el-input id="templateIdInput" :disabled="readOnly" v-model="channelConfig.whatsAppTemplateName"></el-input>
                </el-form-item>
              </template>
            </el-col>
          </el-row>

          <!-- Media Upload Link Goes HERE -->
          <template v-if="selectedWhatsApptemplate && ['whatsapp_gupshup_enterprise'].indexOf(selectedProviderObj.provider_type) < 0">
            <div class="border -mt-4 rounded-lg overflow-hidden">
              <div class="p-2 text-sm border-b">Template Info: {{ selectedWhatsApptemplate.type }}</div>
              <div class="p-2 text-xs text-gray-500 bg-white" v-html="selectedWhatsApptemplate.body"></div>
            </div>
            <el-row class="mt-3" :gutter="20" v-if="selectedWhatsApptemplate.type != 'TEXT'">
              <el-col :span="18">
                <el-form-item class="providerField" :label="selectedWhatsApptemplate.type + ' URL'">
                  <el-input :disabled="readOnly" v-model="channelConfig.whatsAppMediaURL" style="width: 100%"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item class="providerField" :label="selectedWhatsApptemplate.type + ' NAME'">
                  <el-input :disabled="readOnly" v-model="channelConfig.whatsAppMediaFileName" style="width: 100%"> </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <!-- If zoko, show messge type option -->
          <template v-if="selectedProviderObj && selectedProviderObj.provider_type == 'whatsapp_zoko'">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item class="messageField mt-0" label="WhatsApp Message Type" prop="messageType">
                  <div class="leading-4" v-if="readOnly">{{ channelConfig.whatsAppMsgType }}</div>
                  <el-select v-model="channelConfig.whatsAppMsgType" class="w-full" v-else>
                    <el-option label="Text" value="Text"></el-option>
                    <el-option label="Image" value="Image"></el-option>
                    <el-option label="Video" value="Video"></el-option>
                    <el-option label="Document" value="Document"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item class="messageField mt-0" label="Template Has Buttons?" prop="messageType">
                  <div class="leading-4" v-if="readOnly">{{ channelConfig.whatsAppButtonsEnabled ? 'Yes' : 'No' }}</div>
                  <el-select v-model="channelConfig.whatsAppButtonsEnabled" class="w-full" v-else>
                    <el-option label="Yes" :value="true"></el-option>
                    <el-option label="No" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- Media URL -->
            <el-row :gutter="20" v-if="channelConfig.whatsAppMsgType != 'Text'">
              <el-col :span="18">
                <el-form-item class="providerField" :label="channelConfig.whatsAppMsgType + ' URL'">
                  <el-input :disabled="readOnly" v-model="channelConfig.whatsAppMediaURL" style="width: 100%"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item class="providerField" :label="channelConfig.whatsAppMsgType + ' Name'">
                  <el-input :disabled="readOnly" v-model="channelConfig.whatsAppMediaFileName" style="width: 100%"> </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <!-- Non enterprise Gupshup options -->
          <template v-if="selectedProviderObj && selectedProviderObj.provider_type != 'whatsapp_gupshup_enterprise'">
            <!-- Message Input Box -->
            <el-form-item class="messageField" label="WhatsApp Message" prop="message" v-if="selectedProviderObj && showMessageInputFor.includes(selectedProviderObj.provider_type)">
              <el-input id="messageWhatsAppInput" :disabled="readOnly" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" v-model="whatsAppForm.message"></el-input>
              <el-popover :disabled="readOnly" v-model="whatsAppEmojiPiker" popper-class="customTooltip" style="width: 355px" trigger="click" v-if="!readOnly">
                <picker type="hidden" :showPreview="false" :perLine="20" :exclude="['flags']" @select="whatsAppEmojiSelected" :native="true" set="emojione" />
                <el-button size="small" type="primary" plain class="whatsAppUserIconBtn" slot="reference">
                  <i class="fa fa-smile-o" aria-hidden="true"></i>
                </el-button>
              </el-popover>

              <el-popover :disabled="readOnly" v-model="whatsAppDynmicUserPopver" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                <el-button size="mini" type="primary" plain class="whatsAppUserIconBtn" slot="reference">
                  <i class="el-icon-user-solid" aria-hidden="true"></i>
                </el-button>
              </el-popover>

              <!-- Character Count -->
              <div class="characterCount">{{ whatsAppForm.message.length }} Characters</div>
            </el-form-item>

            <!-- Key-Value Pairs -->
            <el-card class="keyValueContainer" shadow="never" v-if="selectedProviderObj && showKeyValuePairsFor.includes(selectedProviderObj.provider_type)">
              <div slot="header" class="clearfix">
                <span>Template Dynamic Key-Values (Depending on your provider)</span>
              </div>
              <el-row class="keyValueListItem" :gutter="5" v-for="(pair, index) in keyValuePairs" :key="index">
                <el-col :span="9">
                  <el-input :disabled="readOnly" placeholder="Key" v-model="pair.key"></el-input>
                </el-col>
                <el-col class="seperator" :span="1">=</el-col>
                <el-col :span="12" style="position: relative">
                  <el-input :disabled="readOnly" :id="'whatsAppKeyValuePair' + index" v-model="pair.value" placeholder="Value"></el-input>
                  <el-popover :disabled="readOnly" v-model="pair.userPopover" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                    <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setKeyValueDyamicProp(index, ...arguments)"></DynamicUserProperty>
                    <el-button size="mini" type="text" class="whatsAppUserIconBtn userIconBtnForPair" slot="reference">
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-col>
                <el-col class="seperator" :span="2" v-if="!readOnly">
                  <el-button type="danger" size="mini" plain icon="el-icon-delete" circle @click="keyValuePairs.splice(index, 1)"></el-button>
                </el-col>
              </el-row>

              <el-button v-if="!readOnly" type="primary" plain @click="onAddEmptyKeyValuePair">Add Key-Value Pair</el-button>
            </el-card>

            <!-- Show Value List For -->
            <el-card class="keyValueContainer" shadow="never" v-if="selectedProviderObj && showValueListFor.includes(selectedProviderObj.provider_type)">
              <div slot="header" class="clearfix">
                <span>Template Placeholders (Depending on the template)</span>
              </div>
              <el-row class="keyValueListItem" :gutter="5" v-for="(pair, index) in keyValuePairs" :key="index">
                <el-col :span="22" style="position: relative">
                  <el-input :disabled="readOnly" :id="'whatsAppKeyValuePair' + index" v-model="pair.value" placeholder="Enter placeholder text here"></el-input>
                  <el-popover :disabled="readOnly" v-model="pair.userPopover" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                    <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setKeyValueDyamicProp(index, ...arguments)"></DynamicUserProperty>
                    <el-button size="mini" type="text" class="whatsAppUserIconBtn userIconBtnForPair" slot="reference">
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-col>
                <el-col class="seperator" :span="2" v-if="!readOnly">
                  <el-button type="danger" size="mini" plain icon="el-icon-delete" circle @click="keyValuePairs.splice(index, 1)"></el-button>
                </el-col>
              </el-row>

              <el-button v-if="!readOnly" type="primary" plain @click="onAddEmptyKeyValuePair">Add Template Placeholder</el-button>
            </el-card>

            <!-- Header Body Footer Value List -->
            <template v-if="selectedProviderObj && showHeaderBodyFooterFormFor.indexOf(selectedProviderObj.provider_type) >= 0">
              <template v-for="(subType, index) in ['header', 'body', 'footerText', 'footer']">
                <el-card class="keyValueContainer border" shadow="never" :key="index">
                  <div slot="header" class="clearfix">
                    <span class="capitalize">
                      <template v-if="subType == 'header'">Header</template>
                      <template v-if="subType == 'body'">Body</template>
                      <template v-if="subType == 'footerText'">Footer Text</template>
                      <template v-if="subType == 'footer'">Button</template>

                      Variables</span
                    >
                  </div>
                  <div class="flex w-full keyValueListItem" v-for="(pair, index) in headerBodyFooterForm[subType]" :key="index">
                    <div class="flex mr-1.5" v-if="subType == 'footer' && ['whatsapp_dialog360', 'whatsapp_cloud_api'].indexOf(selectedProviderObj.provider_type) < 0">
                      <el-input :disabled="readOnly" type="number" style="width: 100px" v-model="pair.key" placeholder="Button No."></el-input>
                    </div>
                    <div class="flex-auto position-relative">
                      <el-input :disabled="readOnly" :id="'whatsAppHeaderBodyFooterFormForm' + subType + index" v-model="pair.value" placeholder="Enter variable value here"></el-input>
                      <el-popover :disabled="readOnly" v-model="pair.userPopover" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                        <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setKeyValueDyamicPropForHeaderBodyFooterFormForm(index, subType, ...arguments)"></DynamicUserProperty>
                        <el-button size="mini" type="text" class="whatsAppUserIconBtn userIconBtnForPair" slot="reference">
                          <i class="el-icon-user-solid" aria-hidden="true"></i>
                        </el-button>
                      </el-popover>
                    </div>
                    <div class="pl-1.5 pt-1.5" v-if="!readOnly">
                      <el-button type="danger" size="mini" plain icon="el-icon-delete" circle @click="headerBodyFooterForm[subType].splice(index, 1)"></el-button>
                    </div>
                  </div>

                  <el-button v-if="!readOnly" type="primary" size="mini" plain @click="onAddHeaderBodyFooterFormVariable(subType)">Add Variable</el-button>
                </el-card>
              </template>
            </template>
          </template>
        </el-form>

        <!-- Gupshup Enterprise Form -->
        <el-form v-if="selectedProviderObj && selectedProviderObj.provider_type == 'whatsapp_gupshup_enterprise'" :disabled="readOnly" class="detailedWhatsAppForm" ref="whatsAppInputForm" v-bind:class="{ readOnly: readOnly === true }" label-position="top" :model="gupshupEnterpriseForm" :rules="whatsAppFormRules">
          <el-card shadow="never">
            <!-- Message Type -->
            <el-form-item class="messageField mt-0" label="WhatsApp Message Type" prop="messageType">
              <div class="leading-4" v-if="readOnly">{{ gupshupEnterpriseForm.messageType }}</div>
              <el-radio-group v-model="gupshupEnterpriseForm.messageType" size="small" v-else>
                <el-radio label="TEXT" border>Text</el-radio>
                <el-radio label="IMAGE" border>Image</el-radio>
                <el-radio label="VIDEO" border>Video</el-radio>
                <el-radio label="DOCUMENT" border>Document</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-row :gutter="20">
              <template v-if="gupshupEnterpriseForm.messageType.toUpperCase() != 'TEXT'">
                <!-- Media URL -->
                <el-col :span="24" style="position: relative">
                  <el-form-item class="messageField" label="Media URL" prop="mediaUrl">
                    <el-input :disabled="readOnly" id="gupshupEnterpriseForm-mediaUrl" v-model="gupshupEnterpriseForm.mediaUrl" placeholder="Enter Header Text"></el-input>
                    <el-popover :disabled="readOnly" v-model="gupshupEnterprisePopovers.mediaUrl" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                      <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDynamicPropOnTarget('gupshupEnterpriseForm-mediaUrl', gupshupEnterpriseForm, 'mediaUrl', ...arguments)"></DynamicUserProperty>
                      <el-button size="mini" type="text" class="whatsAppUserIconBtn userIconBtnForPair" slot="reference">
                        <i class="el-icon-user-solid" aria-hidden="true"></i>
                      </el-button>
                    </el-popover>
                  </el-form-item>
                </el-col>
              </template>

              <!-- Header -->
              <el-col :span="12" style="position: relative">
                <el-form-item class="messageField" label="WhatsApp Header" prop="header">
                  <el-input :disabled="readOnly" id="gupshupEnterpriseForm-header" v-model="gupshupEnterpriseForm.header" placeholder="Enter Header Text"></el-input>
                  <el-popover :disabled="readOnly" v-model="gupshupEnterprisePopovers.header" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                    <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDynamicPropOnTarget('gupshupEnterpriseForm-header', gupshupEnterpriseForm, 'header', ...arguments)"></DynamicUserProperty>
                    <el-button size="mini" type="text" class="whatsAppUserIconBtn userIconBtnForPair" slot="reference">
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-form-item>
              </el-col>
              <!-- Footer -->
              <el-col :span="12" style="position: relative">
                <el-form-item class="messageField" label="WhatsApp Footer" prop="footer">
                  <el-input id="gupshupEnterpriseForm-footer" v-model="gupshupEnterpriseForm.footer" placeholder="Enter Footer Text"></el-input>
                  <el-popover v-model="gupshupEnterprisePopovers.footer" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                    <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDynamicPropOnTarget('gupshupEnterpriseForm-footer', gupshupEnterpriseForm, 'footer', ...arguments)"></DynamicUserProperty>
                    <el-button size="mini" type="text" class="whatsAppUserIconBtn userIconBtnForPair" slot="reference">
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-form-item>
              </el-col>

              <!-- Message Body -->
              <el-col :span="24" style="position: relative">
                <el-form-item class="messageField" label="WhatsApp Message Body" prop="body">
                  <el-input id="gupshupEnterpriseForm-body" type="textarea" :autosize="{ minRows: 4, maxRows: 20 }" v-model="gupshupEnterpriseForm.body" placeholder="Enter WhatsApp Message Body"></el-input>
                  <el-popover v-model="gupshupEnterprisePopovers.body" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                    <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDynamicPropOnTarget('gupshupEnterpriseForm-body', gupshupEnterpriseForm, 'body', ...arguments)"></DynamicUserProperty>
                    <el-button size="mini" type="text" class="whatsAppUserIconBtn userIconBtnForPair" slot="reference">
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-form-item>
              </el-col>

              <!-- Buttons Input -->
              <el-col :span="24" class="pt-8">
                <el-checkbox v-model="gupshupEnterpriseForm.hasButtons">This template has buttons</el-checkbox>
                <el-form-item v-if="gupshupEnterpriseForm.hasButtons" class="messageField mt-0" label="Enter Button Dynamic URL (If applicable)" prop="buttonDynamicURL">
                  <el-input id="gupshupEnterpriseForm-buttonDynamicURL" v-model="gupshupEnterpriseForm.buttonDynamicURL" placeholder="Enter WhatsApp Message Body"></el-input>
                  <el-popover v-model="gupshupEnterprisePopovers.buttonDynamicURL" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                    <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDynamicPropOnTarget('gupshupEnterpriseForm-buttonDynamicURL', gupshupEnterpriseForm, 'buttonDynamicURL', ...arguments)"></DynamicUserProperty>
                    <el-button size="mini" type="text" class="whatsAppUserIconBtn userIconBtnForPair" slot="reference">
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- <el-button v-if="!readOnly" type="primary" plain @click="onAddEmptyKeyValuePair">Add Template Placeholder</el-button> -->
          </el-card>
        </el-form>

        <!-- Short Liks -->
        <el-card class="shortLinksContainer" shadow="never">
          <div slot="header" class="clearfix shortLinksHeader">
            <div class="titleSection">
              <span>Campaign Short Links</span>
              <template v-if="!readOnly">
                <el-button size="mini" type="primary" plain class="generateLinkButton" @click="showShortLinkPopup">Create Short Link</el-button>
                <ShortLinkPopup channel="whatsApp" :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" ref="shortLinkPopup" @newlink="onAddShortLink"></ShortLinkPopup>
              </template>
            </div>
          </div>
          <template v-if="whatsAppForm.shortLinks.length > 0">
            <div class="shortLink" v-for="(link, index) in whatsAppForm.shortLinks" :key="index">
              <div class="header">
                <div class="headerText">{{ link.code }} {{ link.onlyCode ? ' - Only Short Code' : '' }}</div>
                <el-popconfirm v-if="!readOnly" class="delete-icon" confirmButtonText="Delete" @confirm="onDeleteShortLink(index)" cancelButtonText="Cancel" icon="el-icon-info" iconColor="red" title="Delete this link?">
                  <el-button type="danger" icon="el-icon-delete" slot="reference" size="mini" plain circle></el-button>
                </el-popconfirm>
              </div>
              <div class="url">{{ link.originalUrl }}</div>
            </div>
          </template>
          <div class="no-data" v-else>No short links created yet.</div>
        </el-card>
      </el-col>

      <!-- Right Side Preview -->
      <el-col :span="8">
        <div ref="mobilePreview" class="rightSidePreview">
          <el-button class="btnSendSmsMsg" size="mini" type="primary" @click="showTestPopup()"> Send Test Message </el-button>

          <!-- Mobile Preview -->
          <div class="mobileDiv">
            <!-- Sms Header -->
            <div class="smsHeader">
              <i class="el-icon-arrow-left"></i>
              <div class="headerText">Your Brand</div>
            </div>
            <!-- Notification Content -->
            <el-row class="smsContent">
              <el-col :span="24" class="contentContiner" v-if="selectedProviderObj && showMessageInputFor.includes(selectedProviderObj.provider_type)">{{ whatsAppMessagePreview }}</el-col>
              <el-col :span="24" class="contentContiner" v-else>Message preview is not available for given provider.</el-col>
            </el-row>

            <!-- SMS Footer -->
            <div class="smsFooter" v-if="selectedProviderObj && showMessageInputFor.includes(selectedProviderObj.provider_type)">
              {{ whatsAppMessagePreview.length }} Characters
              <div class="footerNote">Actual character count will very if you are using dynamic message attributes.</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- Send Test WhatsApp Dialog  -->
    <el-dialog append-to-body class="whatsAppTestDailog" :visible.sync="showWhatsAppTestPopup" width="30%" center title="Send Test WhatsApp Message">
      <el-form :model="whatsAppTestForm" ref="pushNotificationTestForm" @submit.native.prevent>
        <div class="mobileNumbarHeader">Enter WhatsApp Mobile Number</div>
        <el-form-item>
          <el-input class="countryCode" placeholder="91" v-model="whatsAppTestForm.countryCode" type="age" autocomplete="on"></el-input>
          <el-input class="numberInput" placeholder="Enter WhatsApp Mobile Number" v-model="whatsAppTestForm.mobileNumber" type="age" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button class="sendBtn" :loading="sendingTestWhatsApp" @click="sendTestMessage()" type="primary">Send Test Message</el-button>
          <el-button class="sendBtn" :loading="sendingTestWhatsApp" @click="showWhatsAppTestPopup = false" type="danger" plain>Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- Template Selector Popup -->
    <WhatsAppTemplateSelectorPopup ref="templateSelectPopup" @onTemplateSelect="onTemplateChange" />
  </div>
</template>

<style lang="scss" src="./whatsAppEditor.scss"></style>

<script>
import WhatsAppEditor from './whatsAppEditorComponent';
export default WhatsAppEditor;
</script>
