<template>
  <table border="0" cellpadding="0" cellspacing="0" class="blockTable" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
    <tr>
      <td class="mailFooterBlock" style="padding: 0px !important">
        <!-- Header Type 1 -->
        <template v-if="content.template == 'white-footer-1' || content.template == 'dark-footer-1'">
          <table class="mail-footer-1" cellpadding="0" cellspacing="0" border="0" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
            <!-- Image -->
            <tr v-if="content.logo.enabled">
              <td class="mailFooterLogo" align="center" valign="top" width="100%" style="text-align: center">
                <a :href="content.logo.url">
                  <img :src="content.logo.image" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name : 'Logo'" :height="content.logo.height" style="margin: auto" :style="{ height: content.logo.height + 'px' }" />
                </a>
              </td>
            </tr>

            <!-- Address -->
            <template v-if="content.address.enabled">
              <tr>
                <td align="center" valign="top" style="text-align: center">
                  <DragDropEmailTextBlock :content="content.address"></DragDropEmailTextBlock>
                </td>
              </tr>
            </template>

            <!-- Social Icons -->
            <template v-if="content.socialLinks.enabled">
              <tr>
                <td class="mailFooterSocialLinks" align="right" valign="middle" style="text-align: center">
                  <template v-for="(social, index) in content.socialLinks.list">
                    <span :key="index">
                      <a :href="social.url" style="display: inline-block" :style="{ 'line-height': content.socialLinks.height + 0 + 'px !important' }">
                        <img :src="social.image" :height="content.socialLinks.height" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name + ' Social Icon' : 'Social Icon'" style="margin: auto" :style="{ height: content.socialLinks.height + 'px' }" />
                      </a>
                      <span style="color: transparent !important" v-if="index != content.socialLinks.list.length - 1"> &nbsp;&nbsp; </span>
                    </span>
                  </template>
                </td>
              </tr>
            </template>

            <!-- App Store Icons -->
            <template v-if="content.appStoreLinks.enabled">
              <tr>
                <td style="line-height: 20px; color: transparent !important">&nbsp;</td>
              </tr>
              <tr>
                <td class="mailFooterSocialLinks" align="right" valign="middle" style="text-align: center">
                  <template v-for="(store, index) in content.appStoreLinks.list">
                    <span :key="index">
                      <a v-if="store.enabled" :href="store.url" style="display: inline-block">
                        <img style="display: inline-block" :src="store.image" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name + ' App Store Icon ' : 'App Store Icon'" :height="content.appStoreLinks.height" :style="{ height: content.appStoreLinks.height + 'px !important', width: 'auto  !important' }" />
                      </a>
                      <span style="color: transparent !important" v-if="index != content.appStoreLinks.list.length - 1"> &nbsp;&nbsp; </span>
                    </span>
                  </template>
                </td>
              </tr>
            </template>

            <!-- Unsubscribe Text -->
            <tr>
              <td valign="middle">
                <DragDropEmailTextBlock :content="content.unsubscribe"></DragDropEmailTextBlock>
              </td>
            </tr>
          </table>
        </template>

        <!-- Header Type 2 -->
        <template v-if="content.template == 'white-footer-2' || content.template == 'dark-footer-2'">
          <table class="mail-footer-2" cellpadding="0" cellspacing="0" border="0" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
            <!-- Social Icons -->
            <template v-if="content.socialLinks.enabled">
              <tr>
                <td class="mailFooterSocialLinks" align="right" valign="middle" style="text-align: center">
                  <template v-for="(social, index) in content.socialLinks.list">
                    <span :key="index">
                      <a :href="social.url" style="display: inline-block" :style="{ 'line-height': content.socialLinks.height + 0 + 'px !important' }">
                        <img :src="social.image" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name + ' Social Icon' : 'Social Icon'" :height="content.socialLinks.height" style="margin: auto" :style="{ height: content.socialLinks.height + 'px' }" />
                      </a>
                      <span style="color: transparent !important" v-if="index != content.socialLinks.list.length - 1"> &nbsp;&nbsp; </span>
                    </span>
                  </template>
                </td>
              </tr>
            </template>

            <!-- App Store Icons -->
            <template v-if="content.appStoreLinks.enabled">
              <tr>
                <td style="line-height: 20px; color: transparent !important">&nbsp;</td>
              </tr>
              <tr>
                <td class="mailFooterSocialLinks" align="right" valign="middle" style="text-align: center">
                  <template v-for="(store, index) in content.appStoreLinks.list">
                    <span :key="index">
                      <a v-if="store.enabled" :href="store.url" :key="index" style="display: inline-block">
                        <img style="display: inline-block" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name + ' App Store' : 'App Store'" :src="store.image" :height="content.appStoreLinks.height" :style="{ height: content.appStoreLinks.height + 'px !important', width: 'auto  !important' }" />
                      </a>
                      <span style="color: transparent !important" v-if="index != content.appStoreLinks.list.length - 1"> &nbsp;&nbsp; </span>
                    </span>
                  </template>
                </td>
              </tr>
            </template>

            <!-- Address -->
            <template v-if="content.address.enabled">
              <tr>
                <td align="center" valign="top" style="text-align: center">
                  <DragDropEmailTextBlock :content="content.address"></DragDropEmailTextBlock>
                </td>
              </tr>
            </template>

            <!-- Image -->
            <tr v-if="content.logo.enabled">
              <td class="mailFooterLogo" align="center" valign="top" width="100%" style="text-align: center">
                <a :href="content.logo.url">
                  <img :src="content.logo.image" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name + ' Footer Logo' : 'Footer Logo'" :height="content.logo.height" style="margin: auto" :style="{ height: content.logo.height + 'px' }" />
                </a>
              </td>
            </tr>

            <!-- Unsubscribe Text -->
            <tr>
              <td valign="middle">
                <DragDropEmailTextBlock :content="content.unsubscribe"></DragDropEmailTextBlock>
              </td>
            </tr>
          </table>
        </template>

        <!-- Header Type 3 -->
        <template v-if="content.template == 'white-footer-3' || content.template == 'dark-footer-3'">
          <table class="mail-footer-3" cellpadding="0" cellspacing="0" border="0" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
            <tr>
              <td width="25" style="color: transparent !important" class="hide-on-mobile">&nbsp;</td>
              <td width="50%" class="mailFooterLeftSide">
                <div v-if="content.logo.enabled" align="left" valign="top" width="100%" style="text-align: left">
                  <a style="display: inline" :href="content.logo.url">
                    <img :src="content.logo.image" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name + ' Footer Logo' : 'Footer Logo'" :height="content.logo.height" :style="{ height: content.logo.height + 'px' }" />
                  </a>
                </div>
                <DragDropEmailTextBlock :content="content.address"></DragDropEmailTextBlock>
              </td>
              <td width="50%" class="mailFooterSocialLinks" align="right" valign="middle" style="text-align: center">
                <div v-if="content.socialLinks.enabled">
                  <template v-for="(social, index) in content.socialLinks.list">
                    <span :key="index">
                      <a :href="social.url" style="display: inline-block" :style="{ 'line-height': content.socialLinks.height + 0 + 'px !important' }">
                        <img :src="social.image" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name + ' Social Icon' : 'Social Icon'" :height="content.socialLinks.height" style="margin: auto" :style="{ height: content.socialLinks.height + 'px' }" />
                      </a>
                      <span style="color: transparent !important" v-if="index != content.socialLinks.list.length - 1"> &nbsp;&nbsp; </span>
                    </span>
                  </template>
                </div>
                <div v-if="content.appStoreLinks.enabled">
                  <div style="color: transparent !important" v-if="content.socialLinks.enabled">&nbsp;</div>
                  <div class="mailFooterSocialLinks" align="right" valign="middle" style="text-align: center">
                    <template v-for="(store, index) in content.appStoreLinks.list">
                      <span :key="index">
                        <a v-if="store.enabled" :href="store.url" :key="index" style="display: inline-block">
                          <img style="display: inline-block" :src="store.image" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name + ' App Store' : 'App Store'" :height="content.appStoreLinks.height" :style="{ height: content.appStoreLinks.height + 'px !important', width: 'auto  !important' }" />
                        </a>
                        <span style="color: transparent !important" v-if="index != content.appStoreLinks.list.length - 1"> &nbsp;&nbsp; </span>
                      </span>
                    </template>
                  </div>
                </div>
              </td>
            </tr>

            <!-- Unsubscribe Text -->
            <tr>
              <td valign="middle" colspan="3">
                <DragDropEmailTextBlock :content="content.unsubscribe"></DragDropEmailTextBlock>
              </td>
            </tr>
          </table>
        </template>
      </td>
    </tr>
  </table>
</template>

<script>
import DragDropEmailTextBlock from './dragDropEmailTextBlock';

export default {
  name: 'dragDropEmailFooterBlock',
  props: {
    content: {
      type: Object,
      required: true
    },
    brandInfo: {
      type: Object,
      required: true
    }
  },
  components: {
    DragDropEmailTextBlock
  },
  data() {
    return {};
  },

  methods: {},
  created() {}
};
</script>
