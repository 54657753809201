import { Picker } from 'emoji-mart-vue';
import DynamicUserProperty from '../../../../dynamicUserFilterComponent/dynamicUserFilter';
import campaignPreviewService from '@/services/campaignMessagePreview';
import _ from 'lodash';
import customerModule from '../../../../../services/customer';

export default {
  name: 'WhatsAppEditor',
  components: {
    Picker,
    DynamicUserProperty
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    allowDynamicEvents: {
      type: Boolean,
      required: false,
      default: true
    },
    isJourneyCampaign: {
      required: true,
      type: Boolean
    }
  },
  watch: {},
  data() {
    let initialEmailList = [];
    if (this.$store.state.token.email) {
      initialEmailList.push(this.$store.state.token.email);
    }

    return {
      customChannelRawBodyUserPopver: false,

      form: {
        httpMethod: 'POST',
        url: '',
        bodyType: 'json',
        queryParams: [],
        bodyParams: [],
        rawBody: '',
        headers: []
      },

      formRules: {
        url: [
          { required: true, message: 'URL is required.', trigger: 'blur' },
          { min: 1, max: 5000, message: 'URL can not exceed 5000 characers.', trigger: 'blur' }
        ]
      },
      sendingTestMessage: false,

      // Send Test Message Variables
      userAttributeToSearchBy: 'email',
      showPushNotificationTestPopup: false,
      selectedUserList: [],
      queryString: '',
      inputOfUser: ''
    };
  },

  methods: {
    setKeyValueDyamicProp(elementId, arr, index, value) {
      // Fetch Current Position
      let position = document.getElementById(elementId).selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      arr[index].value = insert(arr[index].value, position, value);
      arr[index].userPopover = false;
    },

    setDyamicProp(elementId, obj, key, value) {
      // Fetch Current Position
      let position = document.getElementById(elementId).selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      obj[key] = insert(obj[key], position, value);
      this.customChannelRawBodyUserPopver = false;
    },

    readProcessedContentV1() {
      return {
        content: {
          custom_channel_content: JSON.stringify(this.form)
        },
        channelConfig: null,
        providerId: null
      };
    },

    async sendTestMessage() {
      try {
        let isValid = await this.validateForm();
        if (!isValid) return;

        this.sendingTestMessage = true;

        let result = await campaignPreviewService.sendCustomApiCampaignPreview(this.form);
        if (result.data.statusCode == 200) {
          this.successToast(`Message sent.`);
          this.showWhatsAppTestPopup = false;
        } else {
          this.errorToast(`Failed to send message. ${result.data.statusCode}-${result.data.statusMessage}`);
        }
        this.sendingTestMessage = false;
      } catch (e) {
        console.error(e);
        this.errorToast(`Something went wrong.`);
        this.sendingTestMessage = false;
      }
    },

    /**************************** Start: Push Notification Test Methods *********************************/

    async showSendTestNotificationDilog() {
      // Validate input first.
      let isValid = await this.validateForm();
      if (!isValid) {
        this.errorToast('Validation Error. Please resolve validation issues.');
        return;
      }
      this.showPushNotificationTestPopup = true;
    },

    async testPushNotificationContent() {
      let params = {
        customerList: this.selectedUserList,
        content: this.form
      };

      this.sendingTestNotification = true;
      campaignPreviewService
        .sendCustomApiCampaignPreview(params)
        .then((result) => {
          if (result.data.success) {
            this.successToast(`Push notification sent.`);
            this.showPushNotificationTestPopup = false;
          } else if (result.data.message) {
            this.errorToast(result.data.message);
          }
          this.sendingTestNotification = false;
        })
        .catch((e) => {
          this.reportError(e);
          this.errorToast(`Something went wrong.`);
          this.sendingTestNotification = false;
        });
    },

    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },

    fetchUserListForPushContentTest(queryString, cb) {
      console.log('calling search api');
      let params = {
        queryString: queryString,
        customerProp: this.userAttributeToSearchBy,
        pushType: 'mobile'
      };

      let results = [];
      customerModule
        .searchCustomerForCampaignMessageSend(params, this)
        .then((result) => {
          if (result.data.success) {
            results = queryString ? result.data.data.filter(this.createFilter(queryString)) : result.data.data;
          }
          cb(results);
        })
        .catch((e) => {
          this.reportError(e);
          this.errorToast(`Something went wrong. Please try again later.`);
          cb(results);
        });
    },

    updateSelectedUserList(item) {
      let user = _.find(this.selectedUserList, (u) => {
        return u.id == item.id;
      });
      if (!user) {
        this.selectedUserList.push(item);
      }
      this.inputOfUser = '';
    },

    /**************************** End: Push Notification Test Methods *********************************/

    async validateForm() {
      return new Promise((resolve) => {
        this.$refs['customChannelInputForm'].validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    async renderCampaignContent(data) {
      this.form = JSON.parse(data.content.custom_channel_content);
    },

    renderCampaignContentForJourney(data) {
      this.renderCampaignContent(data);
    }
  },

  mounted() {}
};
