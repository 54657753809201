<template>
  <el-drawer append-to-body="" :with-header="false" custom-class="messageSentLogsDialog" direction="rtl" :visible.sync="visible" size="1200px">
    <!-- Loader -->
    <div v-if="loader" class="loader" v-loading="true"></div>

    <template v-else>
      <el-container class="mainContainer" v-if="logs.length > 0">
        <el-aside class="leftSide">
          <div class="headerDiv">Last 20 Sent Messages</div>
          <div class="itemsContainer">
            <div class="logItem" v-for="(item, index) in logs" :class="{ selectedItem: index == selectedIndex }" :key="index" @click="onSelectMessage(index)">
              {{ getFormattedTimestamp(item.created_at_date) }}
              &nbsp;&nbsp;
              <!-- <i class="el-icon-user-solid"></i> -->
              <el-link :href="`/customer/detail?id=${item.customer_id}`" :underline="false" target="_blank"><i class="el-icon-user-solid"></i></el-link>
            </div>
          </div>
        </el-aside>

        <el-container class="rightSide">
          <template v-if="selectedMessage">
            <div class="emailContent" v-if="selectedMessage.channel == 'email'">
              <div class="emailSubject">{{ selectedMessage.message.subject }}</div>
              <div class="emailContent">
                <iframe class="htmlIframe" :srcdoc="selectedMessage.message.html"></iframe>
              </div>
            </div>
            <div class="smsContent" v-else-if="selectedMessage.channel == 'sms'">
              <div class="contentHeader">
                <div class="headerContent">SMS Content</div>
              </div>
              <div class="contentHolder">
                {{ selectedMessage.message }}
              </div>
            </div>
            <div class="whatsAppContent" v-else-if="selectedMessage.channel == 'whatsApp'">
              <div class="contentHeader">
                <div class="headerContent">WhatsApp Message</div>
              </div>
              <div class="contentHolder">
                {{ selectedMessage.message }}
              </div>
            </div>
            <div class="webPushContent" v-else-if="selectedMessage.channel == 'webPush'">
              <div class="contentHeader">
                <div class="headerTitle">Notification Link (Opened when notification clicked)</div>
                <div class="headerContent">
                  {{ selectedMessage.message.glytcs_dl }}
                </div>
              </div>

              <div class="notification chromeWindows10">
                <div class="notifImageContainer">
                  <div class="notifImage" v-if="selectedMessage.message.glytcs_img" :style="{ 'background-image': 'url(' + selectedMessage.message.glytcs_img + ')' }"></div>
                </div>
                <div class="body">
                  <div class="icon" v-if="selectedMessage.message.glytcs_icn" :style="{ 'background-image': 'url(' + selectedMessage.message.glytcs_icn + ')' }"></div>
                  <div class="icon" v-else></div>
                  <div class="content">
                    <div class="title">{{ selectedMessage.message.glytcs_tl }}</div>
                    <div class="message">{{ selectedMessage.message.glytcs_msg }}</div>
                    <div class="webSiteLink">
                      <span v-if="selectedMessage.message.glytcs_icn">Google Chrome &middot;</span>
                      yourwebsite.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-container>
      </el-container>

      <div v-else class="noDataDiv">No messages sent yet.</div>
    </template>

    <el-button class="closeBtn" @click="visible = false" icon="el-icon-close" type="text"></el-button>
  </el-drawer>
</template>

<style lang="scss" src="./messageSentLog.scss"></style>

<script>
import messageSentLogComponent from './messageSentLogComponent';
export default messageSentLogComponent;
</script>
