import companyModule from '@/services/company';
import eventModule from '@/services/event';

export default {
  name: 'DefaultEvents',
  components: {},
  data() {
    return {
      fetchingData: true,
      savingData: false,
      popupVisible: false,

      revenueEventInfo: {
        event: '',
        property: ''
      },

      revenueEventList: null,
      revenueEventMetadata: null,
      revenuePropertyList: null,
      savingRevenueEvents: false
    };
  },

  methods: {
    //#region ----------------------------- Start: Revenue Tracking Events --------------------

    // Fetch event list from server
    async fetchRevenueEventList() {
      try {
        let result = await eventModule.getEventList(this);

        this.revenueEventList = [];
        let listFromServer = Object.keys(result.data.data.eventList);
        for (var i = 0; i < listFromServer.length; i++) {
          if (this.grwDefaultEventList.indexOf(listFromServer[i]) < 0) {
            this.revenueEventList.push(listFromServer[i]);
          }
        }

        // Remove all events which are hard coded growlytics events.
        this.revenueEventMetadata = result.data.data.eventList;
        this.fetchingData = false;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Something went wrong.');
        this.fetchingData = false;
      }
    },

    onRevenueEventSelect(dontClearProperty) {
      this.revenueEvent = {
        name: this.revenueEventInfo.event,
        property: null,
        groupBy: null,
        filters: []
      };
      this.revenuePropertyList = this.revenueEventMetadata[this.revenueEventInfo.event];
      if (!dontClearProperty) {
        this.revenueEventInfo.property = null;
      }
    },

    async saveRevenueTrackingEvents() {
      // Validation first.
      if (!this.revenueEventInfo.event) {
        // Validate Revenue Event
        this.warningToast('Please select revenue event.');
        return;
      } else if (!this.revenueEventInfo.property) {
        // Validate Revenue Property
        this.warningToast('Please select revenue property.');
        return;
      }

      // Make request to save data
      this.savingRevenueEvents = true;

      try {
        let params = {
          event_type: 'revenue',
          event: this.revenueEventInfo.event,
          property: this.revenueEventInfo.property
        };
        await companyModule.saveDefaultEvents(params);
        this.successToast('Saved revenue tracking changes.');
        this.savingRevenueEvents = false;
        this.popupVisible = false;
        this.$emit('changedRevenueTracking');
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Failed to save changes. Something went wrong.`);
        this.sendingTestNotification = false;
        this.savingRevenueEvents = false;
      }
    },

    //#endregion ----------------------------- End: Revenue Tracking Events --------------------

    async fetchDefaultEventSettings() {
      this.fetchingData = true;
      try {
        let result = await companyModule.getDefaultEventSettings('revenue');
        if (result && result.data) {
          this.revenueEventInfo = this.overrideJson(this.revenueEventInfo, result.data);
          if (this.revenueEventInfo.event) this.onRevenueEventSelect(true);
        }
        this.fetchingData = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Something went wrong.`);
      }
    },

    async showPopup() {
      this.popupVisible = true;
      await this.fetchRevenueEventList();
      this.fetchDefaultEventSettings();
    }
  }
};
