<template>
  <div id="dynamicUserFilterPage">
    <el-cascader-panel v-model="selectedDynamicProp" @change="onSelectProp" filterable :options="dynamicGrwProperty"></el-cascader-panel>
  </div>
</template>

<style lang="scss" src="./dynamicUserFilter.scss"></style>

<script>
import dynamicUserFilterComponent from './dynamicUserFilterComponent';
export default dynamicUserFilterComponent;
</script>
