import { fetchCampaignUserStats } from '../../../services/campaign';
const moment = require('moment');

export default {
  name: 'campaignReportCustomerList',

  data() {
    return {
      dialogVisible: false,
      campaignId: null,

      // Loader Variables
      fetchingData: false,

      // Campaign Message response pop up
      campaignMsgResData: [],
      currentPage: 1,
      currentPageSize: 50,
      total: null
    };
  },

  methods: {
    getCustomerListLink(customerId) {
      return '/customer/detail?id=' + customerId;
    },

    showPopup() {
      this.fetchingData = false;

      this.campaignMsgResData = [];
      this.currentPage = 1;
      this.currentPageSize = 50;
      this.total = null;

      this.dialogVisible = true;
      this.showCampaignCustomerList();
    },

    handleSizeChange(val) {
      this.currentPage = 1;
      this.currentPageSize = val;
      this.total = null;
      this.showCampaignCustomerList();
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.showCampaignCustomerList();
    },

    async showCampaignCustomerList() {
      this.fetchingData = true;
      this.showCampaignStatusDailog = true;

      // Build params.
      let params = {
        campaignId: this.campaignId,
        status: 'failed',
        pageNumber: this.currentPage,
        pageSize: this.currentPageSize
      };

      try {
        // Fetch list
        let result = await fetchCampaignUserStats(params);
        this.campaignMsgResData = result.data.list;
        this.total = result.data.count;

        for (var i = 0; i < this.campaignMsgResData.length; i++) {
          this.campaignMsgResData[i].created_at = this.getDisplayTime(this.campaignMsgResData[i].created_at);
        }
        this.fetchingData = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Something went wrong.`);
        this.fetchingData = false;
      }
    },

    getDisplayTime(date) {
      if (!date) {
        return `N/A`;
      }
      var dt = moment(date, 'x');
      return dt.format('DD-MMM-YYYY hh:mm:ss A');
    }
  },

  mounted() {
    this.campaignId = this.$route.params.id;
    this.showCampaignCustomerList();
  }
};
