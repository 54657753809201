import campaignPreviewService from '@/services/campaignMessagePreview';
import BeatLoader from '@/components/loaders/BeatLoader.vue';
import campaignService from '@/services/campaignv1';

export default {
  name: 'sendTestSmsPopup',
  components: {
    BeatLoader
  },
  props: {},
  data() {
    return {
      visible: false,
      mode: 'submit',

      smsTestForm: {
        countryCode: '91',
        mobileNumber: ''
      },

      sendingTestSms: false,
      inputOfUser: '',

      smsContent: null,

      testMessageId: null,
      sendTestResponse: null,
      ackCheckInterval: null,
      acknowledgementResponse: null
    };
  },

  methods: {
    async sendTestSms() {
      try {
        if (!this.smsTestForm.mobileNumber) {
          this.warningToast('Please enter mobile number');
          return;
        }

        this.sendingTestSms = true;
        this.testMessageId = this.generateUUID() + '_TEST';

        let params = this.smsContent;
        params.to = [this.smsTestForm.mobileNumber];
        params.messageId = this.testMessageId;
        let result = await campaignPreviewService.sendSmsCampaignPreview(params);
        result = result.data;
        if (result.response.success) {
          if (result.meta.ackAllowed) {
            this.mode = 'test-response';
            // Set interval to check messages every 3 secs.
            this.ackCheckInterval = setInterval(() => {
              this.checkForResponse();
            }, 3000);
          } else {
            this.successToast(`Request submitted to SMS vendor.`);
            this.visible = false;
          }

          this.sendingTestSms = false;
        } else {
          let failureMsg = result.response.statusCode + ' - ' + result.response.statusMessage;
          this.errorToast('Failed to send message - ' + failureMsg);
        }
        this.sendingTestSms = false;
      } catch (e) {
        console.error(e);
        this.errorToast(`Something went wrong.`);
        this.sendingTestSms = false;
      }
    },

    showPopup(smsContent) {
      console.log('Hey... show popup', smsContent);

      // Clear previous state
      this.mode = 'submit';
      this.smsTestForm.countryCode = '91';
      this.smsTestForm.mobileNumber = '';

      this.sendingTestSms = false;
      this.smsContent = smsContent;

      this.testMessageId = null;
      this.sendTestResponse = null;
      this.ackCheckInterval = null;
      this.acknowledgementResponse = null;

      this.visible = true;
    },

    async checkForResponse() {
      try {
        console.log('Checking for response');
        // this.testMessageId = '13d36d74-0562-4aff-8cfa-20bdaf037cda_TEST';
        let params = {
          id: this.testMessageId
        };
        console.log('Params', params);
        let result = await campaignService.checkForMessageAcknowledgement(params);
        if (result && result.data.entry) {
          clearInterval(this.ackCheckInterval);
          this.acknowledgementResponse = result.data.entry;
        }
      } catch (err) {
        console.error('Failed to check message acknowledgement.');
      }
    }
  },
  mounted() {}
};
