<template>
  <div class="oneColumnDashboardComponent">
    <div class="numbers">{{ getNumberWithComma(data.value ? data.value : 0) }}</div>
    <div class="title">{{ data.title }}</div>
  </div>
</template>

<style lang="scss" src="./oneColumnNumber.scss"></style>

<script>
import OneColumnNumberComponent from './oneColumnNumberComponent';
export default OneColumnNumberComponent;
</script>
