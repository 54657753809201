<template>
  <el-dialog append-to-body custom-class="journeyTemplateListDialog" :visible.sync="visible" width="1280px">
    <!-- Header -->
    <div slot="title" class="dialog-header">
      Select Journey Template
      <div class="headerRightSide">
        <!-- <el-button size="small" type="success" @click="">Create Journey From Scratch</el-button> -->
      </div>
    </div>

    <div v-if="showLoader" class="loader" v-loading="true"></div>

    <template v-else>
      <!-- Template List -->
      <div class="templateList" v-if="selectedTemplate == null">
        <!-- Left Side Filters -->
        <div class="leftSide">
          <div class="filterContainer">
            <div class="filterHeader">Type</div>
            <div class="filterItems">
              <el-checkbox-group v-model="selectedJourneyTypes" @change="applyFilter">
                <el-checkbox v-for="(typeValue, typeKey) in journeyTypeFilters" :label="typeKey" :key="typeKey">{{ typeValue }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="filterContainer">
            <div class="filterHeader">Goal</div>
            <div class="filterItems">
              <el-checkbox-group v-model="selectedJourneyGoals" @change="applyFilter">
                <el-checkbox v-for="(typeValue, typeKey) in journeyGoalFilters" :label="typeKey" :key="typeKey">{{ typeValue }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>

        <!-- Right Side -->
        <div class="rightSide">
          <el-row class="templateCategory" :gutter="12" v-for="(categoryTemplates, categoryName) in journeyTemplateList" :key="categoryName">
            <el-col :span="24" class="categoryNameDiv">
              {{ categoryName }}
            </el-col>
            <el-col class="elColumn" :span="8" v-for="(template, index) in categoryTemplates" :key="index">
              <div class="templateItem" @click="selectedTemplate = template">
                <div class="templateInfo">
                  <div class="title">{{ template.title }}</div>
                  <div class="subTitle">{{ template.subTitle }}</div>
                  <div class="description">{{ template.description }}</div>
                  <!-- <div class="tagsContainer">
                    <div class="tagItem" v-for="(tag, index) in template.tags" :key="index">
                      {{ tag }}
                    </div>
                  </div> -->
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- Template Preview -->
      <div class="templatePreview" v-else>
        <div class="previewHeader">
          <i class="el-icon-back" style="cursor: pointer" @click="selectedTemplate = null"></i> &nbsp;
          <span>{{ selectedTemplate.title }}</span>
          <div style="display: inline-block; padding-left: 30px">
            <el-button type="primary" size="small" @click="onTemplateSelected">Use This Template</el-button>
          </div>
        </div>

        <!-- Template Description -->
        <div class="templateDescription">
          {{ selectedTemplate.description }}
          <el-row>
            <el-col :span="6">Journey Time: {{ selectedTemplate.previewInfo.time }}</el-col>
            <el-col :span="16">Campaigns: {{ selectedTemplate.previewInfo.campaigns }}</el-col>
          </el-row>
        </div>

        <!-- Template Image -->
        <div class="templateImage">
          <el-image style="width: 100%" :src="selectedTemplate.image" fit="scale-down"></el-image>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" src="./journeyTemplates.scss"></style>

<script>
import journeyTemplatesComponent from './journeyTemplatesComponent';
export default journeyTemplatesComponent;
</script>
