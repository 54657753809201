<template>
  <div class="campaignCustomerListPage">
    <div class="loader" v-if="fetchingData" v-loading="true"></div>

    <template v-else>
      <el-tabs type="border-card" class="campaignCustomerListTabs rounded-lg overflow-hidden shadow-none" v-model="selectedTab" @tab-click="onTabClicked()">
        <el-tab-pane label="Delivered" name="delivered" class="nopadding">
          <div class="mb-2" style="height: 30px">
            <div class="float-left text-md text-gray-600 text-md pt-1">{{ getNumberWithComma(total) }} Delivered</div>
            <div class="float-right">
              <el-button type="primary" plain size="mini" icon="el-icon-download" @click="downloadData">Download</el-button>
            </div>
          </div>
          <template>
            <el-table :data="list" border style="width: 100%">
              <el-table-column prop="name" label="Customer Name" width="250px">
                <template slot-scope="scope">
                  <router-link class="detailsLink text-primary-700" :to="getCustomerListLink(scope.row.id)" target="_blank">
                    <span>{{ scope.row.name ? scope.row.name : 'Name Not Available' }}</span>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="email" label="Email" width="300px"> </el-table-column>
              <el-table-column prop="mobile" label="Mobile" width="250px"> </el-table-column>
              <el-table-column prop="created_at" label="Time"> </el-table-column>
            </el-table>
          </template>
          <div class="pagination pagination-bottom">
            <div class="totalCount">Total {{ total }} Messages</div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[50, 100, 200]" :page-size="currentPageSize" layout="sizes, prev, pager, next, jumper" :total="total"></el-pagination>
          </div>
        </el-tab-pane>

        <el-tab-pane label="Skipped" name="skipped" class="nopadding">
          <div class="mb-2" v-if="groupedList">
            <div class="inline-block" v-for="(item, index) in groupedList" :key="index">
              <div class="bg-gray-200 mx-1 my-1 mb-2 rounded-full flex w-auto overflow-hidden">
                <div class="px-2 py-1 bg-red-500 text-white flex-initial text-sm leading-6">{{ item.count }}</div>
                <div class="py-1 px-2 pr-3 flex-1 text-gray-600 text-sm leading-6">
                  {{ item.reason }}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="mb-2" style="height: 30px">
              <div class="float-left text-md text-gray-600 text-md pt-1">{{ getNumberWithComma(total) }} Skipped</div>
              <div class="float-right">
                <el-button type="primary" plain size="mini" icon="el-icon-download" @click="downloadData">Download</el-button>
              </div>
            </div>
            <template>
              <el-table :data="list" border style="width: 100%">
                <el-table-column prop="name" label="Customer Name" width="350px">
                  <template slot-scope="scope">
                    <router-link class="detailsLink text-primary-700" :to="getCustomerListLink(scope.row.id)" target="_blank">
                      <span>{{ scope.row.name ? scope.row.name : 'Name Not Available' }}</span>
                    </router-link>
                    <div class="text-gray-400"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ scope.row.email ? scope.row.email : 'Not Available' }}</div>
                    <div class="text-gray-400"><i class="fa fa-phone" aria-hidden="true"></i> {{ scope.row.mobile ? scope.row.mobile : 'Not Available' }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Time" width="250px"> </el-table-column>
                <el-table-column prop="info" label="Failure Reason">
                  <template slot-scope="scope">
                    {{ scope.row.reason ? scope.row.reason : '--' }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <div class="pagination pagination-bottom">
              <div class="totalCount">Total {{ total }} Messages</div>
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[50, 100, 200]" :page-size="currentPageSize" layout="sizes, prev, pager, next, jumper" :total="total"></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Failed" name="failed" class="nopadding">
          <div class="mb-2">
            <div class="inline-block" v-for="(item, index) in groupedList" :key="index">
              <div class="bg-gray-200 mx-1 my-1 mb-2 rounded-full flex w-auto overflow-hidden">
                <div class="px-2 py-1 bg-red-500 text-white flex-initial text-sm leading-6">{{ item.count }}</div>
                <div class="py-1 px-2 pr-3 flex-1 text-gray-600 text-sm leading-6">
                  {{ item.reason }}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="mb-2" style="height: 30px">
              <div class="float-left text-md text-gray-600 text-md pt-1">{{ getNumberWithComma(total) }} Failed</div>
              <div class="float-right">
                <el-button type="primary" plain size="mini" icon="el-icon-download" @click="downloadData">Download</el-button>
              </div>
            </div>
            <template>
              <el-table :data="list" border style="width: 100%">
                <el-table-column prop="name" label="Customer Name" width="350px">
                  <template slot-scope="scope">
                    <router-link class="detailsLink text-primary-700" :to="getCustomerListLink(scope.row.cid)" target="_blank">
                      <span>{{ scope.row.name ? scope.row.name : 'Name Not Available' }}</span>
                    </router-link>
                    <div class="text-gray-400"><i class="fa fa-envelope-o" aria-hidden="true"></i> {{ scope.row.email ? scope.row.email : 'Not Available' }}</div>
                    <div class="text-gray-400"><i class="fa fa-phone" aria-hidden="true"></i> {{ scope.row.mobile ? scope.row.mobile : 'Not Available' }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Time" width="250px"> </el-table-column>
                <el-table-column prop="info" label="Failure Reason">
                  <template slot-scope="scope">
                    {{ scope.row.info ? scope.row.info : '--' }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <div class="pagination pagination-bottom">
              <div class="totalCount">Total {{ total }} Messages</div>
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[50, 100, 200]" :page-size="currentPageSize" layout="sizes, prev, pager, next, jumper" :total="total"></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Opened" name="opened">
          <div class="mb-2" style="height: 30px">
            <div class="float-left text-md text-gray-600 text-md pt-1">{{ getNumberWithComma(total) }} Opens</div>
            <div class="float-right">
              <el-button type="primary" plain size="mini" icon="el-icon-download" @click="downloadData">Download</el-button>
            </div>
          </div>
          <template>
            <el-table :data="list" border style="width: 100%">
              <el-table-column prop="name" label="Customer Name" width="250px">
                <template slot-scope="scope">
                  <router-link class="detailsLink text-primary-700" :to="getCustomerListLink(scope.row.id)" target="_blank">
                    <span>{{ scope.row.name ? scope.row.name : 'Name Not Available' }}</span>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="email" label="Email" width="300px"> </el-table-column>
              <el-table-column prop="mobile" label="Mobile" width="250px"> </el-table-column>
              <el-table-column prop="created_at" label="Time"> </el-table-column>
            </el-table>
          </template>
          <div class="pagination pagination-bottom">
            <div class="totalCount">Total {{ total }} Messages</div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[50, 100, 200]" :page-size="currentPageSize" layout="sizes, prev, pager, next, jumper" :total="total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Clicked" name="clicked">
          <div class="mb-2" style="height: 30px">
            <div class="float-left text-md text-gray-600 text-md pt-1">{{ getNumberWithComma(total) }} Clicks</div>
            <div class="float-right">
              <el-button type="primary" plain size="mini" icon="el-icon-download" @click="downloadData">Download</el-button>
            </div>
          </div>
          <template>
            <el-table :data="list" border style="width: 100%">
              <el-table-column prop="name" label="Customer Name" width="250px">
                <template slot-scope="scope">
                  <router-link class="detailsLink text-primary-700" :to="getCustomerListLink(scope.row.id)" target="_blank">
                    <span>{{ scope.row.name ? scope.row.name : 'Name Not Available' }}</span>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="email" label="Email" width="300px"> </el-table-column>
              <el-table-column prop="mobile" label="Mobile" width="250px"> </el-table-column>
              <el-table-column prop="created_at" label="Time"> </el-table-column>
            </el-table>
          </template>
          <div class="pagination pagination-bottom">
            <div class="totalCount">Total {{ total }} Messages</div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[50, 100, 200]" :page-size="currentPageSize" layout="sizes, prev, pager, next, jumper" :total="total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Converted" name="converted">
          <div class="mb-2" style="height: 30px">
            <div class="float-left text-md text-gray-600 text-md pt-1">{{ getNumberWithComma(total) }} Converted</div>
            <div class="float-right">
              <el-button type="primary" plain size="mini" icon="el-icon-download" @click="downloadData">Download</el-button>
            </div>
          </div>
          <template>
            <el-table :data="list" border style="width: 100%">
              <el-table-column prop="name" label="Customer Name" width="250px">
                <template slot-scope="scope">
                  <router-link class="detailsLink text-primary-700" :to="getCustomerListLink(scope.row.id)" target="_blank">
                    <span>{{ scope.row.name ? scope.row.name : 'Name Not Available' }}</span>
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="email" label="Email" width="300px"> </el-table-column>
              <el-table-column prop="mobile" label="Mobile" width="250px"> </el-table-column>
              <el-table-column prop="created_at" label="Time"> </el-table-column>
            </el-table>
          </template>
          <div class="pagination pagination-bottom">
            <div class="totalCount">Total {{ total }} Messages</div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[50, 100, 200]" :page-size="currentPageSize" layout="sizes, prev, pager, next, jumper" :total="total"></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<style lang="scss" src="./campaignRecipientReport.scss"></style>

<script>
import campaignRecipientReportComponent from './campaignRecipientReportComponent';
export default campaignRecipientReportComponent;
</script>
