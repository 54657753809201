import savedBlocksModule from '@/services/savedBlocks';
const _ = require('lodash');
import companyModule from '@/services/company';

export default {
  name: 'dragDropBlockSeperator',
  components: {},
  data() {
    return {
      dialogVisible: false,
      dialogTab: 'all',
      blockIndex: null,
      columnIndex: null,
      columnVerticalIndex: null,
      selectedBlockCategory: 'general',
      blocks: {
        Blocks: [
          {
            type: 'text',
            name: 'Text',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png',
            content: {
              deviceVisiblity: 'all',
              html: `<h2>Our online store is now open!</h2><p>We're excited to announce that our products are available for purchase online. Now you can shop and browse from the comfort of your own home.</p>`,
              margin: {
                top: 20,
                left: 20,
                right: 20,
                bottom: 20
              }
            }
          },
          {
            type: 'image',
            name: 'Image',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/image.png',
            content: {
              deviceVisiblity: 'all',
              srcMode: 'upload',
              src: null,
              width: 100,
              alignment: 'center',
              radius: 0,
              link: null
            }
          },
          {
            type: 'button',
            name: 'Button ',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/button.png',
            content: {
              deviceVisiblity: 'all',
              text: 'Learn More',
              textColor: '#FFFFFF',
              buttonColor: '#000000',
              fontSize: 16,
              link: '',
              radius: 5,
              alignment: 'center',
              margin: {
                top: 20,
                left: 20,
                right: 20,
                bottom: 20
              },
              insidePadding: {
                top: 10,
                left: 20,
                right: 20,
                bottom: 10
              }
            }
          },
          {
            type: 'divider',
            name: 'Divider',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/divider.png',
            content: {
              version: 1,
              height: 2,
              color: '#D1D6ED',
              width: 100,
              deviceVisiblity: 'all',
              margin: {
                top: 10,
                left: 10,
                right: 10,
                bottom: 10
              }
            }
          },
          // Social Icons
          {
            type: 'social-icons',
            name: 'Social Icons',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/social-media.png',
            content: {
              version: 1,
              deviceVisiblity: 'all',
              iconList: [
                { id: 'facebook', title: 'Facebook', image: 'https://static.growlytics.in/drag-drop-editor-assets/facebook.png', url: '' },
                { id: 'twitter', title: 'Twitter', image: 'https://static.growlytics.in/drag-drop-editor-assets/twitter.png', url: '' },
                { id: 'instagram', title: 'Instagram', image: 'https://static.growlytics.in/drag-drop-editor-assets/instagram.png', url: '' }
              ],
              alignment: 'center',
              color: 'blue',
              imageHeight: 40,
              margin: {
                top: 10,
                left: 10,
                right: 10,
                bottom: 10
              },
              iconSpacing: 10,
              borderRadius: 5,
              rowBackgroundColor: '#F3F3F3'
            }
          },
          // Mail footer
          {
            type: 'text',
            name: 'Mail Footer',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/footer.png',
            content: {
              deviceVisiblity: 'all',
              html: '<p style="text-align:center;"><span style="color:hsl(0,0%,60%);font-size:13px;">No longer want to receive these emails? </span><a href="https://dc.growlytics.in/#[unsubscribe]#"><span style="color:hsl(0,0%,60%);font-size:13px;">Unsubscribe</span></a><span style="color:hsl(0,0%,60%);font-size:13px;">.</span></p><p style="text-align:center;">&nbsp;</p><p style="text-align:center;"><span style="font-size:17px;color:hsl(0,0%,60%);">Your Company Name</span></p><p style="text-align:center;"><span style="font-size:13px;color:hsl(0,0%,60%);">Complete Coompany Address Here</span></p>',
              margin: { top: 20, left: 20, right: 20, bottom: 30 },
              rowBackgroundColor: '#F3F3F3'
            }
          }
        ],
        'E-Commerce': [
          //Cart
          {
            type: 'cart',
            name: 'Cart Items',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/cart.png',
            content: {
              deviceVisiblity: 'all',
              button: {
                text: 'Checkout Now',
                color: 'black',
                textColor: '#FFFFFF',
                buttonColor: '#000000',
                fontSize: 14,
                link: '',
                radius: 5,
                alignment: 'center',
                margin: {
                  top: 10,
                  left: 10,
                  right: 10,
                  bottom: 0
                },
                insidePadding: {
                  top: 16,
                  left: 25,
                  right: 25,
                  bottom: 16
                }
              },
              margin: {
                top: 20,
                left: 50,
                right: 50,
                bottom: 20
              }
            }
          },
          // Products
          {
            type: 'product',
            name: 'Products List',
            // icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/product-grid.png',
            icon: 'https://i.postimg.cc/13yjSLhQ/product-grid-1.png',
            content: {
              deviceVisiblity: 'all',
              productInfo: {
                event: 'Add To Cart',
                sort: 'popular',
                filterType: 'all',
                include: [],
                products: []
              },
              cols: 3,
              rows: 3,
              showPrice: true,
              productNameColor: '#777777',
              productPriceColor: '#777777',
              margin: {
                top: 10,
                left: 10,
                right: 10,
                bottom: 10
              },
              button: {
                visible: true,
                text: 'Buy Now',
                textColor: '#FFFFFF',
                buttonColor: '#34AD4BFF',
                fontSize: 13,
                radius: 3,
                alignment: 'center',
                margin: {
                  top: 10,
                  left: 10,
                  right: 10,
                  bottom: 10
                },
                insidePadding: {
                  top: 8,
                  bottom: 8,
                  left: 12,
                  right: 12
                }
              }
            }
          },
          // Single Product
          {
            type: 'single-product',
            name: 'Single Product',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/single-product.png',
            content: {
              deviceVisiblity: 'all',
              productInfo: {
                id: '',
                product: null
              },
              showPrice: true,
              productNameColor: '#777777',
              productPriceColor: '#777777',
              productDescriptionColor: '#777777',
              margin: {
                top: 20,
                left: 10,
                right: 10,
                bottom: 20
              },
              button: {
                visible: true,
                text: 'Buy Now',
                textColor: '#FFFFFF',
                buttonColor: '#34AD4BFF',
                fontSize: 16,
                radius: 3,
                alignment: 'left',
                margin: {
                  top: 15,
                  left: 0,
                  right: 10,
                  bottom: 10
                },
                insidePadding: {
                  top: 11,
                  bottom: 11,
                  left: 18,
                  right: 18
                }
              }
            }
          },
          // Discount Coupon
          {
            type: 'discount',
            name: 'Discount',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/discount.png',
            content: {
              deviceVisiblity: 'all',
              rowBackgroundColor: '#F5F5F5FF',
              couponInfo: {
                dynamic: true,
                id: '',
                manual: {
                  code: 'ENTER-COUPON-CODE',
                  expiryText: 'Expires in 2 days.'
                }
              },
              title: {
                html: '<h1 style="text-align:center;"><span style="color:rgb(45,64,82);">Get 20% Off</span></h1><p style="text-align:center;"><span style="background-color:rgb(245,245,245);color:rgb(45,64,82);font-size:16px;">Here is a coupon code just for you!</span></p>',
                margin: { top: 20, bottom: 0, left: 0, right: 0 }
              },
              expiryText: {
                textColor: '#909090FF',
                fontWeight: 'normal',
                fontSize: 11,
                margin: { top: 12, bottom: 20, left: 0, right: 0 },
                text: `Expires on {coupon-expiry-time}.`
              },
              coupon: {
                textColor: '#FF6A00',
                couponText: 'XXXX-XXXX-XXXX',
                backgroundColor: '#FFFFFF',
                fontSize: 24,
                borderRadius: 5,
                fontWeight: 'bold',
                padding: {
                  top: 11,
                  bottom: 11,
                  left: 18,
                  right: 18
                },
                margin: {
                  top: 25,
                  bottom: 11,
                  left: 18,
                  right: 18
                }
              },
              button: {
                visible: true,
                text: 'Shop Now',
                link: null,
                textColor: '#FFFFFF',
                buttonColor: '#34AD4BFF',
                fontSize: 15,
                radius: 0,
                alignment: 'center',
                margin: {
                  top: 5,
                  left: 0,
                  right: 10,
                  bottom: 10
                },
                insidePadding: {
                  top: 7,
                  bottom: 8,
                  left: 18,
                  right: 18
                }
              }
            }
          },
          // Product Review
          {
            type: 'product-review',
            name: 'Ask For Review',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/rating.png',
            content: {
              deviceVisiblity: 'all',
              orderId: null,
              productNameColor: '#777777',
              productDescriptionColor: '#777777',
              margin: {
                top: 20,
                left: 10,
                right: 10,
                bottom: 20
              },
              button: {
                visible: true,
                text: 'Give Review',
                textColor: '#FFFFFF',
                buttonColor: '#FF7500FF',
                fontSize: 16,
                radius: 50,
                alignment: 'left',
                margin: {
                  top: 15,
                  left: 0,
                  right: 10,
                  bottom: 10
                },
                insidePadding: {
                  top: 11,
                  bottom: 11,
                  left: 18,
                  right: 18
                }
              }
            }
          },
          // Custom HTML
          {
            type: 'custom-html',
            name: 'Custom HTML',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/custom-html.png',
            content: {
              deviceVisiblity: 'all',
              html: '<span>HTML Snippet Goes Here.....</span>'
            }
          }
        ],
        Rows: [
          {
            type: 'columns-one',
            name: '1 Column',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/single-column.png',
            content: {
              leftWidth: 25,
              deviceVisiblity: 'all',
              dontKeepMobileResponsive: false,
              columnContentVerticalAlign: 'top',
              columns: [[]]
            }
          },
          {
            type: 'columns-two',
            name: '2 Columns',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/right-bigger.png',
            content: {
              leftWidth: 25,
              deviceVisiblity: 'all',
              dontKeepMobileResponsive: false,
              columnContentVerticalAlign: 'top',
              columns: [[], []]
            }
          },
          {
            type: 'columns-two',
            name: '2 Columns',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/two-columns.png',
            content: {
              leftWidth: 50,
              deviceVisiblity: 'all',
              dontKeepMobileResponsive: false,
              columnContentVerticalAlign: 'top',
              columns: [[], []]
            }
          },
          {
            type: 'columns-two',
            name: '2 Columns',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/left-bigger.png',
            content: {
              leftWidth: 75,
              deviceVisiblity: 'all',
              dontKeepMobileResponsive: false,
              columnContentVerticalAlign: 'top',
              columns: [[], []]
            }
          },
          {
            type: 'columns-three',
            name: '3 Columns',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/three-columns.png',
            content: {
              deviceVisiblity: 'all',
              dontKeepMobileResponsive: false,
              columnContentVerticalAlign: 'top',
              columns: [[], [], []]
            }
          },
          {
            type: 'columns-four',
            name: '4 Columns',
            icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/four-columns.png',
            content: {
              deviceVisiblity: 'all',
              dontKeepMobileResponsive: false,
              columnContentVerticalAlign: 'top',
              columns: [[], [], [], []]
            }
          }
        ]
      },

      headerBlocks: [
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/headers/white-header-1.png'),
          title: 'White Header 1',
          type: 'email-header',
          content: {
            version: 1,
            logo: '{{--brand-logo--}}',
            logoHeight: 45,
            logoClickUrl: '{{--brand-site-url--}}',
            template: 'white-header-1',
            deviceVisiblity: 'all',
            links: {
              color: '#740AC8',
              weight: 'normal',
              alignment: 'right',
              fontSize: 14,
              list: [
                { text: 'HOME', url: '{{--brand-site-url--}}' },
                { text: 'ABOUT', url: '{{--brand-site-url--}}' },
                { text: 'BLOG', url: '{{--brand-site-url--}}' },
                { text: 'CONTACT', url: '{{--brand-site-url--}}' }
              ]
            },
            margin: {
              top: 10,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        },
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/headers/dark-header-1.png'),
          title: 'Dark Header 1',
          type: 'email-header',
          content: {
            version: 1,
            template: 'dark-header-1',
            deviceVisiblity: 'all',

            logo: '{{--brand-logo--}}',
            logoClickUrl: '{{--brand-site-url--}}',
            logoHeight: 45,
            rowBackgroundColor: '#740AC8',
            links: {
              color: '#FFFFFF',
              weight: 'normal',
              alignment: 'right',
              fontSize: 14,
              list: [
                { text: 'HOME', url: '{{--brand-site-url--}}' },
                { text: 'ABOUT', url: '{{--brand-site-url--}}' },
                { text: 'BLOG', url: '{{--brand-site-url--}}' },
                { text: 'CONTACT', url: '{{--brand-site-url--}}' }
              ]
            },
            margin: {
              top: 10,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        },
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/headers/white-header-2.png'),
          title: 'White Header 2',
          type: 'email-header',
          content: {
            version: 1,
            template: 'white-header-2',
            deviceVisiblity: 'all',

            logo: '{{--brand-logo--}}',
            logoClickUrl: '{{--brand-site-url--}}',
            logoHeight: 45,
            links: {
              color: '#740AC8',
              weight: 'normal',
              fontSize: 14,
              list: [
                { text: 'HOME', url: '{{--brand-site-url--}}' },
                { text: 'ABOUT', url: '{{--brand-site-url--}}' },
                { text: 'BLOG', url: '{{--brand-site-url--}}' },
                { text: 'CONTACT', url: '{{--brand-site-url--}}' }
              ]
            },
            margin: {
              top: 15,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        },
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/headers/dark-header-2.png'),
          title: 'Dark Header 2',
          type: 'email-header',
          content: {
            version: 1,
            template: 'dark-header-2',
            deviceVisiblity: 'all',

            logo: '{{--brand-logo--}}',
            logoClickUrl: '{{--brand-site-url--}}',
            logoHeight: 45,
            rowBackgroundColor: '#740AC8',
            links: {
              color: '#FFFFFF',
              weight: 'normal',
              fontSize: 14,
              list: [
                { text: 'HOME', url: '{{--brand-site-url--}}' },
                { text: 'ABOUT', url: '{{--brand-site-url--}}' },
                { text: 'BLOG', url: '{{--brand-site-url--}}' },
                { text: 'CONTACT', url: '{{--brand-site-url--}}' }
              ]
            },
            margin: {
              top: 15,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        },
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/headers/white-header-3.png'),
          title: 'White Header 3',
          type: 'email-header',
          content: {
            version: 1,
            template: 'white-header-3',
            deviceVisiblity: 'all',
            logo: '{{--brand-logo--}}',
            logoClickUrl: '{{--brand-site-url--}}',
            logoHeight: 45,
            rowBackgroundColor: '#FFFFFF',
            margin: {
              top: 10,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        },
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/headers/dark-header-3.png'),
          title: 'Dark Header 3',
          type: 'email-header',
          content: {
            version: 1,
            template: 'dark-header-3',
            deviceVisiblity: 'all',
            logo: '{{--brand-logo--}}',
            logoClickUrl: '{{--brand-site-url--}}',
            logoHeight: 45,
            rowBackgroundColor: '#740AC8',
            margin: {
              top: 10,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        }
      ],

      heroSections: [
        {
          image: 'https://editor.liramail.com/images/feshto/thumbnails/hero-19.png',
          type: 'email-hero-block',
          content: {
            template: 'block-1',
            deviceVisiblity: 'all',

            rowBackgroundColor: '#F5F5F5FF',

            logo: {
              enabled: true,
              image: '{{--brand-logo--}}',
              url: '{{--brand-site-url--}}',
              height: 45
            },

            address: {
              enabled: true,
              margin: {
                top: 30,
                left: 10,
                right: 10,
                bottom: 25
              },
              html: '<p style="text-align:center;"><span style="color:rgb(130,140,151);font-size:9pt;">{{--brand-address--}}</span></p>'
            },

            unsubscribe: {
              margin: {
                top: 25,
                left: 0,
                right: 0,
                bottom: 0
              },
              html: '<p style="text-align: center;"><span style="font-size: 10pt; color: #7e8c8d;">No longer want to receive emails? <a style="color: #7e8c8d;" href="https://dc.growlytics.in/#[unsubscribe]#">Unsubscribe</a></span></p>'
            },

            socialLinks: {
              enabled: true,
              height: 33,
              iconColor: '#424c5f',
              list: [
                { icon: 'facebook', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-facebook.png', url: '{{--brand-site-url--}}' },
                { icon: 'twitter', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-twitter.png', url: '{{--brand-site-url--}}' },
                { icon: 'instagram', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-instagram.png', url: '{{--brand-site-url--}}' },
                { icon: 'whatsapp', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-whatsapp.png', url: '{{--brand-site-url--}}' },
                { icon: 'youtube', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-youtube.png', url: '{{--brand-site-url--}}' }
              ]
            },

            appStoreLinks: {
              enabled: true,
              height: 35,
              list: [
                { enabled: true, title: 'Play Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/google-store-dark.png', url: '{{--brand-site-url--}}' },
                { enabled: true, title: 'Apple Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/apple-store-dark.png', url: '{{--brand-site-url--}}' }
              ],
              margin: {
                top: 25,
                left: 10,
                right: 10,
                bottom: 10
              }
            }
          }
        }
      ],

      footerBlocks: [
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/footers/white-footer-1.png'),
          title: 'White Footer 1',
          type: 'email-footer',
          content: {
            version: 1,
            template: 'white-footer-1',
            deviceVisiblity: 'all',

            rowBackgroundColor: '#F5F5F5FF',

            logo: {
              enabled: true,
              image: '{{--brand-logo--}}',
              url: '{{--brand-site-url--}}',
              height: 45
            },

            address: {
              enabled: true,
              margin: {
                top: 30,
                left: 10,
                right: 10,
                bottom: 25
              },
              html: '<p style="text-align:center;"><span style="color:rgb(130,140,151);font-size:9pt;">{{--brand-address--}}</span></p>'
            },

            unsubscribe: {
              margin: {
                top: 25,
                left: 0,
                right: 0,
                bottom: 0
              },
              html: '<p style="text-align: center;"><span style="font-size: 10pt; color: #7e8c8d;">No longer want to receive emails? <a style="color: #7e8c8d;" href="https://dc.growlytics.in/#[unsubscribe]#">Unsubscribe</a></span></p>'
            },

            socialLinks: {
              enabled: true,
              height: 33,
              iconColor: '#424c5f',
              list: [
                { icon: 'facebook', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-facebook.png', url: '{{--brand-site-url--}}' },
                { icon: 'twitter', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-twitter.png', url: '{{--brand-site-url--}}' },
                { icon: 'instagram', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-instagram.png', url: '{{--brand-site-url--}}' },
                { icon: 'whatsapp', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-whatsapp.png', url: '{{--brand-site-url--}}' },
                { icon: 'youtube', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-youtube.png', url: '{{--brand-site-url--}}' }
              ]
            },

            appStoreLinks: {
              enabled: true,
              height: 35,
              list: [
                { enabled: true, title: 'Play Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/google-store-dark.png', url: '{{--brand-site-url--}}' },
                { enabled: true, title: 'Apple Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/apple-store-dark.png', url: '{{--brand-site-url--}}' }
              ]
            },
            margin: {
              top: 25,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        },
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/footers/dark-footer-1.png'),
          title: 'Dark Footer 1',
          type: 'email-footer',
          content: {
            version: 1,
            template: 'white-footer-1',
            deviceVisiblity: 'all',

            rowBackgroundColor: '#740AC8',

            logo: {
              enabled: true,
              image: '{{--brand-logo--}}',
              url: '{{--brand-site-url--}}',
              height: 45
            },

            address: {
              enabled: true,
              margin: {
                top: 30,
                left: 10,
                right: 10,
                bottom: 25
              },
              html: '<p style="text-align:center;"><span style="color:#FFFFFF;font-size:9pt;">{{--brand-address--}}</span></p>'
            },

            unsubscribe: {
              margin: {
                top: 25,
                left: 0,
                right: 0,
                bottom: 0
              },
              html: '<p style="text-align: center;"><span style="color: #ffffff; font-size: 10pt;">No longer want to receive emails? <a style="color: #ffffff;" href="https://dc.growlytics.in/#[unsubscribe]#">Unsubscribe</a></span></p>'
            },

            socialLinks: {
              enabled: true,
              height: 33,
              iconColor: '#FFFFFF',
              list: [
                { icon: 'facebook', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-facebook.png', url: '{{--brand-site-url--}}' },
                { icon: 'twitter', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-twitter.png', url: '{{--brand-site-url--}}' },
                { icon: 'instagram', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-instagram.png', url: '{{--brand-site-url--}}' },
                { icon: 'whatsapp', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-whatsapp.png', url: '{{--brand-site-url--}}' },
                { icon: 'youtube', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-youtube.png', url: '{{--brand-site-url--}}' }
              ]
            },

            appStoreLinks: {
              enabled: true,
              height: 35,
              list: [
                { enabled: true, title: 'Play Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/google-store-white.png', url: '{{--brand-site-url--}}' },
                { enabled: true, title: 'Apple Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/apple-store-white.png', url: '{{--brand-site-url--}}' }
              ]
            },
            margin: {
              top: 25,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        },
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/footers/white-footer-2.png'),
          type: 'email-footer',
          title: 'White Footer 2',
          content: {
            version: 1,
            template: 'white-footer-2',
            deviceVisiblity: 'all',

            rowBackgroundColor: '#F5F5F5FF',

            logo: {
              enabled: true,
              image: '{{--brand-logo--}}',
              url: '{{--brand-site-url--}}',
              height: 45
            },

            address: {
              enabled: true,
              margin: {
                top: 30,
                left: 10,
                right: 10,
                bottom: 25
              },
              html: '<p style="text-align:center;"><span style="color:rgb(130,140,151);font-size:9pt;">{{--brand-address--}}</span></p>'
            },

            unsubscribe: {
              margin: {
                top: 20,
                left: 0,
                right: 0,
                bottom: 0
              },
              html: '<p style="text-align: center;"><span style="font-size: 10pt; color: #7e8c8d;">No longer want to receive emails? <a style="color: #7e8c8d;" href="https://dc.growlytics.in/#[unsubscribe]#">Unsubscribe</a></span></p>'
            },

            socialLinks: {
              enabled: true,
              height: 33,
              iconColor: '#424c5f',
              list: [
                { icon: 'facebook', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-facebook.png', url: '{{--brand-site-url--}}' },
                { icon: 'twitter', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-twitter.png', url: '{{--brand-site-url--}}' },
                { icon: 'instagram', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-instagram.png', url: '{{--brand-site-url--}}' },
                { icon: 'whatsapp', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-whatsapp.png', url: '{{--brand-site-url--}}' },
                { icon: 'youtube', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-youtube.png', url: '{{--brand-site-url--}}' }
              ]
            },

            appStoreLinks: {
              enabled: true,
              height: 35,
              list: [
                { enabled: true, title: 'Play Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/google-store-dark.png', url: '{{--brand-site-url--}}' },
                { enabled: true, title: 'Apple Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/apple-store-dark.png', url: '{{--brand-site-url--}}' }
              ]
            },
            margin: {
              top: 25,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        },
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/footers/dark-footer-2.png'),
          title: 'Dark Footer 2',
          type: 'email-footer',
          content: {
            version: 1,
            template: 'dark-footer-2',
            deviceVisiblity: 'all',

            rowBackgroundColor: '#740AC8',

            logo: {
              enabled: true,
              image: '{{--brand-logo--}}',
              url: '{{--brand-site-url--}}',
              height: 45
            },

            address: {
              enabled: true,
              margin: {
                top: 30,
                left: 10,
                right: 10,
                bottom: 25
              },
              html: '<p style="text-align:center;"><span style="color:#FFFFFF;font-size:9pt;">{{--brand-address--}}</span></p>'
            },

            unsubscribe: {
              margin: {
                top: 25,
                left: 0,
                right: 0,
                bottom: 0
              },
              html: '<p style="text-align: center;"><span style="color: #ffffff; font-size: 10pt;">No longer want to receive emails? <a style="color: #ffffff;" href="https://dc.growlytics.in/#[unsubscribe]#">Unsubscribe</a></span></p>'
            },

            socialLinks: {
              enabled: true,
              height: 33,
              iconColor: '#FFFFFF',
              list: [
                { icon: 'facebook', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-facebook.png', url: '{{--brand-site-url--}}' },
                { icon: 'twitter', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-twitter.png', url: '{{--brand-site-url--}}' },
                { icon: 'instagram', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-instagram.png', url: '{{--brand-site-url--}}' },
                { icon: 'whatsapp', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-whatsapp.png', url: '{{--brand-site-url--}}' },
                { icon: 'youtube', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-youtube.png', url: '{{--brand-site-url--}}' }
              ]
            },

            appStoreLinks: {
              enabled: true,
              height: 35,
              list: [
                { enabled: true, title: 'Play Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/google-store-white.png', url: '{{--brand-site-url--}}' },
                { enabled: true, title: 'Apple Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/apple-store-white.png', url: '{{--brand-site-url--}}' }
              ]
            },
            margin: {
              top: 25,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        },
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/footers/white-footer-3.png'),
          type: 'email-footer',
          title: 'White Footer 3',
          content: {
            version: 1,
            template: 'white-footer-3',
            deviceVisiblity: 'all',

            rowBackgroundColor: '#F5F5F5FF',

            logo: {
              enabled: true,
              image: '{{--brand-logo--}}',
              url: '{{--brand-site-url--}}',
              height: 45
            },

            address: {
              enabled: true,
              margin: {
                top: 15,
                left: 0,
                right: 10,
                bottom: 25
              },
              html: '<p style="text-align:left;"><span style="color:rgb(130,140,151);font-size:9pt;">{{--brand-address--}}</span></p>'
            },

            unsubscribe: {
              margin: {
                top: 13,
                left: 0,
                right: 0,
                bottom: 0
              },
              html: '<p style="text-align: center;"><span style="font-size: 10pt; color: #7e8c8d;">No longer want to receive emails? <a style="color: #7e8c8d;" href="https://dc.growlytics.in/#[unsubscribe]#">Unsubscribe</a></span></p>'
            },

            socialLinks: {
              enabled: true,
              height: 33,
              iconColor: '#424c5f',
              list: [
                { icon: 'facebook', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-facebook.png', url: '{{--brand-site-url--}}' },
                { icon: 'twitter', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-twitter.png', url: '{{--brand-site-url--}}' },
                { icon: 'instagram', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-instagram.png', url: '{{--brand-site-url--}}' },
                { icon: 'whatsapp', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-whatsapp.png', url: '{{--brand-site-url--}}' },
                { icon: 'youtube', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/dark/dark-youtube.png', url: '{{--brand-site-url--}}' }
              ]
            },

            appStoreLinks: {
              enabled: true,
              height: 35,
              list: [
                { enabled: true, title: 'Play Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/google-store-dark.png', url: '{{--brand-site-url--}}' },
                { enabled: true, title: 'Apple Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/apple-store-dark.png', url: '{{--brand-site-url--}}' }
              ]
            },
            margin: {
              top: 25,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        },
        {
          image: require('@/assets/images/emailEditor/predefinedBlocks/footers/dark-footer-3.png'),
          title: 'Dark Footer 3',
          type: 'email-footer',
          content: {
            version: 1,
            template: 'dark-footer-3',
            deviceVisiblity: 'all',

            rowBackgroundColor: '#740AC8',

            logo: {
              enabled: true,
              image: '{{--brand-logo--}}',
              url: '{{--brand-site-url--}}',
              height: 45
            },

            address: {
              enabled: true,
              margin: {
                top: 15,
                left: 0,
                right: 10,
                bottom: 25
              },
              html: '<p style="text-align:left;"><span style="color:#FFFFFF;font-size:9pt;">{{--brand-address--}}</span></p>'
            },

            unsubscribe: {
              margin: {
                top: 13,
                left: 0,
                right: 0,
                bottom: 0
              },
              html: '<p style="text-align: center;"><span style="color: #ffffff; font-size: 10pt;">No longer want to receive emails? <a style="color: #ffffff;" href="https://dc.growlytics.in/#[unsubscribe]#">Unsubscribe</a></span></p>'
            },

            socialLinks: {
              enabled: true,
              height: 33,
              iconColor: '#FFFFFF',
              list: [
                { icon: 'facebook', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-facebook.png', url: '{{--brand-site-url--}}' },
                { icon: 'twitter', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-twitter.png', url: '{{--brand-site-url--}}' },
                { icon: 'instagram', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-instagram.png', url: '{{--brand-site-url--}}' },
                { icon: 'whatsapp', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-whatsapp.png', url: '{{--brand-site-url--}}' },
                { icon: 'youtube', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/social-icons/white/white-youtube.png', url: '{{--brand-site-url--}}' }
              ]
            },

            appStoreLinks: {
              enabled: true,
              height: 35,
              list: [
                { enabled: true, title: 'Play Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/google-store-white.png', url: '{{--brand-site-url--}}' },
                { enabled: true, title: 'Apple Store', image: 'https://static.growlytics.in/drag-drop-editor-assets/predefined-templates/apple-store-white.png', url: '{{--brand-site-url--}}' }
              ]
            },
            margin: {
              top: 25,
              left: 10,
              right: 10,
              bottom: 10
            }
          }
        }
      ],

      // Saved block list variables
      savedBlockList: null,
      fetchingBlocksList: true,
      selectedSavedBlockId: null,
      selectedSavedBlockDetail: null,

      // Company metadata for merging brand info.
      companyBrandInfo: null
    };
  },

  methods: {
    async onTabChange() {
      if (this.dialogTab != 'saved') return;
      if (this.savedBlockList != null) return;
      this.fetchSavedBlockList();
    },

    async fetchSavedBlockList() {
      this.fetchingBlocksList = true;

      try {
        this.savedBlockList = await savedBlocksModule.getList(this);
        this.savedBlockList = this.savedBlockList.data;
        if (this.savedBlockList.length > 0) {
          this.selectedSavedBlockId = this.savedBlockList[0].id;
          this.getDetailsOfSavedBlock();
        }
        this.fetchingBlocksList = false;
      } catch (err) {
        console.error(err);
        this.reportError(err);
        this.errorToast('Failed to get saved blocks.');
      }
    },

    async getDetailsOfSavedBlock() {
      this.fetchingBlocksList = true;

      try {
        this.selectedSavedBlockDetail = await savedBlocksModule.getDetail(this.selectedSavedBlockId, this);
        this.selectedSavedBlockDetail = this.selectedSavedBlockDetail.data;
        this.selectedSavedBlockDetail.content = JSON.parse(this.selectedSavedBlockDetail.content);
        this.fetchingBlocksList = false;
      } catch (err) {
        console.error(err);
        this.reportError(err);
        this.errorToast('Failed to get saved blocks.');
      }
    },

    async onDeleteSavedBlock() {
      this.fetchingBlocksList = true;

      try {
        await savedBlocksModule.delete(this.selectedSavedBlockId, this);
        this.successToast('Block Deleted.');
        this.fetchSavedBlockList();
      } catch (err) {
        console.error(err);
        this.reportError(err);
        this.errorToast('Failed to get saved blocks.');
      }
    },

    handleBeforeClose() {
      this.dialogVisible = false;
      this.dialogTab = 'all';
      this.savedBlockList = null;
      this.fetchingBlocksList = true;
      this.selectedSavedBlockId = null;
      this.selectedSavedBlockDetail = null;
    },

    showPopup(index, columnIndex, columnVerticalIndex) {
      this.dialogVisible = true;
      this.blockIndex = index;
      this.columnIndex = columnIndex;
      this.columnVerticalIndex = columnVerticalIndex;
    },

    async onBlockSelected(block) {
      this.dialogVisible = false;
      let newBlock = JSON.parse(JSON.stringify(block));
      newBlock.id = Date.now();

      let data = {
        block: newBlock,
        index: this.blockIndex,
        columnIndex: this.columnIndex,
        columnVerticalIndex: this.columnVerticalIndex
      };

      // If block type is header/footer, fetch and merge brand contents.
      if (newBlock.type == 'email-header' || newBlock.type == 'email-footer') {
        if (!this.companyBrandInfo) {
          let result = await companyModule.getCompanyMetadata();
          this.companyBrandInfo = result.data;
        }

        if (this.companyBrandInfo) {
          if (newBlock.type == 'email-header') {
            newBlock.content.logo = newBlock.content.logo.replace(/{{--brand-logo--}}/g, this.companyBrandInfo.brand_logo);
            newBlock.content.logoClickUrl = newBlock.content.logoClickUrl.replace(/{{--brand-site-url--}}/g, this.companyBrandInfo.site_url);
            if (newBlock.content.logoClickUrl && !newBlock.content.logoClickUrl.startsWith('http')) {
              newBlock.content.logoClickUrl = 'https://' + newBlock.content.logoClickUrl;
            }
          }

          if (newBlock.type == 'email-footer') {
            newBlock.content.logo.image = newBlock.content.logo.image.replace(/{{--brand-logo--}}/g, this.companyBrandInfo.brand_logo);
            newBlock.content.logo.url = newBlock.content.logo.url.replace(/{{--brand-site-url--}}/g, this.companyBrandInfo.site_url);
            if (newBlock.content.logo.url && !newBlock.content.logo.url.startsWith('http')) {
              newBlock.content.logo.url = 'https://' + newBlock.content.logo.url;
            }
          }

          if (newBlock.content.links) {
            for (let i = 0; i < newBlock.content.links.list.length; i++) {
              newBlock.content.links.list[i].url = newBlock.content.links.list[i].url.replace(/{{--brand-site-url--}}/g, this.companyBrandInfo.site_url);
              if (newBlock.content.links.list[i].url && !newBlock.content.links.list[i].url.startsWith('http')) {
                newBlock.content.links.list[i].url = 'https://' + newBlock.content.links.list[i].url;
              }
            }
          }

          if (newBlock.content.socialLinks) {
            for (let i = 0; i < newBlock.content.socialLinks.list.length; i++) {
              newBlock.content.socialLinks.list[i].url = newBlock.content.socialLinks.list[i].url.replace(/{{--brand-site-url--}}/g, this.companyBrandInfo.site_url);
              if (newBlock.content.socialLinks.list[i].url && !newBlock.content.socialLinks.list[i].url.startsWith('http')) {
                newBlock.content.socialLinks.list[i].url = 'https://' + newBlock.content.socialLinks.list[i].url;
              }
            }
          }

          if (newBlock.content.appStoreLinks) {
            for (let i = 0; i < newBlock.content.appStoreLinks.list.length; i++) {
              newBlock.content.appStoreLinks.list[i].url = newBlock.content.appStoreLinks.list[i].url.replace(/{{--brand-site-url--}}/g, this.companyBrandInfo.site_url);
              if (newBlock.content.appStoreLinks.list[i].url && !newBlock.content.appStoreLinks.list[i].url.startsWith('http')) {
                newBlock.content.appStoreLinks.list[i].url = 'https://' + newBlock.content.appStoreLinks.list[i].url;
              }
            }
          }

          if (newBlock.content.address) {
            newBlock.content.address.html = newBlock.content.address.html.replace(/{{--brand-address--}}/g, this.companyBrandInfo.address);
          }
        }
      }

      this.$emit('blockSelected', data);

      this.marketingTrackEvent('Email Block Added', {
        Type: newBlock.type,
        'Company Name': this.$store.state.token.companyName,
        'Company Id': this.$store.state.token.companyId,
        'User Name': this.$store.state.token.name,
        'User Email': this.$store.state.token.email,
        'Platform Type': this.$store.state.token.companyPlatformType
      });
    },

    onBlockSavedSelected(savedBlock) {
      // Find and override existing block
      let originalBlock = _.find(this.blocks, (blockInfo) => {
        return blockInfo.type == savedBlock.type;
      });

      if (originalBlock) {
        savedBlock = this.overrideJson(originalBlock, savedBlock);
      }

      this.onBlockSelected(savedBlock);
    }
  },

  mounted() {
    for (let category in this.blocks) {
      let categoryBlocks = this.blocks[category];
      for (let i = 0; i < categoryBlocks.length; i++) {
        let block = categoryBlocks[i];

        // Block paddding
        if (!block.content.margin) {
          block.content.margin = {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          };
        }

        // section background
        if (!block.content.rowBackgroundColor) {
          block.content.rowBackgroundColor = '#FFFFFF';
        }
        // section background image
        if (!block.content.backgroundImage) {
          block.content.rowBackgroundImage = null;
        }
      }
    }

    for (let i = 0; i < this.headerBlocks.length; i++) {
      let block = this.headerBlocks[i];

      // Block paddding
      if (!block.content.margin) {
        block.content.margin = {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        };
      }

      // section background
      if (!block.content.rowBackgroundColor) {
        block.content.rowBackgroundColor = '#FFFFFF';
      }
      // section background image
      if (!block.content.backgroundImage) {
        block.content.rowBackgroundImage = null;
      }
    }

    for (let i = 0; i < this.heroSections.length; i++) {
      let block = this.heroSections[i];

      // Block paddding
      if (!block.content.margin) {
        block.content.margin = {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        };
      }

      // section background
      if (!block.content.rowBackgroundColor) {
        block.content.rowBackgroundColor = '#FFFFFF';
      }
      // section background image
      if (!block.content.backgroundImage) {
        block.content.rowBackgroundImage = null;
      }
    }

    for (let i = 0; i < this.footerBlocks.length; i++) {
      let block = this.footerBlocks[i];

      if (!block.content) continue;

      // Block paddding
      if (!block.content.margin) {
        block.content.margin = {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        };
      }

      // section background
      if (!block.content.rowBackgroundColor) {
        block.content.rowBackgroundColor = '#FFFFFF';
      }
      // section background image
      if (!block.content.backgroundImage) {
        block.content.rowBackgroundImage = null;
      }
    }
  }
};
