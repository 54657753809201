<template>
  <el-drawer custom-class="updateUserAttributeDrawer" :visible.sync="dialogVisible" :direction="direction" :before-close="onCancel">
    <div slot="title">
      Sync With Facebook
      <a href="http://help.growlytics.in/en/articles/5584367-updating-customer-attributes-in-journey" target="_blank" class="ml-2">
        <button class="text-white background-transparent -mt-1 px-2 py-0 text-xs leading-5 outline-none focus:outline-none ease-linear transition-all duration-150 border border-white rounded-sm" type="button">Learn More <i class="el-icon-top-right"></i></button>
      </a>
    </div>

    <!-- Loader -->
    <template v-if="loading"> </template>

    <!-- No facebook account connected error -->
    <template v-else-if="providerList.length == 0">
      <div class="rounded-md bg-gray-50 border px-3 mb-3 text-center mt-20 py-32 mx-auto w-1/2">
        <i class="fa fa-facebook-official text-8xl text-blue-600"></i>
        <div class="text-sm font-medium mt-2 text-gray-400">No facebook ad account connected yet.</div>
        <div class="mt-8">
          <a href="http://help.growlytics.in/en/articles/5584367-updating-customer-attributes-in-journey" target="_blank" class="ml-2">
            <button class="mr-3 text-white -mt-1 px-3 py-2 text-sm leading-5 outline-none focus:outline-none bg-primary-600 rounded-sm" type="button">Connect Facebook Account <i class="el-icon-top-right"></i></button>
          </a>
        </div>
      </div>
    </template>

    <!-- General Info -->
    <template v-else>
      <el-form :model="form" ref="adNetworkForm" :rules="formRules">
        <div class="bg-white border rounded-md overflow-hidden mb-4 reportComponent">
          <!-- Header -->
          <div class="min-w-full border-b border-b-gray-200 bg-gray-100">
            <span style="display: inline-block" class="px-3 py-1 leading-10 text-left text-base text-black tracking-wider">Sync Customer in journey with custom audience</span>
            <div class="min-w-full -mt-2 px-3 mb-2 text-gray-500" style="font-size: 12px">Add or remove customer from facebook custom audience.</div>
          </div>

          <div class="bg-white pt-3 pb-1 px-3 border-b">
            <el-row :gutter="20">
              <el-col :span="12">
                <!-- SElect Attribute To Update -->
                <el-form-item label="Select Facebook Ad Account" prop="provider">
                  <el-select v-model="form.provider" filterable class="w-60 max-w-60" @change="fetchFBAudienceList">
                    <el-option v-for="(provider, index) in providerList" :value="provider.id" :label="provider.provider_name" :key="index">{{ provider.name }} </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" class="position-relative">
                <!-- Add Audience Button -->
                <div v-if="form.provider" class="position-absolute addAudienceButton right-3" style="z-index: 1000">
                  <el-button size="mini" type="primary" plain @click="onShowAudienceDialog">Add Audience</el-button>
                </div>
                <!-- Select Attribute To Update -->
                <el-form-item label="Select Facebook Audience" prop="target">
                  <el-select v-if="!fetchingAudienceList" v-model="form.target" filterable class="w-60 max-w-60">
                    <el-option v-for="(audience, index) in audienceList" :value="audience.id" :label="audience.name" :key="index">
                      <span style="float: left">{{ audience.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ audience.id }}</span>
                    </el-option>
                  </el-select>
                  <div class="pl-4" v-else>
                    <beat-loader :loading="true" color="rgb(220, 223, 230)" size="8px"></beat-loader>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <div class="bg-white pt-3 px-3">
            <el-form-item label="Add or remove customer from audience?" prop="action">
              <el-radio label="add" v-model="form.action" size="small" border>Add Customer to FB Audience</el-radio>
              <el-radio label="remove" v-model="form.action" size="small" border>Remove Customer from FB Audience</el-radio>
            </el-form-item>
          </div>
        </div>

        <!-- Notes - How customers are synced  -->
        <div class="rounded-md bg-yellow-50 pt-3 px-3" style="border: 1px solid #f1c582">
          <div class="font-base text-yellow-700">How customers are synced with facebook?</div>
          <div class="mt-2 text-xs text-yellow-600">
            Customers will be identified on facebook using following four attributes. If these attributes are not available, customer will not be synced and will be marked as not reachable in journey reports.
            <el-row>
              <el-col :span="12">
                <ul class="ml-4 mt-2 leading-6">
                  <li>Email</li>
                  <li>Mobile Number</li>
                </ul>
              </el-col>
              <el-col :span="12">
                <ul class="ml-4 mt-2 leading-6">
                  <li>Google Advertising ID (Only Android Apps)</li>
                  <li>iOS advertising identifier (Only iOS Apps)</li>
                </ul>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="footerButtons">
          <el-button v-if="!jurneyPublished" type="primary" @click="onValidateAndSave"> Continue </el-button>
          <el-button v-else type="primary" @click="publishChanges"> Publish Changes </el-button>
          <el-button type="danger" plain @click="onCancel">Cancel</el-button>
        </div>
      </el-form>
    </template>

    <CreateFbAudiencePopup ref="createFbAudiencePopup" @success="onAudienceCreateSuccess"></CreateFbAudiencePopup>
  </el-drawer>
</template>

<style lang="scss" src="./journeyAdNetworkSyncNodePopup.scss"></style>

<script>
import journeyAdNetworkSyncNodePopupComponent from './journeyAdNetworkSyncNodePopupComponent';
export default journeyAdNetworkSyncNodePopupComponent;
</script>
