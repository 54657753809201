exports.dummyButton = {
  text: 'Buy Now',
  textColor: '#dddddd',
  backgroundColor: '#228B22',
  iosUrl: '',
  androidUrl: '#fafafa',
  closeOnClick: true,
  borderRadius: 5
};

exports.typeList = [
  {
    type: 'single',
    typeName: 'Single User',
    header: "It's Popular",
    userTitle: 'Larry H.',
    city: 'New York',
    userCount: '0',
    activity: 'viewed this product',
    time: '30 minutes',
    icon: 'https://static.growlytics.in/on-site/social-proof-icon.jpg'
  },
  {
    type: 'all',
    typeName: 'All Users',
    header: "It's Popular",
    userCount: '37',
    userTitle: 'visitors',
    activity: 'viewed this product',
    time: '30 minutes',
    icon: 'https://static.growlytics.in/on-site/social-proof-icon.jpg'
  }
];
