const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.uploadImage = function (formData) {
  let headers = buildAuthHeaders();
  headers['Content-Type'] = 'multipart/form-data';
  return axios.post(baseUrlNew + 'images', formData, {
    headers: headers,
    timeout: 1000 * 600
  });
};

exports.getImages = function (params) {
  return axios.get(baseUrlNew + `images`, {
    params: params,
    headers: buildAuthHeaders()
  });
};

exports.toggleImageBookmark = function (params) {
  return axios.post(baseUrlNew + `images/bookmark`, params, {
    headers: buildAuthHeaders()
  });
};

exports.deleteCampaignImage = function (imageId) {
  return axios.delete(baseUrlNew + `images/${imageId}`, {
    headers: buildAuthHeaders()
  });
};

exports.svg2img = (params) => {
  return axios.post(baseUrlNew + `images/socialIcon`, params, {
    headers: buildAuthHeaders()
  });
};
