<template>
  <table border="0" cellpadding="0" cellspacing="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
    <tr class="rowMarginTop" v-if="content.margin.top > 0">
      <td :height="content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': content.margin.top + 'px', 'line-height': content.margin.top + 'px' }">&nbsp;</td>
    </tr>
    <tr class="rowContent">
      <!-- Margin Left -->
      <td v-if="content.margin.left > 0" :width="content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
      <!-- Content -->
      <td style="padding: 0px">
        <table cellpadding="0" cellspacing="0" border="0" width="100%" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
          <tr class="cellContent">
            <td style="padding: 0px">
              <div v-html="content.html"></div>
            </td>
          </tr>
        </table>
      </td>
      <!-- Margin Right -->
      <td v-if="content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
    </tr>
    <tr class="rowMarginBottom" v-if="content.margin.bottom > 0">
      <td style="padding: 0px; color: transparent !important" :width="content.margin.bottom" align="center" valign="top" :style="{ 'font-size': content.margin.bottom + 'px', 'line-height': content.margin.bottom + 'px' }">&nbsp;</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'dragDropBlockSeperator',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      suggestedProducts: null,
      productSetGrid: null,
      productSetSizing: null,
      fetchingBlockContent: false
    };
  },

  methods: {},

  created() {}
};
</script>
