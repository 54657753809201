// const _ = require('lodash');
import CampaignGoalsPopup from '@/components/campaign/campaignGoalsPopup/campaignGoalsPopup';
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import JourneyExitCriteriaPopup from '@/components/journeyExitCriteria/journeyExitCriteria';

export default {
  name: 'JourneySettingsPopup',
  props: {},
  components: {
    CampaignGoalsPopup,
    UserPropertyFilterComponent,
    JourneyExitCriteriaPopup
  },
  data() {
    return {
      visible: false,
      title: '',
      buttonText: '',
      showLoader: false,
      readOnly: false,
      useAudienceFilter: false,
      journeyForm: {
        follow_dnd: false,
        follow_capping: false,
        audience: { filters: [], performedFilter: [], notPerformedFilter: [], matchAll: true }
      },

      journeyFormRules: {}
    };
  },

  methods: {
    showDialog(title, buttonText, form, readOnly) {
      this.title = title;
      this.buttonText = buttonText;
      this.visible = true;

      // parse user filters if provided.
      if (form.audience) {
        form.audience = JSON.parse(form.audience);
        this.useAudienceFilter = true;
      }

      // Read existing settings.
      this.journeyForm = this.overrideJson(this.journeyForm, JSON.parse(JSON.stringify(form)));
      this.journeyForm.follow_capping = this.journeyForm.follow_capping ? true : false;
      this.journeyForm.follow_dnd = this.journeyForm.follow_dnd ? true : false;

      // Render - Campaign Goals
      this.readOnly = readOnly ? true : false;
      window.requestAnimationFrame(() => {
        this.$refs.goalsPopup.renderContent(this.journeyForm.goal_info, this.readOnly);
      });

      // Render - Exit Criteria
      window.requestAnimationFrame(() => {
        this.$refs.journeyExitCriteriaDialog.renderContent(this.journeyForm.exit_criteria);
      });
    },

    async validateSettings() {
      // Validate Goals
      if (!this.readOnly) {
        let isValid = await this.$refs.goalsPopup.validateForm();
        if (!isValid) return false;
      }

      // Validate Exit Criteria.
      let isValid = await this.$refs.journeyExitCriteriaDialog.validateSettings();
      if (!isValid) return false;

      return new Promise((resolve) => {
        this.$refs['saveJourneyForm'].validate((valid) => {
          resolve(valid);
        });
      });
    },

    async onDialogSaveClick() {
      // Validate settings.
      let isValid = await this.validateSettings();
      if (!isValid) return;

      this.journeyForm.goal_info = this.$refs.goalsPopup.readProcessedContent();
      this.journeyForm.exit_criteria = this.$refs.journeyExitCriteriaDialog.readProcessedContent();

      // Read audience
      if (this.useAudienceFilter) {
        this.journeyForm.audience = JSON.stringify(this.$refs.userFilter.getFilters());
      } else this.journeyForm.audience = null;

      this.$emit('onSaveJourneySettings', this.journeyForm);
      this.visible = false;
    }
  },

  mounted() {}
};
