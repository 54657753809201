<template>
  <div class="styleBuilder">
    <el-row :gutter="20">
      <!-- Ck Editor -->
      <el-col :span="24" class="textOptions">
        <div class="textEditorContainer">
          <el-popover popper-class="dynamicUserProp" v-model="ckEditorDynmicPopover" trigger="click">
            <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :inputType="'inputType'" @setDynamicPropText="addDynamicPropToTextBlock"></DynamicUserProperty>
            <el-button type="text" info class="userIconBtn" slot="reference" circle>
              <i class="el-icon-user-solid" aria-hidden="true"></i>
            </el-button>
          </el-popover>
          <template v-if="editorVisible">
            <MceTextEditor :customFontList="customFontList" ref="mceEditor" :height="editorHeight" @onBlockEditorHtmlChange="onTextEditorDataChanges" :inputValue="inputData.html" />
          </template>
        </div>
      </el-col>

      <!-- Text Margin -->
      <el-col :span="24" class="optionItem paddingContainer" v-if="inputData.margin">
        <label class="label"> Text Spacing </label>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
            <label class="label bottom">Top</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
            <label class="label bottom">Bottom</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.left" :min="0" :max="300" size="mini"></el-input-number>
            <label class="label bottom">Left</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.right" :min="0" :max="300" size="mini"></el-input-number>
            <label class="label bottom">Right</label>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" src="../styleBuilder.scss"></style>

<script>
import textStyleBuilderComponent from './textStyleBuilderComponent';
export default textStyleBuilderComponent;
</script>
