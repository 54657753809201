<template>
  <div id="webPushEditorPage">
    <el-container style="margin-right: 0px">
      <el-row class="webPushEditor" :gutter="20">
        <!-- Left Side Form -->
        <el-col class="leftSideForm" :span="15" v-bind:class="{ readOnly: readOnly === true }">
          <!-- Form  -->
          <el-form ref="webPushForm" v-bind:class="{ readOnly: readOnly === true }" label-position="top" :model="webPush" :rules="webPushFormRules">
            <!-- WebPush Title -->
            <el-form-item label="Title" prop="title">
              <el-input id="titleWPushInput" :disabled="readOnly" v-model="webPush.title"></el-input>
              <el-popover v-model="titleDynmicUserPopver" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                <el-button type="text" info class="userIconBtn" slot="reference" circle>
                  <i class="el-icon-user-solid" aria-hidden="true"></i>
                </el-button>
              </el-popover>
              <el-popover v-model="titleEmojiPiker" popper-class="customTooltip" trigger="click" v-if="!readOnly">
                <picker :showPreview="false" :perLine="20" style="width: 355px" :exclude="['flags']" @select="onTitleEmojiSelected" :native="true" set="emojione" />
                <el-button type="text" info class="emojiButton" slot="reference" circle>
                  <i class="fa fa-smile-o" aria-hidden="true"></i>
                </el-button>
              </el-popover>
            </el-form-item>

            <!-- WebPush Message -->
            <el-form-item label="Message" prop="message">
              <el-input id="messageWPushInput" :disabled="readOnly" v-model="webPush.message"></el-input>
              <el-popover v-model="messageEmojiPiker" popper-class="customTooltip" trigger="click" v-if="!readOnly">
                <picker style="width: 355px" :showPreview="false" @select="onMessageEmojiSelected" :native="true" set="emojione" />
                <el-button type="text" info class="emojiButton" slot="reference" circle>
                  <i class="fa fa-smile-o" aria-hidden="true"></i>
                </el-button>
              </el-popover>

              <el-popover v-model="messageDynmicUserPopver" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'message'" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                <el-button type="text" info class="userIconBtn" slot="reference" circle>
                  <i class="el-icon-user-solid" aria-hidden="true"></i>
                </el-button>
              </el-popover>
            </el-form-item>

            <!--WebPush  Image Url -->
            <el-form-item label=" Notification Image URL" prop="image">
              <div class="imageFormItem">
                <el-input id="imageWPushInput" :disabled="readOnly" v-model="webPush.image" placeholder="Notification Image URL"></el-input>
                <!-- Image upload. -->
                <template v-if="!readOnly">
                  <el-button type="primary" plain class="imageIconBtn" @click="$refs.webPushImageManager.toggleVisiblity()"> Select Image </el-button>
                  <ImageManager @onSelect="onWebPushImageUpload(webPush, 'image', ...arguments)" ref="webPushImageManager"></ImageManager>
                </template>

                <!-- Dynamic User Property Popover -->
                <el-popover v-model="imageDynmicUserPopver" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                  <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyForImageComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'image'" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                  <el-button type="primary" plain slot="reference" class="imageIconBtn">
                    <i class="el-icon-user-solid" aria-hidden="true"></i>
                  </el-button>
                </el-popover>
              </div>
              <div class="hint">Recommended size: 1080px x 540px. Aspect ratio (width:height): 2:1. Actual rendering depends on screen size. JPG, JPEG, PNG formats only. GIF format also available but for iOS 10+ only. Less than 1 MB in size. URL must begin with HTTPS.</div>
            </el-form-item>

            <!-- WebPush Icon Image -->
            <el-form-item label=" Icon URL (Square Icon)" prop="icon">
              <div class="imageFormItem">
                <el-input :disabled="readOnly" v-model="webPush.icon" placeholder="Icon URL"></el-input>
                <!-- Image upload. -->
                <template v-if="!readOnly">
                  <el-button type="primary" plain class="imageIconBtn" @click="$refs.webPushIconManager.toggleVisiblity()"> Select Image </el-button>
                  <ImageManager @onSelect="onWebPushImageUpload(webPush, 'icon', ...arguments)" ref="webPushIconManager"></ImageManager>
                </template>
              </div>
              <div class="hint">Recommended size: 192px x 192px. Actual rendering depends on screen size. JPG, JPEG, PNG formats only. Less than 1 MB in size. URL must begin with HTTPS.</div>
            </el-form-item>

            <!-- Deep Link -->
            <el-form-item label="Click Link - Will be opened when user clicks on notification" prop="deepLinkUrl">
              <el-input id="deepLinkUrlWPushInput" :disabled="readOnly" v-model="webPush.deepLinkUrl" placeholder="Deeplink Url"></el-input>
              <el-popover v-model="deepLinkDynmicUserPopver" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyForDeepLinkComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'deepLinkUrl'" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                <el-button type="text" info class="userIconBtn" slot="reference" circle>
                  <i class="el-icon-user-solid" aria-hidden="true"></i>
                </el-button>
              </el-popover>
              <div class="hint">Deeplink can be any URI (eg. myapp://productdetails/itemabc). URL can be HTTP or HTTPS (eg. https://www.growlytics.in)</div>
            </el-form-item>
          </el-form>
        </el-col>

        <!-- Right Side Preview -->
        <el-col :span="9">
          <div ref="webPushPreview" class="rightSidePreview">
            <!-- Web Push Preview -->
            <div class="webPushDiv">
              <div class="webPushSwitchMode">
                <el-select v-model="selectWebPushPriview" placeholder="Select">
                  <el-option v-for="item in priviewMode" :key="item.value" :label="item.key" :value="item.value"> </el-option>
                </el-select>
              </div>

              <!-- chromeWindows10 Notification -->
              <div class="notification chromeWindows10" v-if="selectWebPushPriview == 'chromeWindows10'">
                <div class="notifImageContainer">
                  <div class="notifImage" v-if="webPush.image" :style="{ 'background-image': 'url(' + webPush.image + ')' }"></div>
                </div>
                <div class="body">
                  <div class="icon" v-if="webPush.icon" :style="{ 'background-image': 'url(' + webPush.icon + ')' }"></div>
                  <div class="icon" v-else></div>
                  <div class="content">
                    <div class="title">{{ webPush.title }}</div>
                    <div class="message">{{ webPush.message }}</div>
                    <div class="webSiteLink">
                      <span v-if="webPush.icon">Google Chrome &middot;</span>
                      yourwebsite.com
                    </div>
                  </div>
                </div>
              </div>

              <!-- Chrome Mac WebPush -->
              <div class="notification chromeMac" v-if="selectWebPushPriview == 'chromeMac'">
                <div class="leftSide">
                  <div class="icon"></div>

                  <div class="content">
                    <div class="title">{{ webPush.title }}</div>
                    <div class="message">{{ webPush.message }}</div>
                  </div>

                  <div class="notifImage" v-if="webPush.icon" :style="{ 'background-image': 'url(' + webPush.icon + ')' }"></div>
                </div>
                <div class="rightSide">
                  <div class="closeBtn">
                    <span>Close</span>
                  </div>
                  <div class="settingsBtn">
                    <span>Settings</span>
                  </div>
                </div>
              </div>

              <!-- Android - Chrome Notification -->
              <div class="notification chromeAndroid" v-if="selectWebPushPriview == 'chromeAndroid'">
                <div class="body">
                  <div class="notificationHeader">
                    <div class="smallAppIcon"><i class="fa fa-chrome" aria-hidden="true"></i></div>
                    <div class="headerContent">
                      <span class="appName">Chrome &middot;</span>
                      <span class="webSiteLink">yourwebsite.com&middot; now</span>
                    </div>
                  </div>

                  <div class="content">
                    <div class="contentOfWebPush">
                      <div class="title">{{ webPush.title }}</div>
                      <div class="message">{{ webPush.message }}</div>
                    </div>
                    <div class="icon" v-if="webPush.icon" :style="{ 'background-image': 'url(' + webPush.icon + ')' }"></div>
                  </div>
                  <div class="notifImage" v-if="webPush.image" :style="{ 'background-image': 'url(' + webPush.image + ')' }"></div>
                </div>
                <div class="siteSettingDiv">SITE SETTINGS</div>
              </div>

              <!-- Chrome Mac WebPush -->
              <div class="notification firefoxMac" v-if="selectWebPushPriview == 'fireFoxMac'">
                <div class="leftSide">
                  <div class="icon firefox"></div>

                  <div class="content">
                    <div class="title">{{ webPush.title }}</div>
                    <div class="message">{{ webPush.message }}</div>
                  </div>

                  <div class="notifImage" v-if="webPush.icon" :style="{ 'background-image': 'url(' + webPush.icon + ')' }"></div>
                </div>
                <div class="rightSide">
                  <div class="closeBtn">
                    <span>Close</span>
                  </div>
                  <div class="settingsBtn">
                    <span>Settings</span>
                  </div>
                </div>
              </div>

              <!-- ChromeWindows WebPush -->
              <div class="notification fireFoxWindows" v-if="selectWebPushPriview == 'fireFoxWindow'">
                <!-- Header -->
                <div class="notificationHeader">
                  <div class="title">{{ webPush.title }}</div>
                  <i class="el-icon-close closeIcon"></i>
                </div>

                <!-- Body -->
                <div class="body">
                  <div class="icon" v-if="webPush.icon" :style="{ 'background-image': 'url(' + webPush.icon + ')' }"></div>
                  <div class="icon" v-else></div>
                  <div class="content">
                    <div class="message">{{ webPush.message }}</div>
                    <div class="webSiteLink">yourwebsite.webengagepush.com</div>
                  </div>
                </div>

                <!-- Settings Div -->
                <div class="settingIcon">
                  <span><i class="el-icon-s-tools"></i></span>
                </div>
              </div>

              <div class="w-full mt-4 text-center">
                <el-button class="sendTestNotificationBtn" type="primary" @click="showWebPushContentTestForm()">Send Test Notification</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- // Push Notification Test dialog  -->
      <el-dialog class="webPushTestDailog" append-to-body :visible.sync="showWebPushNotificationTestPopup" width="1000px" center title="Send a test Web Push Notification ">
        <el-form ref="pushNotificationTestForm" @submit.native.prevent>
          <!-- // Query Search  -->
          <el-form-item required>
            <!-- Header -->
            <div class="subHeader">Only customer who are reachable on WebPush Notification will be shown in search.</div>

            <!-- // User To Search -->
            <div class="inputDetailOfUser">
              <el-autocomplete v-model="inputOfUser" class="selectAttribute" :fetch-suggestions="fetchUserListForPushContentTest" placeholder="Search User" @select="updateSelectedUserList">
                <el-select v-model="userAttributeToSearchBy" slot="prepend" placeholder="Select user property to search by">
                  <el-option label="Email" value="email"></el-option>
                  <el-option label="Mobile" value="mobile"></el-option>
                  <el-option label="Name" value="name"></el-option>
                  <el-option label="Growlytics ID" value="id"></el-option>
                </el-select>
              </el-autocomplete>
            </div>
          </el-form-item>

          <!-- // Show User List  -->
          <div class="userListDiv" v-if="selectedUserList.length > 0">
            <el-table :data="selectedUserList">
              <el-table-column prop="email" label="Email"> </el-table-column>
              <el-table-column prop="name" label="Name"> </el-table-column>
              <el-table-column prop="id" label="Id"> </el-table-column>
              <el-table-column prop="mobile" label="Mobile"> </el-table-column>
              <el-table-column width="50" label="">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="deleteRow(scope.$index, selectedUserList)" type="text" size="small">
                    <el-button type="text" class="deleteBtn" icon="el-icon-delete"></el-button>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- // Test Button  -->
          <div class="sendTestBtn" v-if="selectedUserList.length > 0">
            <el-button class="sendTestNotificationBtn" :loading="sendingTestNotification" type="success" size="mini" @click="testPushNotificationContent()">Send a test notification</el-button>
          </div>
        </el-form>
      </el-dialog>
    </el-container>
  </div>
</template>

<style lang="scss" src="./webPushEditor.scss"></style>

<script>
import WebPushEditorComponent from './webPushEditorComponent';
export default WebPushEditorComponent;
</script>
