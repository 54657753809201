const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.saveBlock = function (params) {
  return axios.post(baseUrlNew + 'emailBlock', params, {
    headers: buildAuthHeaders()
  });
};

exports.getList = function () {
  return axios.get(baseUrlNew + 'emailBlock', {
    headers: buildAuthHeaders()
  });
};

exports.getDetail = function (id) {
  return axios.get(baseUrlNew + `emailBlock/${id}`, {
    headers: buildAuthHeaders()
  });
};

exports.delete = function (id) {
  return axios.delete(baseUrlNew + `emailBlock/${id}`, {
    headers: buildAuthHeaders()
  });
};
