<template>
  <div id="eventFilterComponent">
    <!-- <div class="header">Events</div> -->

    <!-- Event Filters -->
    <div class="eventFilter" v-bind:class="{ readOnly: readOnly === true }" v-if="selectedEvent.eventName">
      <!-- Header -->
      <div class="filterHeader">
        <template v-if="!fetchingData">
          <el-select class="addNewEventBtn" size="mini" v-if="!readOnly" v-model="selectedEvent.eventName" filterable @change="onEventSelect()" placeholder="Select Event">
            <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
              <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
            </el-option-group>
          </el-select>
          <span class="eventNameFeild" v-else>{{ selectedEvent.eventName }}</span>
        </template>

        <el-button class="addFilterButton" icon="el-icon-plus" v-if="!readOnly" type="text" size="mini" @click="addPropertyFilter(selectedEvent)">Add Filter</el-button>
      </div>

      <!-- Filter Of Particular Event -->
      <div class="propertyFilterList" v-if="selectedEvent.filters.length > 0">
        <div class="propertyFilter" v-for="(subFilter, index1) in selectedEvent.filters" :key="index1">
          <!-- And/OR TAG -->
          <div>
            <template v-if="!readOnly">
              <el-radio-group v-if="index1 != 0" class="andOrSwitch" size="mini" v-model="selectedEvent.matchAnyFilter">
                <el-radio-button :label="false">and</el-radio-button>
                <el-radio-button :label="true">or</el-radio-button>
              </el-radio-group>
              <div class="text-sm">Where</div>
            </template>
            <template v-else>
              {{ selectedEvent.matchAnyFilter }}
              <span class="readOnlyExtraFeild" v-if="index1 != 0">and</span>
            </template>
          </div>

          <!-- Select Filter Property -->
          <el-select class="smallDropdown" size="mini" v-if="!readOnly" v-model="subFilter.propertyName" filterable @change="onFilterPropertyChange(subFilter, selectedEvent.properties)" placeholder="Select Property">
            <!-- <el-option v-for="(property, index) in selectedEvent.properties" :key="index" :label="property.name" :value="property.name"></el-option> -->
            <el-option-group v-for="eventPropGroup in selectedEvent.properties" :key="eventPropGroup.label" :label="eventPropGroup.label">
              <el-option v-for="(property, index) in eventPropGroup.options" :key="index" :label="property.name" :value="property.name"> </el-option>
            </el-option-group>
          </el-select>
          <span class="readOnlyFeild" v-else> {{ subFilter.propertyName }}</span>

          <!-- Select Comparision Type -->
          <!-- {{subFilter}} -->
          <template v-if="subFilter.comparisionTypeList">
            <el-select class="smallDropdown" size="mini" v-if="!readOnly" v-model="subFilter.comparisonType" filterable placeholder="Select Event Property">
              <el-option v-for="(compType, index2) in subFilter.comparisionTypeList" :key="index2" :label="compType.label" :value="compType.key"></el-option>
            </el-select>
            <span class="readOnlyTypeFeild" v-else>
              {{ getDisplayText(subFilter.comparisonType, subFilter.comparisionTypeList) }}
            </span>
          </template>
          <!-- {{subFilter}} -->

          <!-- Input Field List Based on types -->
          <template v-if="subFilter.comparisonType && subFilter.comparisonType != 'is not null' && subFilter.comparisonType != 'is null'">
            <div class="filterInputContainer">
              <!-- Render Date For Date Type -->
              <div class="inputSearch" v-if="subFilter.propertyInfo.type == 'date'">
                <el-date-picker size="mini" v-model="subFilter.value" v-if="!readOnly" type="datetime" placeholder="Select date and time"> </el-date-picker>
                <span class="readOnlyFeild" v-else>{{ subFilter.value }}</span>
              </div>

              <!-- Render Number input for number type -->
              <div class="inputSearch" v-if="subFilter.propertyInfo.type == 'decimal' || subFilter.propertyInfo.type == 'number' || subFilter.propertyInfo.type == 'bigint'">
                <el-input size="mini" placeholder="Enter value" v-if="!readOnly" v-model="subFilter.value"></el-input>
                <span class="readOnlyFeild" v-else>{{ subFilter.value }}</span>
              </div>

              <!-- Render Searchable String for string type  -->
              <div class="inputSearch" v-if="subFilter.propertyInfo.type == 'string'">
                <el-autocomplete autocomplete="off" size="mini" placeholder="Enter search term" v-if="!readOnly" @focus="setCurrentEventData(selectedEvent.eventName, subFilter)" v-model="subFilter.value" :fetch-suggestions="fetchEventPropertySuggestion" clearable></el-autocomplete>
                <span class="readOnlyFeild" v-else>{{ subFilter.value }}</span>
              </div>

              <!-- Boolean - Yes/No dropdown -->
              <div v-if="subFilter.propertyInfo.type == 'boolean'">
                <el-select size="mini" v-if="!readOnly" v-model="subFilter.value">
                  <el-option label="True/Yes" value="true"></el-option>
                  <el-option label="False/No" value="false"></el-option>
                </el-select>
                <span class="readOnlyFeild" v-else>{{ subFilter.value }}</span>
              </div>

              <!-- Text Array -->
              <template v-if="subFilter.propertyInfo.type == 'text array' || subFilter.propertyInfo.type == 'string array'">
                <el-input size="small" class="smallDropdown w-full" v-if="!readOnly" placeholder="Enter comma seperated list items to match" v-model="subFilter.value"></el-input>
                <span class="readonlyField" v-else>{{ subFilter.value }}</span>
              </template>
            </div>
          </template>

          <!-- Delete Button -->
          <el-button class="addFilterButton deleteBtn" v-if="!readOnly" icon="el-icon-delete" @click="deletePropertyFilter(selectedEvent.filters, index1)" type="text" size="mini"></el-button>
        </div>
      </div>
    </div>

    <!-- Loader for fetching data -->
    <div class="loader" v-if="fetchingData">
      <beat-loader :loading="fetchingData" color="rgb(220, 223, 230)" size="8px"></beat-loader>
    </div>

    <!-- Add event filter button -->
    <template v-if="!selectedEvent.eventName && !fetchingData">
      <el-select class="addNewEventBtn" v-if="!readOnly" size="mini" v-model="selectedEvent.eventName" filterable @change="onEventSelect()" placeholder="Select Event">
        <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
          <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
        </el-option-group>
      </el-select>
      <span class="readOnlyFeild" v-else>{{ selectedEvent.eventName }}</span>
    </template>
  </div>
</template>

<style lang="scss" src="./campaignEventFilter.scss"></style>

<script>
import CampaignEventFilterComponent from './campaignEventFilterComponent';
export default CampaignEventFilterComponent;
</script>
