<template>
  <el-dialog append-to-body class="templateGalleryDailogBox" :lock-scroll="true" :visible.sync="visible" width="1200px">
    <!-- Header -->
    <div class="dialog-header">
      <div style="flex: 1; margin-bottom: -5px">
        <div class="editorButtons" v-bind:class="{ selectedEditorButton: galleryMode == 'default' }" @click="onChangeGalleryMode('default')">&nbsp;&nbsp;&nbsp;&nbsp; Templates &nbsp;&nbsp;&nbsp;&nbsp;</div>

        <div class="editorButtons" v-bind:class="{ selectedEditorButton: galleryMode == 'custom' }" @click="onChangeGalleryMode('custom')">&nbsp;&nbsp;&nbsp;&nbsp; Saved Templates &nbsp;&nbsp;&nbsp;&nbsp;</div>

        <el-button style="float: right; margin-top: 8px; margin-right: 15px; font-size: 20px; color: #555" type="text" icon="el-icon-close" size="medium" @click="visible = false"> </el-button>
      </div>
    </div>

    <!-- // show list of templates here -->
    <div class="body">
      <div v-if="galleryMode == 'default'">
        <!-- Loader -->
        <div class="loadingDiv" v-if="fetchingDefaultGallery" v-loading="true" style="min-height: 300px"></div>

        <el-row :gutter="30">
          <el-col :span="8" v-for="(template, index) in defaultGallery" :key="index">
            <div class="defaultTemplteItem">
              <div class="templateItemInfo">
                {{ template.name }} <br />
                <el-button class="selectTemplateBtn" type="success" size="mini" @click="onApplyTemplate(template)">Use This Template</el-button>
              </div>
              <iframe class="templateIframe" :srcdoc="template.html"></iframe>
            </div>
          </el-col>
        </el-row>
      </div>

      <div v-else-if="galleryMode == 'custom'" :gutter="40">
        <!-- Loader -->
        <div class="loadingDiv" v-if="fetchingSavedGallery" v-loading="true" style="min-height: 300px"></div>

        <el-row :gutter="30" v-if="!fetchingSavedGallery && savedGallery.length > 0">
          <el-col :span="8" v-for="(template, index) in savedGallery" :key="index">
            <div class="defaultTemplteItem">
              <div class="templateItemInfo">
                {{ template.name }} <br />
                <el-button class="selectTemplateBtn" type="success" size="mini" @click="onApplyTemplate(template)">Use Template</el-button>
                <el-button class="selectTemplateBtn" type="danger" size="mini" @click="deleteTemplate(template.id)">Delete Template</el-button>
              </div>
              <iframe class="templateIframe" :srcdoc="template.html"></iframe>
            </div>
          </el-col>
        </el-row>

        <div v-else-if="!fetchingSavedGallery" class="noDatafound">No templates saved yet.</div>
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss" src="./templateGallery.scss"></style>

<script>
import TemplateGalleryComponent from './templateGalleryComponent';
export default TemplateGalleryComponent;
</script>
