import DynamicUserProperty from '@/components/dynamicUserFilterComponent/dynamicUserFilter';
import { validateWebSocialProofMessage } from '@/services/validation';
import colorPicker from '@caohenghu/vue-colorpicker';
import webSocialProofStructure from './webSocialProofStructure';
import WebSocialProofWidget from './webSocialProofWidget';
import eventModule from '@/services/event';

export default {
  name: 'WebSocialProofEditorComponent',
  components: {
    colorPicker,
    DynamicUserProperty,
    WebSocialProofWidget
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    campaignTriggerEvent: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // Content variables.
      contentAlreadyRendered: false,
      content: {
        skipOlderThan: '10080',
        minActivities: 1,
        maxActivities: 1000000,
        themeColor: '#66c33a',
        event: {
          name: null,
          property: null,
          triggerEventProperty: null,
          triggerEvent: null
        },
        widget: null
      },

      // Widget Type Chooser Dialog
      messageTypeSelectorDialogVisible: false,
      builtInProofTypes: webSocialProofStructure.typeList,

      // Validation Errors
      validationErrors: {},

      // Event Activity Variables.
      serverEventList: null,
      eventList: null,
      eventPropertyList: null,
      triggerEventPropertyList: null,
      skipTimingOptions: {
        1440: '24 hours',
        2880: '2 days',
        4320: '3 days',
        10080: '7 days',
        21600: '15 days',
        43200: '30 days'
      }
    };
  },
  watch: {
    'content.skipOlderThan': function () {
      if (this.content.widget) {
        this.content.widget.time = this.skipTimingOptions[this.content.skipOlderThan];
      }
    },
    'content.event.triggerEventProperty': function () {
      console.log('new value is ', this.content.event.triggerEventProperty);
      if (this.content.widget && !this.content.event.triggerEventProperty) {
        this.content.event.property = null;
      }
    }
  },
  methods: {
    // On Layout Change From Popup
    onWebSocialProofLayoutChange(proofObj) {
      this.content.widget = JSON.parse(JSON.stringify(proofObj));
      this.content.widget.time = this.skipTimingOptions[this.content.skipOlderThan];
      this.content.widget.icon = null;
      this.content.event.triggerEventProperty = null;
      this.messageTypeSelectorDialogVisible = false;
    },

    // From parent: Apply existing Content on edit
    async renderCampaignContent(input, campaign) {
      // Set property list of selected trigger event
      if (this.serverEventList == null) await this.fetchEventList();

      if (!this.contentAlreadyRendered) {
        this.contentAlreadyRendered = true;

        let content = input.content;
        if (!content || !content.widget) {
          // Show Message types dialog.
          this.messageTypeSelectorDialogVisible = true;
        } else {
          this.content = content;
          this.eventPropertyList = this.serverEventList[this.content.event.name];
        }
      }

      // Set campaign Trigger event from campaign if given
      if (campaign) {
        this.campaignTriggerEvent = JSON.parse(campaign.action_event_list).eventName;
      }

      this.triggerEventPropertyList = this.serverEventList[this.campaignTriggerEvent];
      if (this.content.event.triggerEvent) {
        if (this.content.event.triggerEvent != this.campaignTriggerEvent) {
          this.content.event.triggerEventProperty = null;
        }
      }
      this.content.event.triggerEvent = this.campaignTriggerEvent;
    },

    // Validate called from parent
    async validateForm() {
      let errorList = [];
      this.readProcessedContentV1();
      // Read error list.
      let content = this.content;
      let result = validateWebSocialProofMessage(this.selectedType, content);
      console.log('proof validation result', result);
      if (result.error) {
        errorList = result.error.details;
      }

      this.validationErrors = {};
      for (var i = 0; i < errorList.length; i++) {
        let path = errorList[i].path.join('.');
        this.validationErrors[path] = errorList[i].message;
      }

      return Object.keys(this.validationErrors).length == 0;
    },

    // From Parent: On Next Click
    async readProcessedContentV1() {
      let response = {
        providerId: null,
        content: {
          on_site_content: JSON.stringify(this.content)
        }
      };
      return response;
    },

    // Show
    showChangeLayoutDailog() {
      this.messageTypeSelectorDialogVisible = true;
    },

    /************************* Internal Rendering Methods *****************************/

    onThemeColorChange(color) {
      this.content.themeColor = this.RGBAToHexA(color.rgba.r, color.rgba.g, color.rgba.b, color.rgba.a);
    },

    onEventSelect() {
      // Read properties of given event
      this.eventPropertyList = this.serverEventList[this.content.event.name];
      this.content.event.property = null;
    },

    async fetchEventList() {
      try {
        let result = await eventModule.getEventList(this);
        let listFromServer = Object.keys(result.data.data.eventList);
        this.serverEventList = result.data.data.eventList;

        // Build Custom Events
        let customEventsList = [];
        for (let i = 0; i < listFromServer.length; i++) {
          if (this.grwDefaultEventList.indexOf(listFromServer[i]) == -1) {
            customEventsList.push(listFromServer[i]);
          }
        }

        // Build White-listed events
        let defaultEventsList = [];
        let channelDefaultEvents = this.grwChannelWiseAllowedDefaultEvents['webSocialProof'];
        if (channelDefaultEvents) {
          for (let i = 0; i < listFromServer.length; i++) {
            if (channelDefaultEvents.indexOf(listFromServer[i]) != -1) {
              defaultEventsList.push(listFromServer[i]);
            }
          }
        }

        this.eventList = [];
        this.eventList.push(
          {
            label: 'Growlytics Events',
            options: defaultEventsList
          },
          {
            label: 'Custom Events',
            options: customEventsList
          }
        );

        console.log('event list is', this.eventList);
      } catch (err) {
        this.reportError(err);
        this.errorToast('Something went wrong.');
      }
    }
  },

  mounted() {
    // this.onWebSocialProofLayoutChange('modal');
    // this.onWebSocialProofLayoutChange('bigModal');
    // this.onWebSocialProofLayoutChange('cover');
    // this.onWebSocialProofLayoutChange('header');
    // this.onWebSocialProofLayoutChange('footer');

    this.fetchEventList();
  }
};
