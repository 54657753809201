import { Picker } from 'emoji-mart-vue';
import colorPicker from '@caohenghu/vue-colorpicker';
import DynamicUserProperty from '@/components/dynamicUserFilterComponent/dynamicUserFilter';
import inAppStructure from './inAppStructure';
import { validateInAppNotificationMessage } from '@/services/validation';
import customerModule from '../../../../../services/customer';
import campaignPreviewService from '@/services/campaignMessagePreview';
const _ = require('lodash');

export default {
  name: 'InAppEditorComponent',
  components: {
    Picker,
    colorPicker,
    DynamicUserProperty
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    allowDynamicEvents: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      contentAlreadyRendered: false,

      // Message Type Chooser Dialog
      messageTypeSelectorDialogVisible: false,
      availableInAppInfo: inAppStructure.inAppTypes,
      selectedType: null,
      content: null,

      // Validation Errors
      validationErrors: {},
      isStickyPreviewMode: false,

      // Send test notif dialog
      showInAppNotifTestPopup: false,
      userAttributeToSearchBy: 'email',
      selectedUserList: [],
      queryString: '',
      inputOfUser: '',
      sendingTestNotification: false
    };
  },

  methods: {
    handleScroll() {
      let top = document.querySelector('#inAppEditor').getBoundingClientRect().top;
      if ((top < 87) & !this.isStickyPreviewMode) {
        // console.log('making sticky');
        let el = this.$refs.mobilePreview;
        // Make it sticky
        this.isStickyPreviewMode = true;
        el.classList.add('sticky');
      } else if (top > 87 && this.isStickyPreviewMode) {
        // console.log('unmaking sticky');
        let el = this.$refs.mobilePreview;
        this.isStickyPreviewMode = false;
        el.classList.remove('sticky');
      }
    },

    // On Layout Change From Popup
    onInAppLayoutChange(type) {
      this.selectedType = type;
      this.content = inAppStructure.getInAppStructure(type);
      this.messageTypeSelectorDialogVisible = false;
    },

    // From parent: Apply existing Content on edit
    renderCampaignContent(input) {
      if (!this.contentAlreadyRendered) {
        this.contentAlreadyRendered = true;

        let content = input.content;

        if ((!content || !content.type) && this.selectedType == null) {
          // Show Message types dialog.
          this.messageTypeSelectorDialogVisible = true;
        } else if (content.type) {
          this.content = content.content;
          this.selectedType = content.type;
        }

        window.requestAnimationFrame(() => {
          this.handleScroll();
        });
      }
    },

    renderCampaignContentForJourney(data) {
      if (data.content) {
        data.content = JSON.parse(data.content);
      }
      this.renderCampaignContent(data);
    },

    // Validate called from parent
    validateForm() {
      let errorList = [];
      this.readProcessedContentV1();

      // Read error list.
      let content = this.content;
      let result = validateInAppNotificationMessage(this.selectedType, content);
      if (result.error) {
        errorList = result.error.details;
      }

      this.validationErrors = {};
      for (var i = 0; i < errorList.length; i++) {
        let path = errorList[i].path.join('.');
        this.validationErrors[path] = errorList[i].message;
      }

      // Atleast one buttno must be present.
      if (!this.content.buttons.visible && !this.content.closeButton.visible) {
        this.validationErrors['closeBtn'] = 'Close button not provided. Please enable close button or add custom button.';
      }

      console.log('validation result', this.validationErrors);
      return Object.keys(this.validationErrors).length == 0;
    },

    // From Parent: On Next Click
    readProcessedContentV1() {
      // If no buttons provided, set buttons.visible to false, true otherwise.
      if (this.content.buttons.buttons.length == 0) {
        this.content.buttons.visible = false;
      } else {
        this.content.buttons.visible = true;
      }

      // Media Image: IF not url is empty, set media.visible
      if (this.content.buttons.buttons.length == 0) {
        this.content.buttons.visible = false;
      } else {
        this.content.buttons.visible = true;
      }

      //
      if (this.content.text.media.url.length == 0) {
        this.content.text.media.visible = false;
      } else {
        this.content.text.media.visible = true;
      }

      let response = {
        providerId: null,
        content: {
          in_app_content: JSON.stringify({
            type: this.selectedType,
            content: this.content
          })
        }
      };

      return response;
    },

    // Show
    showChangeLayoutDailog() {
      this.messageTypeSelectorDialogVisible = true;
    },

    onAddButton() {
      let button = JSON.parse(JSON.stringify(inAppStructure.dummyButton));
      this.content.buttons.buttons.push(button);
    },

    /************************* Internal Rendering Methods *****************************/

    getFlexValueForPosition(position) {
      if (position == 'center') {
        return 'center';
      } else if (position == 'top') {
        return 'flex-start';
      } else {
        return 'flex-end';
      }
    },

    getHeaderFooterFlexDirection(type) {
      if (type == 'header') {
        return 'flex-start';
      } else {
        return 'flex-end';
      }
    },

    onTitleColorPicked(color) {
      this.content.text.titleColor = this.RGBAToHexA(color.rgba.r, color.rgba.g, color.rgba.b, color.rgba.a);
    },

    onCloseIconColorPicked(color) {
      this.content.closeButton.color = this.RGBAToHexA(color.rgba.r, color.rgba.g, color.rgba.b, color.rgba.a);
    },

    onDescriptionColorPicked(color) {
      this.content.text.descriptionColor = this.RGBAToHexA(color.rgba.r, color.rgba.g, color.rgba.b, color.rgba.a);
    },

    onBackgroundColorPicked(color) {
      this.content.background.bgColor = this.RGBAToHexA(color.rgba.r, color.rgba.g, color.rgba.b, color.rgba.a);
    },

    setButtonColor(button, isTextcolor, color) {
      if (isTextcolor) button.textColor = this.RGBAToHexA(color.rgba.r, color.rgba.g, color.rgba.b, color.rgba.a);
      else button.backgroundColor = this.RGBAToHexA(color.rgba.r, color.rgba.g, color.rgba.b, color.rgba.a);
    },

    /**************************** Start: Push Notification Test Methods *********************************/

    async onSendTestNotification() {
      // Validate Contents First
      if (!this.validateForm()) {
        this.errorToast('Please resolve issues mentioned.');
      }

      // Process Content
      let campaignContent = this.readProcessedContentV1();
      campaignContent = typeof campaignContent.content == 'string' ? JSON.parse(campaignContent.content) : campaignContent.content;

      let params = {
        customerList: this.selectedUserList,
        content: campaignContent
      };
      this.sendingTestNotification = true;
      try {
        let result = await campaignPreviewService.sendInAppCampaignPreview(params);
        if (result.data.success) {
          this.successToast(`Notification sent.`);
          this.showPushNotificationTestPopup = false;
        } else if (result.data.message) {
          this.errorToast(result.data.message);
        }
        this.sendingTestNotification = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Something went wrong.`);
        this.sendingTestNotification = false;
      }
    },

    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },

    fetchUserListForPushContentTest(queryString, cb) {
      let params = {
        queryString: queryString,
        customerProp: this.userAttributeToSearchBy,
        pushType: 'mobile'
      };

      let results = [];
      customerModule
        .fetchUserSuggestionListForPushTest(params, this)
        .then((result) => {
          if (result.data.success) {
            results = queryString ? result.data.data.filter(this.createFilter(queryString)) : result.data.data;
          }
          cb(results);
        })
        .catch((e) => {
          this.reportError(e);
          this.errorToast(`Something went wrong. Please try again later.`);
          cb(results);
        });
    },

    updateSelectedUserList(item) {
      let user = _.find(this.selectedUserList, (u) => {
        return u.id == item.id;
      });
      if (!user) {
        this.selectedUserList.push(item);
      }
      this.inputOfUser = '';
    },

    showSendTestNotifPopup() {
      this.showInAppNotifTestPopup = true;
    }

    /**************************** End: Push Notification Test Methods *********************************/
  },

  mounted() {
    // this.onInAppLayoutChange('modal');
    // this.onInAppLayoutChange('bigModal');
    // this.onInAppLayoutChange('cover');
    // this.onInAppLayoutChange('header');
    // this.onInAppLayoutChange('footer');

    // Add scroll listener
    let appDiv = document.getElementById('appDiv');
    appDiv.onscroll = this.handleScroll;
  },

  beforeDestroy() {
    // Remove scroll listener
    let appDiv = document.getElementById('appDiv');
    appDiv.onscroll = undefined;
  }
};
