import productSetsModule from '@/services/productSets';
import couponModule from '@/services/coupon';
import ButtonComponent from './dragDropButtonBlock';
import DragDropEmailHeaderBlock from './../dragDropEmailHeaderBlock';
import DragDropEmailFooterBlock from './../dragDropEmailFooterBlock';
import DragDropEmailTextBlock from './../dragDropEmailTextBlock';
import DragDropEmailMinimalTextBlock from './../dragDropEmailMinimalTextBlock';
import DragDropEmailCouponBlock from './../dragDropEmailCouponBlock';
import DragDropEmailHeroBlock from './../dragDropHeroBlock';

export default {
  name: 'dragDropBlockSeperator',
  props: {
    isSelectedBlock: {
      type: Boolean,
      default: false
    },
    block: {
      type: Object,
      required: true
    },
    isSubBlock: {
      type: Boolean,
      required: true
    },
    brandInfo: {
      type: Object
    },
    mailOptions: {
      type: Object,
      required: true
    }
  },
  components: {
    ButtonComponent,
    DragDropEmailTextBlock,
    DragDropEmailCouponBlock,
    DragDropEmailMinimalTextBlock,
    DragDropEmailHeaderBlock,
    DragDropEmailFooterBlock,
    DragDropEmailHeroBlock
  },
  data() {
    return {
      suggestedProducts: null,
      productSetGrid: null,
      productSetSizing: null,
      fetchingBlockContent: false,

      singleProduct: null,
      discountInfo: null
    };
  },

  methods: {
    buildProductsTable() {
      let sizing = {
        imageHeight: 125,
        width: 33.33,
        productName: {
          marginTop: 5,
          fontSize: 13
        },
        productPrice: {
          marginTop: 3,
          fontSize: 13
        }
      };
      if (this.block.content.cols == 2) {
        sizing.imageHeight = 180;
        sizing.width = 33.33;
        sizing.productName.marginTop = 5;
        sizing.productName.fontSize = 15;
        sizing.productPrice.marginTop = 3;
        sizing.productPrice.fontSize = 12;
      } else if (this.block.content.cols == 1) {
        sizing.imageHeight = 250;
        sizing.width = 100;
        sizing.productName.marginTop = 7;
        sizing.productName.fontSize = 16;
        sizing.productPrice.marginTop = 5;
        sizing.productPrice.fontSize = 14;
      }
      this.productSetSizing = sizing;

      // Build rendering structure.
      let productsProcessed = 0;
      let grid = [];

      // compress product name
      let allowedSize = 50;
      // if (this.block.content.cols == 2) allowedSize = 90;
      // else if (this.block.content.cols == 1) allowedSize = 140;

      for (let i = 0; i < this.block.content.rows; i++) {
        if (productsProcessed >= this.suggestedProducts.length) break;

        let row = [];
        for (let j = 0; j < this.block.content.cols; j++) {
          if (productsProcessed >= this.suggestedProducts.length) break;

          if (this.suggestedProducts[productsProcessed].name) {
            let productNameSize = this.suggestedProducts[productsProcessed].name.length;
            if (productNameSize > allowedSize) {
              this.suggestedProducts[productsProcessed].name = this.suggestedProducts[productsProcessed].name.substr(0, allowedSize) + '...';
            } else {
              // this.suggestedProducts[productsProcessed].name = this.suggestedProducts[productsProcessed].name;
            }
          }

          row.push(this.suggestedProducts[productsProcessed]);

          productsProcessed++;
        }
        grid.push(row);
      }

      this.productSetGrid = grid;
    },

    async fetchDiscount() {
      try {
        this.fetchingBlockContent = true;
        let apiInput = this.block.content.discountId;
        let result = await couponModule.getDetails(apiInput);
        this.discountInfo = result.data;
        this.buildProductsTable();
        this.fetchingBlockContent = false;
      } catch (err) {
        this.fetchingBlockContent = false;
        console.error('Failed to fetch suggested products.', err);
        this.reportError(err);
      }
    },

    async fetchProductSetData() {
      try {
        this.fetchingBlockContent = true;
        let apiInput = this.block.content.productInfo;
        let result = await productSetsModule.getProductSetData(apiInput);
        this.suggestedProducts = result.data;
        this.buildProductsTable();
        this.fetchingBlockContent = false;
      } catch (err) {
        this.fetchingBlockContent = false;
        console.error('Failed to fetch suggested products.', err);
        this.reportError(err);
      }
    },

    async fetchSingleProductData() {
      this.singleProduct = {
        id: '11gyj01scgkkjten9j',
        name: 'Product Name For Preview',
        description: `Product description for preview. Lorem Ipsum is simply dummy text of the printing and typesetting industry. There are many variations of passages of Lorem Ipsum available.`,
        image: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/single-product-image-preview.png',
        url: 'https://growlytics.in',
        price: 390,
        original_higher_price: null,
        cnt: '28'
      };

      let productId = this.block.content.productInfo.id;
      if (!productId) return;

      try {
        this.fetchingBlockContent = true;
        let result = await productSetsModule.getProductDetails(productId);
        if (result.data) {
          this.singleProduct = result.data;
        }
        this.fetchingBlockContent = false;
      } catch (err) {
        this.fetchingBlockContent = false;
        console.error('Failed to fetch single product.', err);
        this.reportError(err);
      }
    },

    // Called From Parent
    changeBlockConents(skipDataFetch, modifiedBlock) {
      if (this.block.type == 'single-product') {
        if (!skipDataFetch) {
          this.block = modifiedBlock;
          this.fetchSingleProductData();
        }
      } else {
        if (skipDataFetch) {
          this.buildProductsTable();
        } else {
          this.fetchProductSetData();
        }
      }
    },

    getCalculatedContainerStyles() {
      let styles = {
        'max-width': this.mailOptions.mailMaxWidth
      };
      if (!this.isSubBlock) {
        styles['background-color'] = this.block.content.rowBackgroundColor ? this.block.content.rowBackgroundColor : 'transparent';
        styles['background-image'] = this.block.content.rowBackgroundImage ? 'url(' + this.block.content.rowBackgroundImage + ')' : 'none';
        styles['background-size'] = 'cover';
        styles['background-position'] = 'center top';
        styles['background-repeat'] = 'no-repeat';
      } else {
        styles['width'] = '100%';
      }

      return styles;
    },

    prepareHtml() {
      if (this.block.type == 'button') {
        let buttonHtml = this.$refs.buttonRef.generateHtml();

        let styleList = this.getCalculatedContainerStyles();
        let styleListKeys = Object.keys(styleList);
        let calculatedStyles = ``;
        for (let i = 0; i < styleListKeys.length; i++) {
          let key = styleListKeys[i];
          let value = styleList[key];
          calculatedStyles += `${key}: ${value}; `;
        }

        // Set device visiblity
        let classList = [];
        if (this.block.content.deviceVisiblity == 'desktop') {
          classList.push('hide-on-mobile');
        } else if (this.block.content.deviceVisiblity == 'mobile') {
          classList.push('hide-on-desktop');
        }

        let buttonContent = `
        <table class="blockSection ${classList.join(' ')}" id="block_${this.block.id}" style="${calculatedStyles} border-spacing: 0; border-collapse: collapse; border: none;" width="${this.isSubBlock ? '100%' : this.mailOptions.mailMaxWidth}" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td style="width: 100%; padding: 0px" width="100%">
              ${buttonHtml}
            </td>
          </tr>
        </table>`;

        return buttonContent;
      }
    }
  },

  created() {
    if (this.block.type == 'product') {
      if (!this.suggestedProducts) {
        this.fetchProductSetData();
      }
    } else if (this.block.type == 'single-product') {
      this.fetchSingleProductData();
    }
  }
};
