<template>
  <div class="ckTextEditor mailBody">
    <div class="blockSection">
      <div ref="editorElement" style="width: 100%; height: 200px" :style="{ height: height }"></div>
    </div>
  </div>
</template>

<style lang="scss" src="./ckTextEditor.scss"></style>

<script>
import chartPageSelectorComponent from './ckTextEditorComponent';
export default chartPageSelectorComponent;
</script>
