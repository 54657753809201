exports.journeyTemplateList = {
  'Abandoned Cart': [
    {
      id: 'abandoned-cart-3',
      title: 'Abandoned Cart',
      subTitle: '3 Reminders',
      description: '7 out of 10 shopping carts are abandoned.  Recover more of them and drive up sales with a series of 3 followup messages on all available engagement channels.',
      previewInfo: {
        campaigns: '3 Emails, 2 WebPush, 1 WhatsApp, 1 SMS',
        time: '3 Days'
      },
      tags: ['Nurture Prospects', 'Convert Sales', 'Abandoned Cart'],
      image: 'https://static.growlytics.in/journeys/templates/ac-3-reminders.png'
    },
    {
      id: 'abandoned-cart-2',
      title: 'Abandoned Cart',
      subTitle: '2 Reminders',
      description: 'Recover abandoned carts with a series of 2 followup messages on all available engagement channels.',
      tags: ['Nurture Prospects', 'Convert Sales', 'Abandoned Cart'],
      previewInfo: {
        campaigns: '2 Emails, 2 WebPush, 1 WhatsApp, 1 SMS',
        time: '2 Days'
      },
      image: 'https://static.growlytics.in/journeys/templates/ac-2-reminders.png'
    },
    {
      id: 'abandoned-cart-1',
      title: 'Abandoned Cart',
      subTitle: '1 Reminder',
      description: 'Recover abandoned carts with single reminder message on all available engagement channels.',
      tags: ['Nurture Prospects', 'Convert Sales', 'Abandoned Cart'],
      previewInfo: {
        campaigns: '1 Email, 1 WebPush, 1 WhatsApp, 1 SMS',
        time: '1 Day'
      },
      image: 'https://static.growlytics.in/journeys/templates/ac-1-reminder.png'
    }
  ],

  'Browse Abandonment': [
    {
      id: 'browse-abandonment',
      title: 'Product Browse Abandonment',
      description: 'Did you see something you liked? Convert curiosity into cash with this basic Browse Abandonment series.',
      tags: ['Nurture Prospects', 'Convert Sales', 'Browse Abandonment'],
      previewInfo: {
        campaigns: '1 Email, 1 WebPush',
        time: '2 Days'
      },
      image: 'https://static.growlytics.in/journeys/templates/browse-abandon.png'
    },
    {
      id: 'browse-abandonment-advanced',
      title: 'Advanced Browse Abandonment',
      description: 'Targeting visitors based on their browsing behavior can yield high returns. This flow has split paths based on how many items someone has recently viewed.',
      tags: ['Nurture Prospects', 'Convert Sales', 'Browse Abandonment'],
      previewInfo: {
        campaigns: '2 Emails',
        time: '2 Hours'
      },
      image: 'https://static.growlytics.in/journeys/templates/browse-abandon-advanced.png'
    }
  ],

  'Engage Inactive Customers': [
    {
      id: 'engage-inactive-customers',
      title: 'Engage In-active Customers',
      subTitle: 'In-active 45 days after first purchase.',
      description: `Encourage lapsed customers to come back and see what's new with this standard Winback Series.`,
      tags: ['Convert Sales', 'Customer Winback'],
      previewInfo: {
        campaigns: '2 Emails, 1 WebPush, 1 SMS',
        time: '2 Days'
      },
      image: 'https://static.growlytics.in/journeys/templates/in-active-customers.png'
    }
  ],

  'Post Purchase': [
    {
      id: 'post-purchase-new-vs-returning',
      title: 'Purchase Thank You',
      subTitle: 'New vs. returning customers',
      description: `Build customer loyalty by sending a thank you email post-purchase. Treat new vs. returning purchase messages seperately.`,
      tags: ['Build Loyalty', 'Post-Purchase'],
      previewInfo: {
        campaigns: '1 Email, 1 WebPush, 1 SMS',
        time: 'Around 7 Hours'
      },
      image: 'https://static.growlytics.in/journeys/templates/post-purchase-new-vs-returning.png'
    },
    {
      id: 'post-purchase-normal-vs-loyal',
      title: 'Post Purchase',
      subTitle: 'Normal vs. loyal customers',
      description: `Build customer loyalty by sending a thank you email post-purchase. This flow has split paths for normal vs. loyal customers.`,
      tags: ['Build Loyalty', 'Post-Purchase'],
      previewInfo: {
        campaigns: '1 Email, 1 WebPush, 1 SMS',
        time: '1 To 3 Days'
      },
      image: 'https://static.growlytics.in/journeys/templates/post-purchase-normal-vs-loyal.png'
    },
    {
      id: 'post-purchase-discount-offer',
      title: 'Post Purchase',
      subTitle: 'With Offers To Loyal Customers',
      description: `Treat loyal customers with offers to increase sales.`,
      tags: ['Build Loyalty', 'Post-Purchase'],
      previewInfo: {
        campaigns: '1 Email, 1 WebPush, 1 SMS',
        time: '3 Days'
      },
      image: 'https://static.growlytics.in/journeys/templates/post-purchase-discount-offer.png'
    }
  ],

  'Welcome Series': [
    {
      id: 'welcome-standard',
      title: 'Subscriber Welcome',
      subTitle: 'Standard',
      description: `Welcome new subscribers with a 3-email series that provides an introduction to your business and encourages conversion.`,
      tags: ['Subscriber Welcome'],
      previewInfo: {
        campaigns: '3 Emails',
        time: '7 Days'
      },
      image: 'https://static.growlytics.in/journeys/templates/welcome-standard.png'
    },
    {
      id: 'welcome-cust-vs-non-cust',
      title: 'Subscriber Welcome',
      subTitle: 'Customer v. Non-Customer',
      description: `Welcome all new subscribers, but identify those that are truly just beginning their journey with you & may need an incentive to make that first purchase.`,
      tags: ['Subscriber Welcome', 'Build Loyalty', 'Nurture Prospects'],
      previewInfo: {
        campaigns: '2 Emails',
        time: '3 Days'
      },
      image: 'https://static.growlytics.in/journeys/templates/welcome-cust-vs-non-cust.png'
    },
    {
      id: 'welcome-with-discount',
      title: 'Subscriber Welcome',
      subTitle: 'With Final Discount',
      description: `This Welcome Series has 4 emails that go to all subscribers, with a fourth that only sends to those that haven’t already converted.`,
      tags: ['Subscriber Welcome', 'Build Loyalty'],
      previewInfo: {
        campaigns: '4 Emails',
        time: '10 Days'
      },
      image: 'https://static.growlytics.in/journeys/templates/welcome-with-discount.png'
    }
  ]
};
