<template>
  <el-drawer custom-class="wait-time-drawer" :visible.sync="dialogVisible" :direction="direction" :before-close="onCancel">
    <div slot="title">
      Set wait time for the next step
      <!-- <a href="http://help.growlytics.in/en/articles/5584367-updating-customer-attributes-in-journey" target="_blank" class="ml-2">
        <button class="text-white background-transparent -mt-1 px-2 py-0 text-xs leading-5 outline-none focus:outline-none ease-linear transition-all duration-150 border border-white rounded-sm" type="button">Learn More <i class="el-icon-top-right"></i></button>
      </a> -->
    </div>
    <el-radio-group v-model="radioOption">
      <el-radio label="immediately">Don't wait - Move immediately</el-radio><br />
      <el-radio label="custom">
        Wait for &nbsp;
        <el-input-number class="timeInput" v-model="timeInput" size="small" controls-position="right" :disabled="radioOption == 'immediately'" :min="1"></el-input-number>

        <el-select v-model="timeUnit" size="small" :disabled="radioOption == 'immediately'">
          <el-option v-for="item in ['minutes', 'hours', 'days']" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-radio>
    </el-radio-group>

    <div class="errorMsg" v-if="errorMsg">
      {{ errorMsg }}
    </div>

    <div class="footerButtons">
      <el-button v-if="!jurneyPublished" type="success" @click="onValidateAndSave">Continue </el-button>
      <el-button v-else type="success" @click="publishChanges"> Publish Changes </el-button>
      <el-button type="danger" plain @click="onCancel">Cancel</el-button>
    </div>
  </el-drawer>
</template>

<script>
let moment = require('moment');
export default {
  props: {
    jurneyPublished: { type: Boolean, required: true }
  },
  data() {
    return {
      nodeId: null,
      dialogVisible: false,
      direction: 'btt',
      existingData: null,

      // Data Variables
      radioOption: 'immediately',
      timeInput: 10,
      timeUnit: 'minutes',
      errorMsg: null
    };
  },
  methods: {
    onValidateAndSave() {
      this.errorMsg = '';

      // Validate Changes
      if (this.radioOption == 'custom') {
        if (isNaN(this.timeInput)) {
          this.errorMsg = 'Please provide valid time.';
        }
      }

      // Calculate time in minutes.
      let minutes = 0;
      let displayText = 'Immediately';
      if (this.radioOption == 'custom') {
        let duration = moment.duration(this.timeInput, this.timeUnit);
        minutes = duration.asMinutes();
        displayText = `Within\n${this.timeInput} ${this.timeUnit}`;
      }

      // Save Changes
      let timerData = {
        displayText: displayText,
        minutes: minutes,
        uiOptions: {
          radioOption: this.radioOption,
          timeInput: this.timeInput,
          timeUnit: this.timeUnit
        }
      };

      if (!this.existingData) this.existingData = {};
      this.existingData.timer = timerData;

      this.$emit('onChange', {
        nodeId: this.nodeId,
        data: this.existingData
      });
      this.onCancel();
    },

    publishChanges() {
      this.$swal({
        title: 'Make changes to live journey?',
        text: "This journey is live, you won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update live journey.'
      }).then(async () => {
        this.onValidateAndSave();
      });
    },

    onCancel() {
      Object.assign(this.$data, this.$options.data());
    },
    validate() {
      // getFilters
    },
    showDialog(nodeId, existingData) {
      this.nodeId = nodeId;
      this.existingData = existingData;
      if (existingData && existingData.timer && existingData.timer.uiOptions) {
        this.radioOption = existingData.timer.uiOptions.radioOption;
        this.timeInput = existingData.timer.uiOptions.timeInput;
        this.timeUnit = existingData.timer.uiOptions.timeUnit;
      }
      this.dialogVisible = true;
    }
  }
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.wait-time-drawer {
  max-width: 60%;
  height: auto !important;
  margin: auto;

  .el-drawer__header {
    height: 55px;
    background: $primary;
    padding: 5px 15px;
    color: white;
    margin: 0px;
  }

  .el-drawer__body {
    margin-top: 35px !important;
    width: 80% !important;
    max-height: 70vh;
    overcondition: auto;

    margin: auto;

    .el-radio {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .timeInput {
      width: 100px;
    }

    .el-select {
      margin-left: 5px;
      width: 150px;
    }

    .errorMsg {
      color: red;
      margin-top: -10px;
      font-size: 12px;
      margin-left: 95px;
    }

    .title {
      font-size: 13px;
      margin-bottom: 10px;
    }

    .footerButtons {
      width: 100%;
      text-align: center;
      border-top: 1px solid #ddd;
      padding: 30px 0px 20px 0px !important;
      margin-top: 30px;

      .el-button {
        min-width: 200px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  *:focus {
    outline: 0;
  }
}
</style>
