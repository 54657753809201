// const _ = require('lodash');
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import EventPicker from '@/components/chartFilters/campaignEventFilter/campaignEventFilter';

export default {
  name: 'JourneyExitCriteriaPopup',
  props: {},
  components: {
    UserPropertyFilterComponent,
    EventPicker
  },
  data() {
    return {
      segmentationError: null,
      exitCriteria: {
        v: 1,
        enabled: false,
        segments: {
          enabled: false,
          query: { filters: [], performedFilter: [], notPerformedFilter: [], matchAll: true }
        },
        events: {
          enabled: false,
          list: []
        }
      }
    };
  },

  methods: {
    renderContent(existingCriteria) {
      // parse user filters if provided.
      if (existingCriteria) {
        this.exitCriteria = this.overrideJson(this.exitCriteria, existingCriteria);
      }
    },

    async validateSettings() {
      // Validate Events.
      let isValid = true;
      if (this.exitCriteria.events.enabled) {
        for (let i = 0; i < this.exitCriteria.events.list.length; i++) {
          let comp = this.$refs['eventPicker' + i][0];
          let selectedEvent = comp.getFilters();

          if (selectedEvent == null) {
            this.exitCriteria.events.list[i] = { error: 'Please specify valid event with filters.' };
            isValid = false;
          } else {
            this.exitCriteria.events.list[i] = selectedEvent;
          }
        }
      }

      // Validate Segment, if specified.
      if (this.exitCriteria.segments.enabled) {
        this.exitCriteria.segments.query = this.$refs.userFilter.getFilters();
        this.segmentationError = null;
        if (this.exitCriteria.segments.query.filters.length == 0 && this.exitCriteria.segments.query.performedFilter.length == 0 && this.exitCriteria.segments.query.performedFilter.length == 0) {
          this.segmentationError = 'Please specify atleast one filter for segment or disable segment criteria.';
          isValid = false;
        }
      }

      this.$forceUpdate();
      if (!isValid) {
        this.warningToast('Exit Criteria - Please provide valid input for exit criteria.');
        return false;
      }

      return true;
    },

    readProcessedContent() {
      return this.exitCriteria;
    }
  },

  mounted() {}
};
