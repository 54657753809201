const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

const fetchCampaignDetails = function (campaignId) {
  return axios.get(baseUrlNew + `campaign/${campaignId}`, {
    headers: buildAuthHeaders()
  });
};

const fetchCampaignStats = function (campaignId, params) {
  return axios.post(baseUrlNew + `campaign-report/stats/${campaignId}`, params, {
    headers: buildAuthHeaders()
  });
};

const fetchCampaignUserStats = function (params) {
  return axios.post(baseUrlNew + `campaign-report/stats/${params.campaignId}/users`, params, {
    headers: buildAuthHeaders()
  });
};

const downloadCampaignUserStats = function (params) {
  return axios.post(baseUrlNew + `campaign-report/stats/${params.campaignId}/users/download`, params, {
    headers: buildAuthHeaders()
  });
};

const fetchCampaignList = function (params) {
  return axios.post(baseUrlNew + `campaign`, params, {
    headers: buildAuthHeaders()
  });
};

const fetchCampaignListForScheduledReports = function () {
  return axios.get(baseUrlNew + `campaign/for-report`, {
    headers: buildAuthHeaders()
  });
};

const fetchChannelProviderList = function (params) {
  return axios.post(baseUrlNew + `channel/`, params, {
    headers: buildAuthHeaders()
  });
};

const fetchWebPushVapidProviderDetails = function () {
  return axios.get(baseUrlNew + `channel/webPush`, {
    headers: buildAuthHeaders()
  });
};

const saveChannelProviderInfo = function (params) {
  return axios.post(baseUrlNew + `channel/create`, params, {
    headers: buildAuthHeaders()
  });
};

const fetchMobilePushFCMProviderDetails = function () {
  return axios.get(baseUrlNew + `channel/mobilePush`, {
    headers: buildAuthHeaders()
  });
};

const generateWebPushVapidCred = function () {
  return axios.get(baseUrlNew + `channel/webPush/credentials/generate`, {
    headers: buildAuthHeaders()
  });
};

module.exports = {
  fetchCampaignUserStats,
  fetchCampaignList,
  fetchChannelProviderList,
  saveChannelProviderInfo,
  fetchMobilePushFCMProviderDetails,
  generateWebPushVapidCred,
  fetchWebPushVapidProviderDetails,
  fetchCampaignStats,
  downloadCampaignUserStats,
  fetchCampaignListForScheduledReports,

  // Campaign Report Page
  fetchCampaignDetails
};
