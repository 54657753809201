import OneColumnNumberComponent from '@/components/graphs/oneColumnNumber/oneColumnNumber';
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
const moment = require('moment');
import CampaignMixin from '@/mixins/campaignMixin';

import { fetchCampaignDetails, fetchCampaignStats } from '@/services/campaign';
import { getCampaignContent, cloneCampaign, deleteCampaign } from '@/services/campaignv1';
import campaignTrackingModule from '@/services/campaignTracking';

import CampaignCustomerList from '@/pages/campaignReport/campaignCustomerList/campaignCustomerList';
import DynamicTimelineGraphComponent from '@/components/graphs/dynamicTimelineGraph/dynamicTimelineGraph';
import RevenueTrackingEventPopup from '@/components/revenueTrackingEventsPopup/revenueTrackingEventsPopup';
import CampaignRecipientReportComponent from '@/components/campaignRecipientReport/campaignRecipientReport';

/******** Campaign Content Imports */
import EmailBuilder from '@/components/campaign/contentBuilder/email/editor/emailEditor';
import MobilePushBuilder from '@/components/campaign/contentBuilder/mobilePush/editor/mobilePushEditor';
import WebPushBuilder from '@/components/campaign/contentBuilder/webPush/editor/webPushEditor';
import SmsBuilder from '@/components/campaign/contentBuilder/sms/editor/smsEditor';
import WhatsAppBuilder from '@/components/campaign/contentBuilder/whatsApp/editor/whatsAppEditor';
import InAppBuilder from '@/components/campaign/contentBuilder/inAppNotification/editor/inAppEditor';
import CustomChannelBuilder from '@/components/campaign/contentBuilder/customChannel/editor/customChannelContentEditor';
import WebSocialProofBuilder from '@/components/campaign/contentBuilder/webSocialProof/webSocialProofEditor';

export default {
  name: 'campaignDetail',
  props: {
    journeyCampaignId: {
      type: String,
      default: null
    }
  },
  components: {
    EmailBuilder,
    MobilePushBuilder,
    WebPushBuilder,
    SmsBuilder,
    WhatsAppBuilder,
    InAppBuilder,
    OneColumnNumberComponent,
    UserPropertyFilterComponent,
    CampaignCustomerList,
    DynamicTimelineGraphComponent,
    WebSocialProofBuilder,
    RevenueTrackingEventPopup,
    CampaignRecipientReportComponent,
    CustomChannelBuilder
  },
  data() {
    return {
      selectedTab: 'overview',

      actionEventFilter: '',
      fetchingCampaignDetails: false,
      cloningCampaing: false,
      campaignReport: null,
      campaignId: null,
      campaignStatus: null,
      showDetail: false,
      drawer: false,
      direction: 'rtl',
      fits: ['none'],

      // Campaign Stats
      fetchingCampaignStats: true,
      campaignStats: null,

      // Dailog Box variable
      showActionEventDailog: false,

      // Priview content
      showCampaignContent: false,
      mailPreviewMobileMode: false,

      // email Attachments variable
      showEmailAttachments: false,

      eligible: {
        value: null,
        title: 'ELIGIBLE'
      },
      sent: {
        value: null,
        title: 'SENT'
      },
      failures: {
        value: null,
        title: 'FAILED'
      },
      delivered: {
        value: null,
        title: 'DELIVERED'
      },
      unique_opens: {
        value: null,
        title: 'UNIQUE OPENS'
      },
      opens: {
        value: null,
        title: 'TOTAL OPENS'
      },
      unique_clicks: {
        value: null,
        title: 'UNIQUE CLICKS'
      },
      clicks: {
        value: null,
        title: 'TOTAL CLICKS'
      },
      conversions: {
        value: null,
        title: 'TOTAL CONVERSIONS'
      },
      revenue: {
        value: null,
        title: 'TOTAL REVENUE'
      },

      // Analytics Chart Variables
      analytics: {
        dateFilterValues: [],
        durationType: 'latest',
        durationGroup: 'day',
        groupByDurationList: [
          {
            key: 'Hourly',
            value: 'hour'
          },
          {
            key: 'Daily',
            value: 'day'
          },
          {
            key: 'Weekly',
            value: 'week'
          },
          {
            key: 'Monthly',
            value: 'month'
          }
        ]
      },
      analyticsTimingFilter: '43200',
      fetchingAnalyticsData: true,
      analyticsGraphData: null,

      // Goals Tab,
      goals: null,
      goalConversionDuration: '2880',
      fetchingGoals: true,

      // Revenue List dialog
      showRevenueDetailsDialog: false,
      revenueDialogTitle: '',
      fetchingRevenueDetails: true,
      revenueEventList: [],
      revenueTreeProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  mixins: [CampaignMixin],
  watch: {
    analyticsTimingFilter: {
      handler: function () {
        if (this.analyticsTimingFilter) {
          this.groupByDurationList = this.getGroupByDurationList(this.analyticsTimingFilter);
          this.analytics.durationGroup = this.groupByDurationList[0].value;
          this.onOverviewTimingChange();
        }
      }
    }
  },
  methods: {
    async onTabClicked(tabName) {
      this.selectedTab = tabName;
      console.log('here', this.selectedTab);
      if (this.selectedTab == 'goals') {
        this.fetchGoals();
      } else if (this.selectedTab == 'content') {
        let channel = this.campaignReport.campaign.channel;

        // Fetch Details
        let response = await getCampaignContent(this.campaignReport.campaign.id);

        let content = response.data;
        // Handle web social proof message parsing.
        if (content && content.content && content.content.on_site_content) {
          content = JSON.parse(content.on_site_content);
        } else if (content && content.in_app_content) {
          content = JSON.parse(content.in_app_content);
        }

        requestAnimationFrame(() => {
          if (channel == 'email') {
            content.email_config = this.campaignReport.channelMap.config;
            this.$refs.emailEditor.renderCampaignContent(content);
          }
          if (channel == 'mobilePush') {
            this.$refs.mobilePushEditor.renderCampaignContent(content);
          } else if (channel == 'webPush') {
            this.$refs.webPushEditor.renderCampaignContent(content);
          } else if (channel == 'sms') {
            this.$refs.smsEditor.renderCampaignContent(content);
          } else if (channel == 'whatsApp') {
            this.$refs.whatsAppEditor.renderCampaignContent(content);
          } else if (channel == 'custom') {
            this.$refs.customChannelEditor.renderCampaignContent(content);
          } else if (channel == 'inApp') {
            if (content.content.in_app_content && content.content.in_app_content != null) {
              content.content = JSON.parse(content.content.in_app_content);
            } else {
              content = null;
            }
            this.$refs.inAppEditor.renderCampaignContent(content);
          } else if (channel == 'webSocialProof') {
            if (content.content.on_site_content && content.content.on_site_content != null) {
              content.content = JSON.parse(content.content.on_site_content);
            } else {
              content = null;
            }
            this.$refs.webSocialProofEditor.renderCampaignContent(content);
          }
        });
      }
    },

    // Refetch report
    refreshPage() {
      this.fetchCampaignReport();
      this.onOverviewTimingChange();
    },

    onOverviewTimingChange() {
      this.fetchAnalyticsData();
      this.fetchCampaignStats();
    },

    // fetching Email campaign data
    async fetchCampaignReport(isAtPageLoad) {
      try {
        this.fetchingCampaignDetails = true;

        this.populateTimeRange();

        let result = await fetchCampaignDetails(this.campaignId, this);

        this.campaignReport = result.data;

        // If campaign is in draft mode, redirect to edit page.
        if (this.campaignReport.campaign.status == 'draft') {
          this.$router.push({ name: 'campaignGeneralInfoUpdate', params: { id: this.campaignReport.campaign.id } });
          return;
        }

        // Parse timing
        if (this.campaignReport.campaign.start_time) {
          this.campaignReport.campaign.start_time = parseInt(this.campaignReport.campaign.start_time);
          this.campaignReport.campaign.start_time = this.getCampaignDisplayTime(this.campaignReport.campaign.start_time);
        } else {
          this.campaignReport.campaign.start_time = '--';
        }
        if (this.campaignReport.end_time) {
          this.campaignReport.campaign.end_time = parseInt(this.campaignReport.campaign.end_time);
          this.campaignReport.campaign.end_time = this.getCampaignDisplayTime(this.campaignReport.campaign.end_time);
        } else {
          this.campaignReport.campaign.end_time = '--';
        }

        // Parse utm info
        if (this.campaignReport.campaign.utm_tracking_config) {
          this.campaignReport.utm_tracking_config = JSON.parse(this.campaignReport.campaign.utm_tracking_config);
        }

        // Parse email contents if provided.
        if (this.campaignReport.channelMap && this.campaignReport.channelMap.config) {
          this.campaignReport.channelMap.config = JSON.parse(this.campaignReport.channelMap.config);
        }

        // // Process campaign contents
        // this.campaignReport.config = typeof this.campaignReport.config == 'string' ? JSON.parse(this.campaignReport.config) : this.campaignReport.config;
        // this.campaignReport.email_attachments = JSON.parse(this.campaignReport.email_attachments);
        this.fetchingCampaignDetails = false;

        if (isAtPageLoad) {
          this.fetchAnalyticsData();
          this.fetchCampaignStats();
        }
      } catch (e) {
        if (e.status == 404) {
          this.$router.push({ name: '404' });
          return;
        }
        this.errorToast('Something went wrong.');
        this.reportError(e);
        this.fetchingCampaignDetails = false;
      }
    },

    async fetchCampaignStats() {
      // Fetch data
      try {
        this.fetchingCampaignStats = true;
        this.showRevenueTrackingPopup = false;

        this.populateTimeRange();

        // Fetch stats
        let params = {
          campaignId: this.campaignId
        };

        if (this.campaignReport.campaign.type != 'oneTime') {
          params.startTime = this.startTime.format('YYYY-MM-DD HH:mm:ss');
          params.endTime = this.endTime.format('YYYY-MM-DD HH:mm:ss');
        }
        let result = await fetchCampaignStats(this.campaignId, params, this);

        this.campaignStats = result.data;
        this.eligible.value = this.campaignStats.eligible ? this.campaignStats.eligible : 'NA';
        this.sent.value = this.campaignStats.sent;
        this.failures.value = this.campaignStats.failed;
        this.delivered.value = this.campaignStats.delivered;
        this.opens.value = this.campaignStats.opened;
        this.unique_opens.value = this.campaignStats.unique_opened;
        this.clicks.value = this.campaignStats.clicked;
        this.unique_clicks.value = this.campaignStats.unique_clicked;
        this.conversions.value = this.campaignStats.conversions;
        this.revenue.value = this.campaignStats.revenue;
        this.fetchingCampaignStats = false;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Something went wrong.');
        this.fetchingCampaignStats = false;
      }
    },

    async showDefaultRevenueDetails() {
      let params = {
        campaignId: this.campaignId
      };

      if (this.campaignReport.campaign.type != 'oneTime') {
        params.startTime = this.startTime.format('YYYY-MM-DD HH:mm:ss');
        params.endTime = this.endTime.format('YYYY-MM-DD HH:mm:ss');
      }
      this.showRevenuePopup(params, campaignTrackingModule.fetchCampaignRevenueList);
    },

    async fetchAnalyticsData() {
      // Show anlytics only for journey based campaigns.
      if (this.campaignReport.campaign.type != 'journey') {
        return;
      }

      // TODO: Implement after kafka based campaigns implemented.
      return;

      // this.fetchingAnalyticsData = true;

      // if (this.analyticsTimingFilter === '') {
      //   this.startTime = moment(this.analytics.dateFilterValues[0]);
      //   this.endTime = moment(this.analytics.dateFilterValues[1]);
      //   this.analytics.durationType = 'exact';
      // } else {
      //   this.startTime = moment().subtract(this.analyticsTimingFilter, 'minutes');
      //   this.endTime = moment();
      //   this.analytics.durationType = 'latest';
      // }

      // //Build params
      // let campaignIdFilter = [{ comparisionType: '=', property: 'Campaign Name', value: this.campaignId }];
      // let params = {
      //   startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
      //   endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
      //   filters: {
      //     eventFilters: [
      //       { eventName: 'Notification Sent', groupByList: [], filters: campaignIdFilter },
      //       { eventName: 'Notification Delivered', groupByList: [], filters: campaignIdFilter },
      //       { eventName: 'Email Opened', groupByList: [], filters: campaignIdFilter },
      //       { eventName: 'Notification Clicked', groupByList: [], filters: campaignIdFilter }
      //     ],
      //     userFilters: { filters: [], performedFilter: [], notPerformedFilter: [], matchAll: true }
      //   },
      //   durationGroup: this.analytics.durationGroup,
      //   durationType: this.analytics.durationType,
      //   duration: this.analyticsTimingFilter
      // };

      // // Fetch data
      // try {
      //   let result = await eventModule.getEventDynamicTimelineGraphData(params, this);
      //   this.analytics.data = result.data.data;
      //   this.analyticsGraphData = {
      //     data: result.data.data,
      //     durationGroup: this.analytics.durationGroup,
      //     durationType: this.analytics.durationType,
      //     duration: this.analyticsTimingFilter,
      //     startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
      //     endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss')
      //   };
      //   console.log('sending to graph', this.analyticsGraphData);

      //   this.fetchingAnalyticsData = false;
      // } catch (err) {
      //   if (err.status == 404) {
      //     this.$router.push({ name: '404' });
      //     return;
      //   }
      //   this.reportError(err);
      //   this.errorToast('Something went wrong.');
      //   this.fetchingAnalyticsData = false;
      // }
    },

    // Show customers popup
    showCampaignCustomerList() {
      this.$refs.campaignReportCustomerlist.showPopup('sent');
    },

    // show Revenue TrackingPopup
    showRevenueTrackingEventPopup() {
      this.$refs['revenueTrackingEventPopup'].showPopup();
    },

    //#region ---------------- Start: Edit campaign ------------------------
    // edit campaign
    editCampaign() {
      this.$router.push({
        path: `/campaigns/${this.campaignReport.campaign.channel}/create?campaignId=${this.campaignReport.campaign.id}&status=${this.campaignReport.campaign.status}`
      });
    },

    async deleteCampaign() {
      this.$swal({
        title: 'Delete campaign?',
        text: "Are you sure you want to delete campaign? You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async () => {
        try {
          // Delete Campaign
          await deleteCampaign(this.campaignReport.campaign.id);
          this.successToast('Campaign Deleted.');
          this.$router.push(`/campaigns/${this.campaignReport.campaign.channel}`);
        } catch (err) {
          this.reportError(err);
          this.errorToast('Failed to delete campaign. Please contact support. ');
        }
      });
    },

    // clone campaign
    async cloneCampaign() {
      // Confirm With Popup First
      try {
        await this.$swal({
          title: 'Create duplicate campaign?',
          text: 'Are you sure you want to create a copy of this campaign?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Create Duplicate'
        });
      } catch (err) {
        // Cacelled, Ignore
        return;
      }

      // Hit API
      try {
        this.cloningCampaing = true;

        // Hit API
        let response = await cloneCampaign(this.campaignReport.campaign.id);

        // Redirect to campaign report page.
        this.successToast(`Duplicate campaign created successfully.`);
        location.href = '/campaign/report/' + response.data;

        this.cloningCampaing = false;
      } catch (err) {
        this.reportError(err);
        this.errorToast(`Failed to create a copy. Something went wrong.`);
        this.cloningCampaing = false;
      }
    },

    //#endregion ---------------- End: Edit campaign ------------------------

    //#region ---------------- Start: Goals Of campaign ------------------------

    // Refetch report
    async fetchGoals() {
      try {
        this.fetchingGoals = true;

        // Fetch goals
        let params = {
          duration: parseInt(this.goalConversionDuration),
          events: [],
          startTime: moment().subtract(48, 'months').format('YYYY-MM-DD HH:mm:ss'),
          endTime: moment().format('YYYY-MM-DD HH:mm:ss')
        };
        let result = await campaignTrackingModule.getConversionGoals(this.campaignId, params, this);

        this.goals = result.data;

        this.fetchingGoals = false;
      } catch (e) {
        this.errorToast('Unable to show campaign goals. Please contct support.');
        this.reportError(e);
        // this.fetchingGoals = false;
      }
    },

    async showUTMRevenueDetails(goalName) {
      // Fetch goals
      let params = {
        duration: parseInt(this.goalConversionDuration),
        event: goalName,
        startTime: moment().subtract(48, 'months').format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment().format('YYYY-MM-DD HH:mm:ss')
      };

      this.showRevenuePopup(params, campaignTrackingModule.getUTMConversionsList, goalName);
    },

    //#endregion ------------- End: Goals Of campaign --------------------------

    async showRevenuePopup(params, fetchMethod, eventName) {
      try {
        this.showRevenueDetailsDialog = true;
        this.fetchingRevenueDetails = true;

        if (eventName) this.revenueDialogTitle = eventName;
        else this.revenueDialogTitle = 'Revenue Details';

        // Fetch stats
        let result = await fetchMethod(this.campaignId, params, this);

        // Preprae tree
        let eventList = result.data;
        this.revenueEventList = [];
        for (let i = 0; i < eventList.length; i++) {
          let item = {
            id: eventList[i].created_at_date,
            customer_id: eventList[i].customer_id,
            label: moment(eventList[i].created_at_date).format('YYYY-MM-DD hh:mm A'),
            children: []
          };
          for (let attributeName in eventList[i].data) {
            item.children.push({
              id: attributeName,
              label: attributeName + ': ' + (eventList[i].data[attributeName] ? eventList[i].data[attributeName] : 'None')
            });
          }
          this.revenueEventList.push([item]);
        }
        this.fetchingRevenueDetails = false;
      } catch (err) {
        this.showRevenueDetailsDialog = false;
        this.fetchingRevenueDetails = false;
        this.errorToast('Unable to fetch revenue details. Please contct support.');
        this.reportError(err);
      }
    },

    async populateTimeRange() {
      if (this.analyticsTimingFilter === '') {
        this.startTime = moment(this.analytics.dateFilterValues[0]);
        this.endTime = moment(this.analytics.dateFilterValues[1]);
        this.analytics.durationType = 'exact';
      } else {
        this.startTime = moment().subtract(this.analyticsTimingFilter, 'minutes');
        this.endTime = moment();
        this.analytics.durationType = 'latest';
      }
    }
  },

  created() {
    this.campaignId = this.$route.params.id;
    if (this.journeyCampaignId) {
      this.campaignId = this.journeyCampaignId;
    }
    this.fetchCampaignReport(true);
  }
};
