import colorPicker from '@caohenghu/vue-colorpicker';
import CampaignMixin from '@/mixins/campaignMixin';

export default {
  components: {
    colorPicker
  },
  props: {
    inputData: Object
  },
  mixins: [CampaignMixin],
  data() {
    return {
      emailFonts: []
    };
  },

  methods: {
    show() {},

    setColor(obj, property, args) {
      let color = args[0];
      obj[property] = this.RGBAToHexA(color.rgba.r, color.rgba.g, color.rgba.b, color.rgba.a).toUpperCase();
    },

    async prepareMailFonts() {
      this.emailFonts = await this.getEmailFonts();
    },

    close() {}
  },

  mounted() {
    this.prepareMailFonts();
  }
};
