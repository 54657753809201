let joint = require('jointjs');
let portStructures = require('./jointPortsStructure');
const _ = require('lodash');

let defaultLinkParams = {
  attrs: {
    line: {
      stroke: '#aeb3bd',
      strokeWidth: 3,
      targetMarker: {
        type: 'path',
        stroke: '#aeb3bd',
        fill: '#aeb3bd'
      }
    }
  },
  labels: [
    {
      attrs: {
        text: {
          text: ''
        }
      }
    }
  ]
};

/**************** Common Variables: Send Message Element  **********************************/

let width = 220;
let height = 60;
let commonOutPort = {
  label: {
    position: {
      name: 'right',
      args: {
        x: width + 27,
        y: 10,
        height: 10,
        attrs: {
          '.': {
            'text-anchor': 'start',
            'font-size': 11,
            fill: '#000000',
            'font-weight': 'bold',
            display: 'none'
          }
        }
      }
    }
  },
  attrs: {
    '.joint-port-body': {
      width: 0,
      x: width,
      y: 0,
      magnet: false,
      visibility: 'hidden',
      'xlink:href': 'https://static.growlytics.in/dashboard-assets/assets/img/journey/right-arrow.png',
      cursor: 'pointer'
    }
  },
  markup: `<image class="joint-port-body"/>`
};

let commonCss = {
  size: {
    width: width,
    height: height
  },
  attrs: {
    '.viewport': {
      'user-select': 'none'
    },
    '.card': {
      fill: '#caf1bc',
      y: 15,
      width: 220,
      height: 50,
      'pointer-events': 'visiblePainted',
      rx: 5,
      ry: 5,
      cursor: 'move',
      'box-shadow': '0px 0px 8px 0px rgba(198,245,188,1)'
    },

    '.icon': {
      width: 35,
      height: 35,
      ref: '.card',
      x: 5,
      y: -2,
      'ref-y': 10,
      opacity: 1
    },

    '.label': {
      x: 47,
      y: 33,
      'font-size': 10,
      fill: '#555',
      'text-anchor': 'start',
      'user-select': 'none'
    },

    '.name': {
      x: 47,
      y: 50,
      'font-size': 12,
      fill: '#55555',
      'text-anchor': 'start',
      cursor: 'pointer',
      'user-select': 'none'
    },

    '.delete': {
      x: 189,
      y: 5,
      'xlink:href': 'https://static.growlytics.in/dashboard-assets/assets/img/journey/delete.png',
      width: 22,
      height: 22,
      cursor: 'pointer',
      visibility: 'hidden'
    },

    '.duplicate': {
      x: 162,
      y: 5,
      'xlink:href': 'https://static.growlytics.in/dashboard-assets/assets/img/journey/duplicate.png',
      width: 22,
      height: 22,
      cursor: 'pointer',
      visibility: 'hidden'
    },

    '.invalidInput': {
      x: 134,
      y: 5,
      'xlink:href': 'https://static.growlytics.in/dashboard-assets/assets/img/journey/problem.png',
      width: 22,
      height: 22,
      cursor: 'pointer',
      visibility: 'hidden'
    },
    '.analyticsIcon': {
      x: 200,
      y: 47,
      'xlink:href': 'https://static.growlytics.in/dashboard-assets/assets/img/journey/analytics.png',
      width: 15,
      height: 15,
      cursor: 'pointer',
      visibility: 'hidden'
    }
  },
  ports: {
    groups: {
      'send-in': {
        attrs: {
          '.joint-port-body': {
            width: 0,
            height: 0,
            fill: '#8096E0',
            magnet: 'passive',
            stroke: 'none'
          },
          '.joint-port-label': {
            display: 'none'
          }
        },
        markup: '<rect class="joint-port-body"/>'
      },
      sent: JSON.parse(JSON.stringify(commonOutPort)),
      opened: JSON.parse(JSON.stringify(commonOutPort)),
      clicked: JSON.parse(JSON.stringify(commonOutPort)),
      notOpened: JSON.parse(JSON.stringify(commonOutPort)),
      notClicked: JSON.parse(JSON.stringify(commonOutPort)),
      triggered: JSON.parse(JSON.stringify(commonOutPort)),
      yes: JSON.parse(JSON.stringify(commonOutPort)),
      no: JSON.parse(JSON.stringify(commonOutPort)),
      timeout: JSON.parse(JSON.stringify(commonOutPort)),
      afterUpdate: JSON.parse(JSON.stringify(commonOutPort)),

      abTestA: JSON.parse(JSON.stringify(commonOutPort)),
      abTestB: JSON.parse(JSON.stringify(commonOutPort)),
      abTestC: JSON.parse(JSON.stringify(commonOutPort)),
      abTestD: JSON.parse(JSON.stringify(commonOutPort)),
      abTestE: JSON.parse(JSON.stringify(commonOutPort))
    }
  }
};

let commonStructure = {
  markup: `<svg class="viewport" stroke-color="black" xmlns="http://www.w3.org/2000/svg">
            <rect class="card"/>
            <image class="delete"/>
            <image class="duplicate"/>
            <image class="invalidInput"/>
            <image class="icon"/>
            <text class="label">Send SMS</text>   
            <text class="name edit-on-click"/>  
            <image class="analyticsIcon"/>
        </svg>`
};

/**************** Send Message Element  **********************************/
// Add innintialize method
let sendMessageStructure = JSON.parse(JSON.stringify(commonStructure));
sendMessageStructure.initialize = function () {
  joint.dia.Element.prototype.initialize.apply(this, arguments);

  this.attr(`.delete/grw-delete`, true);
  this.attr(`.duplicate/grw-duplicate`, true);
  this.attr(`.analyticsIcon/grw-analytics`, true);

  let newString = this.get('message') ? this.get('message') : '';
  newString = newString.substring(0, 25);
  if (this.get('message').length > 25) {
    newString += '...';
  }
  this.attr(`.name/text`, newString);

  this.attr(`.label/text`, this.get('nodeSubText'));
  this.attr(`.name/edit-on-click`, true);

  if (this.get('isPublished')) {
    this.attr(`.analyticsIcon/visibility`, 'visible');
    let ports = this.getPorts();
    for (let i = 0; i < ports.length; i++) {
      this.removePort(ports[i].id);
    }
  } else {
    this.attr(`.analyticsIcon/visibility`, 'hidden');
    if (this.getPorts().length == 0) {
      if (this.attributes.sub_type == 'email') this.addPorts(portStructures.emailActionPortList);
      else if (this.attributes.sub_type == 'updateUser') this.addPorts(portStructures.updateUserActionPortList);
      else if (this.attributes.sub_type == 'facebook' || this.attributes.sub_type == 'google') this.addPorts(portStructures.adNetworkActionPortList);
      else this.addPorts(portStructures.restActionPortList);
    }
  }
};
let sendMessageNode = joint.dia.Element.define('Growlytics.Action', commonCss, sendMessageStructure);
let SendMessageNode = sendMessageNode.extend({});
exports.CreateSendMessageNode = function (elementInfo, graph, x, y) {
  if (!x) {
    x = 200;
    y = 300;
  }

  let info = JSON.parse(JSON.stringify(elementInfo));
  info.position = {
    x: x,
    y: y
  };
  info.attrs = {
    '.icon': {
      'xlink:href': elementInfo.image
    },
    '.name': {
      text: elementInfo.title
    },
    '.buttonText': {
      text: 'On Success'
    }
  };

  let node = new SendMessageNode(info);

  node.addTo(graph);
};

/**************** Journey Start Element  **********************************/
// Add innintialize method
let startNodeStructure = JSON.parse(JSON.stringify(commonStructure));
startNodeStructure.initialize = function () {
  joint.dia.Element.prototype.initialize.apply(this, arguments);

  // Dont Show Delete for start node.
  this.attr(`.delete/display`, 'none');
  this.attr(`.duplicate/display`, 'none');
  this.attr(`.analyticsIcon/grw-analytics`, true);

  // Set card background to blue
  this.attr('.card/fill', '#e3e5f5');

  // Set horizontal alignment
  let newString = this.get('message') ? this.get('message') : '';
  newString = newString.substring(0, 25);
  if (this.get('message').length > 25) {
    newString += '...';
  }
  this.attr(`.name/text`, newString);

  this.attr(`.label/text`, this.get('nodeSubText'));
  this.attr(`.name/edit-on-click`, true);

  if (this.get('isPublished')) {
    this.attr(`.analyticsIcon/visibility`, 'visible');
    let ports = this.getPorts();
    for (let i = 0; i < ports.length; i++) {
      this.removePort(ports[i].id);
    }
  } else {
    this.attr(`.analyticsIcon/visibility`, 'hidden');
    if (this.getPorts().length == 0) {
      this.addPorts(portStructures.startNodePortList);
    }
  }
};

let StartNode = joint.dia.Element.define('Growlytics.Start', commonCss, startNodeStructure);
exports.CreateStartNode = function (elementInfo, graph) {
  let info = JSON.parse(JSON.stringify(elementInfo));
  info.position = {
    x: 60,
    y: 60
  };
  info.attrs = {
    '.icon': {
      'xlink:href': elementInfo.image
    }
  };

  // Init Node
  let node = new StartNode(info);
  node.addTo(graph);
};

/**************** Flow Element  **********************************/
// Add innintialize method
let flowNodeStructure = JSON.parse(JSON.stringify(commonStructure));
flowNodeStructure.initialize = function () {
  joint.dia.Element.prototype.initialize.apply(this, arguments);

  //Block Actions
  this.attr(`.delete/grw-delete`, true);
  this.attr(`.duplicate/grw-duplicate`, true);
  this.attr(`.analyticsIcon/grw-analytics`, true);

  // Set card background to red
  this.attr('.card/fill', '#ffd9d9');

  let newString = this.get('message') ? this.get('message') : '';
  newString = newString.substring(0, 25);
  if (this.get('message').length > 25) {
    newString += '...';
  }
  this.attr(`.name/text`, newString);
  this.attr(`.label/text`, this.get('nodeSubText'));
  this.attr(`.name/edit-on-click`, true);

  if (this.get('isPublished')) {
    this.attr(`.analyticsIcon/visibility`, 'visible');
    let ports = this.getPorts();
    for (let i = 0; i < ports.length; i++) {
      this.removePort(ports[i].id);
    }
  } else {
    this.attr(`.analyticsIcon/visibility`, 'hidden');
    if (this.getPorts().length == 0) {
      if (this.attributes.sub_type == 'wait_time') {
        this.addPorts(portStructures.waitTimeNodePortList);
      } else {
        this.addPorts(portStructures.waitEventNodePortList);
      }
    }
  }
};
let FlowNode = joint.dia.Element.define('Growlytics.Flow', commonCss, flowNodeStructure);
exports.CreateFlowNode = function (elementInfo, graph, x, y) {
  let info = JSON.parse(JSON.stringify(elementInfo));
  info.position = {
    x: x,
    y: y
  };
  info.attrs = {
    '.icon': {
      'xlink:href': elementInfo.image
    }
  };

  // Init Node
  let node = new FlowNode(info);
  node.addTo(graph);
};

/**************** Condition Element  **********************************/
// Add inintialize method
let conditionNodeStructure = JSON.parse(JSON.stringify(commonStructure));
conditionNodeStructure.initialize = function () {
  joint.dia.Element.prototype.initialize.apply(this, arguments);

  //Delete
  this.attr(`.delete/grw-delete`, true);
  this.attr(`.duplicate/grw-duplicate`, true);
  this.attr(`.analyticsIcon/grw-analytics`, true);

  // Set card background to orange
  this.attr('.card/fill', '#ffe6b8');

  // Set horizontal alignment
  let newString = this.get('message') ? this.get('message') : '';
  newString = newString.substring(0, 25);
  if (this.get('message').length > 25) {
    newString += '...';
  }
  this.attr(`.name/text`, newString);
  this.attr(`.label/text`, this.get('nodeSubText'));
  this.attr(`.name/edit-on-click`, true);

  if (this.get('isPublished')) {
    this.attr(`.analyticsIcon/visibility`, 'visible');
    let ports = this.getPorts();
    for (let i = 0; i < ports.length; i++) {
      this.removePort(ports[i].id);
    }
  } else {
    this.attr(`.analyticsIcon/visibility`, 'hidden');
    if (this.getPorts().length == 0) {
      this.addPorts(portStructures.yesNoNodePortList);
    }
  }
};
let ConditionNode = joint.dia.Element.define('Growlytics.Condition', commonCss, conditionNodeStructure);
exports.CreateConditionNode = function (elementInfo, graph, x, y) {
  let info = JSON.parse(JSON.stringify(elementInfo));
  info.position = {
    x: x,
    y: y
  };
  info.attrs = {
    '.icon': {
      'xlink:href': elementInfo.image
    }
  };

  // Init Node
  let node = new ConditionNode(info);
  node.addTo(graph);
};

/**************** A/B Split Element  **********************************/
let abSplitNodeStructure = JSON.parse(JSON.stringify(commonStructure));
abSplitNodeStructure.initialize = function () {
  joint.dia.Element.prototype.initialize.apply(this, arguments);

  //Delete
  this.attr(`.delete/grw-delete`, true);
  this.attr(`.duplicate/grw-duplicate`, true);
  this.attr(`.analyticsIcon/grw-analytics`, true);

  // Set card background to orange
  this.attr('.card/fill', '#ffd9d9');

  // Set horizontal alignment
  let newString = this.get('message') ? this.get('message') : '';
  newString = newString.substring(0, 25);
  if (this.get('message').length > 25) {
    newString += '...';
  }
  this.attr(`.name/text`, newString);
  this.attr(`.label/text`, this.get('nodeSubText'));
  this.attr(`.name/edit-on-click`, true);

  if (this.get('isPublished')) {
    this.attr(`.analyticsIcon/visibility`, 'visible');
    let ports = this.getPorts();
    for (let i = 0; i < ports.length; i++) {
      this.removePort(ports[i].id);
    }
  } else {
    this.attr(`.analyticsIcon/visibility`, 'hidden');
  }
};
let AbSplitNode = joint.dia.Element.define('Growlytics.AbSplit', commonCss, abSplitNodeStructure);
exports.CreateAbSplitNode = function (elementInfo, graph, x, y) {
  let info = JSON.parse(JSON.stringify(elementInfo));
  info.position = {
    x: x,
    y: y
  };
  info.attrs = {
    '.icon': {
      'xlink:href': elementInfo.image
    }
  };

  // Init Node
  let node = new AbSplitNode(info);
  node.addTo(graph);
  return node;
};
exports.abSplitModifyPorts = (graph, node, pathList) => {
  // Fix Ports.
  let ports = node.getPorts();
  for (let i = 0; i < ports.length; i++) {
    node.removePort(ports[i].id);
  }
  node.addPorts(portStructures.buildAbSplitPortList(pathList));

  // Remove links if required.
  let links = graph.getConnectedLinks(node);
  for (let i = 0; i < links.length; i++) {
    let link = links[i];
    if (link.attributes.source.id == node.id) {
      let linkId = link.attributes.sub_type;
      let pathInfo = _.find(pathList, (branch) => {
        return branch.id == linkId;
      });
      if (!pathInfo) {
        // Remove Path Link, if not present.
        link.remove();
      } else {
        // Rename link's title, with percentage.
        let label = `${pathInfo.name} (${pathInfo.percentage}%)`;
        link.label(0, { attrs: { text: { text: label } } });
      }
    }
  }
};

/**************** Handle Element Events  **********************************/

exports.onLinkAdded = function (graph, linkView, evt, targetCellView) {
  // Read source node.
  let sourceCellView = linkView.sourceView;

  // Remove existing link.
  linkView.model.remove();

  let linkLabelText = linkView.model.label().attrs.text.text;

  // For Ab split, add percentage in link.
  if (sourceCellView.model.attributes.blockType == 'split') {
    let sourcePortId = linkView.model.attributes.source.port;

    let sourcePortList = sourceCellView.model.attributes.ports.items;
    let sourcePort = _.find(sourcePortList, (port) => {
      return port.id == sourcePortId;
    });
    if (sourcePort) linkLabelText = sourcePort.attrs.text.text;
  }

  // Build new Link
  let linkParams = JSON.parse(JSON.stringify(defaultLinkParams));
  linkParams.labels = [];
  var link3 = new joint.shapes.standard.Link(linkParams);
  link3.source(sourceCellView.model);
  link3.target(targetCellView.model);
  link3.set('sub_type', linkView.model.attributes.sub_type);

  // IF action, add timer as well.
  if (sourceCellView.model.attributes.type == 'Growlytics.Action' && linkView.model.attributes.sub_type != 'sent' && linkView.model.attributes.sub_type != 'afterUpdate') {
    // Add Label data
    let linkData = {
      timer: {
        uiOptions: {
          radioOption: 'custom',
          timeInput: 30,
          timeUnit: 'days'
        },
        minutes: 43200,
        displayText: 'Within\n30 days'
      }
    };
    link3.set('grwLinkData', linkData);
    link3.appendLabel({
      markup: [
        {
          tagName: 'text',
          selector: 'titleText'
        },
        {
          tagName: 'image',
          selector: 'timer-image'
        },
        {
          tagName: 'text',
          selector: 'label'
        }
      ],
      attrs: {
        titleText: {
          y: -12,
          x: 15,
          widt: 200,
          lineHeight: 13,
          text: linkLabelText,
          fill: '#000000',
          fontSize: 10,
          textAnchor: 'middle'
        },
        label: {
          y: 30,
          x: 17,
          widt: 200,
          lineHeight: 13,
          text: linkData.timer.displayText,
          fill: '#000000',
          fontSize: 10,
          textAnchor: 'middle'
        },
        'timer-image': {
          y: -10,
          ref: 'label',
          href: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/wait_timer.png',
          width: 30,
          height: 30,
          cursor: 'pointer'
        }
      },
      position: {
        distance: 0.5,
        offset: 0,
        // angle: 270,
        args: {
          keepGradient: true,
          ensureLegibility: true
        }
      }
    });
  } else {
    link3.appendLabel({
      attrs: {
        text: {
          text: linkLabelText,
          fontSize: 12
        }
      },
      position: {
        distance: 0.5,
        offset: 0,

        args: {
          keepGradient: true,
          ensureLegibility: true
        }
      }
    });
  }

  // Add Delete Button
  link3.appendLabel({
    markup: [
      {
        tagName: 'image',
        selector: 'delete-image'
      }
    ],
    attrs: {
      'delete-image': {
        y: -15,
        ref: 'label',
        href: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/delete.png',
        width: 27,
        height: 27,
        cursor: 'pointer',
        visibility: 'hidden'
      }
    },
    position: {
      distance: 0.7,
      offset: 0,
      // angle: 270,
      args: {
        keepGradient: true,
        ensureLegibility: true
      }
    }
  });

  link3.addTo(graph);
};

exports.onMouseClick = function (cellView, evt, graph) {
  let target = evt.target;
  if (target.tagName == 'tspan') {
    target = evt.target.parentElement;
  }

  if (target.attributes['grw-delete']) {
    cellView.model.remove();
  } else if (target.attributes['grw-duplicate']) {
    let clone = cellView.model.clone();
    clone.attributes.position.y += 60;
    clone.attributes.position.x += 60;
    clone.addTo(graph);
  } else if (target.attributes['grw-analytics']) {
    graph.vueComponent.showAnalyticsForNode(cellView.model);
  } else if (target.attributes['edit-on-click']) {
    graph.vueComponent.showNodeEditor(cellView.model);
  }
};

exports.onLinkMouseClick = function (linkView, evt, graph) {
  let target = evt.target;
  if (target.tagName == 'tspan') {
    target = evt.target.parentElement;
  }

  if (target.attributes['joint-selector'] && target.attributes['joint-selector'].value == 'timer-image') {
    graph.vueComponent.showLinkEditor(linkView.model, 'timer');
  }

  if (target.attributes['joint-selector'] && target.attributes['joint-selector'].value == 'delete-image') {
    linkView.model.remove();
  }
};

exports.onLinkValidate = function (cellViewS, magnetS, cellViewT, magnetT, end, linkView) {
  // Dont allow joining to same node
  if (cellViewS === cellViewT) return false;

  // Don't allow dropping on start node
  if (cellViewT && cellViewT.model.get('type') == 'start') {
    return false;
  }

  // Set link label
  let portGroup = linkView.sourceMagnet.getAttribute('port-group');
  let linkLabel = '';
  if (portGroup == 'sent') {
    linkLabel = 'After Send';
  } else if (portGroup == 'opened') {
    linkLabel = 'On Opened';
  } else if (portGroup == 'clicked') {
    linkLabel = 'On Clicked';
  } else if (portGroup == 'notOpened') {
    linkLabel = "Din't Open";
  } else if (portGroup == 'notClicked') {
    linkLabel = "Din't Click";
  } else if (portGroup == 'yes') {
    linkLabel = 'Yes';
  } else if (portGroup == 'no') {
    linkLabel = 'No';
  } else if (portGroup == 'timeout') {
    linkLabel = 'After Wait';
  } else if (portGroup == 'triggered') {
    linkLabel = 'Event Triggered';
  } else if (portGroup == 'afterUpdate') {
    linkLabel = 'After Update';
  }

  // Ab Split Exits
  else if (portGroup == 'abTestA') {
    linkLabel = 'Path A';
  } else if (portGroup == 'abTestB') {
    linkLabel = 'Path B';
  } else if (portGroup == 'abTestC') {
    linkLabel = 'Path C';
  } else if (portGroup == 'abTestD') {
    linkLabel = 'Path D';
  } else if (portGroup == 'abTestE') {
    linkLabel = 'Path E';
  }

  linkView.model.label(0, {
    attrs: {
      text: {
        text: linkLabel
      }
    }
  });

  linkView.model.set('sub_type', portGroup);

  return true;
};

exports.onElementMouseEnter = function (cellView) {
  cellView.model.attr('.name/text-decoration', 'underline');
  // if (evt.target.tagName == 'tspan') {
  //   target = evt.target.parentElement;
  // }

  if (cellView.model.get('isPublished')) {
    return;
  }

  cellView.model.attr('.delete/visibility', 'visible');
  cellView.model.attr(`.duplicate/visibility`, 'visible');
  cellView.model.attr('.buttonText/display', 'inline');
  cellView.model.attr('.buttonRect/display', 'inline');

  for (let portId in cellView._portElementsCache) {
    let portContainer = cellView._portElementsCache[portId];
    if (portContainer.portContentElement.attr('port-group') != 'send-in') {
      portContainer.portContentElement.attr('visibility', 'visible');
      portContainer.portContentElement.attr('width', 20);
      portContainer.portLabelElement.attr('display', 'inline');
    }
  }
};

exports.onElementMouseLeave = function (cellView) {
  cellView.model.attr('.name/text-decoration', 'none');
  cellView.model.attr('.delete/visibility', 'hidden');
  cellView.model.attr(`.duplicate/visibility`, 'hidden');
  cellView.model.attr('.buttonText/display', 'none');
  cellView.model.attr('.buttonRect/display', 'none');

  for (let portId in cellView._portElementsCache) {
    let portContainer = cellView._portElementsCache[portId];
    if (portContainer.portContentElement.attr('port-group') != 'send-in') {
      portContainer.portContentElement.attr('visibility', 'hidden');
      portContainer.portContentElement.attr('width', 0);
      portContainer.portLabelElement.attr('display', 'none');
    }
  }
};

exports.onLinkMouseEnter = function (linkView) {
  if (linkView.model.get('isPublished')) {
    return;
  }

  let deleteLabelIndex = linkView.model.labels().length - 1;
  linkView.model.label(deleteLabelIndex, { attrs: { 'delete-image': { visibility: 'visible' } } });
};

exports.onLinkMouseLeave = function (linkView) {
  let deleteLabelIndex = linkView.model.labels().length - 1;
  linkView.model.label(deleteLabelIndex, { attrs: { 'delete-image': { visibility: 'hidden' } } });
};

//
let shapes = joint.shapes;
shapes.Growlytics = {};
shapes.Growlytics.Condition = ConditionNode;
shapes.Growlytics.Flow = FlowNode;
shapes.Growlytics.Start = StartNode;
shapes.Growlytics.Action = SendMessageNode;
shapes.Growlytics.AbSplit = AbSplitNode;
exports.shapesList = joint.shapes;

exports.defaultLinkParams = defaultLinkParams;

exports.jointObj = joint;
