<template>
  <EmailEditor class="unlayerEditorContainer" :options="editorOptions" ref="emailEditor" />
</template>

<script>
import { EmailEditor } from 'vue-email-editor';

export default {
  components: {
    EmailEditor
  },
  data() {
    return {
      editorOptions: {
        fonts: {
          showDefaultFonts: false,
          customFonts: [
            {
              label: 'Andale Mono',
              value: 'andale mono,times'
            },
            {
              label: 'Arial',
              value: 'arial,helvetica,sans-serif'
            },
            {
              label: 'Arial Black',
              value: 'arial black,avant garde,arial'
            },
            {
              label: 'Book Antiqua',
              value: 'book antiqua,palatino'
            },
            {
              label: 'Comic Sans MS',
              value: 'comic sans ms,sans-serif'
            },
            {
              label: 'Courier New',
              value: 'courier new,courier'
            },
            {
              label: 'Georgia',
              value: 'georgia,palatino'
            },
            {
              label: 'Helvetica',
              value: 'helvetica,sans-serif'
            },
            {
              label: 'Impact',
              value: 'impact,chicago'
            },
            {
              label: 'Symbol',
              value: 'symbol'
            },
            {
              label: 'Tahoma',
              value: 'tahoma,arial,helvetica,sans-serif'
            },
            {
              label: 'Terminal',
              value: 'terminal,monaco'
            },
            {
              label: 'Times New Roman',
              value: 'times new roman,times'
            },
            {
              label: 'Trebuchet MS',
              value: 'trebuchet ms,geneva'
            },
            {
              label: 'Verdana',
              value: 'verdana,geneva'
            },
            {
              label: 'Lobster Two',
              value: "'Lobster Two',cursive",
              url: 'https://fonts.googleapis.com/css?family=Lobster+Two:400,700'
            },
            {
              label: 'Playfair Display',
              value: "'Playfair Display',serif",
              url: 'https://fonts.googleapis.com/css?family=Playfair+Display:400,700'
            },
            {
              label: 'Rubik',
              value: "'Rubik',sans-serif",
              url: 'https://fonts.googleapis.com/css?family=Rubik:400,700'
            },
            {
              label: 'Source Sans Pro',
              value: "'Source Sans Pro',sans-serif",
              url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700'
            },
            {
              label: 'Open Sans',
              value: "'Open Sans',sans-serif",
              url: 'https://fonts.googleapis.com/css?family=Open+Sans:400,700'
            },
            {
              label: 'Crimson Text',
              value: "'Crimson Text',serif",
              url: 'https://fonts.googleapis.com/css?family=Crimson+Text:400,700'
            },
            {
              label: 'Montserrat',
              value: "'Montserrat',sans-serif",
              url: 'https://fonts.googleapis.com/css?family=Montserrat:400,700'
            },
            {
              label: 'Old Standard TT',
              value: "'Old Standard TT',serif",
              url: 'https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700'
            },
            {
              label: 'Lato',
              value: "'Lato',sans-serif",
              url: 'https://fonts.googleapis.com/css?family=Lato:400,700'
            },
            {
              label: 'Raleway',
              value: "'Raleway',sans-serif",
              url: 'https://fonts.googleapis.com/css?family=Raleway:400,700'
            },
            {
              label: 'Cabin',
              value: "'Cabin',sans-serif",
              url: 'https://fonts.googleapis.com/css?family=Cabin:400,700'
            },
            {
              label: 'Pacifico',
              value: "'Pacifico',cursive",
              url: 'https://fonts.googleapis.com/css?family=Pacifico'
            }
          ]
        }
      }
    };
  },
  methods: {
    validateContent() {
      return true;
    },

    async extractHtml() {
      return new Promise((resolve) => {
        this.$refs.emailEditor.editor.exportHtml((data) => {
          resolve(data.html);
        });
      });
    },

    async extractJSON() {
      return new Promise((resolve) => {
        this.$refs.emailEditor.editor.saveDesign((data) => {
          resolve(data);
        });
      });
    },

    async getProcessedContents() {
      return {
        json: {
          type: 'unlayerDragDrop',
          data: await this.extractJSON()
        },
        html: await this.extractHtml()
      };
    },

    async renderContent(contentJson) {
      if (!contentJson || !contentJson.data) return;
      this.$refs.emailEditor.editor.loadDesign(contentJson.data);
    }
  }
};
</script>

<style lang="scss" scoped>
.unlayerEditorContainer {
  height: 95vh;
}
</style>
