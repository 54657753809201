<template>
  <el-drawer custom-class="journeyFlowNodePopup" :visible.sync="dialogVisible" :direction="direction" :before-close="onCancel">
    <div slot="title">
      A/B Test
      <div class="menuBetaTag">PREVIEW</div>
    </div>

    <div class="p-4">
      <div class="border rounded-md overflow-hidden">
        <div class="bg-gray-50 border-b p-2 text-gray-600 font-semibold">A/B Test Paths & Distribution</div>

        <!-- Branch List -->
        <div class="px-3 py-3 border-b" v-for="(branch, index) in splitBranches" :key="index">
          <div class="inline-block text-gray-500 mr-2">Path {{ String.fromCharCode(64 + index + 1) }}</div>
          <!-- Percentage -->
          <div class="inline-block ml-3">
            <div class="text-xs text-gray-600 pb-0.5">Percentage</div>
            <el-input style="width: 120px" class="" type="number" v-model="branch.percentage">
              <template slot="append">%</template>
            </el-input>
          </div>
          <!-- Path Description -->
          <div class="inline-block ml-4">
            <div class="text-xs text-gray-600 pb-0.5">Path Description(Optional)</div>
            <el-input style="width: 400px" v-model="branch.description" maxlength="20" />
          </div>
          <!-- Delete Icon -->
          <div class="inline-block ml-3" v-if="splitBranches.length > 2 && !jurneyPublished">
            <el-button type="danger" icon="el-icon-delete" circle plain @click="splitBranches.splice(index, 1)"></el-button>
          </div>
        </div>

        <!-- Add More Path Button -->
        <div class="bg-gray-50 p-3" v-if="!jurneyPublished">
          <template v-if="splitBranches.length < 5">
            <el-button type="primary" size="small" plain @click="onAddSplitBranch">Add Path</el-button>
          </template>
          <template v-else>
            <div class="text-sm text-gray-500">Maximum 5 branches can be created.</div>
          </template>
        </div>
      </div>
    </div>

    <div class="footerButtons">
      <el-button v-if="!jurneyPublished" type="success" @click="onValidateAndSave"> Save Changes </el-button>
      <el-button v-else type="success" @click="publishChanges"> Publish Changes </el-button>
      <el-button type="danger" @click="onCancel">Cancel</el-button>
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: {
    jurneyPublished: {
      type: Boolean,
      required: true
    }
  },
  components: {},
  data() {
    return {
      nodeId: null,
      dialogVisible: false,
      flowType: '',
      direction: 'btt',

      // A/B Split Variables
      splitBranches: [
        { id: 'abTestA', description: '', percentage: 50 },
        { id: 'abTestB', description: '', percentage: 50 }
      ],
      dragBranches: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    };
  },

  methods: {
    onValidateAndSave() {
      // Validate Branch Name & Percentage.
      let totalPercentage = 0;
      for (let i = 0; i < this.splitBranches.length; i++) {
        let branch = this.splitBranches[i];
        branch.id = 'abTest' + String.fromCharCode(65 + i);
        branch.name = 'Path ' + String.fromCharCode(65 + i);

        // Validate Percentage.
        if (isNaN(branch.percentage)) {
          this.warningToast('Invalid percentage provided. It should be a number.');
          return;
        }
        branch.percentage = parseInt(branch.percentage);
        if (branch.percentage < 0 || branch.percentage > 100) {
          this.warningToast('Percentage should be between 0 to 100.');
          return;
        }

        totalPercentage += parseInt(branch.percentage);
      }

      // Validate total percentage.
      console.log('Total Perc', totalPercentage);
      if (totalPercentage != 100) {
        this.warningToast('Total percentage of all branch should be 100%.');
        return;
      }

      let data = this.splitBranches;

      this.$emit('onChange', {
        nodeId: this.nodeId,
        data: data
      });

      this.onCancel();
    },

    onCancel() {
      Object.assign(this.$data, this.$options.data());
    },

    publishChanges() {
      this.$swal({
        title: 'Make changes to live journey?',
        text: "This journey is live, you won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update live journey.'
      }).then(async () => {
        this.onValidateAndSave();
      });
    },

    showDialog(nodeId, flowType, existingData) {
      // console.log('existing data', flowType, existingData);
      this.nodeId = nodeId;
      this.flowType = flowType;

      if (existingData) {
        this.splitBranches = existingData;
      }

      this.dialogVisible = true;
    },

    /*********** A/B Split *************/

    onAddSplitBranch() {
      this.splitBranches.push({ description: '', percentage: 0 });
    }
  }
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.journeyFlowNodePopup {
  width: 900px !important;
  height: auto !important;
  margin: auto;

  .el-drawer__header {
    height: 55px;
    background: $primary !important;
    padding: 5px 15px;
    color: white;
    margin: 0px;

    .menuBetaTag {
      line-height: 16px;
      font-size: 9px;
      vertical-align: middle;
      // background: linear-gradient(90deg, #a7aaa7, #39d806);
      background: #eb0e0e;
      display: inline-block;
      color: white;
      letter-spacing: 1px;

      margin: -2px 10px 0px 10px;
      padding: 2px 10px 2px 10px;
      border-radius: 10px;
    }
  }

  input:disabled,
  textarea:disabled {
    background: white !important;
    color: #303133 !important;
  }

  .el-drawer__body {
    margin: auto;
    width: 100% !important;
    overflow: auto;
    padding: 10px;
    max-height: 90vh;
    padding: 0px;

    .title {
      font-size: 12px !important;
      margin-bottom: 4px !important;
      color: #607d8b !important;
    }

    .footerButtons {
      width: 100%;
      text-align: center;
      border-top: 1px solid #ddd;
      padding: 15px 0px 15px 0px !important;
      margin-top: 30px;

      .el-button {
        min-width: 200px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  *:focus {
    outline: 0;
  }
}
</style>
