// import juice from 'juice';

export default {
  name: 'chartPageSelectorComponent',
  props: {
    editorType: {
      type: String,
      default: 'dragDrop'
    },
    height: {
      type: String,
      default: '500px'
    }
  },
  components: {},
  data() {
    return {
      editorInstance: null
    };
  },

  methods: {
    setDynamicProp(text, isUnsubscribeLink) {
      this.editorInstance.model.change((writer) => {
        let position = this.editorInstance.model.document.selection.getFirstPosition();
        if (isUnsubscribeLink) {
          writer.insertText('Unsubscribe', { linkHref: 'https://dc.growlytics.in/#[unsubscribe]#' }, position);
        } else {
          writer.insertText(text, {}, position);
        }
      });
    },

    onInsertImage(imageUrl) {
      // const doc = this.editorInstance.model.document;

      this.editorInstance.model.change((writer) => {
        const imageElement = writer.createElement('image', {
          src: imageUrl
        });

        // Insert the image in the current selection location.
        this.editorInstance.model.insertContent(imageElement, this.editorInstance.model.document.selection);
      });
    },

    onFocus() {
      // console.log('Editor focused.', { event, editor });
    },

    onBlur() {
      // console.log('Editor focused.', { event, editor });
    },

    onInput() {
      this.$emit('textChange');
    },

    getProcessedContents() {
      let data = this.editorInstance.getData();
      // data = juice.inlineContent(data, ckeditorStyles);
      return data;
    },

    renderContent(content) {
      // Select toolbar based on variable provided in props.
      let toolbarOptions = ['fontSize', 'insertImage', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'bold', 'italic', 'strikethrough', 'code', 'subscript', 'superscript', 'link', 'alignment', 'undo', 'redo', 'imageTextAlternative', 'imageStyle:full', 'imageStyle:alignLeft', 'imageStyle:alignRight', 'insertTable', 'bulletedList', 'numberedList'];
      if (this.editorType == 'dragDrop') {
        // toolbarOptions = ['heading', '|', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|', 'link', 'alignment', 'bold', 'italic', 'underline', 'strikethrough', '|', 'bulletedList', 'numberedList'];
        toolbarOptions = ['heading', '|', 'fontfamily', 'fontsize', '|', 'alignment', '|', 'fontColor', 'fontBackgroundColor', '|', 'link', 'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|', 'bulletedList', 'numberedList'];
      } else if (this.editorType == 'dragDrop-RichText') {
        toolbarOptions = ['heading', 'mediaEmbed', 'insertTable', 'link', '|', 'bold', 'italic', 'strikethrough', 'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'highlight', '|', 'bulletedList', 'numberedList', 'indent', 'outdent', '|', 'horizontalLine', 'blockQuote'];
      }

      let richTextData = {
        fontFamily: {
          defaultLabel: 'Arial',
          options: ['Arial', 'Arial Black', 'Century Gothic', 'Comic Sans MS', 'Courier', 'Courier New', 'Georgia', 'Geneva', 'Helvetica', 'Lucida', 'Lucida Grande', 'Ms Serif', 'New York', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana']
        },
        fontSize: {
          defaultLabel: '12px',
          options: [9, 11, 'default', 13, 14, 15, 16, 17, 19, 21, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]
        },
        fontColor: {
          colors: colors
        },
        lineHeight: {
          options: [0.5, 1, 1.5, 2, 2.5]
        },
        image: {
          resizeUnit: 'px',
          toolbar: ['imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight', '|', 'imageTextAlternative'],
          styles: ['full', 'alignLeft', 'alignRight']
        },
        toolbar: {
          // shouldNotGroupWhenFull: true,
          items: toolbarOptions
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties']
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4' }
          ]
        }
      };

      requestAnimationFrame(() => {
        let component = this;
        if (component.editorInstance) component.editorInstance.destroy();

        component.$refs.editorElement.innerHTML = content.original ? content.original : 'Start writing here..';
        window.ClassicEditor.create(component.$refs.editorElement, richTextData)
          .then((editor) => {
            component.editorInstance = editor;
            component.editorInstance.model.document.on('change:data', component.onInput);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }
};

let colors = [
  { color: 'rgb(249,237,235)', label: ' ' },
  { color: 'rgb(242,215,212)', label: ' ' },
  { color: 'rgb(229,175,169)', label: ' ' },
  { color: 'rgb(217,138,129)', label: ' ' },
  { color: 'rgb(205,98,86)', label: ' ' },
  { color: 'rgb(191,58,43)', label: ' ' },
  { color: 'rgb(170,52,39)', label: ' ' },
  { color: 'rgb(145,44,33)', label: ' ' },
  { color: 'rgb(121,37,27)', label: ' ' },
  { color: 'rgb(100,30,23)', label: ' ' },
  { color: 'rgb(253,238,237)', label: ' ' },
  { color: 'rgb(250,218,214)', label: ' ' },
  { color: 'rgb(245,185,178)', label: ' ' },
  { color: 'rgb(240,147,137)', label: ' ' },
  { color: 'rgb(236,114,101)', label: ' ' },
  { color: 'rgb(231,77,60)', label: ' ' },
  { color: 'rgb(203,67,52)', label: ' ' },
  { color: 'rgb(174,58,45)', label: ' ' },
  { color: 'rgb(150,50,39)', label: ' ' },
  { color: 'rgb(122,40,31)', label: ' ' },
  { color: 'rgb(244,237,247)', label: ' ' },
  { color: 'rgb(236,223,241)', label: ' ' },
  { color: 'rgb(215,188,225)', label: ' ' },
  { color: 'rgb(196,156,211)', label: ' ' },
  { color: 'rgb(177,124,197)', label: ' ' },
  { color: 'rgb(155,88,182)', label: ' ' },
  { color: 'rgb(138,79,161)', label: ' ' },
  { color: 'rgb(117,67,137)', label: ' ' },
  { color: 'rgb(99,57,116)', label: ' ' },
  { color: 'rgb(82,47,96)', label: ' ' },
  { color: 'rgb(244,237,247)', label: ' ' },
  { color: 'rgb(231,216,238)', label: ' ' },
  { color: 'rgb(210,181,222)', label: ' ' },
  { color: 'rgb(186,142,205)', label: ' ' },
  { color: 'rgb(165,106,190)', label: ' ' },
  { color: 'rgb(141,67,173)', label: ' ' },
  { color: 'rgb(126,60,154)', label: ' ' },
  { color: 'rgb(108,51,132)', label: ' ' },
  { color: 'rgb(90,43,110)', label: ' ' },
  { color: 'rgb(75,36,92)', label: ' ' },
  { color: 'rgb(232,241,248)', label: ' ' },
  { color: 'rgb(213,230,241)', label: ' ' },
  { color: 'rgb(170,205,228)', label: ' ' },
  { color: 'rgb(128,179,214)', label: ' ' },
  { color: 'rgb(82,152,199)', label: ' ' },
  { color: 'rgb(40,127,184)', label: ' ' },
  { color: 'rgb(36,112,163)', label: ' ' },
  { color: 'rgb(31,98,142)', label: ' ' },
  { color: 'rgb(26,81,117)', label: ' ' },
  { color: 'rgb(21,66,96)', label: ' ' },
  { color: 'rgb(233,244,251)', label: ' ' },
  { color: 'rgb(216,235,248)', label: ' ' },
  { color: 'rgb(173,213,240)', label: ' ' },
  { color: 'rgb(134,194,234)', label: ' ' },
  { color: 'rgb(95,174,227)', label: ' ' },
  { color: 'rgb(51,152,219)', label: ' ' },
  { color: 'rgb(46,135,194)', label: ' ' },
  { color: 'rgb(39,115,165)', label: ' ' },
  { color: 'rgb(33,97,140)', label: ' ' },
  { color: 'rgb(27,80,116)', label: ' ' },
  { color: 'rgb(231,248,245)', label: ' ' },
  { color: 'rgb(208,241,234)', label: ' ' },
  { color: 'rgb(164,229,216)', label: ' ' },
  { color: 'rgb(117,215,195)', label: ' ' },
  { color: 'rgb(73,202,176)', label: ' ' },
  { color: 'rgb(26,188,156)', label: ' ' },
  { color: 'rgb(23,166,137)', label: ' ' },
  { color: 'rgb(20,144,119)', label: ' ' },
  { color: 'rgb(17,121,100)', label: ' ' },
  { color: 'rgb(13,99,82)', label: ' ' },
  { color: 'rgb(233,246,244)', label: ' ' },
  { color: 'rgb(208,236,230)', label: ' ' },
  { color: 'rgb(161,217,205)', label: ' ' },
  { color: 'rgb(114,197,181)', label: ' ' },
  { color: 'rgb(69,181,159)', label: ' ' },
  { color: 'rgb(22,162,134)', label: ' ' },
  { color: 'rgb(19,139,115)', label: ' ' },
  { color: 'rgb(17,121,100)', label: ' ' },
  { color: 'rgb(14,103,85)', label: ' ' },
  { color: 'rgb(12,85,71)', label: ' ' },
  { color: 'rgb(233,247,239)', label: ' ' },
  { color: 'rgb(211,238,222)', label: ' ' },
  { color: 'rgb(170,223,192)', label: ' ' },
  { color: 'rgb(126,206,159)', label: ' ' },
  { color: 'rgb(81,189,126)', label: ' ' },
  { color: 'rgb(40,175,96)', label: ' ' },
  { color: 'rgb(35,154,84)', label: ' ' },
  { color: 'rgb(30,133,73)', label: ' ' },
  { color: 'rgb(25,112,62)', label: ' ' },
  { color: 'rgb(21,91,50)', label: ' ' },
  { color: 'rgb(234,250,241)', label: ' ' },
  { color: 'rgb(214,245,227)', label: ' ' },
  { color: 'rgb(173,235,199)', label: ' ' },
  { color: 'rgb(128,224,168)', label: ' ' },
  { color: 'rgb(87,214,140)', label: ' ' },
  { color: 'rgb(46,204,112)', label: ' ' },
  { color: 'rgb(41,179,98)', label: ' ' },
  { color: 'rgb(35,154,84)', label: ' ' },
  { color: 'rgb(29,129,71)', label: ' ' },
  { color: 'rgb(24,104,57)', label: ' ' },
  { color: 'rgb(254,249,231)', label: ' ' },
  { color: 'rgb(252,243,207)', label: ' ' },
  { color: 'rgb(249,231,159)', label: ' ' },
  { color: 'rgb(247,219,110)', label: ' ' },
  { color: 'rgb(244,207,62)', label: ' ' },
  { color: 'rgb(241,196,14)', label: ' ' },
  { color: 'rgb(211,171,13)', label: ' ' },
  { color: 'rgb(182,148,12)', label: ' ' },
  { color: 'rgb(153,125,10)', label: ' ' },
  { color: 'rgb(125,101,8)', label: ' ' },
  { color: 'rgb(254,245,231)', label: ' ' },
  { color: 'rgb(252,235,207)', label: ' ' },
  { color: 'rgb(250,215,158)', label: ' ' },
  { color: 'rgb(248,197,114)', label: ' ' },
  { color: 'rgb(245,176,66)', label: ' ' },
  { color: 'rgb(243,156,18)', label: ' ' },
  { color: 'rgb(213,138,16)', label: ' ' },
  { color: 'rgb(185,119,14)', label: ' ' },
  { color: 'rgb(157,101,12)', label: ' ' },
  { color: 'rgb(128,83,10)', label: ' ' },
  { color: 'rgb(252,242,232)', label: ' ' },
  { color: 'rgb(250,228,209)', label: ' ' },
  { color: 'rgb(245,204,168)', label: ' ' },
  { color: 'rgb(240,177,122)', label: ' ' },
  { color: 'rgb(235,150,76)', label: ' ' },
  { color: 'rgb(231,126,35)', label: ' ' },
  { color: 'rgb(204,112,30)', label: ' ' },
  { color: 'rgb(173,95,26)', label: ' ' },
  { color: 'rgb(146,80,22)', label: ' ' },
  { color: 'rgb(120,65,18)', label: ' ' },
  { color: 'rgb(251,238,229)', label: ' ' },
  { color: 'rgb(246,220,203)', label: ' ' },
  { color: 'rgb(238,188,155)', label: ' ' },
  { color: 'rgb(229,153,102)', label: ' ' },
  { color: 'rgb(220,118,50)', label: ' ' },
  { color: 'rgb(209,84,0)', label: ' ' },
  { color: 'rgb(184,73,0)', label: ' ' },
  { color: 'rgb(158,63,0)', label: ' ' },
  { color: 'rgb(133,53,0)', label: ' ' },
  { color: 'rgb(112,45,0)', label: ' ' },
  { color: 'rgb(252,253,253)', label: ' ' },
  { color: 'rgb(252,253,253)', label: ' ' },
  { color: 'rgb(246,248,248)', label: ' ' },
  { color: 'rgb(243,246,246)', label: ' ' },
  { color: 'rgb(240,243,244)', label: ' ' },
  { color: 'rgb(237,241,242)', label: ' ' },
  { color: 'rgb(207,210,211)', label: ' ' },
  { color: 'rgb(179,182,183)', label: ' ' },
  { color: 'rgb(151,154,155)', label: ' ' },
  { color: 'rgb(124,126,126)', label: ' ' },
  { color: 'rgb(249,250,250)', label: ' ' },
  { color: 'rgb(241,242,243)', label: ' ' },
  { color: 'rgb(227,230,232)', label: ' ' },
  { color: 'rgb(216,220,222)', label: ' ' },
  { color: 'rgb(203,207,210)', label: ' ' },
  { color: 'rgb(189,195,199)', label: ' ' },
  { color: 'rgb(167,172,175)', label: ' ' },
  { color: 'rgb(144,149,152)', label: ' ' },
  { color: 'rgb(121,126,129)', label: ' ' },
  { color: 'rgb(99,103,105)', label: ' ' },
  { color: 'rgb(244,246,246)', label: ' ' },
  { color: 'rgb(233,236,236)', label: ' ' },
  { color: 'rgb(213,220,220)', label: ' ' },
  { color: 'rgb(191,201,202)', label: ' ' },
  { color: 'rgb(169,182,183)', label: ' ' },
  { color: 'rgb(149,166,167)', label: ' ' },
  { color: 'rgb(131,144,145)', label: ' ' },
  { color: 'rgb(114,125,126)', label: ' ' },
  { color: 'rgb(97,106,107)', label: ' ' },
  { color: 'rgb(78,85,86)', label: ' ' },
  { color: 'rgb(241,243,243)', label: ' ' },
  { color: 'rgb(231,233,233)', label: ' ' },
  { color: 'rgb(204,209,209)', label: ' ' },
  { color: 'rgb(179,187,188)', label: ' ' },
  { color: 'rgb(152,163,164)', label: ' ' },
  { color: 'rgb(128,141,142)', label: ' ' },
  { color: 'rgb(111,122,123)', label: ' ' },
  { color: 'rgb(97,106,107)', label: ' ' },
  { color: 'rgb(82,90,91)', label: ' ' },
  { color: 'rgb(65,72,72)', label: ' ' },
  { color: 'rgb(235,237,239)', label: ' ' },
  { color: 'rgb(215,219,224)', label: ' ' },
  { color: 'rgb(172,181,190)', label: ' ' },
  { color: 'rgb(132,145,159)', label: ' ' },
  { color: 'rgb(93,110,126)', label: ' ' },
  { color: 'rgb(53,74,95)', label: ' ' },
  { color: 'rgb(45,64,82)', label: ' ' },
  { color: 'rgb(40,56,72)', label: ' ' },
  { color: 'rgb(33,46,59)', label: ' ' },
  { color: 'rgb(27,38,49)', label: ' ' },
  { color: 'rgb(233,235,236)', label: ' ' },
  { color: 'rgb(213,217,220)', label: ' ' },
  { color: 'rgb(172,179,185)', label: ' ' },
  { color: 'rgb(130,140,151)', label: ' ' },
  { color: 'rgb(86,99,113)', label: ' ' },
  { color: 'rgb(43,61,79)', label: ' ' },
  { color: 'rgb(38,54,69)', label: ' ' },
  { color: 'rgb(33,46,59)', label: ' ' },
  { color: 'rgb(29,41,53)', label: ' ' },
  { color: 'rgb(24,33,43)', label: ' ' }
];

// let ckeditorStyles = `:root {
//   --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
//   --ck-color-mention-text: hsl(341, 100%, 30%);
//   --ck-highlight-marker-blue: hsl(201, 97%, 72%);
//   --ck-highlight-marker-green: hsl(120, 93%, 68%);
//   --ck-highlight-marker-pink: hsl(345, 96%, 73%);
//   --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
//   --ck-highlight-pen-green: hsl(112, 100%, 27%);
//   --ck-highlight-pen-red: hsl(0, 85%, 49%);
//   --ck-image-style-spacing: 1.5em;
//   --ck-todo-list-checkmark-size: 16px;
// }

// /* ckeditor5-image/theme/imagestyle.css */
//  .image-style-side {
//   float: right;
//   margin-left: var(--ck-image-style-spacing);
//   max-width: 50%;
// }
// /* ckeditor5-image/theme/imagestyle.css */
//  .image-style-align-left {
//   float: left;
//   margin-right: var(--ck-image-style-spacing);
// }
// /* ckeditor5-image/theme/imagestyle.css */
//  .image-style-align-center {
//   margin-left: auto;
//   margin-right: auto;
// }
// /* ckeditor5-image/theme/imagestyle.css */
//  .image-style-align-right {
//   float: right;
//   margin-left: var(--ck-image-style-spacing);
// }
// /* ckeditor5-image/theme/imageresize.css */
//  .image.image_resized {
//   max-width: 100%;
//   display: block;
//   box-sizing: border-box;
// }
// /* ckeditor5-image/theme/imageresize.css */
//  .image.image_resized img {
//   width: 100%;
// }
// /* ckeditor5-image/theme/imageresize.css */
//  .image.image_resized > figcaption {
//   display: block;
// }
// /* ckeditor5-image/theme/imagecaption.css */
//  .image > figcaption {
//   display: table-caption;
//   caption-side: bottom;
//   word-break: break-word;
//   color: hsl(0, 0%, 20%);
//   background-color: hsl(0, 0%, 97%);
//   padding: .6em;
//   font-size: .75em;
//   outline-offset: -1px;
// }
// /* ckeditor5-image/theme/image.css */
//  .image {
//   display: table;
//   clear: both;
//   text-align: center;
//   margin: 1em auto;
// }
// /* ckeditor5-image/theme/image.css */
//  .image img {
//   display: block;
//   margin: 0 auto;
//   max-width: 100%;
//   min-width: 50px;
// }
// /* ckeditor5-highlight/theme/highlight.css */
//  .marker-yellow {
//   background-color: var(--ck-highlight-marker-yellow);
// }
// /* ckeditor5-highlight/theme/highlight.css */
//  .marker-green {
//   background-color: var(--ck-highlight-marker-green);
// }
// /* ckeditor5-highlight/theme/highlight.css */
//  .marker-pink {
//   background-color: var(--ck-highlight-marker-pink);
// }
// /* ckeditor5-highlight/theme/highlight.css */
//  .marker-blue {
//   background-color: var(--ck-highlight-marker-blue);
// }
// /* ckeditor5-highlight/theme/highlight.css */
//  .pen-red {
//   color: var(--ck-highlight-pen-red);
//   background-color: transparent;
// }
// /* ckeditor5-highlight/theme/highlight.css */
//  .pen-green {
//   color: var(--ck-highlight-pen-green);
//   background-color: transparent;
// }
// /* ckeditor5-font/theme/fontsize.css */
//  .text-tiny {
//   font-size: .7em;
// }
// /* ckeditor5-font/theme/fontsize.css */
//  .text-small {
//   font-size: .85em;
// }
// /* ckeditor5-font/theme/fontsize.css */
//  .text-big {
//   font-size: 1.4em;
// }
// /* ckeditor5-font/theme/fontsize.css */
//  .text-huge {
//   font-size: 1.8em;
// }
// /* ckeditor5-block-quote/theme/blockquote.css */
//  blockquote {
//   overflow: hidden;
//   padding-right: 1.5em;
//   padding-left: 1.5em;
//   margin-left: 0;
//   margin-right: 0;
//   font-style: italic;
//   border-left: solid 5px hsl(0, 0%, 80%);
// }
// /* ckeditor5-block-quote/theme/blockquote.css */
// [dir="rtl"] blockquote {
//   border-left: 0;
//   border-right: solid 5px hsl(0, 0%, 80%);
// }
// /* ckeditor5-basic-styles/theme/code.css */
//  code {
//   background-color: hsla(0, 0%, 78%, 0.3);
//   padding: .15em;
//   border-radius: 2px;
// }
// /* ckeditor5-table/theme/table.css */
//  .table {
//   margin: 1em auto;
//   display: table;
// }
// /* ckeditor5-table/theme/table.css */
//  .table table {
//   border-collapse: collapse;
//   border-spacing: 0;
//   width: 100%;
//   height: 100%;
//   border: 1px double hsl(0, 0%, 70%);
// }
// /* ckeditor5-table/theme/table.css */
//  .table table td,
//  .table table th {
//   min-width: 2em;
//   padding: .4em;
//   border: 1px solid hsl(0, 0%, 75%);
// }
// /* ckeditor5-table/theme/table.css */
//  .table table th {
//   font-weight: bold;
//   background: hsla(0, 0%, 0%, 5%);
// }
// /* ckeditor5-table/theme/table.css */
// [dir="rtl"] .table th {
//   text-align: right;
// }
// /* ckeditor5-table/theme/table.css */
// [dir="ltr"] .table th {
//   text-align: left;
// }
// /* ckeditor5-page-break/theme/pagebreak.css */
//  .page-break {
//   position: relative;
//   clear: both;
//   padding: 5px 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// /* ckeditor5-page-break/theme/pagebreak.css */
//  .page-break::after {
//   content: '';
//   position: absolute;
//   border-bottom: 2px dashed hsl(0, 0%, 77%);
//   width: 100%;
// }
// /* ckeditor5-page-break/theme/pagebreak.css */
//  .page-break__label {
//   position: relative;
//   z-index: 1;
//   padding: .3em .6em;
//   display: block;
//   text-transform: uppercase;
//   border: 1px solid hsl(0, 0%, 77%);
//   border-radius: 2px;
//   font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
//   font-size: 0.75em;
//   font-weight: bold;
//   color: hsl(0, 0%, 20%);
//   background: hsl(0, 0%, 100%);
//   box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }
// /* ckeditor5-media-embed/theme/mediaembed.css */
//  .media {
//   clear: both;
//   margin: 1em 0;
//   display: block;
//   min-width: 15em;
// }
// /* ckeditor5-html-embed/theme/htmlembed.css */
//  .raw-html-embed {
//   margin: 1em auto;
//   min-width: 15em;
//   font-style: normal;
// }
// /* ckeditor5-horizontal-line/theme/horizontalline.css */
//  hr {
//   margin: 15px 0;
//   height: 4px;
//   background: hsl(0, 0%, 87%);
//   border: 0;
// }
// /* ckeditor5-code-block/theme/codeblock.css */
//  pre {
//   padding: 1em;
//   color: hsl(0, 0%, 20.8%);
//   background: hsla(0, 0%, 78%, 0.3);
//   border: 1px solid hsl(0, 0%, 77%);
//   border-radius: 2px;
//   text-align: left;
//   direction: ltr;
//   tab-size: 4;
//   white-space: pre-wrap;
//   font-style: normal;
//   min-width: 200px;
// }
// /* ckeditor5-code-block/theme/codeblock.css */
//  pre code {
//   background: unset;
//   padding: 0;
//   border-radius: 0;
// }
// /* ckeditor5-mention/theme/mention.css */
//  .mention {
//   background: var(--ck-color-mention-background);
//   color: var(--ck-color-mention-text);
// }
// @media print {
//   /* ckeditor5-page-break/theme/pagebreak.css */
//    .page-break {
//       padding: 0;
//   }
//   /* ckeditor5-page-break/theme/pagebreak.css */
//    .page-break::after {
//       display: none;
//   }
// }`;
