<template>
  <el-dialog append-to-body custom-class="smsTestDailog" :visible.sync="visible" width="500px" center des title="Send Test SMS" :show-close="false" :destroy-on-close="true">
    <template v-if="mode == 'submit'">
      <el-form :model="smsTestForm" ref="pushNotificationTestForm" @submit.native.prevent>
        <div class="mobileNumbarHeader">Enter Mobile Number</div>
        <el-form-item>
          <el-input class="countryCode" placeholder="91" v-model="smsTestForm.countryCode" type="age" autocomplete="on"></el-input>
          <el-input class="numberInput" placeholder="Enter mobile number" v-model="smsTestForm.mobileNumber" type="age" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
    </template>

    <!-- Test Response Response -->
    <div class="py-6 text-center" v-if="mode == 'test-response'">
      <!-- Loading -->
      <template v-if="!acknowledgementResponse">
        <beat-loader :loading="true" color="rgb(220, 223, 230)" size="20px"></beat-loader>
        <div class="pt-4 text-lg text-gray-500 font-semibold">Waiting For Delivery Report From SMS Vendor</div>
      </template>

      <div v-else>
        <!-- Header -->
        <div class="text-center">
          <!-- SUCCESS ICON -->
          <i v-if="acknowledgementResponse.success" class="fa-solid fa-hexagon-check text-green-700" style="font-size: 70px"></i>
          <!-- ERROR ICON -->
          <i v-else class="fa-solid fa-hexagon-exclamation text-red-600" style="font-size: 70px"></i>

          <div class="text-lg font-semibold mt-2 text-gray-800">
            {{ acknowledgementResponse.success ? 'SMS DELIVERED' : 'SMS Delivery Failed' }}
          </div>
        </div>

        <div class="text-center mt-3" v-if="!acknowledgementResponse.success">
          <div>
            <div class="font-semibold text-red-800">ERROR STATUS: {{ acknowledgementResponse.failureType }}</div>
          </div>
          <div class="mt-1 text-xs">{{ acknowledgementResponse.failureReason }}</div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="text-right w-full">
        <template v-if="mode == 'submit'">
          <el-button :loading="sendingTestSms" size="small" @click="sendTestSms()" type="primary">Send Test SMS</el-button>
          <el-button :loading="sendingTestSms" size="small" @click="visible = false" type="danger" plain>Cancel</el-button>
        </template>
        <template v-else>
          <el-button size="small" @click="visible = false" type="danger">Close</el-button>
        </template>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" src="./sendTestSmsPopup.scss"></style>

<script>
import sendTestSmsPopupComponent from './sendTestSmsPopupComponent';
export default sendTestSmsPopupComponent;
</script>
