import imageMgtService from '../../../../services/imageMgtService';

export default {
  data() {
    return {
      uploadingImage: false,
      visible: false,
      imageList: null,
      fetchingImageList: true,
      pageNo: 1,
      pageSize: 30,

      selectedTab: 'all',
      bookmarkedImageCount: null,
      totalImageCount: null
    };
  },
  props: {
    isImagePage: {
      default: false,
      type: Boolean,
      required: false
    }
  },
  methods: {
    onFileSelected(file) {
      let fileSize = (file.size / 1024 / 1024).toFixed(2);
      if (fileSize > 16) {
        this.errorToast('File must be less than 16MB.');
        return false;
      }
      this.uploadImage(file);
      return false;
    },

    async uploadImage(file) {
      try {
        this.uploadingImage = true;
        let formData = new FormData();
        formData.append('image', file);

        let result = await imageMgtService.uploadImage(formData, this);
        this.imageList.unshift(result.data);
        this.uploadingImage = false;
      } catch (err) {
        console.log('Image upload failed', err);
        this.errorToast('Failed to upload image, plase contact support.');
        this.uploadingImage = false;
      }
    },

    async deleteImage(image, index) {
      this.imageList.splice(index, 1);
      imageMgtService.deleteCampaignImage(image.id, this).catch((err) => {
        console.log('Failed to delete image', err);
        this.errorToast('Failed delete image, plase contact support.');
      });
    },

    async onTabChange(tab) {
      this.selectedTab = tab;

      // Reset pagination.
      this.pageNo = 1;

      this.fetchImageList(false);
    },

    async fetchImageList(readTotal) {
      this.fetchingImageList = true;

      let params = {
        page: this.pageNo,
        size: this.pageSize,
        total: readTotal ? 1 : 0,
        onlyBookmarked: this.selectedTab === 'bookmarked' ? 1 : 0
      };

      imageMgtService
        .getImages(params, this)
        .then((result) => {
          this.imageList = result.data.list;
          if (result.data.total) {
            this.bookmarkedImageCount = result.data.total.bookmarked;
            this.totalImageCount = result.data.total.all;
          }
          this.fetchingImageList = false;
        })
        .catch((err) => {
          console.log('Failed to fetch images', err);
          this.errorToast('Failed fetch images, plase contact support.');
          this.fetchingImageList = false;
        });
    },

    onPageChange(val) {
      this.pageNo = val;
      this.fetchImageList(false);
    },

    onImageSelected(img) {
      this.visible = false;
      this.$emit('onSelect', img);
    },

    async onCopyImageUrl(img) {
      setTimeout(async () => {
        try {
          await navigator.clipboard.writeText(img.url);
          this.successToast('Link Copied.');
        } catch (err) {
          console.log('Failed to copy image URL', err);
          this.errorToast('Failed to copy image URL, plase contact support.');
        }
      }, 500);
    },

    toggleVisiblity() {
      this.visible = !this.visible;
      if (this.visible && this.imageList == null) {
        this.fetchImageList(true);
      }
    },

    async onToggleImageBookmark(index) {
      try {
        let image = this.imageList[index];
        image.is_bookmarked = !image.is_bookmarked;
        if (image.is_bookmarked) {
          this.bookmarkedImageCount++;
        } else {
          this.bookmarkedImageCount--;
          if (this.selectedTab === 'bookmarked') this.imageList.splice(index, 1);
        }

        await imageMgtService.toggleImageBookmark(
          {
            id: image.id
          },
          this
        );
      } catch (err) {
        console.log('Failed to toggle image bookmark', err);
        this.errorToast('Failed to bookmark image, plase contact support.');
      }
    }
  },

  mounted() {
    if (this.isImagePage) {
      this.fetchImageList(true);
    }
  }
};
