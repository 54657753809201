<template>
  <div class="journeyExitCriteriaContent">
    <div class="rounded-md border overflow-hidden" shadow="never">
      <div class="bg-gray-100 p-2">
        <el-checkbox v-model="exitCriteria.enabled">
          <div class="text-primary-600">Use Exit Criteria</div>
        </el-checkbox>
        <div class="ml-4 text-gray-500 text-xs -mt-1 leading-4">Use exit criteria to remove customers who no longer fit the journey's purpose or for whom the journey's content is no longer relevant.</div>
      </div>

      <div class="p-3 border-t" v-if="exitCriteria.enabled">
        <!-- Journey Event Triggers -->
        <div class="border rounded-lg overflow-hidden">
          <!-- Header -->
          <div class="px-3 py-2 exit-criteria-card-header">
            <el-checkbox class="text-md" v-model="exitCriteria.events.enabled">
              <div class="text-primary-600">Exit Journey - When Event Performed</div>
            </el-checkbox>
            <div class="ml-4 text-gray-500 text-xs -mt-1 leading-4">Specify the list of events. If any of these events fired by customers while they are in journey, they will forcefully exit the journey.</div>
          </div>

          <!-- List of Events -->
          <div class="border-top" v-if="exitCriteria.events.enabled">
            <div class="border-b" v-for="(event, index) in exitCriteria.events.list" :key="index">
              <el-row class="w-full">
                <el-col :span="23">
                  <EventPicker :ref="'eventPicker' + index" class="w-full ml-3 my-2" :readOnly="false" v-bind:selectedOnActionEvent="event" channelType="journey"></EventPicker>

                  <div class="w-full ml-3 mt-0 mb-2 text-red-600 text-sm" v-if="event && event.error">{{ event.error }}</div>
                </el-col>
                <el-col class="pt-3" :span="1">
                  <el-button @click="exitCriteria.events.list.splice(index, 1)" size="mini" plain type="danger" icon="el-icon-delete" circle></el-button>
                </el-col>
              </el-row>
            </div>
            <div class="py-2.5 px-3">
              <el-button v-if="exitCriteria.events.list.length < 3" type="primary" plain size="mini" @click="exitCriteria.events.list.push({ data: null })">Add Event</el-button>
              <div v-else class="text-xs text-gray-400">Maximum 3 events allowed.</div>
            </div>
          </div>
        </div>

        <!-- Journey Segment Exits -->
        <div class="border rounded overflow-hidden mt-4">
          <!-- Header -->
          <div class="px-2 py-2 exit-criteria-card-header">
            <el-checkbox v-model="exitCriteria.segments.enabled">
              <div class="text-primary-600">Exit Journey - Exclude User If In Segment</div>
            </el-checkbox>
            <div class="ml-4 text-gray-500 text-xs -mt-1 leading-4">Specify segment criteria. If customer meets the segment criteria when leaving "Wait For Event" or "Wait For Time" step, customer will forcefully exit journey if customer is in segment.</div>
          </div>

          <!-- Segment Criteria -->
          <div class="border-top px-3 py-2" v-if="exitCriteria.segments.enabled">
            <div class="w-full ml-1 mt-2 text-red-600 text-sm" v-if="segmentationError">{{ segmentationError }}</div>
            <UserPropertyFilterComponent v-bind:filterTitle="'Exit Segment Criteria'" ref="userFilter" v-bind:formattedPropertyFilter="exitCriteria.segments.query"></UserPropertyFilterComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./journeyExitCriteria.scss"></style>

<script>
import journeyExitCriteriaComponent from './journeyExitCriteriaComponent';
export default journeyExitCriteriaComponent;
</script>
