<template>
  <el-drawer custom-class="dragDropSelectorDialog" title="Select Email Block" :visible.sync="dialogVisible" direction="btt" :before-close="handleBeforeClose" width="920px" append-to-body>
    <el-tabs v-model="dialogTab" @tab-click="onTabChange">
      <!-- Default Blocks -->
      <el-tab-pane class="defaultBlocksTab" label="All Blocks" name="all">
        <el-row>
          <el-col :span="3" class="sideBar">
            <div class="categoryItem text-sm m-2 py-1.5 pl-2 rounded capitalize" @click="selectedBlockCategory = category" :class="{ activeCategory: selectedBlockCategory == category }" v-for="(category, index) in ['general', 'headers', 'footers']" :key="index">
              {{ category }}
            </div>
          </el-col>
          <el-col :span="21">
            <div class="blockListContainer">
              <!-- General Blocks -->
              <template v-if="selectedBlockCategory == 'general'">
                <el-row class="generalBlocksList" :gutter="5" style="margin-bottom: 20px !important" v-for="(categoryBlocks, category) in blocks" :key="category">
                  <el-col class="subHeader" :span="24">{{ category }}</el-col>
                  <el-col :span="4" v-for="(block, index) in categoryBlocks" :key="index">
                    <div class="blockContainer" v-on:click="onBlockSelected(block)">
                      <div class="block">
                        <div class="blockIcon" :style="{ 'background-image': 'url(' + block.icon + ')' }"></div>
                        <div class="blockText">
                          {{ block.name }}
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </template>

              <!-- Header Blocks -->
              <div class="predefinedBlockList" v-if="selectedBlockCategory == 'headers'">
                <div class="grid grid-cols-2 gap-0">
                  <div class="p-3 rounded" v-for="(headerBlock, index) in headerBlocks" :key="index">
                    <div class="previewItem">
                      <div class="border rounded overflow-hidden cursor-pointer hover:shadow-lg" @click="onBlockSelected(headerBlock)">
                        <img :src="headerBlock.image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Hero Blocks -->
              <div class="predefinedBlockList" v-if="selectedBlockCategory == 'Hero Section'">
                <div class="grid grid-cols-2 gap-0">
                  <div class="p-3 rounded" v-for="(heroBlock, index) in heroSections" :key="index">
                    <div class="previewItem">
                      <div class="border rounded overflow-hidden cursor-pointer hover:shadow-lg" @click="onBlockSelected(heroBlock)">
                        <img :src="heroBlock.image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Footer Blocks -->
              <div class="predefinedBlockList" v-if="selectedBlockCategory == 'footers'">
                <div class="grid grid-cols-2 gap-0">
                  <div class="p-3 rounded" v-for="(footerBlock, index) in footerBlocks" :key="index">
                    <div class="previewItem">
                      <div class="border rounded overflow-hidden cursor-pointer hover:shadow-lg" @click="onBlockSelected(footerBlock)">
                        <img :src="footerBlock.image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>

      <!-- Saved Blocks -->
      <el-tab-pane class="savedBlocksTab" label="Saved Blocks" name="saved">
        <div v-if="fetchingBlocksList" v-loading="true" class="loader"></div>
        <div v-else>
          <div v-if="savedBlockList.length > 0">
            <el-row class="blodkDropdown">
              <el-col :span="14">
                <div class="title">Select Saved Block</div>
                <el-select filterable v-model="selectedSavedBlockId" @change="getDetailsOfSavedBlock">
                  <el-option v-for="(savedBlock, index) in savedBlockList" :key="index" :label="savedBlock.name" :value="savedBlock.id"> </el-option>
                </el-select>
              </el-col>
              <el-col :span="10" style="text-align: right">
                <el-button type="success" @click="onBlockSavedSelected(selectedSavedBlockDetail.content)">Use This Block</el-button>
                &nbsp;
                <el-popconfirm confirmButtonText="Delete" cancelButtonText="Cancel" @confirm="onDeleteSavedBlock" icon="el-icon-info" iconColor="red" title="Delete this block?">
                  <el-button type="danger" slot="reference">Delete Block</el-button>
                </el-popconfirm>
              </el-col>
            </el-row>
            <div class="saveBlockDetail" v-if="selectedSavedBlockDetail">
              <div class="hint">Images sometimes many not appear in preview, due to browser constraints.</div>
              <img :src="selectedSavedBlockDetail.image" />
            </div>
          </div>
          <div v-else class="noDatafound">No Saved Blocks Found</div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-drawer>
</template>

<style lang="scss" src="./dragDropBlockSelector.scss"></style>

<script>
import dragDropBlockSelectorComponent from './dragDropBlockSelectorComponent';
export default dragDropBlockSelectorComponent;
</script>
