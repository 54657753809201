export default {
  name: 'utmTrackingInput',
  props: {
    channelType: {
      required: true,
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      enabled: false,
      overridePrams: true,
      utmForm: {
        source: this.channelType,
        medium: 'growlytics',
        campaign: ''
      },
      utmFormRules: {
        source: [
          { required: true, message: 'UTM Source is required.', trigger: 'blur' },
          { min: 1, max: 50, message: 'UTM Source can not exceed 50 characers.', trigger: 'blur' }
        ],
        medium: [
          { required: true, message: 'UTM Medium is required.', trigger: 'blur' },
          { min: 1, max: 50, message: 'UTM Medium can not exceed 50 characers.', trigger: 'blur' }
        ],
        campaign: [
          { required: true, message: 'UTM Campaign is required.', trigger: 'blur' },
          { min: 1, max: 50, message: 'UTM Campaign can not exceed 50 characers.', trigger: 'blur' }
        ]
      }
    };
  },

  methods: {
    render(info) {
      if (info) {
        this.enabled = true;
        this.utmForm.source = info.source;
        this.utmForm.medium = info.medium;
        this.utmForm.campaign = info.campaign;
        this.overridePrams = info.override;
      } else {
        this.utmForm.medium = this.channelType;
        this.utmForm.source = 'growlytics';
      }
    },

    getContents() {
      if (!this.enabled) return null;
      let params = this.utmForm;
      params.override = this.overridePrams;
      return params;
    },

    async validateForm() {
      if (!this.enabled) return true;
      return new Promise((resolve) => {
        this.$refs['utmInputForm'].validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    }
  },

  mounted() {
    this.utmForm.medium = this.channelType;
    this.utmForm.source = 'growlytics';
  }
};
