import ShortLinkPopup from '@/components/campaign/shortLink/shortLink';
import campaignPreviewService from '@/services/campaignMessagePreview';
import { Picker } from 'emoji-mart-vue';
import { fetchChannelProviderList } from '../../../../../services/campaign';
import DynamicUserProperty from '../../../../dynamicUserFilterComponent/dynamicUserFilter';
import WhatsAppTemplateSelectorPopup from './../whatsAppTemplateSelector/whatsAppTemplateSelectorPopup';

export default {
  name: 'WhatsAppEditor',
  components: {
    Picker,
    DynamicUserProperty,
    ShortLinkPopup,
    WhatsAppTemplateSelectorPopup
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    allowDynamicEvents: {
      type: Boolean,
      required: false,
      default: true
    },
    isJourneyCampaign: {
      required: true,
      type: Boolean
    }
  },
  watch: {
    'whatsAppForm.message': {
      deep: true,
      handler() {
        this.onWhatsAppContentChange();
      }
    },
    'whatsAppForm.selectedProvider': {
      deep: true,
      handler() {
        this.onProviderSelected();
      }
    }
  },
  data() {
    return {
      whatsAppDynmicUserPopver: false,
      whatsAppEmojiPiker: null,

      contentAlreadyRendered: false,

      // Validation form
      whatsAppFormRules: {
        selectedProvider: [
          {
            required: true,
            message: 'Please select WhatsApp provider.',
            trigger: 'change'
          }
        ]
      },

      // Whatsapp templates
      selectedWhatsApptemplate: null,

      // Content Builder Variables
      whatsAppForm: {
        message: 'Whatsapp Message  Goes Here 😀',
        selectedProvider: null,
        shortLinks: []
      },
      whatsAppMessagePreview: '',

      providerList: null,
      selectedProviderObj: {},
      channelConfig: {
        whatsAppTemplateName: null,
        whatsAppMediaURL: null,
        whatsAppMsgType: 'Text',
        whatsAppMediaFileName: null,
        selectedTemplateInfo: null,

        whatsAppButtonsEnabled: false
      },

      // Dynamic Input Params.
      showTemplateInputFor: ['whatsapp_freshchat', 'whatsapp_interakt', 'whatsapp_dialog360', 'whatsapp_businessonbot', 'whatsapp_zoko', 'whatsapp_wati', 'whatsapp_gupshup_v1', 'whatsapp_cloud_api', 'whatsapp_gupshup_enterprise'],
      showMessageInputFor: ['whatsapp_gupshup'],
      showKeyValuePairsFor: ['whatsapp_freshchat', 'whatsapp_wati'],
      showValueListFor: ['whatsapp_zoko', 'whatsapp_gupshup_v1'],

      showHeaderBodyFooterFormFor: ['whatsapp_interakt', 'whatsapp_businessonbot', 'whatsapp_dialog360', 'whatsapp_cloud_api'],
      headerBodyFooterForm: { header: [], body: [], footerText: [], footer: [] },

      // Key Value Pairs..
      keyValuePairs: [],

      // Gupshup Enterprise Form
      gupshupEnterprisePopovers: {
        header: false,
        footer: false,
        body: false,
        buttonDynamicURL: false,
        mediaUrl: false,
        caption: false
      },
      gupshupEnterpriseForm: {
        messageType: 'Text',
        header: null,
        footer: null,
        body: null,
        mediaUrl: null,
        caption: null,
        hasButtons: false,
        buttonDynamicURL: null
      },

      // Send test message Popup variables
      showWhatsAppTestPopup: false,
      sendingTestWhatsApp: false,
      whatsAppTestForm: {
        countryCode: '91',
        mobileNumber: ''
      }
    };
  },

  methods: {
    async onProviderSelected() {
      if (this.providerList == null) {
        setTimeout(() => {
          this.onProviderSelected();
        }, 100);
        return;
      }

      this.selectedProviderObj = this.providerList.find((obj) => {
        return this.whatsAppForm.selectedProvider == obj.id;
      });
    },

    onAddEmptyKeyValuePair() {
      this.keyValuePairs.push({
        key: null,
        value: null,
        userPopover: false
      });
    },

    onAddHeaderBodyFooterFormVariable(key) {
      this.headerBodyFooterForm[key].push({
        key: null,
        value: null,
        userPopover: false
      });
    },

    async fetchWhatsAppProviderList() {
      // Return if content already fetched.
      if (this.providerList != null) return;

      try {
        let params = {
          channel: 'whatsApp',
          readArchived: this.readOnly
        };
        let result = await fetchChannelProviderList(params, this);
        if (result.data.success) {
          this.providerList = [];
          if (result.data.data.length <= 0) {
            this.warningToast('No whatsapp providers is configured.');
            return;
          }
          this.providerList = result.data.data;
        }
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong');
      }
    },

    setDynamicProp(text) {
      // Fetch Current Position
      let position = document.getElementById('messageWhatsAppInput').selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      this.whatsAppForm.message = insert(this.whatsAppForm.message, position, text);
      this.whatsAppDynmicUserPopver = false;
    },

    setDynamicPropOnTarget(targetElement, targetObj, targetProp, text) {
      // Fetch Current Position
      let position = document.getElementById(targetElement).selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      targetObj[targetProp] = targetObj[targetProp] ? targetObj[targetProp] : '';
      targetObj[targetProp] = insert(targetObj[targetProp], position, text);
      this.gupshupEnterprisePopovers[targetProp] = false;
    },

    setKeyValueDyamicProp(index, value) {
      // Fetch Current Position
      let id = 'whatsAppKeyValuePair' + index;
      let position = document.getElementById(id).selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      this.keyValuePairs[index].value = insert(this.keyValuePairs[index].value, position, value);
      this.keyValuePairs[index].userPopover = false;
    },

    setKeyValueDyamicPropForHeaderBodyFooterFormForm(index, subType, value) {
      // Fetch Current Position
      let id = 'whatsAppHeaderBodyFooterFormForm' + subType + index;
      let position = document.getElementById(id).selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      this.headerBodyFooterForm[subType][index].value = insert(this.headerBodyFooterForm[subType][index].value, position, value);
      this.headerBodyFooterForm[subType][index].userPopover = false;
    },

    whatsAppEmojiSelected(emoji) {
      // Fetch Current Position
      let position = document.getElementById('messageWhatsAppInput').selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      this.whatsAppForm.message = insert(this.whatsAppForm.message, position, emoji.native);
      this.whatsAppEmojiPiker = false;
    },

    showShortLinkPopup() {
      this.$refs.shortLinkPopup.showPopup();
    },

    onAddShortLink(data) {
      this.whatsAppForm.shortLinks.push(data);
    },

    onDeleteShortLink(index) {
      let link = this.whatsAppForm.shortLinks[index];

      // Check if the code already exists in message.
      let indexOf = this.whatsAppForm.message.indexOf(link.code);
      if (indexOf >= 0) {
        this.errorToast('Short link is already being used and can not be deleted.');
        return;
      }

      this.whatsAppForm.shortLinks.splice(index, 1);
    },

    onWhatsAppContentChange() {
      this.whatsAppMessagePreview = this.whatsAppForm.message;
      for (let i = 0; i < this.whatsAppForm.shortLinks.length; i++) {
        let snippet = this.whatsAppForm.shortLinks[i].code;
        this.whatsAppMessagePreview = this.whatsAppMessagePreview.replace(snippet, 'https://i.gltcs.in/XXXXXXXXX');
      }
    },

    /**************************** Start: WhatsApp Template Mgt *********************************/

    showTemplateSelectPopup() {
      this.$refs.templateSelectPopup.showDialog(this.selectedProviderObj.id);
    },

    onTemplateChange(template, isPageLoadHandler) {
      this.selectedWhatsApptemplate = template;
      this.channelConfig.whatsAppMsgType = this.selectedWhatsApptemplate.type;
      this.channelConfig.whatsAppTemplateName = this.selectedWhatsApptemplate.id;
      this.channelConfig.selectedTemplateInfo = template;

      // If gupshup enterprise, autofill the form fields from template values.
      if (this.selectedProviderObj.provider_type == 'whatsapp_gupshup_enterprise' && !isPageLoadHandler) {
        this.gupshupEnterpriseForm.messageType = this.toPascalCase(template.type);
        this.gupshupEnterpriseForm.header = template.header;
        this.gupshupEnterpriseForm.footer = template.footer;
        this.gupshupEnterpriseForm.body = template.body;
        this.gupshupEnterpriseForm.mediaUrl = null;
        this.gupshupEnterpriseForm.caption = null;
        this.gupshupEnterpriseForm.hasButtons = template.hasButtons;
        this.gupshupEnterpriseForm.buttonDynamicURL = null;

        console.log('Updated gupshup form:', this.gupshupEnterpriseForm);
      }
    },

    /**************************** Start: Test Popup Methods *********************************/

    async showTestPopup() {
      // If no provider selected, ask to select provider.
      let isValid = await this.validateForm();
      if (!isValid) {
        return;
      }
      this.showWhatsAppTestPopup = true;
    },

    async sendTestMessage() {
      try {
        this.sendingTestWhatsApp = true;

        let params = this.readProcessedContentV1();
        params.to = [this.whatsAppTestForm.countryCode + this.whatsAppTestForm.mobileNumber];
        params.providerId = this.whatsAppForm.selectedProvider;

        let result = await campaignPreviewService.sendTestWhatsAppMessage(params);
        if (result.data.statusCode == 200) {
          this.successToast(`Message sent.`);
          this.showWhatsAppTestPopup = false;
        } else {
          this.errorToast(`Failed to send message. ${result.data.statusCode}-${result.data.statusMessage}`);
        }
        this.sendingTestWhatsApp = false;
      } catch (e) {
        console.error(e);
        this.errorToast(`Something went wrong.`);
        this.sendingTestWhatsApp = false;
      }
    },

    /**************************** End: Test Popup Methods *********************************/

    async validateForm() {
      return new Promise((resolve) => {
        this.$refs['whatsAppInputForm'].validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    readProcessedContentV1() {
      // Build message string.
      let message = '';
      if (['whatsapp_interakt', 'whatsapp_businessonbot', 'whatsapp_dialog360', 'whatsapp_cloud_api'].indexOf(this.selectedProviderObj.provider_type) >= 0) {
        message = JSON.stringify(this.headerBodyFooterForm);
      } else if (this.selectedProviderObj.provider_type == 'whatsapp_gupshup_enterprise') {
        message = JSON.stringify(this.gupshupEnterpriseForm);
      } else if (this.showMessageInputFor.includes(this.selectedProviderObj.provider_type)) {
        message = this.whatsAppForm.message;
      } else {
        // Json As String
        message = JSON.stringify(this.keyValuePairs);
      }

      return {
        content: {
          whatsapp_message: message,
          short_links: JSON.stringify(this.whatsAppForm.shortLinks)
        },
        channelConfig: JSON.stringify(this.channelConfig),
        providerId: this.whatsAppForm.selectedProvider
      };
    },

    async renderCampaignContent(data) {
      if (!this.contentAlreadyRendered) {
        this.contentAlreadyRendered = true;

        // Fetch provider list & wait for it.
        await this.fetchWhatsAppProviderList();
        if (this.providerList == null) {
          setTimeout(() => {
            this.renderCampaignContent(data);
          }, 100);
          return;
        }
        // Read providers first.
        this.whatsAppForm.selectedProvider = data.providerId ? data.providerId : null;
        this.selectedProviderObj = this.providerList.find((obj) => {
          return this.whatsAppForm.selectedProvider == obj.id;
        });
        if (data.content) {
          // READ MESSAGE
          let message = data.content;
          message = data.content.whatsapp_message ? data.content.whatsapp_message : message;

          // Transform Message
          if (!message || !this.selectedProviderObj) {
            // Dont do anything.
          } else if (['whatsapp_interakt', 'whatsapp_businessonbot', 'whatsapp_dialog360', 'whatsapp_cloud_api'].indexOf(this.selectedProviderObj.provider_type) >= 0) {
            this.headerBodyFooterForm = JSON.parse(message);
          } else if (this.selectedProviderObj.provider_type == 'whatsapp_gupshup_enterprise') {
            this.gupshupEnterpriseForm = JSON.parse(message);
          } else if (this.showMessageInputFor.includes(this.selectedProviderObj.provider_type)) {
            this.whatsAppForm.message = message;
          } else {
            // Json As String
            this.keyValuePairs = JSON.parse(message);
          }

          this.whatsAppForm.shortLinks = data.content.short_links ? JSON.parse(data.content.short_links) : this.whatsAppForm.shortLinks;
        }
      }

      if (data.channelConfig) {
        this.channelConfig = JSON.parse(data.channelConfig);

        if (!this.channelConfig.whatsAppMsgType) {
          this.channelConfig.whatsAppMsgType = 'Text';
        }
        if (!this.channelConfig.hasButtons) {
          this.channelConfig.hasButtons = false;
        }

        this.onTemplateChange(this.channelConfig.selectedTemplateInfo, true);
      }

      this.onWhatsAppContentChange();
    },

    renderCampaignContentForJourney(data) {
      if (data.content) {
        let newContent = {};
        newContent.whatsapp_message = data.content.whatsAppMessage ? data.content.whatsAppMessage : data.content.whatsapp_message;
        newContent.short_links = data.content.shortLinks ? data.content.shortLinks : data.content.short_links;
        data.content = newContent;
      }
      this.renderCampaignContent(data);
    }
  },

  mounted() {
    this.onWhatsAppContentChange();
    this.fetchWhatsAppProviderList();
  }
};
