<template>
  <el-dialog append-to-body custom-class="createSegmentMarketingPopup" :visible.sync="visible" width="680px">
    <!-- Header -->
    <div slot="title" class="dialog-header">Create Facebook Audience</div>

    <div v-if="visible">
      <el-form ref="fbAudienceCreate" label-position="top" :model="form" :rules="formRules">
        <!-- Provider Info -->
        <el-form-item label="Facebook Provider">
          <div class="-mt-3">{{ providerInfo.provider_name }}</div>
        </el-form-item>

        <!-- Name -->
        <el-form-item label="Audience Name" prop="name">
          <el-input v-model="form.name" max="300" placeholder="Give facebook audience a name"></el-input>
        </el-form-item>

        <!-- Description -->
        <el-form-item label="Audience Description" prop="description">
          <el-input type="textarea" :rows="3" v-model="form.description" max="1000" placeholder="Enter description for facebook audience"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <!-- Header -->
    <div slot="footer" class="footer">
      <el-button type="primary" @click="onCreate" :loading="savingData">Create Facebook Audience</el-button>
      <el-button type="danger" plain @click="visible = false" :loading="savingData">Cancel</el-button>
    </div>
  </el-dialog>
</template>

<style lang="scss" src="./createFacebookAudiencePopup.scss"></style>

<script>
import createFacebookAudiencePopupComponent from './createFacebookAudiencePopupComponent';
export default createFacebookAudiencePopupComponent;
</script>
