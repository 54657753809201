<template>
  <el-drawer custom-class="journeyFlowNodePopup" :visible.sync="dialogVisible" :direction="direction" :before-close="onCancel">
    <div slot="title">
      {{ title }}
      <!-- <a href="http://help.growlytics.in/en/articles/5584367-updating-customer-attributes-in-journey" target="_blank" class="float-right mr-4">
        <button type="button" class="inline-flex items-center px-2 py-1 border text-sm leading-4 rounded-sm text-white">How wait-time step works?</button>
      </a> -->
    </div>
    <!-- Wait For Time Node -->
    <div class="p-4" v-if="flowType == 'wait_time'">
      <el-radio-group class="titleRadio" v-model="waitTimeType" size="mini">
        <el-radio label="normal" border>Wait For Some Time</el-radio>
        <el-radio label="week" border>Wait For Day Of Week</el-radio>
        <el-radio label="month" border>Wait For Day Of Month</el-radio>
      </el-radio-group>

      <div v-if="waitTimeType == 'normal'">
        <div class="title">Specify Wait Time</div>
        <!-- Duration Number -->
        <el-select v-model="waitDuration" placeholder="Select" size="small" filterable style="width: 100px">
          <el-option v-for="item in 365" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
        &nbsp;
        <!-- Duration Type -->
        <el-select v-model="durationType" size="small">
          <el-option v-for="item in ['minutes', 'hours', 'days']" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </div>

      <!-- Week Day Selection -->
      <div class="customTimeSlotContainer" v-else-if="waitTimeType == 'week'">
        <div class="customTimeSlotTitle">Specify week days and time slot for each week day. Earliest weekday matched will be considered.</div>
        <el-row class="customTimeSlotRow" v-for="(slot, index) in weeklyTimeSlots" :key="index">
          <el-col :span="1"><i v-if="weeklyTimeSlots.length > 1" @click="onRemoveWeeklyTimeSlot(index)" class="el-icon-delete"></i></el-col>
          <el-col :span="8">
            <div class="title">Select Day Of Week</div>
            <el-select v-model="slot.day" style="width: 100%" size="small">
              <el-option value="any" label="Any Day"></el-option>
              <el-option value="weekdays" label="Week days (Monday To Friday)"></el-option>
              <el-option value="weekends" label="Week ends (Saturday & Sunday)"></el-option>
              <el-option value="0" label="Sunday"></el-option>
              <el-option value="1" label="Monday"></el-option>
              <el-option value="2" label="Tuesday"></el-option>
              <el-option value="3" label="Wednesday"></el-option>
              <el-option value="4" label="Thursday"></el-option>
              <el-option value="5" label="Friday"></el-option>
              <el-option value="6" label="Saturday"></el-option>
            </el-select>
          </el-col>
          <el-col :span="15" style="padding-left: 10px">
            <div class="title">Select Time Slot</div>
            <el-time-picker style="width: 100%" size="small" format="hh:mm A" is-range v-model="slot.slotDisplayValue" range-separator="To" start-placeholder="Start time" end-placeholder="End time"> </el-time-picker>
          </el-col>
        </el-row>
        <el-button type="primary" size="small" style="margin-top: 15px" plain @click="onAddNewWeeklyTimeSlot">Add Week Day</el-button>
      </div>

      <!-- Month Day Selection -->
      <div class="customTimeSlotContainer" v-else-if="waitTimeType == 'month'">
        <div class="customTimeSlotTitle">Specify dates and time slot for each date. Earliest date matched will be considered.</div>
        <el-row class="customTimeSlotRow" v-for="(slot, index) in monthlyTimeSlots" :key="index">
          <el-col :span="1"><i v-if="weeklyTimeSlots.length > 1" @click="onRemoveMonthlyTimeSlot(index)" class="el-icon-delete"></i></el-col>
          <el-col :span="8">
            <div class="title">Select Date Of Month</div>
            <el-select v-model="slot.day" style="width: 100%" size="small">
              <el-option value="any" label="Any Date"></el-option>
              <el-option v-for="index in 31" :key="index" :label="index" :value="index"></el-option>
            </el-select>
          </el-col>
          <el-col :span="15" style="padding-left: 10px">
            <div class="title">Select Time Slot</div>
            <el-time-picker style="width: 100%" size="small" format="hh:mm A" is-range v-model="slot.slotDisplayValue" range-separator="To" start-placeholder="Start time" end-placeholder="End time"> </el-time-picker>
          </el-col>
        </el-row>
        <el-button type="primary" size="small" style="margin-top: 15px" plain @click="onAddNewMonthlyTimeSlot">Add New Date</el-button>
      </div>
    </div>

    <!-- Wait For Event Node -->
    <div class="p-4" v-else-if="flowType == 'wait_event'">
      <div class="title">Select Event To Wait For</div>
      <EventPicker ref="eventPicker" v-bind:readOnly="false" v-bind:selectedOnActionEvent="selectedEvent" v-bind:channelType="selectedChannelCode"></EventPicker>

      <div class="title mt-6">Specify Wait Time</div>
      <!-- Duration Number -->
      <el-select v-model="waitDuration" placeholder="Select" size="small" filterable style="width: 100px">
        <el-option v-for="item in 365" :key="item" :label="item" :value="item"> </el-option>
      </el-select>
      &nbsp;
      <!-- Duration Type -->
      <el-select v-model="durationType" size="small"> <el-option v-for="item in ['minutes', 'hours', 'days']" :key="item" :label="item" :value="item"> </el-option> </el-select>
    </div>

    <!-- Split Node -->
    <div class="p-4" v-else-if="flowType == 'split'">
      <div class="border rounded-md overflow-hidden">
        <div class="bg-gray-50 border-b p-2 text-gray-600 font-semibold">Branch Distribution</div>

        <!-- Branch List -->
        <draggable v-model="splitBranches" group="branches" @start="dragBranches = true" @end="dragBranches = false" v-bind="dragOptions" handle=".dragContainer">
          <transition-group type="transition" :name="!dragBranches ? 'flip-list' : null">
            <div class="px-3 py-3 border-b" v-for="(branch, index) in splitBranches" :key="index">
              <div class="inline-block text-gray-500 mr-2">
                <div class="dragContainer inline-block mr-2">
                  <i class="fa fa-bars"></i>
                </div>
                #{{ index + 1 }}
              </div>
              <!-- Branch Name -->
              <div class="inline-block">
                <div class="text-xs text-gray-600 pb-0.5">Branch Name</div>
                <el-input style="width: 300px" v-model="branch.name" maxlength="20" />
              </div>
              <!-- Percentage -->
              <div class="inline-block ml-2">
                <div class="text-xs text-gray-600 pb-0.5">Percentage</div>
                <el-input style="width: 120px" class="" type="number" v-model="branch.percentage">
                  <template slot="append">%</template>
                </el-input>
              </div>
              <!-- Delete Icon -->
              <div class="inline-block ml-3" v-if="splitBranches.length > 2">
                <el-button type="danger" icon="el-icon-delete" circle plain @click="splitBranches.splice(index, 1)"></el-button>
              </div>
            </div>
          </transition-group>
        </draggable>

        <div class="bg-gray-50 p-3">
          <template v-if="splitBranches.length < 5">
            <el-button type="primary" size="small" plain @click="onAddSplitBranch">Add Branch</el-button>
          </template>
          <template v-else>
            <div class="text-sm text-gray-500">Maximum 5 branches can be created.</div>
          </template>
        </div>
      </div>
    </div>

    <!-- Custom time slots -->
    <div class="dndHours mt-8 px-4" v-if="flowType == 'wait_time' || flowType == 'wait_event'">
      <a href="http://help.growlytics.in/en/articles/5583396-using-time-slots-in-journey" target="_blank" class="absolute right-10 mr-4">
        <button class="text-secondary-800 background-transparent uppercase font-bold px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Learn More <i class="el-icon-top-right"></i></button>
      </a>
      <el-checkbox v-model="normalTimeOptions.useTimeSlots" class="font-medium text-gray-700"> Wait for time slot </el-checkbox>
      <div class="text-gray-400 text-xs pl-4 -mt-2">If enabled, with this option, customer will additinally wait for time slot. For example, you can mention the morning time slot to make sure customers go to next step in journey only during morning hours.</div>
      <template v-if="normalTimeOptions.useTimeSlots">
        <div class="flex pl-4 pt-3" v-for="(timeslot, index) in normalTimeOptions.timeSlots" :key="index">
          <div class="flex-initial">
            <div class="text-gray-400 text-xs">Slot Start Time</div>
            <el-select style="width: 100%" size="small" v-model="timeslot.startTime" filterable>
              <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"></el-option>
            </el-select>
          </div>
          <div class="flex-initial pl-4">
            <div class="text-gray-400 text-xs">Slot End Time</div>
            <el-select style="width: 100%" size="small" v-model="timeslot.endTime" filterable>
              <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"></el-option>
            </el-select>
          </div>
          <div class="flex-initial pl-4" v-if="normalTimeOptions.timeSlots.length > 1">
            <div class="text-gray-400 text-xs">&nbsp;</div>
            <el-button @click="normalTimeOptions.timeSlots.splice(index, 1)" size="small" type="danger" icon="el-icon-delete" circle plain></el-button>
          </div>
        </div>

        <!-- Add more time slots button. -->
        <button type="button" class="inline-flex items-center ml-4 mt-3 px-3 py-2 text-xs leading-3 font-medium rounded-md text-primary-700 bg-primary-50 hover:bg-primary-600 hover:text-gray-50 focus:outline-none" @click="onAddNewNormalTimeSlot">Add time slot</button>
      </template>
    </div>

    <!-- DND Hours -->
    <div class="dndHours mt-4 hidden">
      <el-checkbox v-model="considerDND" class="font-medium text-gray-700"> Wait for DND hours as well. </el-checkbox>
      <div class="text-gray-400 text-xs pl-4 -mt-2">If enabled, with this option, customer will wait for DND hours as well, if he is going to next step in journey during dnd hours.</div>
    </div>

    <div class="footerButtons bg-gray-50">
      <el-button v-if="!jurneyPublished" type="success" @click="onValidateAndSave"> Save Changes </el-button>
      <el-button v-else type="success" @click="publishChanges"> Publish Changes </el-button>
      <el-button type="danger" @click="onCancel">Cancel</el-button>
    </div>
  </el-drawer>
</template>

<script>
import EventPicker from '@/components/chartFilters/campaignEventFilter/campaignEventFilter';
const moment = require('moment');
import draggable from 'vuedraggable';

export default {
  props: {
    jurneyPublished: {
      type: Boolean,
      required: true
    }
  },
  components: { EventPicker, draggable },
  data() {
    return {
      nodeId: null,
      title: 'Wait For Time',
      dialogVisible: false,
      flowType: '',
      direction: 'btt',

      // Wait Time Variables
      waitDuration: 1,
      durationType: 'hours',
      waitTimeType: 'normal',
      considerDND: false,
      normalTimeOptions: {
        useTimeSlots: false,
        timeSlots: [
          {
            startTime: 8,
            endTime: 21.5
          }
        ]
      },
      timeSlotInputSchema: {
        day: 'any',
        slotDisplayValue: ['2021-01-20T04:30:00.000Z', '2021-01-20T13:30:00.000Z']
      },
      weeklyTimeSlots: [
        {
          day: 'any',
          slotDisplayValue: ['2021-01-20T04:30:00.000Z', '2021-01-20T13:30:00.000Z']
        }
      ],
      monthlyTimeSlots: [
        {
          day: 'any',
          slotDisplayValue: ['2021-01-20T04:30:00.000Z', '2021-01-20T13:30:00.000Z']
        }
      ],

      // Wait Event Variables
      selectedEvent: null,
      selectedChannelCode: 'journey',

      // A/B Split Variables
      splitBranches: [
        { name: 'Branch A', percentage: 50 },
        { name: 'Branch B', percentage: 50 }
      ],
      dragBranches: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    };
  },

  methods: {
    onValidateAndSave() {
      // No validation required, all fields have default values.

      // Save Changes
      let data = null;
      if (this.flowType == 'wait_time') {
        // Prepare timeslots.
        let timeslots = null;
        if (this.waitTimeType == 'week') {
          for (let i = 0; i < this.weeklyTimeSlots.length; i++) {
            this.weeklyTimeSlots[i].startRange = this.getMinutesOfDay(this.weeklyTimeSlots[i].slotDisplayValue[0]);
            this.weeklyTimeSlots[i].endRange = this.getMinutesOfDay(this.weeklyTimeSlots[i].slotDisplayValue[1]);
          }
          timeslots = this.weeklyTimeSlots;
        } else if (this.waitTimeType == 'month') {
          for (let i = 0; i < this.monthlyTimeSlots.length; i++) {
            this.monthlyTimeSlots[i].startRange = this.getMinutesOfDay(this.monthlyTimeSlots[i].slotDisplayValue[0]);
            this.monthlyTimeSlots[i].endRange = this.getMinutesOfDay(this.monthlyTimeSlots[i].slotDisplayValue[1]);
          }
          timeslots = this.monthlyTimeSlots;
        } else {
          if (this.normalTimeOptions.useTimeSlots) {
            timeslots = this.normalTimeOptions.timeSlots;
          }
        }

        data = {
          waitDuration: this.waitDuration,
          durationType: this.durationType,
          waitTimeType: this.waitTimeType,
          timeSlots: timeslots,
          dnd: this.considerDND
        };
        console.log('Final output is', data);
      } else if (this.flowType == 'wait_event') {
        // Validate changes.
        data = this.$refs.eventPicker.getFilters();
        if (data == null) {
          this.warningToast('Please specify event to wait for.');
          return;
        }
        data.waitDuration = this.waitDuration;
        data.durationType = this.durationType;
        data.dnd = this.considerDND;

        if (this.normalTimeOptions.useTimeSlots) {
          data.timeSlots = this.normalTimeOptions.timeSlots;
        }
      } else if (this.flowType == 'split') {
        // Validate Branch Name & Percentage.
        let totalPercentage = 0;
        for (let i = 0; i < this.splitBranches.length; i++) {
          let branch = this.splitBranches[i];

          // Validate Branch Name.
          if (!branch.name) {
            this.warningToast('Branch name is required for all branches.');
            return;
          }
          // Validate Percentage.
          if (isNaN(branch.percentage)) {
            this.warningToast('Invalid percentage provided for branch. It should be a number');
            return;
          }
          branch.percentage = parseInt(branch.percentage);
          if (branch.percentage < 0 || branch.percentage > 100) {
            this.warningToast('Percentage should be between 0 to 100.');
            return;
          }

          totalPercentage += parseInt(branch.percentage);
        }

        // Validate total percentage.
        console.log('Total Perc', totalPercentage);
        if (totalPercentage != 100) {
          this.warningToast('Total percentage of all branch should be 100%.');
          return;
        }

        data = this.splitBranches;
      }

      this.$emit('onChange', {
        nodeId: this.nodeId,
        data: data
      });

      this.onCancel();
    },

    onCancel() {
      Object.assign(this.$data, this.$options.data());
    },

    publishChanges() {
      this.$swal({
        title: 'Make changes to live journey?',
        text: "This journey is live, you won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update live journey.'
      }).then(async () => {
        this.onValidateAndSave();
      });
    },

    showDialog(nodeId, flowType, existingData) {
      // console.log('existing data', flowType, existingData);
      this.nodeId = nodeId;
      this.flowType = flowType;

      // Set title based on flow type
      if (flowType == 'wait_time') {
        this.title = 'Wait For Time';
        if (existingData) {
          this.waitDuration = existingData.waitDuration;
          this.durationType = existingData.durationType;

          this.waitTimeType = existingData.waitTimeType ? existingData.waitTimeType : this.waitTimeType;
          if (this.waitTimeType == 'week') {
            this.weeklyTimeSlots = existingData.timeSlots;
          } else if (this.waitTimeType == 'month') {
            this.monthlyTimeSlots = existingData.timeSlots;
          } else {
            if (existingData.timeSlots) {
              this.normalTimeOptions.useTimeSlots = true;
              this.normalTimeOptions.timeSlots = existingData.timeSlots;
            }
          }
        }
      } else if (flowType == 'wait_event') {
        this.title = 'Wait For Event';
        if (existingData) {
          this.durationType = existingData.durationType;
          this.waitDuration = existingData.waitDuration;
          this.selectedEvent = existingData;

          if (existingData.timeSlots) {
            this.normalTimeOptions.useTimeSlots = true;
            this.normalTimeOptions.timeSlots = existingData.timeSlots;
          }
        }
      } else if (flowType == 'split') {
        this.title = 'Split Users';
        if (existingData) {
          this.splitBranches = existingData;
        }
      }

      this.dialogVisible = true;
    },

    /*********** Methods for mangging timeslots *************/

    onAddNewNormalTimeSlot() {
      if (this.normalTimeOptions.timeSlots.length >= 4) {
        this.warningToast('Maximum 4 time slots can be configured.');
        return;
      }
      this.normalTimeOptions.timeSlots.push({
        startTime: 8,
        endTime: 21.5
      });
    },

    onAddNewWeeklyTimeSlot() {
      if (this.weeklyTimeSlots.length >= 7) {
        this.warningToast('Maximum 7 week days can be configured.');
        return;
      }
      this.weeklyTimeSlots.push({ ...this.timeSlotInputSchema });
    },

    onRemoveWeeklyTimeSlot(index) {
      this.weeklyTimeSlots.splice(index, 1);
    },

    onAddNewMonthlyTimeSlot() {
      if (this.monthlyTimeSlots.length >= 20) {
        this.warningToast('Maximum 20 dates can be configured.');
        return;
      }
      this.monthlyTimeSlots.push({ ...this.timeSlotInputSchema });
    },

    onRemoveMonthlyTimeSlot(index) {
      this.monthlyTimeSlots.splice(index, 1);
    },

    getMinutesOfDay(inputDate) {
      let currentTime = moment(inputDate);
      return currentTime.format('HH:mm:ss');
    },

    /*********** A/B Split *************/

    onAddSplitBranch() {
      this.splitBranches.push({ name: '', percentage: null });
    }
  }
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.journeyFlowNodePopup {
  width: 800px !important;
  height: auto !important;
  margin: auto;
  border-radius: 10px 10px 0px 0px;

  .el-drawer__header {
    height: 55px;
    background: $primary !important;
    padding: 5px 15px;
    color: white;
    margin: 0px;
  }

  input:disabled,
  textarea:disabled {
    background: white !important;
    color: #303133 !important;
  }

  .el-drawer__body {
    margin: auto;
    width: 100% !important;
    overflow: auto;
    padding: 10px;
    max-height: 90vh;
    padding: 0px;

    .titleRadio {
      margin-bottom: 15px;
      .el-radio {
        margin-right: 5px !important;
      }
    }

    .title {
      font-size: 12px !important;
      margin-bottom: 4px !important;
      color: #607d8b !important;
    }

    .customTimeSlotContainer {
      .customTimeSlotTitle {
        font-size: 12px;
        color: #607d8b;
        margin-bottom: 15px;
      }
      .customTimeSlotRow {
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 10px 10px;
        background: #fafafa;
        margin-top: 10px;

        .title {
          margin-bottom: 5px;
          font-size: 12px;
          color: #888;
        }

        .el-icon-delete {
          color: red;
          margin-top: 25px;
          cursor: pointer;
        }
      }
    }

    .dndHours {
      .el-checkbox {
        &__label {
          font-weight: 500;
        }
      }
    }

    .footerButtons {
      width: 100%;
      text-align: center;
      border-top: 1px solid #ddd;
      padding: 15px 0px 15px 0px !important;
      margin-top: 30px;

      .el-button {
        min-width: 200px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  *:focus {
    outline: 0;
  }
}
</style>
