<template>
  <div class="lineChartGraph">
    <div class="lineChart" v-if="lineChartOptions">
      <highcharts class="eventsChart" ref="lineGraph" :options="lineChartOptions"></highcharts>
      <div class="chartPagination" v-if="!hidePagination">
        <el-pagination small background @current-change="onPageChange" :current-page.sync="pageNo" :page-size="pageSize" layout="prev, pager, next" :total="datasets.length"></el-pagination>
      </div>
    </div>
    <div v-else class="noDatafound" style="text-align: center; padding-top: 15vh; opacity: 0.5">
      <!-- <img src="https://static.growlytics.in/dashboard-assets/assets/img/segments_icon.png" style="width: 80px;" /> -->
      <br />
      No data found
    </div>
  </div>
</template>

<style lang="scss" src="./dynamicTimelineGraph.scss"></style>

<script>
import DynamicTimelineGraphComponent from './dynamicTimelineGraphComponent';
export default DynamicTimelineGraphComponent;
</script>
