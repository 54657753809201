<template>
  <el-dialog append-to-body custom-class="whatsappTemplateSelectorPopup" :visible.sync="visible">
    <!-- Header -->
    <div slot="title" class="dialog-header">Select WhatsApp Template</div>

    <!-- Loader -->
    <div v-if="loading" class="loadingDiv rounded overflow-hidden" v-loading="true" style="min-height: 300px; background: transparent !important"></div>

    <div class="p-3" v-if="!loading">
      <!-- Search Box -->
      <div v-if="allowSearch" style="width: 100%; height: 50px">
        <div class="float-right">
          <el-input class="display-inline" v-model="searchTerm" placeholder="Search Template By Name" size="small" style="width: 300px" />
          &nbsp;
          <el-button type="primary" size="small" @click="fetchTemplates"> Search</el-button>
        </div>
      </div>

      <!-- Template List -->
      <div class="grid grid-cols-3 gap-4 text-sm borer-0 flex items-start">
        <div v-for="(template, index) in templateList" @click="onTemplateSelect(template)" class="border rounded-md bg-white overflow-hidden px-2 py-2.5 hover:shadow-lg cursor-pointer" :key="index">
          <!-- Header -->
          <div class="text-sm font-semibold text-gray-700">
            {{ template.name }}
            <!-- <div class="pull-right bg-primary-500 text-white text-xs py-1 px-2 rounded" type="primary" size="mini">USE THIS TEMPLATE</div> -->
          </div>

          <!-- Body -->
          <div class="text-gray-500 mt-1 mb-2.5 text-xs">
            {{ template.body }}
          </div>

          <!-- Tags -->
          <div>
            <!-- Template Type -->
            <el-tag type="primary" size="mini">{{ template.type }}</el-tag>
            <!-- Dynamic Buttons -->
            <el-tag v-if="template.buttonType" type="primary" size="mini">{{ template.buttonType }} BUTTONS</el-tag>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss" src="./whatsAppTemplateSelectorPopup.scss"></style>

<script>
import whatsAppTemplateSelectorPopupComponent from './whatsAppTemplateSelectorPopupComponent';
export default whatsAppTemplateSelectorPopupComponent;
</script>
