const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.createCampaign = (params) => {
  return axios.post(baseUrlNew + 'campaign/create', params, {
    headers: buildAuthHeaders()
  });
};

exports.publishCampaign = (campaignId) => {
  return axios.post(
    baseUrlNew + `campaign/publish/${campaignId}`,
    {},
    {
      headers: buildAuthHeaders()
    }
  );
};

exports.cloneCampaign = (campaignId) => {
  return axios.post(
    baseUrlNew + `campaign/clone/${campaignId}`,
    {},
    {
      headers: buildAuthHeaders()
    }
  );
};

exports.deleteCampaign = (campaignId) => {
  return axios.delete(baseUrlNew + `campaign/${campaignId}`, {
    headers: buildAuthHeaders()
  });
};

exports.getCampaignGeneralInfo = (campaignId) => {
  return axios.get(baseUrlNew + `campaign/${campaignId}/general-info`, {
    headers: buildAuthHeaders()
  });
};

exports.updateCampaignGeneralInfo = (campaignId, params) => {
  return axios.post(baseUrlNew + `campaign/${campaignId}/general-info`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getCampaignAudiance = (campaignId) => {
  return axios.get(baseUrlNew + `campaign/${campaignId}/audiance`, {
    headers: buildAuthHeaders()
  });
};

exports.updateCampaignAudiance = (campaignId, params) => {
  return axios.post(baseUrlNew + `campaign/${campaignId}/audiancev2`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getCampaignContent = (campaignId) => {
  return axios.get(baseUrlNew + `campaign/${campaignId}/content`, {
    headers: buildAuthHeaders()
  });
};

exports.updateCampaignContent = (campaignId, params) => {
  return axios.post(baseUrlNew + `campaign/${campaignId}/content`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getCampaignGoals = (campaignId) => {
  return axios.get(baseUrlNew + `campaign/${campaignId}/goals`, {
    headers: buildAuthHeaders()
  });
};

exports.updateCampaignGoals = (campaignId, params) => {
  return axios.post(baseUrlNew + `campaign/${campaignId}/goals`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getDetailsForReviewPage = (campaignId) => {
  return axios.get(baseUrlNew + `campaign/${campaignId}/review`, {
    headers: buildAuthHeaders()
  });
};

exports.checkForMessageAcknowledgement = (params) => {
  return axios.post(baseUrlNew + `campaign/acknowledgement`, params, {
    headers: buildAuthHeaders()
  });
};
