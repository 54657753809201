import Editor from '@tinymce/tinymce-vue';
import debounce from 'debounce';

export default {
  name: 'mceEditorComponent',
  props: {
    inputValue: {
      required: true,
      type: String,
      default: ''
    },
    height: {
      required: false,
      type: Number,
      default: 500
    },
    customFontList: {
      required: false,
      default: []
    }
  },
  components: {
    MceEditor: Editor
  },
  watch: {
    editorContent() {
      this.contentChanged();
    }
  },
  data() {
    // Prepare custom fonts.
    let fontListStr = '';
    if (this.customFontList) {
      for (let i = 0; i < this.customFontList.length; i++) {
        let font = this.customFontList[i];
        fontListStr += `${font.name}=${font.name},${font.fallback}; `;
      }
    }

    return {
      editorInstance: null,
      editorContent: this.inputValue,
      apiKey: 'nq8z6003pkpafo8bp2et1qzytzsj2tsn4v5i2gi96te1togn',
      editorOptions: {
        branding: false,
        height: this.height,

        force_br_newlines: true,
        force_p_newlines: false,
        forced_root_block: 'div',

        // skin_url: 'https://static.growlytics.in/dashboard-assets/mce-editor-skin/skins/ui/CUSTOM',
        // content_css: 'https://static.growlytics.in/dashboard-assets/mce-editor-skin/skins/ui/CUSTOM/content.min.css',
        content_style: `
          body { background: #d2f0f5 !important; }
          p {
            margin: 0px !important;
            white-space: pre-wrap;
          }
          h1, h2, h3 {
            margin-block-start: 0.33em !important;
            margin-block-end: 0.1em !important;
            margin-inline-start: 0px !important;
            margin-inline-end: 0px !important;
          }
        `,

        // menubar: 'format insert view',
        // menu: {
        //   view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
        //   insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
        //   format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat' }
        // },
        menubar: false,

        fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt 20pt 22pt 24pt 28pt 32pt 36pt 40pt 44pt 48pt',

        // Fonts
        font_formats: `
         ${fontListStr}
         Arial=arial,helvetica,sans-serif; 
         Arial Black =arial black,helvetica,sans-serif; 
         Century Gothic=century-gothic, sans-serif;
         Comic Sans MS=comic sans ms,sans-serif; 
         Courier=courier; 
         Courier New=courier new,courier; 
         Georgia=georgia,palatino, arial; 
         Geneva=Geneva,arial;
         Helvetica=helvetica, arial; 
         Helvetica Neue=helvetica neue, arial; 
         Impact=impact,chicago; 
         Lucida=lucida grande,lucida sans unicode,lucida sans,geneva,verdana,sans-serif;
         Lucida Grande=lucida grande,lucida sans unicode,lucida sans, geneva, verdana, sans-serif;
         Lucida Sans=Lucida grande",lucida sans unicode,lucida sans, geneva, verdana, sans-serif;
         MS Serif=ms serif, georgia;
         New York=new york, georgia;
         Palatino=palatino,georgia;
         Palatino Linotype=palatino linotype, palatino, georgia;
         Tahoma=tahoma,helvetica,sans-serif; 
         Terminal=terminal,monaco; 
         Times New Roman=times new roman,times; 
         Trebuchet MS=trebuchet ms,geneva; 
         Verdana=verdana,geneva;`,

        plugins: ['powerpaste advlist autolink lists link charmap', 'searchreplace visualblocks code fullscreen', 'table code', 'textcolor colorpicker', 'lineheight'],
        toolbar: 'formatselect | fontsizeselect | fontselect | forecolor backcolor | link | alignleft aligncenter alignright alignjustify | bold italic underline strikethrough superscript subscript codeformat | bullist numlist | outdent indent | lineheight | table | removeformat code',
        toolbar_mode: 'slide'
        // toolbar1: 'formatselect | fontsizeselect | fontselect | forecolor backcolor',
        // toolbar2: 'bold italic unerline | alignleft aligncenter alignright alignjustify | link'
      }
    };
  },

  methods: {
    contentChanged: debounce(function () {
      this.$emit('onBlockEditorHtmlChange', { data: this.editorContent });
    }, 300),

    setContent(data) {
      if (this.$refs.mceEditor) {
        let editor = this.$refs.mceEditor.editor;
        editor.setContent(data);
      }
    },

    setDynamicProp(text, isUnsubscribeLink) {
      let editor = this.$refs.mceEditor.editor;
      if (isUnsubscribeLink) {
        let link = `<a href="https://dc.growlytics.in/#[unsubscribe]#">Unsubscribe</a>`;
        editor.execCommand('mceInsertContent', false, link);
      } else {
        editor.execCommand('mceInsertContent', false, text);
      }
    }
  },

  mounted() {}
};
