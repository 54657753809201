const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.deleteTemplateById = function (params) {
  return axios.post(baseUrlNew + `templates/deleteTemplateById`, params, {
    headers: buildAuthHeaders()
  });
};

exports.saveTemplate = function (params) {
  return axios.post(baseUrlNew + `templates/saveTemplate`, params, {
    headers: buildAuthHeaders()
  });
};

exports.fetchDefaultTemplateList = function () {
  return axios.get(baseUrlNew + `templates/default`, {
    headers: buildAuthHeaders()
  });
};

exports.fetchMyGalleryTemplateList = function (params) {
  return axios.post(baseUrlNew + `templates/getUserSavedGalleryTemplateList`, params, {
    headers: buildAuthHeaders()
  });
};

exports.updateTemplateImage = function (params) {
  return axios.post(baseUrlNew + `templates/updateImageOfTemplate`, params, {
    headers: buildAuthHeaders()
  });
};
