<template>
  <div id="journeyInsightsComponent">
    <!-- Journey Statistics - Total -->
    <div class="box-card border border-r-none flex mb-3 bg-white rounded-lg overflow-hidden" shadow="never" v-loading="fetchingJourneyStats" style="min-height: 80px">
      <template v-if="!fetchingJourneyStats">
        <div class="border-r flex-1 flex flex-col align-center justify-center text-center py-3" v-for="(type, index) in ['entered', 'waiting', 'exited', 'conversions', 'revenue']" :key="index">
          <div class="text-2xl">
            <template v-if="type == 'revenue'">
              {{ $store.state.token.currency + getNumberWithComma(journeyStats[type].value) }}
            </template>
            <template v-else>
              {{ getNumberWithComma(journeyStats[type].value) }}
            </template>
          </div>
          <div class="text-xs text-primary-400">{{ journeyStats[type].title }}</div>
        </div>
      </template>
    </div>

    <el-row :gutter="20">
      <!-- Journey Statics - In Range -->
      <el-col :span="12">
        <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
          <!-- Header With Time Range -->
          <div class="bg-gray-50 flex border-b border-gray-200 px-3 py-2 leading-7 text-md">
            <span class="font-bold leading-8 flex-1">
              Journey Insights
              <span class="inline-flex items-center rounded-full bg-red-500 px-2 text-xs font-medium text-white">BETA</span>
            </span>
            <div class="datePicker campaignStatsTimePicker">
              <span class="datepickerInFilter">
                <el-select v-model="journeyTimerRangeStatsDateRangeFilter" size="small" @change="onJourneyTimerangeChange">
                  <el-option v-for="(value, key) in sessionTimingOptions" :key="key" :label="value" :value="key"> </el-option>
                </el-select>
                <el-date-picker v-if="journeyTimerRangeStatsDateRangeFilter == ''" v-model="journeyTimerRangeStatsDateRangeFilterValues" size="mini" type="datetimerange" :picker-options="datePickerShortcutList" format="dd-MM-yyyy hh:mm:ss A" @change="onJourneyTimerangeChange"> </el-date-picker>
              </span>
            </div>
          </div>

          <!-- Graph Loader -->
          <div v-if="fetchingJourneyTimerRangeStats" class="w-full h-48" v-loading="true"></div>

          <div class="bg-white" v-else-if="engagementStats">
            <!-- Card Summary -->
            <el-row class="w-full border-b">
              <el-col :span="8">
                <div class="flex flex-col align-center justify-center text-center py-3">
                  <div class="text-lg">{{ getNumberWithComma(journeyTimerRangeStats.entered) }}</div>
                  <div class="text-xs text-primary-600">Customers Entered Journey</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="flex flex-col align-center justify-center text-center py-3">
                  <div class="text-lg">{{ getNumberWithComma(journeyTimerRangeStats.conversions) }}</div>
                  <div class="text-xs text-primary-600">Customers Converted</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="flex flex-col align-center justify-center text-center py-3">
                  <div class="text-lg">{{ $store.state.token.currency + getNumberWithComma(journeyTimerRangeStats.revenue) }}</div>
                  <div class="text-xs text-primary-600">Revenue Generated</div>
                </div>
              </el-col>
            </el-row>

            <!-- Graph -->
            <div class="pl-1 pt-1 pr-3">
              <DynamicTimelineGraphComponent :chartHeight="230" :hidePagination="true" v-bind:graph="journeyTimerGraphData"></DynamicTimelineGraphComponent>
            </div>
          </div>
        </div>
      </el-col>

      <!-- Engagement Trends -->
      <el-col :span="12">
        <div class="engagementChannelTrendCard overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
          <div class="flex bg-gray-50 border-b border-gray-200 px-3 py-2 leading-7 text-md">
            <span class="flex-1 font-bold leading-8">
              Engagement Trends
              <span class="inline-flex items-center rounded-full bg-red-500 px-2 text-xs font-medium text-white">BETA</span>
            </span>
            <div class="datePicker campaignStatsTimePicker">
              <span class="datepickerInFilter">
                <el-select v-model="engagementStatsDateRangeFilter" size="small" @change="onEngagementTrendTimerangeChange">
                  <el-option v-for="(value, key) in sessionTimingOptions" :key="key" :label="value" :value="key"> </el-option>
                </el-select>
                <el-date-picker v-if="engagementStatsDateRangeFilter == ''" v-model="engagementStatsDateRangeFilterValues" size="mini" type="datetimerange" :picker-options="datePickerShortcutList" format="dd-MM-yyyy hh:mm:ss A" @change="onEngagementTrendTimerangeChange"> </el-date-picker>
              </span>
            </div>
          </div>

          <div class="border-b bg-white">
            <nav class="tabsList flex space-x-6 pl-2">
              <div @click="onEngagementChannelTabClick(index)" :class="{ selectedTabItem: selectedEngagementStatsTab == index }" class="tabItem text-gray-500 py-2 hover:text-primary-700 hover:border-gray-300 py-1 px-1 font-base text-xs" v-for="(channel, index) in engagementStatsChannelList" :key="index">{{ channel }}</div>
            </nav>
          </div>

          <div class="bg-white">
            <!-- Graph Loader -->
            <div v-if="fetchingEngagementStats" class="w-full h-40" v-loading="true"></div>

            <!-- Graph -->
            <template v-else-if="engagementStats">
              <!-- Card Summary -->
              <el-row class="w-full border-b">
                <el-col :span="5">
                  <div class="flex flex-col align-center justify-center text-center py-3">
                    <div class="text-lg">{{ getNumberWithComma(engagementStats.sent) }}</div>
                    <div class="text-xs text-primary-400">Sent</div>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="flex flex-col align-center justify-center text-center py-3">
                    <div class="text-lg">{{ getNumberWithComma(engagementStats.opened) }}</div>
                    <div class="text-xs text-primary-400">Opened</div>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="flex flex-col align-center justify-center text-center py-3">
                    <div class="text-lg">{{ getNumberWithComma(engagementStats.clicked) }}</div>
                    <div class="text-xs text-primary-400">Clicked</div>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="flex flex-col align-center justify-center text-center py-3">
                    <div class="text-lg">{{ getNumberWithComma(engagementStats.converted) }}</div>
                    <div class="text-xs text-primary-400">Converted</div>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div class="flex flex-col align-center justify-center text-center py-3">
                    <div class="text-lg">{{ $store.state.token.currency + getNumberWithComma(engagementStats.revenue) }}</div>
                    <div class="text-xs text-primary-400">Revenue</div>
                  </div>
                </el-col>
              </el-row>

              <div class="pl-1 pr-3">
                <DynamicTimelineGraphComponent :chartHeight="200" :hidePagination="true" v-bind:graph="engagementStatsGraphData"></DynamicTimelineGraphComponent>
              </div>
            </template>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- Campaign Wise Conversions -->
    <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4" :loading="!fetchingCampaignStats">
      <div class="flex bg-gray-50 border-b border-gray-200 px-3 py-1 leading-7 text-md">
        <span class="flex-1 font-bold leading-8">Campaign Insights</span>
        <div class="datePicker campaignStatsTimePicker">
          <span class="datepickerInFilter">
            <el-select v-model="campaignStatsDateRangeFilter" size="small" @change="onCampaignStatsTimeRangeChange">
              <el-option v-for="(value, key) in sessionTimingOptions" :key="key" :label="value" :value="key"> </el-option>
            </el-select>
            <el-date-picker v-if="campaignStatsDateRangeFilter == ''" v-model="campaignStatsDateRangeFilterValues" size="mini" type="datetimerange" :picker-options="datePickerShortcutList" format="dd-MM-yyyy hh:mm:ss A" @change="onCampaignStatsTimeRangeChange"> </el-date-picker>
          </span>
        </div>
      </div>

      <!-- Table -->
      <el-table :data="campaignStats" style="width: 100%" v-if="!fetchingCampaignStats">
        <el-table-column label="Campaign Wise Conversions" min-width="200">
          <template slot-scope="scope">
            <router-link class="text-primary-600 font-semibold" :to="'/campaign/report/' + scope.row.campaign.id" target="_blank"> {{ scope.row.campaign.name }} <i class="el-icon-top-right"></i> </router-link>
          </template>
        </el-table-column>
        <el-table-column label="Channel" width="120px">
          <template slot-scope="scope">
            {{ scope.row.campaign.channel.toUpperCase() }}
          </template>
        </el-table-column>
        <el-table-column label="Sent" width="120" sortable prop="stats.sent">
          <template slot-scope="scope">
            {{ scope.row.stats.sent }}
          </template>
        </el-table-column>
        <el-table-column label="Failed" width="120" sortable prop="stats.failed">
          <template slot-scope="scope"> {{ scope.row.stats.failed }} ({{ scope.row.stats.failed_perc ? parseInt(scope.row.stats.failed_perc) : scope.row.stats.failed_perc }}%) </template>
        </el-table-column>
        <el-table-column label="Opened" width="140" sortable prop="stats.opened">
          <template slot-scope="scope"> {{ scope.row.stats.unique_opened }} ({{ scope.row.stats.unique_opened_perc }}%) </template>
        </el-table-column>
        <el-table-column label="Clicked" width="140" sortable prop="stats.clicked">
          <template slot-scope="scope"> {{ scope.row.stats.unique_clicked }} ({{ scope.row.stats.unique_clicked_perc }}%) </template>
        </el-table-column>
        <el-table-column label="Converted" width="140" sortable prop="stats.conversions">
          <template slot-scope="scope"> {{ scope.row.stats.conversions }} ({{ scope.row.stats.conversions_perc }}%) </template>
        </el-table-column>
        <el-table-column label="Messages Sent" width="180">
          <template slot-scope="scope">
            <el-button type="primary" plain size="mini" @click="showSentMessageDialog(scope.row.campaign.id)">View Sent Messages</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- Loader -->
      <div style="height: 200px; width: 100%" v-else v-loading="true"></div>
    </div>

    <!-- Sent Messages Logs Dialog -->
    <MessageSentLogs ref="messagesSentDialog"></MessageSentLogs>
  </div>
</template>

<style lang="scss" src="./journeyInsights.scss"></style>

<script>
import journeyInsightsComponent from './journeyInsightsComponent';
export default journeyInsightsComponent;
</script>
