import campaignPreviewService from '@/services/campaignMessagePreview';

const moment = require('moment');

export default {
  name: 'messageSentLogsDialog',
  data() {
    return {
      visible: false,
      loader: true,
      logs: [],

      selectedIndex: 0,
      selectedMessage: null
    };
  },

  methods: {
    showDialog(campaignId) {
      console.log('show here');
      this.visible = true;
      this.fetchLogs(campaignId);
    },

    getFormattedTimestamp(timestamp) {
      var dt = moment(timestamp);
      return dt.format('DD-MMM-YYYY hh:mm:ss A');
    },

    onSelectMessage(index) {
      let selectedMessage = this.logs[index];
      if (selectedMessage.channel == 'email') {
        // Remove mail open tracking logic.
        selectedMessage.message.html = this.removeEmailOpenTrackingScript(selectedMessage.message.html);
      }
      this.selectedIndex = index;
      this.selectedMessage = selectedMessage;
    },

    async fetchLogs(campaignId) {
      try {
        this.loader = true;
        this.logs = [];

        let result = await campaignPreviewService.getCampaignMessageLog(campaignId);

        this.logs = result.data;
        for (let i = 0; i < this.logs.length; i++) {
          let item = this.logs[i];

          // If it's email, parse email content
          if (item.channel == 'email') {
            item.message = JSON.parse(item.message);
            item.message = {
              subject: item.message[0].replace('subject=> ', ''),
              html: item.message[1].replace('body=> ', '')
            };
          } else if (item.channel == 'webPush') {
            item.message = JSON.parse(item.message);
            item.message = JSON.parse(item.message);
          }
        }

        setTimeout(() => {
          if (this.logs.length > 0) {
            this.onSelectMessage(0);
          }
        }, 1000);
        this.loader = false;
      } catch (err) {
        console.error(err);
        this.reportError(err);
        this.errorToast('Failed to retrieve logs. Something went wrong.');
      }
    },

    removeEmailOpenTrackingScript(html) {
      let matchList = html.match(/<img src="https:\/\/i.gltcs.in\/(.)*?a=o/g);
      for (let i = 0; i < matchList.length; i++) {
        let str = matchList[i].replace('i.gltcs.in', 'ii.gltcs.in');
        html = html.replace(matchList[i], str);
      }
      return html;
    }
  }
};
