<template>
  <div id="smsEditorPage">
    <!-- Editor Section -->
    <el-row :gutter="20">
      <!-- Left Side Form -->
      <el-col class="leftSideForm" :offset="1" :span="15" v-bind:class="{ readOnly: readOnly === true }">
        <!-- Form  -->
        <el-form ref="smsInputForm" v-bind:class="{ readOnly: readOnly === true }" label-position="top" :model="smsForm" :rules="smsFormRules">
          <el-row :gutter="20">
            <!-- Select Provider -->
            <el-col :span="12">
              <el-form-item class="providerField" label="Select SMS Provider" prop="selectedProvider">
                <el-select :disabled="readOnly" v-model="smsForm.selectedProvider" placeholder="Select">
                  <el-option v-for="item in smsProviderList" :key="item.id" :label="item.provider_name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!-- Template ID -->
            <el-col :span="12">
              <el-form-item class="providerField" label="Template ID (Optional)">
                <el-input :disabled="readOnly" v-model="channelConfig.templateId" max="300"></el-input>
              </el-form-item>
              <div class="text-red-600 text-xs pt-1" v-if="tmeplateIdErrorText">{{ tmeplateIdErrorText }}</div>
            </el-col>

            <!-- Is Transactional -->
            <el-col :span="12">
              <el-form-item class="TrasltionalDiv">
                Is Transactional SMS? : &nbsp;&nbsp;&nbsp;&nbsp;<el-switch v-if="!readOnly" :disabled="readOnly" v-model="smsForm.isTrasltional" active-text="Yes" inactive-text="No"> </el-switch>
                <span v-if="readOnly">{{ smsForm.isTrasltional ? 'Yes' : 'No' }} </span>
              </el-form-item>
            </el-col>

            <!-- Sms Message -->
            <el-col :span="24">
              <el-form-item class="messageField" label="Message" prop="message">
                <el-input id="smsTextInput" :disabled="readOnly" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" v-model="smsForm.message"></el-input>

                <el-popover :disabled="readOnly" v-model="smsDynmicUserPopver" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                  <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                  <el-button type="info" class="smsEditorUserIconBtn" slot="reference">
                    <i class="el-icon-user-solid" aria-hidden="true"></i>
                  </el-button>
                </el-popover>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <!-- Short Liks -->
        <el-card class="shortLinksContainer" shadow="never">
          <div slot="header" class="clearfix shortLinksHeader">
            <div class="titleSection">
              <span>Campaign Short Links</span>
              <template v-if="!readOnly">
                <el-button size="mini" type="primary" plain class="generateLinkButton" @click="showShortLinkPopup">Create Short Link</el-button>
                <ShortLinkPopup :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" ref="shortLinkPopup" @newlink="onAddShortLink"></ShortLinkPopup>
              </template>
            </div>
          </div>
          <template v-if="smsForm.shortLinks.length > 0">
            <div class="shortLink" v-for="(link, index) in smsForm.shortLinks" :key="index">
              <div class="header">
                <div class="headerText">{{ link.code }} {{ link.onlyCode ? ' - Only Short Code' : '' }}</div>
                <el-popconfirm v-if="!readOnly" class="delete-icon" confirmButtonText="Delete" @confirm="onDeleteShortLink(index)" cancelButtonText="Cancel" icon="el-icon-info" iconColor="red" title="Delete this link?">
                  <el-button type="danger" icon="el-icon-delete" slot="reference" size="mini" plain circle></el-button>
                </el-popconfirm>
              </div>
              <div class="url">{{ link.originalUrl }}</div>
            </div>
          </template>
          <div class="no-data" v-else>No short links created yet.</div>
        </el-card>
      </el-col>

      <!-- Right Side Preview -->
      <el-col :span="8">
        <div ref="mobilePreview" class="rightSidePreview">
          <el-button class="btnSendSms" size="mini" type="primary" @click="showSendTestSmsPopup()"> Send Test SMS </el-button>

          <!-- Mobile Preview -->
          <div class="mobileDiv">
            <!-- Sms Header -->
            <div class="smsHeader">
              <i class="el-icon-arrow-left"></i>
              +1 (XXX) XXX-XXX
            </div>
            <!-- Notification Content -->
            <el-row class="smsContent">
              <el-col :span="3" class="userIcon">
                <img src="https://static.growlytics.in/dashboard-assets/assets/img/user-icon.png" />
              </el-col>
              <el-col :span="21" class="contentContiner">{{ smsPreviewText }}</el-col>
            </el-row>

            <!-- SMS Footer -->
            <div class="smsFooter">
              ~ {{ smsPreviewText.length }} Characters, {{ parseInt(smsPreviewText.length / 160) + (smsPreviewText.length % 160 > 0 ? 1 : 0) }} SMS Credits
              <div class="footerNote">Actual character count will very if you are using dynamic message attributes or short links.</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <SendTestSMSPopup ref="SendTestSMSPopup"></SendTestSMSPopup>
  </div>
</template>

<style lang="scss" src="./smsEditor.scss"></style>

<script>
import SmsEditorComponent from './smsEditorComponent';
export default SmsEditorComponent;
</script>
