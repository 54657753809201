<template>
  <div id="customChannelEditorPage">
    <el-form ref="customChannelInputForm" v-bind:class="{ readOnly: readOnly === true }" label-position="top" :model="form" :rules="formRules">
      <!-- Card - HTTP Method & URL -->
      <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
        <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md">
          HTTP Endpoint
          <el-button v-if="!readOnly" size="mini" type="primary" @click="showPushNotificationTestPopup = true" class="pull-right float-right">Send Test Message</el-button>
        </div>

        <!-- Header With Filters -->
        <div class="bg-white border-gray-200 px-3 pt-3 pb-1">
          <!-- HTTP Method -->
          <div class="text-xs text-gray-600 mb-1">HTTP Method</div>
          <div class="leading-4 block text-small mb-3" v-if="readOnly">{{ form.httpMethod }}</div>
          <el-form-item class="mt-0" label="" prop="httpMethod" v-else>
            <el-radio-group class="block" v-model="form.httpMethod" size="small">
              <el-radio label="GET" border>GET</el-radio>
              <el-radio label="POST" border>POST</el-radio>
              <el-radio label="DELETE" border>DELETE</el-radio>
            </el-radio-group>
          </el-form-item>

          <!-- URL -->
          <div class="text-xs text-gray-600 mb-2">HTTP Endpoint URL (Do not include query parameters in URL)</div>
          <div class="leading-4 block text-small mb-2" v-if="readOnly">{{ form.url }}</div>
          <el-form-item class="mt-0" label="" prop="url" v-else>
            <el-input class="w-full" type="text" v-model="form.url" placeholder="Enter URL. Example: https://sample.domiainname.com/send"></el-input>
          </el-form-item>
        </div>
      </div>

      <!-- Card - HTTP POST Body -->
      <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4" v-if="form.httpMethod != 'GET'">
        <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md">HTTP Body</div>

        <!-- Header With Filters -->
        <div class="bg-white border-gray-200">
          <div class="px-3 pt-3 border-b">
            <!-- HTTP Method -->
            <div class="text-xs text-gray-600 mb-1">HTTP Body Type</div>
            <div class="leading-4 block" v-if="readOnly">{{ form.bodyType }}</div>
            <el-form-item class="mt-0 mb-2" label="" prop="httpMethod" v-else>
              <el-radio-group class="block" v-model="form.bodyType" size="small">
                <el-radio label="json" border>JSON</el-radio>
                <el-radio label="form" border>Form</el-radio>
                <el-radio label="raw" border>Raw Data</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>

          <div class="px-3 pt-3">
            <!-- Body Key Value Pair -->
            <template v-if="form.bodyType != 'raw'">
              <div class="text-xs text-gray-600 mb-1">HTTP Body Parameters</div>
              <el-row :gutter="10" class="my-2.5" v-for="(param, index) in form.bodyParams" :key="index">
                <el-col :span="11">
                  <el-input :disabled="readOnly" placeholder="Body Param Key" v-model="param.key"></el-input>
                </el-col>
                <el-col :span="11" style="position: relative">
                  <el-input :disabled="readOnly" :id="'customChannelBodyParam' + index" v-model="param.value" placeholder="Body Param Value"></el-input>
                  <el-popover :disabled="readOnly" v-model="param.userPopover" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                    <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setKeyValueDyamicProp('customChannelBodyParam' + index, form.bodyParams, index, ...arguments)"></DynamicUserProperty>
                    <el-button size="mini" type="text" class="customChannelUserIconBtn userIconBtnForPair" slot="reference">
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-col>
                <el-col class="seperator pt-1.5 pl-2" :span="2" v-if="!readOnly">
                  <el-button type="danger" size="mini" plain icon="el-icon-delete" circle @click="form.bodyParams.splice(index, 1)"></el-button>
                </el-col>
              </el-row>
              <el-button v-if="!readOnly" class="mt-2 mb-3" type="primary" size="small" plain @click="form.bodyParams.push({ key: '', value: '', userPopover: false })">Add Body Parameter</el-button>
            </template>

            <!-- Body Raw Data -->
            <div style="position: relative" v-else>
              <div class="text-xs text-gray-600 mb-1">HTTP Raw Body</div>
              <el-input type="textarea" class="mb-3" :rows="8" id="customChannelRawBody" v-model="form.rawBody" placeholder="Raw Body Content Goes Here.."></el-input>
              <el-popover :disabled="readOnly" v-model="customChannelRawBodyUserPopver" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDyamicProp('customChannelRawBody', form, 'rawBody', ...arguments)"></DynamicUserProperty>
                <el-button size="mini" type="text" class="userIconBtnForPair customChannelUserIconBtn" slot="reference">
                  <i class="el-icon-user-solid" aria-hidden="true" style="padding-top: 20px !important"></i>
                </el-button>
              </el-popover>
            </div>
          </div>
        </div>
      </div>

      <!-- Card - Query String Params -->
      <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
        <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md">URL Query Parameters</div>

        <!-- Header With Filters -->
        <div class="bg-white border-gray-200 p-3">
          <el-row :gutter="10" class="my-2.5" v-for="(param, index) in form.queryParams" :key="index">
            <el-col :span="11">
              <el-input :disabled="readOnly" placeholder="Query Parameter Key" v-model="param.key"></el-input>
            </el-col>
            <el-col :span="11" style="position: relative">
              <el-input :disabled="readOnly" :id="'customChannelQueryParam' + index" v-model="param.value" placeholder="Query Parameter Value"></el-input>
              <el-popover :disabled="readOnly" v-model="param.userPopover" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setKeyValueDyamicProp('customChannelQueryParam' + index, form.queryParams, index, ...arguments)"></DynamicUserProperty>
                <el-button size="mini" type="text" class="customChannelUserIconBtn userIconBtnForPair" slot="reference">
                  <i class="el-icon-user-solid" aria-hidden="true"></i>
                </el-button>
              </el-popover>
            </el-col>
            <el-col class="seperator pt-1.5 pl-2" :span="2" v-if="!readOnly">
              <el-button type="danger" size="mini" plain icon="el-icon-delete" circle @click="form.queryParams.splice(index, 1)"></el-button>
            </el-col>
          </el-row>
          <el-button v-if="!readOnly" class="mt-1" type="primary" size="small" plain @click="form.queryParams.push({ key: '', value: '', userPopover: false })">Add Query Parameter</el-button>
        </div>
      </div>

      <!-- Card - HTTP Header Params -->
      <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
        <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md">HTTP Headers</div>

        <!-- Header With Filters -->
        <div class="bg-white border-gray-200 p-3">
          <el-row :gutter="10" class="my-2.5" v-for="(param, index) in form.headers" :key="index">
            <el-col :span="11">
              <el-input :disabled="readOnly" placeholder="Header Key" v-model="param.key"></el-input>
            </el-col>
            <el-col :span="11" style="position: relative">
              <el-input :disabled="readOnly" :id="'customChannelHeaderParam' + index" v-model="param.value" placeholder="Header Value"></el-input>
              <el-popover :disabled="readOnly" v-model="param.userPopover" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setKeyValueDyamicProp('customChannelHeaderParam' + index, form.headers, index, ...arguments)"></DynamicUserProperty>
                <el-button size="mini" type="text" class="customChannelUserIconBtn userIconBtnForPair" slot="reference">
                  <i class="el-icon-user-solid" aria-hidden="true"></i>
                </el-button>
              </el-popover>
            </el-col>
            <el-col class="seperator pt-1.5 pl-2" :span="2" v-if="!readOnly">
              <el-button type="danger" size="mini" plain icon="el-icon-delete" circle @click="form.headers.splice(index, 1)"></el-button>
            </el-col>
          </el-row>
          <el-button v-if="!readOnly" class="mt-1" type="primary" size="small" plain @click="form.headers.push({ key: '', value: '', userPopover: false })">Add Header Parameter</el-button>
        </div>
      </div>
    </el-form>

    <!-- send test dialog -->
    <!-- <el-dialog append-to-body="" title="Generate Short Link" custom-class="shortLinkDialog" :visible.sync="visible" width="600px"> -->
    <el-dialog append-to-body custom-class="customChannelSendTestDailog" :visible.sync="showPushNotificationTestPopup" width="800px" center title="Send Test Message">
      <el-form ref="pushNotificationTestForm" @submit.native.prevent>
        <!-- // Query Search  -->
        <el-form-item required>
          <!-- Header -->
          <div class="subHeader">Search User</div>

          <!-- // User To Search -->
          <div class="inputDetailOfUser">
            <el-autocomplete v-model="inputOfUser" class="selectAttribute" :fetch-suggestions="fetchUserListForPushContentTest" placeholder="Search User" @select="updateSelectedUserList">
              <el-select v-model="userAttributeToSearchBy" slot="prepend" placeholder="Select user property to search by">
                <el-option label="Email" value="email"></el-option>
                <el-option label="Mobile" value="mobile"></el-option>
                <el-option label="Name" value="name"></el-option>
                <el-option label="Growlytics ID" value="id"></el-option>
              </el-select>
            </el-autocomplete>
          </div>
        </el-form-item>

        <!-- // Show User List  -->
        <template v-if="selectedUserList.length > 0">
          <div class="overflow-hidden border rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Mobile</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(row, index) in selectedUserList" :key="index">
                  <td class="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ row.name ? row.name : '--' }}</td>
                  <td class="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{{ row.email ? row.email : '--' }}</td>
                  <td class="whitespace-nowrap px-3 py-3 text-sm text-gray-500">{{ row.mobile ? row.mobile : '--' }}</td>
                  <td class="relative whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <el-button @click="selectedUserList.splice(index, 1)" type="danger" circle size="small" plain icon="el-icon-delete"> </el-button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>
          </div>
        </template>

        <!-- // Test Button  -->
      </el-form>

      <template #footer>
        <div class="w-full text-right">
          <el-button type="primary" class="continueButton" :disabled="selectedUserList.length == 0" :loading="sendingTestNotification" @click="testPushNotificationContent()"> Send Test Message</el-button>
          <el-button type="danger" plain @click="toggleVisiblity">Cancel</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" src="./customChannelContentEditor.scss"></style>

<script>
import customChannelContentEditorComponent from './customChannelContentEditorComponent';
export default customChannelContentEditorComponent;
</script>
