<template>
  <el-dialog custom-class="campaignReportCustomerListPage" title="Failed Campaign Messages" :visible.sync="dialogVisible" width="90%">
    <!-- Top Pagination -->
    <div v-loading="fetchingData">
      <div class="pagination pagination-top">
        <div class="totalCount">Total {{ total }} Messages</div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[50, 100, 200]" :page-size="currentPageSize" layout="sizes, prev, pager, next, jumper" :total="total"></el-pagination>
      </div>
      <template>
        <el-table :data="campaignMsgResData" border style="width: 100%">
          <el-table-column prop="name" label="Customer Name" width="250px">
            <template slot-scope="scope">
              <router-link class="detailsLink" :to="getCustomerListLink(scope.row.cid)" target="_blank">
                <span>{{ scope.row.name ? scope.row.name : 'Name Not Available' }}</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="email" label="CustomerEmail" width="350px">
            <template slot-scope="scope">
              <span>{{ scope.row.email ? scope.row.email : '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="mobile" label="Customer Mobile" width="160px">
            <template slot-scope="scope">
              <span>{{ scope.row.mobile ? scope.row.mobile : '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="Last tried at" width="250px"> </el-table-column>
          <el-table-column prop="info" label="Failure Reason">
            <template slot-scope="scope">
              {{ scope.row.info ? scope.row.info : '--' }}
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination pagination-bottom">
        <div class="totalCount">Total {{ total }} Messages</div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[50, 100, 200]" :page-size="currentPageSize" layout="sizes, prev, pager, next, jumper" :total="total"></el-pagination>
      </div>
    </div>
  </el-dialog>
</template>
<style lang="scss" src="./campaignCustomerList.scss"></style>

<script>
import CampaignReportCustomerList from './campaignCustomerListComponent';
export default CampaignReportCustomerList;
</script>
