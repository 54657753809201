<template>
  <div class="styleBuilder">
    <el-row :gutter="20">
      <!-- Button Text -->
      <el-col :span="24">
        <label class="label"> Button Text </label>
        <el-input size="mini" v-model="inputData.text" type="text"></el-input>
      </el-col>
      <!-- Text Color -->
      <el-col :span="12">
        <label class="label"> Text Color </label>
        <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click" ref="popover-12">
          <div :style="{ background: inputData.textColor }">
            <color-picker :color="inputData.textColor" theme="light" @changeColor="setColor(inputData, 'textColor', arguments)" />
          </div>
          <div class="colorButton" slot="reference">
            <span class="colorPreview" :style="{ 'background-color': inputData.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
            <span class="colorValue">{{ inputData.textColor }}</span>
          </div>
        </el-popover>
      </el-col>
      <!-- Button Color -->
      <el-col :span="12">
        <label class="label"> Button Color </label>
        <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click" ref="popover-12">
          <div :style="{ background: inputData.buttonColor }">
            <color-picker :color="inputData.buttonColor" theme="light" @changeColor="setColor(inputData, 'buttonColor', arguments)" />
          </div>
          <div class="colorButton" slot="reference">
            <span class="colorPreview" :style="{ 'background-color': inputData.buttonColor + ' !important', border: 'none', color: 'white !important' }"></span>
            <span class="colorValue">{{ inputData.buttonColor }}</span>
          </div>
        </el-popover>
      </el-col>
      <!-- Font Size -->
      <el-col :span="24" class="sliderContainer">
        <label class="label"> Text Size </label>
        <el-slider :min="12" :max="70" v-model="inputData.fontSize"> </el-slider>
      </el-col>
      <!-- Alignment -->
      <el-col :span="12" class="alignmentButton">
        <label class="label"> Alignment </label>
        <el-radio-group size="mini" style="display: block; width: 100%" v-model="inputData.alignment">
          <el-radio-button size="mini" label="left">
            <i class="fa fa-align-left" aria-hidden="true"></i>
          </el-radio-button>
          <el-radio-button label="center">
            <i class="fa fa-align-center" aria-hidden="true"></i>
          </el-radio-button>
          <el-radio-button label="right">
            <i class="fa fa-align-right" aria-hidden="true"></i>
          </el-radio-button>
        </el-radio-group>
      </el-col>
      <!-- Border Radius -->
      <el-col :span="12" class="sliderContainer">
        <label class="label"> Button Radius </label>
        <el-slider class="slider" :min="0" :max="50" v-model="inputData.radius"> </el-slider>
      </el-col>
      <!-- Button - Text Padding -->
      <el-col :span="24" class="paddingContainer">
        <label class="label"> Text Padding</label>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.insidePadding.top" :min="0" :max="100" size="mini"></el-input-number>
            <label class="label bottom">Top</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.insidePadding.bottom" :min="0" :max="100" size="mini"></el-input-number>
            <label class="label bottom">Bottom</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.insidePadding.left" :min="0" :max="400" size="mini"></el-input-number>
            <label class="label bottom">Left</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.insidePadding.right" :min="0" :max="400" size="mini"></el-input-number>
            <label class="label bottom">Right</label>
          </el-col>
        </el-row>
      </el-col>
      <!-- Button Margin -->
      <el-col :span="24" class="optionItem paddingContainer">
        <label class="label"> Button Padding </label>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
            <label class="label bottom">Top</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
            <label class="label bottom">Bottom</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.left" :min="0" :max="300" size="mini"></el-input-number>
            <label class="label bottom">Left</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.right" :min="0" :max="300" size="mini"></el-input-number>
            <label class="label bottom">Right</label>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" src="../styleBuilder.scss"></style>

<script>
import buttonStyleBuilderComponent from './buttonStyleBuilderComponent';
export default buttonStyleBuilderComponent;
</script>
