<template>
  <div id="journeyPage">
    <!-- Loader -->
    <div class="loaldingDiv" v-if="fetchingData" v-loading="true" style="min-height: 300px"></div>

    <template v-else>
      <div class="pageHeader">
        <div class="leftSide flex">
          <div class="pt-1 pl-2 pr-3">
            <a @click="backBtn()" style="cursor: pointer">
              <i class="fa-regular text-lg leading-3 text-gray-800 fa-arrow-left-long"></i>
            </a>
          </div>

          <!-- Journey Name -->
          <div class="leading-6 pb-0.5 pt-0.5 cursor-pointer" style="max-width: 550px; border-bottom: 1px dashed #ddd" @click="onChangeJourneyName()">
            <div class="inline overflow-hidden">{{ journeyName }}</div>
            <el-tooltip class="item pl-2" effect="dark" content="Edit Journey Name" placement="bottom">
              <button type="button" class="inline-flex items-center rounded-full shadow-xs p-1">
                <i class="fa-regular fa-pen-to-square text-primary-600"></i>
              </button>
            </el-tooltip>
          </div>

          <!-- Journey Status -->
          <div class="ml-4 pt-1 text-white" style="font-size: 12px">
            <span v-if="journeyStatus == 'draft'" class="inline-flex items-center rounded-sm bg-gray-700 px-2 py-0.5">DRAFT</span>
            <span v-else-if="journeyStatus == 'running'" class="inline-flex items-center rounded-sm bg-green-600 px-2 py-0.5">RUNNING</span>
            <span v-else-if="journeyStatus == 'suspended'" class="inline-flex items-center rounded-sm bg-yellow-600 px-2 py-0.5">SUSPENDED</span>
            <span v-else-if="journeyStatus == 'ended'" class="inline-flex items-center rounded-sm bg-red-600 px-2 py-0.5">STOPPED</span>
            <!-- <el-tag type="success" effect="dark" size="small" v-if="journeyStatus == 'running'">Published</el-tag>
            <el-tag type="warning" effect="dark" size="small" v-else-if="journeyStatus == 'suspended'">SUSPENDED</el-tag>
            <el-tag type="danger" effect="dark" size="small" v-else-if="journeyStatus == 'ended'">STOPPED</el-tag>
            <el-tag type="danger" effect="dark" size="small" v-else-if="journeyStatus != 'draft'">
              {{ journeyStatus.toUpperCase() }}
            </el-tag> -->
          </div>
        </div>

        <div class="rightSide">
          <!-- Draft Journey Actions -->
          <template v-if="journeyStatus == 'draft'">
            <!-- Templates -->
            <el-tooltip class="item" effect="dark" content="Journey Templates" placement="bottom">
              <el-button type="primary" size="mini" v-if="journeyStatus == 'draft'" icon="el-icon-menu" circle plain v-loading.fullscreen.lock="showSaveLoader" @click="showTemplatesDialog(true)"></el-button>
            </el-tooltip>

            <!-- Clone Journey -->
            <el-tooltip class="item" effect="dark" content="Create Copy" placement="bottom">
              <el-button type="primary" size="mini" plain icon="el-icon-document-copy" v-loading.fullscreen.lock="showSaveLoader" @click="showCloneConfirm()" circle></el-button>
            </el-tooltip>
            <!-- Delete Journey -->
            <el-tooltip class="item" effect="dark" content="Delete Journey" placement="bottom">
              <el-button type="danger" size="mini" plain v-if="journeyId" icon="el-icon-delete" v-loading.fullscreen.lock="showSaveLoader" @click="showDeleteConfirm()" circle></el-button>
            </el-tooltip>
            <!-- Journey Settings -->
            <el-button type="primary" size="mini" @click="showSaveDialog('draft')" plain v-loading.fullscreen.lock="showSaveLoader"> <i class="fa-sharp fa-solid fa-gear pr-1"></i>Settings </el-button>

            <!-- Save Draft -->
            <el-button type="primary" size="mini" v-if="journeyStatus == 'draft'" v-loading.fullscreen.lock="showSaveLoader" @click="saveDraft('draft')">
              <i class="fa-regular fa-floppy-disk pr-1"></i>
              Save Draft
            </el-button>
            <!-- Publish Journey -->
            <el-button type="primary" size="mini" v-if="journeyStatus == 'draft'" v-loading.fullscreen.lock="showSaveLoader" @click="showSaveDialog('publish')">
              <i class="fa-regular fa-rocket-launch pr-1"></i>
              Publish Journey
            </el-button>

            <JourneyTemplates ref="journeyTemplates" @templateSelected="onJourneyTemplateSelected"></JourneyTemplates>
          </template>

          <!-- Published Journey Actions -->
          <template v-else>
            <!-- Published Journey - Schedule Reports -->
            <!-- <el-tooltip class="item" effect="dark" v-if="journeyStatus == 'running'" content="Get Daily Updates On Email" placement="bottom">
            <el-button size="mini" type="primary" v-if="journeyId" v-loading.fullscreen.lock="showSaveLoader" @click="showSaveDialog('published-settings')" plain> <i class="fa fa-envelope pr-1" aria-hidden="true"></i> Schedule Reports </el-button>
          </el-tooltip> -->
            <!-- Journey Settings -->
            <el-tooltip class="item" effect="dark" v-if="journeyStatus == 'running'" content="Journey Settings" placement="bottom">
              <el-button type="primary" size="mini" v-if="journeyId" v-loading.fullscreen.lock="showSaveLoader" @click="showSaveDialog('published-settings')"> <i class="fa fa-cog pr-1" aria-hidden="true"></i> Settings </el-button>
            </el-tooltip>
            <!-- Clone -->
            <el-tooltip class="item" effect="dark" content="Create Copy" placement="bottom">
              <el-button type="primary" size="mini" plain icon="el-icon-document-copy" v-if="journeyId" v-loading.fullscreen.lock="showSaveLoader" @click="showCloneConfirm()" circle></el-button>
            </el-tooltip>
            <!-- Suspend -->
            <el-tooltip class="item" effect="dark" v-if="journeyStatus == 'running'" content="Suspend Journey" placement="bottom">
              <el-button type="warning" size="mini" plain v-if="journeyId" v-loading.fullscreen.lock="showSaveLoader" @click="showSuspendForm" circle>
                <i class="fa fa-hand-paper-o" aria-hidden="true"></i>
              </el-button>
            </el-tooltip>
            <!-- End Journey -->
            <el-tooltip class="item" effect="dark" v-if="journeyStatus == 'running' || journeyStatus == 'suspended'" content="Stop Journey" placement="bottom">
              <el-button type="danger" size="mini" plain v-if="journeyId" v-loading.fullscreen.lock="showSaveLoader" @click="showEndJourneyForm" circle>
                <i class="fa fa-stop" aria-hidden="true"></i>
              </el-button>
            </el-tooltip>
            <!-- Delete Journey -->
            <el-tooltip class="item" effect="dark" content="Delete Journey" placement="bottom">
              <el-button type="danger" size="mini" plain v-if="journeyId" icon="el-icon-delete" v-loading.fullscreen.lock="showSaveLoader" @click="showDeleteConfirm()" circle></el-button>
            </el-tooltip>
          </template>
        </div>
      </div>

      <!-- If Draft, Just Show journey diagram -->
      <template v-if="journeyStatus == 'draft'">
        <JourneyDiagram ref="journeyDiagram" :initialData="initialJourney" @saveDraftAtNodechange="saveDraftAtNodechange" :journeyStatus="journeyStatus"></JourneyDiagram>
      </template>

      <!-- If Published -->
      <template v-else>
        <div class="tabHeaders">
          <div class="tabItem" :class="{ activeTab: selectedTab == 'overview' }" @click="onPublishedJourneyTabChange('overview')">Overview</div>
          <div class="tabItem" :class="{ activeTab: selectedTab == 'content' }" @click="onPublishedJourneyTabChange('content')">Journey Steps</div>

          <!-- Analytics Tab -->
          <div v-if="selectedTab == 'content'" class="analyticsTips">Click on <img src="https://static.growlytics.in/dashboard-assets/assets/img/journey/analytics.png" /> icon to view journey step statistics.</div>
        </div>

        <!-- Overview Tab -->
        <div class="overviewTab" v-if="selectedTab == 'overview'">
          <JourneyInsightsComponent :journeyId="journeyId"></JourneyInsightsComponent>
        </div>

        <!-- If Draft, Just Show journey diagram -->
        <div v-else class="journeyStepsTab" style="position: relative">
          <!-- Loader -->
          <template v-if="fetchingJourneyNodeStats">
            <div class="loaldingDiv" v-loading="true" style="height: 80vh"></div>
          </template>
          <template v-else>
            <!-- Date Range Selector -->
            <div style="position: absolute; top: 10px; right: 10px; z-index: 99">
              <div class="datePicker campaignStatsTimePicker">
                <span class="datepickerInFilter">
                  <el-select v-model="campaignStatsTimingFilter" size="mini" @change="fetchJourneyNodeStats">
                    <el-option v-for="(value, key) in sessionTimingOptions" :key="key" :label="value" :value="key"> </el-option>
                  </el-select>
                  <el-date-picker v-if="campaignStatsTimingFilter == ''" v-model="campaignStatsDateFilterValues" size="mini" type="datetimerange" :picker-options="datePickerShortcutList" format="dd-MM-yyyy hh:mm:ss A" @change="fetchJourneyNodeStats"> </el-date-picker>
                </span>
              </div>
            </div>

            <JourneyDiagram ref="journeyDiagram" :initialData="initialJourney" @changeStepContentOfPublishedJourney="changeStepContentOfPublishedJourney" @changeLinkContentOfPublishedJourney="changeLinkContentOfPublishedJourney" :journeyStatus="journeyStatus"></JourneyDiagram>
          </template>
        </div>
      </template>
    </template>

    <!-- Sent Messages Logs Dialog -->
    <MessageSentLogs ref="messagesSentDialog"></MessageSentLogs>

    <!-- Save Dialog -->
    <JourneySettingsPopup ref="journeySettingsDialog" @onSaveJourneySettings="onSaveJourneySettings"></JourneySettingsPopup>
  </div>
</template>

<style lang="scss" src="./createJourney.scss"></style>

<script>
import createJourneyComponent from './createJourneyComponent';
export default createJourneyComponent;
</script>
