import { journeyTemplateList } from './journeyTemplatesList';
import journeyModule from '@/services/journey';
const _ = require('lodash');

export default {
  name: 'JourneyTemplates',
  props: {},

  data() {
    return {
      visible: false,
      showLoader: false,

      // Template List Variables.
      journeyTemplateList: journeyTemplateList,
      journeyTypeFilters: {
        'Abandoned Cart': 'Abandoned Cart',
        'Browse Abandonment': 'Browse Abandonment',
        'Customer Winback': 'Customer Winback',
        'Post-Purchase': 'Post-Purchase Followup',
        'Subscriber Welcome': 'Subscriber Welcome'
      },
      selectedJourneyTypes: [],
      journeyGoalFilters: {
        'Nurture Prospects': 'Nurture Prospects',
        'Convert Sales': 'Convert Sales',
        'Build Loyalty': 'Build Loyalty',
        'In-active Customers': 'Engage In-active Customers'
      },
      selectedJourneyGoals: [],

      // Template Preview Variables
      selectedTemplate: null
    };
  },

  methods: {
    applyFilter() {
      // If no filters applied, return all.
      if (this.selectedJourneyTypes.length == 0 && this.selectedJourneyGoals.length == 0) {
        this.journeyTemplateList = journeyTemplateList;
        return;
      }

      let newList = {};
      let keys = Object.keys(journeyTemplateList);

      for (let a = 0; a < keys.length; a++) {
        let key = keys[a];
        let categoryNewItems = [];
        for (let i = 0; i < journeyTemplateList[key].length; i++) {
          let typeFound = true;
          if (this.selectedJourneyTypes.length > 0) {
            typeFound = _.find(journeyTemplateList[key][i].tags, (tagToMatch) => {
              return this.selectedJourneyTypes.indexOf(tagToMatch) >= 0;
            });
          }

          let goalFound = true;
          if (this.selectedJourneyGoals.length > 0) {
            goalFound = _.find(journeyTemplateList[key][i].tags, (tagToMatch) => {
              return this.selectedJourneyGoals.indexOf(tagToMatch) >= 0;
            });
          }

          if (typeFound && goalFound) {
            categoryNewItems.push(journeyTemplateList[key][i]);
          }
        }

        if (categoryNewItems.length > 0) {
          newList[keys[a]] = categoryNewItems;
        }
      }

      this.journeyTemplateList = newList;
    },

    showDialog() {
      this.selectedTemplate = null;
      this.visible = true;
    },

    async onTemplateSelected() {
      try {
        this.showLoader = true;

        // Read brand info from server
        let templateData = await this.fetchTemplateData();

        // // Read contents from template
        // let content = JSON.stringify(this.selectedTemplate.content);
        // // Put brand info inside json
        // if (brandInfo) {
        //   content = content.replace('{{--brand-logo--}}', brandInfo.brand_logo);
        // }

        // Send content to parent
        this.$emit('templateSelected', templateData);

        // Close popup
        this.visible = false;

        // Show Success Dialog
        this.$swal({
          title: 'Template Copied',
          text: 'Make sure you are putting your brand details on journey campaigns.',
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#67C23A',
          confirmButtonText: 'Continue',
          allowEscapeKey: false,
          allowOutsideClick: false
        });

        this.showLoader = false;
      } catch (error) {
        // Show Loader Back
        this.showLoader = false;
        //Show Error Dialog
        this.reportError(error);
        this.errorToast('Something Went Wrong.');
      }
    },

    onUseBlankTemplate() {
      this.$emit('templateSelected', null);
      this.visible = false;
    },

    async fetchTemplateData() {
      try {
        let result = await journeyModule.getJourneyTemplateData(this.selectedTemplate.id, this);
        return result.data;
      } catch (e) {
        return null;
      }
    }
  },

  mounted() {}
};
