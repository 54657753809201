<template>
  <div class="styleBuilder">
    <el-row :gutter="10">
      <!-- Font Family -->
      <el-col :span="9" class="dropdownContainer mb-2" v-if="inputData.hasOwnProperty('fontFamily')">
        <label class="label"> Font Family </label>
        <el-select v-model="inputData.fontFamily" size="mini" style="width: 100%" filterable>
          <el-option-group v-for="group in emailFonts" :key="group.label" :label="group.label">
            <el-option v-for="(font, index) in group.options" :key="index" :label="font.label" :value="font">
              <span :style="{ 'font-family': font.value }">{{ font.label }}</span>
            </el-option>
          </el-option-group>
        </el-select>
      </el-col>

      <!-- Font Size -->
      <el-col :span="5" class="mb-2" v-if="inputData.hasOwnProperty('fontSize')">
        <label class="label"> Font Size </label>
        <el-select v-model="inputData.fontSize" size="mini" style="width: 100%" filterable>
          <el-option v-for="(size, index) in fontSizeList" :key="index" :label="size + 'px'" :value="size"> </el-option>
        </el-select>
      </el-col>

      <!-- Alignment -->
      <el-col :span="5" class="mb-2" v-if="inputData.hasOwnProperty('alignment')">
        <label class="label"> Alignment </label>
        <el-select v-model="inputData.alignment" size="mini" style="width: 100%" filterable>
          <el-option v-for="(alignment, index) in ['left', 'center', 'right']" :key="index" :label="alignment" :value="alignment"> </el-option>
        </el-select>
      </el-col>

      <!-- Font Weight -->
      <el-col :span="5" class="mb-2" v-if="inputData.hasOwnProperty('fontWeight')">
        <label class="label"> Font Weight </label>
        <el-select v-model="inputData.fontWeight" size="mini" style="width: 100%" filterable>
          <el-option v-for="(size, index) in ['normal', 'bold', 'lighter']" :key="index" :label="size" :value="size"> </el-option>
        </el-select>
      </el-col>

      <!-- Text Color -->
      <el-col :span="7" class="dropdownContainer" v-if="inputData.hasOwnProperty('textColor')">
        <label class="label"> Text Color </label>
        <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
          <div :style="{ background: inputData.textColor }">
            <color-picker :color="inputData.textColor" theme="light" @changeColor="setColor(inputData, 'textColor', arguments)" />
          </div>
          <div class="colorButton" slot="reference">
            <span class="colorPreview" :style="{ 'background-color': inputData.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
            <span class="colorValue">{{ inputData.textColor }}</span>
          </div>
        </el-popover>
      </el-col>

      <!-- Line Height -->
      <el-col :span="5" v-if="inputData.hasOwnProperty('lineHeight')">
        <label class="label"> Line Height </label>
        <el-select v-model="inputData.lineHeight" size="mini" style="width: 100%" filterable>
          <el-option v-for="(lineHeight, index) in lineHeightList" :key="index" :label="lineHeight.label" :value="lineHeight.value"> </el-option>
        </el-select>
      </el-col>

      <!-- Letter Spacing -->
      <el-col :span="6" v-if="inputData.hasOwnProperty('lineHeight')">
        <label class="label"> Letter Spacing </label>
        <el-input-number style="max-width: 100px; overflow: hidden" type="number" controls-position="right" v-model="inputData.letterSpacing" :min="0" :max="5000" size="mini"></el-input-number>
      </el-col>

      <!-- Margin Bottom -->
      <el-col :span="6" v-if="inputData.hasOwnProperty('marginBottom')">
        <label class="label"> Margin Bottom </label>
        <el-input-number style="max-width: 100px; overflow: hidden" type="number" controls-position="right" v-model="inputData.marginBottom" :min="0" :max="5000" size="mini"></el-input-number>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" src="../styleBuilder.scss"></style>
<script>
import emailFontBuilderComponent from './emailFontBuilderComponent';
export default emailFontBuilderComponent;
</script>
