<template>
  <table border="0" cellpadding="0" cellspacing="0" class="blockTable" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
    <!-- Padding Top -->
    <tr v-if="content.margin.top > 0">
      <td style="padding: 0px !important; color: transparent !important" :height="content.margin.top" align="center" valign="top" :style="{ 'font-size': content.margin.top + 'px', 'line-height': content.margin.top + 'px' }">&nbsp;</td>
    </tr>

    <!-- Menu -->
    <tr>
      <!-- Padding Left -->
      <td v-if="content.margin.left > 0" :width="content.margin.left" align="center" valign="top" style="padding: 0px !important; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

      <!-- Content -->
      <td class="mailHeaderBlock" style="padding: 0px !important">
        <!-- Header Type 1 -->
        <template v-if="content.template == 'block-1'">
          <table cellpadding="0" cellspacing="0" align="center" width="100%" style="border: 0; border-collapse: collapse; border-spacing: 0">
            <tbody>
              <tr>
                <td data-bg="Hero Bg" align="center" class="mo-px-25 lozad" bgcolor="#BADDFF" data-background-image="https://editor.liramail.com/images/feshto/feshto_pre/discount-select-item@hero.png" style="background-color: #baddff; background-position: center top; background-repeat: no-repeat; background-size: cover" background="https://editor.liramail.com/images/feshto/feshto_pre/discount-select-item@hero.png" data-loaded="true">
                  <!--[if gte mso 9]><v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="width:700px;height:770px;"><v:fill type="frame" src="https://editor.liramail.com/images/feshto/feshto_pre/discount-select-item@hero.png" color="#BADDFF"></v:fill><v:textbox style="v-text-anchor:middle;" inset="0,0,0,0"><![endif]-->
                  <table cellpadding="0" cellspacing="0" align="center" class="table-640" style="border: 0; border-collapse: collapse; border-spacing: 0; margin: 0 auto; max-width: 640px; width: 640px">
                    <tbody>
                      <tr>
                        <td style="font-size: 120px; height: 120px; line-height: 120px">&nbsp;</td>
                      </tr>
                      <tr>
                        <td data-text="Hero Text 1" data-font="Primary" align="center" style="color: #ffffff; font-family: 'Poppins', DejaVu Sans, Verdana, sans-serif; font-size: 24px; font-weight: 600; letter-spacing: 2px; line-height: 34px; padding: 0; padding-bottom: 5px" contenteditable="true" data-gramm="false">CLICK TO IT!</td>
                      </tr>
                      <tr>
                        <td data-text="Hero Text 2" data-font="Primary" align="center" style="color: #ffffff; font-family: 'Poppins', DejaVu Sans, Verdana, sans-serif; font-size: 22px; font-weight: 400; letter-spacing: 2px; line-height: 32px; padding: 0; padding-bottom: 40px" contenteditable="true" data-gramm="false">SALE ENDS TUESDAY</td>
                      </tr>
                      <tr>
                        <td data-text="Hero Text 3" data-font="Primary" align="center" style="color: #ffffff; font-family: 'Poppins', DejaVu Sans, Verdana, sans-serif; font-size: 32px; font-weight: 600; letter-spacing: 5px; line-height: 42px; padding: 0; padding-bottom: 10px" contenteditable="true" data-gramm="false">UP TO OFF</td>
                      </tr>
                      <tr>
                        <td data-text="Hero Text 4" data-font="Secondary" align="center" class="mo-font-100" style="color: #ffffff; font-family: 'Lora', Palatino, Book Antiqua, Georgia, serif; font-size: 160px; font-style: italic; font-weight: 600; letter-spacing: 1px; line-height: 170px; padding: 0; padding-bottom: 20px" contenteditable="true" data-gramm="false">30%</td>
                      </tr>
                      <tr>
                        <td data-text="Hero Text 5" data-font="Primary" align="center" style="color: #ffffff; font-family: 'Poppins', DejaVu Sans, Verdana, sans-serif; font-size: 32px; font-weight: 600; letter-spacing: 5px; line-height: 42px; padding: 0; padding-bottom: 10px" contenteditable="true" data-gramm="false">EVERYTHING</td>
                      </tr>
                      <tr>
                        <td data-text="Hero Text 6" data-font="Primary" align="center" style="color: #ffffff; font-family: 'Poppins', DejaVu Sans, Verdana, sans-serif; font-size: 22px; font-weight: 400; letter-spacing: 2px; line-height: 32px; padding: 0; padding-bottom: 40px" contenteditable="true" data-gramm="false" data-lm-text="true">WITH CODE: <strong style="font-weight: 700">SALE30</strong></td>
                      </tr>
                      <tr>
                        <td align="center" style="">
                          <table cellpadding="0" cellspacing="0" align="center" class="mo-btn-full" style="border: 0; border-collapse: collapse; border-spacing: 0">
                            <tbody>
                              <tr>
                                <td data-btn="Hero Button" align="center" style="background-color: #000000; display: block; mso-padding-alt: 16px 60px 16px 60px"><a data-font="Primary" href="http://example.com/" style="color: #ffffff; display: block; font-family: 'Poppins', DejaVu Sans, Verdana, sans-serif; font-size: 16px; font-weight: 600; letter-spacing: 1px; line-height: 26px; padding: 16px 60px 16px 60px; text-decoration: none; white-space: nowrap">SHOP NOW</a></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style="font-size: 120px; height: 120px; line-height: 120px">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                  <!--[if (gte mso 9)|(IE)]></v:textbox></v:rect><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </td>

      <!-- Padding Right -->
      <td v-if="content.margin.right > 0" style="padding: 0px !important; color: transparent !important" :width="content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
    </tr>

    <!-- Padding Bottom -->
    <tr v-if="content.margin.bottom > 0">
      <td style="padding: 0px !important; color: transparent !important" :height="content.margin.bottom" align="center" valign="top" :style="{ 'font-size': content.margin.bottom + 'px', 'line-height': content.margin.bottom + 'px' }">&nbsp;</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'dragDropEmailHeaderBlock',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },

  methods: {},
  created() {}
};
</script>
