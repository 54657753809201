<template>
  <div class="mceEditor">
    <MceEditor :api-key="apiKey" ref="mceEditor" v-model="editorContent" output-format="html" :init="editorOptions" />
  </div>
</template>

<style lang="scss" src="./mceTextEditor.scss"></style>

<script>
import chartPageSelectorComponent from './mceTextEditorComponent';
export default chartPageSelectorComponent;
</script>
