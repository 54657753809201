import moment from 'moment';

export default {
  methods: {
    getCampaignDisplayTime(date) {
      var dt = moment(date);
      return dt.format('Do MMMM YYYY hh:mm A');
    }
  },
  data: function () {
    return {
      campaignChannelDisplayNames: {
        email: 'Email',
        mobilePush: 'Mobile Push',
        webPush: 'Web Push',
        sms: 'SMS',
        whatsApp: 'WhatsApp',
        inApp: 'In-App'
      },
      campaignTypeDisplayNames: {
        onAction: 'On Action',
        oneTime: 'One Time',
        journey: 'Journey',
        nonAction: 'In Action Within Time'
      },
      campaignStatusDisplayNames: {
        scheduled: 'Scheduled',
        running: 'Running',
        draft: 'Draft',
        ended: 'Ended'
      },

      emailWebFonts: [
        { label: 'Arial', value: 'Arial' },
        { label: 'Arial Black', value: 'Arial Black", Arial' },
        { label: 'Century Gothic', value: 'Century Gothic", AppleGothic, Arial' },
        { label: 'Courier', value: 'Courier' },
        { label: 'Courier New', value: 'Courier New' },
        { label: 'Georgia', value: 'Georgia' },
        { label: 'Geneva', value: 'Geneva, Arial' },
        { label: 'Helvetica', value: 'Helvetica, Arial' },
        { label: 'Helvetica Neue', value: '"Helvetica Neue", Arial' },
        { label: 'Lucida', value: '"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;' },
        { label: 'Lucida Grande', value: '"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;' },
        { label: 'Lucida Sans', value: '"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;' },
        { label: 'MS Serif', value: '"MS Serif", Georgia' },
        { label: 'New York', value: '"New York", Georgia' },
        { label: 'Palatino', value: 'Palatino, Georgia' },
        { label: 'Palatino Linotype', value: '"Palatino Linotype", Palatino, Georgia' },
        { label: 'Tahoma', value: 'Tahoma, sans-serif' },
        { label: 'Times New Roman', value: 'Times New Roman' },
        { label: 'Trebuchet MS', value: '"Trebuchet MS"' },
        { label: 'Verdana', value: 'Verdana' }
      ],
      fontSizeList: [9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40],
      lineHeightList: [
        { label: 'Normal', value: null },
        { label: '110%', value: 110 },
        { label: '130%', value: 130 },
        { label: '150%', value: 150 },
        { label: '175%', value: 175 },
        { label: '200%', value: 200 },
        { label: '250%', value: 250 },
        { label: '300%', value: 300 }
      ]
    };
  }
};
