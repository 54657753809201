<template>
  <div id="webSocialProofEditorPage">
    <el-container v-if="content.widget">
      <div class="webSocialProofEditor">
        <!-- Left Side Form -->
        <div :span="12" class="leftSideForm" v-bind:class="{ readOnly: readOnly === true }">
          <div class="validationErrorList" v-if="Object.keys(validationErrors).length > 0">
            <div class="errorHeader">Following issues needs to be resolved:</div>
            <div class="validationErrorItem" v-for="(message, path) in validationErrors" :key="path">- {{ message }}</div>
          </div>

          <!-- Form  -->
          <el-form ref="webSocialProofNotificationForm" v-bind:class="{ readOnly: readOnly === true }" label-position="top" :model="content">
            <!-- Card-  Activity Calculation -->
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span>Specify Event For Showing Numbers</span>
              </div>

              <el-row :gutter="15">
                <!-- Trigger Event -->
                <el-col :span="12">
                  <el-form-item label="Trigger Event - To Show Widget" prop="triggerEvent">
                    <el-select :disabled="true" v-model="content.event.triggerEvent" filterable @change="onEventSelect()" placeholder="Select Action Event">
                      <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
                        <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
                      </el-option-group>
                    </el-select>
                    <div class="errorMsg" v-if="validationErrors['event.triggerEvent']">{{ validationErrors['event.triggerEvent'] }}</div>
                    <div class="hint">Widget will be shown when this event is triggered. You can specify this event from basic info section.</div>
                  </el-form-item>
                </el-col>

                <!-- Trigger Event Property -->
                <el-col :span="12">
                  <el-form-item label="Trigger Event Identifier (Optional)" prop="triggerEventProperty">
                    <el-select :disabled="readOnly" v-model="content.event.triggerEventProperty" placeholder="Select Activity Event Identifier" clearable>
                      <el-option v-for="property in triggerEventPropertyList" :label="property.name" :key="property.name" :value="property.name"></el-option>
                    </el-select>
                    <div class="errorMsg" v-if="validationErrors['event.triggerEventProperty']">{{ validationErrors['event.triggerEventProperty'] }}</div>
                    <div class="hint">Select event's property to be considered. For example name of the product or category name etc.</div>
                  </el-form-item>
                </el-col>

                <!-- Activity Event -->
                <el-col :span="12">
                  <el-form-item label="Select Activity Event" prop="skipOlderThan">
                    <el-select :disabled="readOnly" v-model="content.event.name" filterable @change="onEventSelect()" placeholder="Select Action Event">
                      <el-option-group v-for="eventGroup in eventList" :key="eventGroup.label" :label="eventGroup.label">
                        <el-option v-for="eventName in eventGroup.options" :key="eventName" :label="eventName" :value="eventName"> </el-option>
                      </el-option-group>
                    </el-select>
                    <div class="errorMsg" v-if="validationErrors['event.name']">{{ validationErrors['event.name'] }}</div>
                    <div class="hint">Select event to be considered as an activity. For exmple Product Viewed, Category Viewed etc.</div>
                  </el-form-item>
                </el-col>

                <!-- Activity Event Property -->
                <el-col :span="12">
                  <el-form-item label="Select Activity Event Identifier" prop="skipOlderThan">
                    <el-select :disabled="readOnly || !content.event.triggerEventProperty" v-model="content.event.property" placeholder="Select Activity Event Identifier" clearable>
                      <el-option v-for="property in eventPropertyList" :label="property.name" :key="property.name" :value="property.name"></el-option>
                    </el-select>
                    <div class="errorMsg" v-if="validationErrors['event.property']">{{ validationErrors['event.property'] }}</div>
                    <div class="hint">Select event's property to be considered. For example name of the product or category name etc.</div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>

            <!-- Card - Theme Options -->
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span>Theme Options</span>
              </div>

              <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="Widget Header" prop="title">
                    <el-input class="with-colors" :disabled="readOnly" placeholder="Widget Header Text" :class="{ readOnly: readOnly }" maxlength="30" show-word-limit v-model="content.widget.header"></el-input>
                    <div class="errorMsg" v-if="validationErrors['widget.header']">{{ validationErrors['widget.header'] }}</div>
                  </el-form-item>
                </el-col>

                <!-- Widget Icon Url -->
                <el-col :span="12">
                  <el-form-item label="Refer to visitor as" prop="title" v-if="content.widget.type == 'all'">
                    <el-input class="with-colors" :disabled="readOnly" placeholder="Refer to visitor as" :class="{ readOnly: readOnly }" maxlength="20" show-word-limit v-model="content.widget.userTitle"></el-input>
                    <div class="errorMsg" v-if="validationErrors['widget.userTitle']">{{ validationErrors['widget.userTitle'] }}</div>
                  </el-form-item>
                </el-col>

                <!-- Activity Description -->
                <el-col :span="12">
                  <el-form-item label="Activity Description" prop="title">
                    <el-input class="with-colors" :disabled="readOnly" placeholder="Enter activity description" :class="{ readOnly: readOnly }" maxlength="30" show-word-limit v-model="content.widget.activity"></el-input>
                    <div class="errorMsg" v-if="validationErrors['widget.activity']">{{ validationErrors['widget.activity'] }}</div>
                    <div class="hint">Specifiy activity description to show in widget.</div>
                  </el-form-item>
                </el-col>

                <!-- Widget Icon Url -->
                <el-col :span="12">
                  <el-form-item label="Widget Icon Url" prop="title">
                    <el-input class="with-colors" :disabled="readOnly" placeholder="Widget Icon Url" :class="{ readOnly: readOnly }" v-model="content.widget.icon"></el-input>
                    <div class="errorMsg" v-if="validationErrors['widget.icon']">{{ validationErrors['widget.icon'] }}</div>
                    <div class="hint">Please use square image for best rendering. Less than 1 MB in size. Better if url begin with HTTPS.</div>
                  </el-form-item>
                </el-col>

                <!-- Minimum Visitors -->
                <el-col :span="12">
                  <el-form-item label="Minimum Number Of Activities To Show" prop="skipOlderThan">
                    <el-input-number :disabled="readOnly" v-model="content.minActivities" :min="1" :max="100000"></el-input-number>
                    <div class="hint">If less activities performed than the minimum you set within the chosen time frame, the Widget won’t show.</div>
                  </el-form-item>
                </el-col>

                <!-- Maximum Visitors -->
                <el-col :span="12">
                  <el-form-item label="Maximum Number Of Activities To Show" prop="skipOlderThan">
                    <el-input-number :disabled="readOnly" v-model="content.maxActivities" :min="1" :max="100000"></el-input-number>
                    <div class="hint">If more activities performed than the maximum you set within the chosen time frame, the Widget won’t show.</div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-form>
        </div>

        <!-- Right Side Preview -->
        <div class="rightSidePreview">
          <el-popover v-if="!readOnly" popper-class="customTooltip" placement="bottom" width="400" trigger="click">
            <div :style="{ background: content.themeColor }">
              <color-picker :color="content.themeColor" theme="light" @changeColor="onThemeColorChange" />
            </div>
            <div class="colorButton" slot="reference">
              <span class="label">Theme Color</span>
              <span class="colorPreview" :style="{ 'background-color': content.themeColor + ' !important', border: 'none', color: 'white !important' }"></span>
              <span class="colorValue">{{ content.themeColor }}</span>
            </div>
          </el-popover>
          <WebSocialProofWidget :proof="content.widget" :themeColor="content.themeColor"></WebSocialProofWidget>
          <div class="boxDescriptioon">
            <div class="title-div">When</div>
            <div class="description-div">When "{{ content.event.triggerEvent }}" event is triggered</div>
          </div>
          <div class="boxDescriptioon">
            <div class="title-div">Message Summary</div>
            <div class="description-div">
              <template v-if="content.widget.type == 'all'">
                Count of
                <span v-if="!content.event.property">all</span>
                "{{ content.event.name }}" events
              </template>
              <template v-else> Last user who performed "{{ content.event.name }}" </template>

              <!-- Property Matching -->
              <template v-if="content.event.property">
                having
                {{ content.event.name }}[{{ content.event.property }}] = {{ content.event.triggerEvent }}[{{ content.event.triggerEventProperty }}]
              </template>
            </div>
          </div>
        </div>
      </div>
    </el-container>

    <!-- DIALOG - TYPE SELECT  -->
    <el-dialog title="Select Social Proof Message Type" width="900px" :visible.sync="messageTypeSelectorDialogVisible" custom-class="dialog-message-type" :close-on-click-modal="content.widget != null" center :close-on-press-escape="content.widget != null" :show-close="content.widget != null">
      <el-row :gutter="20" class="typesContainer">
        <el-col :span="12" v-for="(proof, index) in builtInProofTypes" :key="index">
          <el-card class="boxContainer">
            <!-- Card Header -->
            <div slot="header" class="clearfix cardHeader">
              <span class="box-name">
                {{ proof.typeName }}
              </span>
              <el-button type="success" class="useThisButton" plain size="mini" @click="onWebSocialProofLayoutChange(proof)">Use This Layout</el-button>
            </div>
            <WebSocialProofWidget :proof="proof"></WebSocialProofWidget>
          </el-card>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<style lang="scss" src="./webSocialProofEditor.scss"></style>

<script>
import webSocialProofEditorComponent from './webSocialProofEditorComponent';
export default webSocialProofEditorComponent;
</script>
