import colorPicker from '@caohenghu/vue-colorpicker';
import MceTextEditor from '@/components/mceTextEditor/mceTextEditor';
import DynamicUserProperty from '@/components/dynamicUserFilterComponent/dynamicUserFilter';

export default {
  components: {
    colorPicker,
    DynamicUserProperty,
    MceTextEditor
  },
  props: {
    inputData: Object,
    isJourneyCampaign: {
      required: true,
      type: Boolean
    },
    editorHeight: {
      type: Number,
      default: 350
    },
    customFontList: {
      required: false,
      default: []
    }
  },
  data() {
    return {
      editorVisible: false,
      ckEditorDynmicPopover: false
    };
  },

  methods: {
    show() {
      this.editorVisible = false;
      requestAnimationFrame(() => {
        this.editorVisible = true;
      });
    },

    addDynamicPropToTextBlock(text) {
      if (text == 'unsubscribe') {
        this.$refs.mceEditor.setDynamicProp(text, true);
      } else {
        this.$refs.mceEditor.setDynamicProp(text, false);
      }
      this.ckEditorDynmicPopover = false;
    },

    onTextEditorDataChanges(data) {
      console.log('data is', data);
      this.inputData.html = data.data;
    },

    close() {}
  }
};
