exports.dummyButton = {
  text: 'Buy Now',
  textColor: '#dddddd',
  backgroundColor: '#228B22',
  iosUrl: '',
  androidUrl: '#fafafa',
  closeOnClick: true,
  borderRadius: 5
};

exports.coverMesage = {
  type: 'cover',
  content: {
    closeButton: {
      visible: true,
      color: '#44435F'
    },
    background: {
      isImage: true,
      imageUrl: 'https://static.growlytics.in/in-app-images/cover.jpeg',
      bgColor: '#dddddd'
    },
    text: {
      visible: true,
      title: 'Air Flight Gate Change',
      titleColor: '#000000',
      description: 'Gate change for BE 1342-1, Check airport departure monitors or contacts the airline directly.',
      descriptionColor: '#000000',
      positionType: 'top',
      media: {
        visible: true,
        type: 'image',
        url: 'https://static.growlytics.in/in-app-images/airplane.png'
      }
    },
    buttons: {
      visible: true,
      buttons: [
        {
          text: 'Show more',
          textColor: '#FFFFFF',
          backgroundColor: '#3CAD2A',
          iosUrl: '',
          androidUrl: '#fafafa',
          closeOnClick: true,
          borderRadius: 5
        },
        {
          text: 'Cancel',
          textColor: '#FF4444',
          backgroundColor: '#FFFFFF',
          iosUrl: '',
          androidUrl: '#fafafa',
          closeOnClick: true,
          borderRadius: 5
        }
      ]
    },
    formData: {
      name: ''
    }
  }
};

exports.modalMessage = {
  type: 'modal',
  content: {
    closeButton: {
      visible: true,
      color: '#44435F'
    },
    containerBackground: {
      opacity: 70,
      color: '#365963'
    },
    background: {
      isImage: false,
      imageUrl: 'https://static.growlytics.in/in-app-images/cover.jpeg',
      bgColor: '#FFFFFF'
    },

    text: {
      visible: true,
      title: 'Air Flight Gate Change',
      titleColor: '#000000',
      description: 'Gate change for BE 1342-1, Check airport departure monitors or contacts the airline directly.',
      descriptionColor: '#000000',
      positionType: 'center',
      media: {
        visible: true,
        type: 'image',
        url: 'https://static.growlytics.in/in-app-images/airplane.png'
      }
    },

    buttons: {
      visible: true,
      buttons: [
        {
          text: 'Show more',
          textColor: '#FFFFFF',
          backgroundColor: '#3CAD2A',
          iosUrl: '',
          androidUrl: '#fafafa',
          closeOnClick: true,
          borderRadius: 25
        }
      ]
    },
    formData: {
      name: ''
    }
  }
};

exports.bigModalMessage = {
  type: 'bigModal',
  content: {
    closeButton: {
      visible: true,
      color: '#44435F'
    },
    containerBackground: {
      opacity: 70,
      color: '#365963'
    },
    background: {
      isImage: false,
      imageUrl: 'https://static.growlytics.in/in-app-images/cover.jpeg',
      bgColor: '#FFFFFF'
    },

    text: {
      visible: true,
      title: 'Air Flight Gate Change',
      titleColor: '#000000',
      description: 'Gate change for BE 1342-1, Check airport departure monitors or contacts the airline directly.',
      descriptionColor: '#000000',
      positionType: 'center',
      media: {
        visible: true,
        type: 'image',
        url: 'https://static.growlytics.in/in-app-images/airplane.png'
      }
    },

    buttons: {
      visible: true,
      buttons: [
        {
          text: 'Show more',
          textColor: '#FFFFFF',
          backgroundColor: '#3CAD2A',
          iosUrl: '',
          androidUrl: '#fafafa',
          closeOnClick: true,
          borderRadius: 25
        },
        {
          text: 'Cancel',
          textColor: '#FF4444',
          backgroundColor: '#FFFFFF',
          iosUrl: '',
          androidUrl: '#fafafa',
          closeOnClick: true,
          borderRadius: 25
        }
      ]
    },
    formData: {
      name: ''
    }
  }
};

exports.headerMessage = {
  type: 'banner',
  content: {
    background: {
      isImage: false,
      imageUrl: 'https://static.growlytics.in/in-app-images/cover.jpeg',
      bgColor: '#FFFFFF'
    },
    text: {
      visible: true,
      title: 'Air Flight Gate Change',
      titleColor: '#000000',
      description: 'Gate change for BE 1342-1, Check airport departure monitors or contacts the airline directly.',
      descriptionColor: '#000000',
      positionType: 'top',
      media: {
        visible: true,
        type: 'image',
        url: 'https://static.growlytics.in/in-app-images/airplane.png'
      }
    },
    buttons: {
      visible: true,
      buttons: [
        {
          text: 'Show more',
          textColor: '#FFFFFF',
          backgroundColor: '#3CAD2A',
          iosUrl: '',
          androidUrl: '',
          closeOnClick: true,
          borderRadius: 5
        },
        {
          text: 'Cancel',
          textColor: '#FF4444',
          backgroundColor: '#FFFFFF',
          iosUrl: '',
          androidUrl: '',
          closeOnClick: true,
          borderRadius: 5
        }
      ]
    },
    formData: {
      name: ''
    }
  }
};

exports.footerMessage = {
  type: 'banner',
  content: {
    background: {
      isImage: false,
      imageUrl: 'https://static.growlytics.in/in-app-images/cover.jpeg',
      bgColor: '#FFFFFF'
    },
    text: {
      visible: true,
      title: 'Air Flight Gate Change',
      titleColor: '#000000',
      description: 'Gate change for BE 1342-1, Check airport departure monitors or contacts the airline directly.',
      descriptionColor: '#000000',
      positionType: 'top',
      media: {
        visible: false,
        type: 'image',
        url: 'https://static.growlytics.in/in-app-images/airplane.png'
      }
    },
    buttons: {
      visible: true,
      buttons: [
        {
          text: 'Show more',
          textColor: '#FFFFFF',
          backgroundColor: '#3CAD2A',
          iosUrl: '',
          androidUrl: '#fafafa',
          closeOnClick: true,
          borderRadius: 5
        },
        {
          text: 'Cancel',
          textColor: '#FF4444',
          backgroundColor: '#FFFFFF',
          iosUrl: '',
          androidUrl: '#fafafa',
          closeOnClick: true,
          borderRadius: 5
        }
      ]
    },
    formData: {
      name: ''
    }
  }
};
exports.inAppTypes = [
  {
    type: 'cover',
    title: 'Full-Screen',
    image: 'https://static.growlytics.in/dashboard-assets/assets/img/in_app_types/cover.png'
  },
  {
    type: 'modal',
    title: 'Normal Popup',
    image: 'https://static.growlytics.in/dashboard-assets/assets/img/in_app_types/modal.png'
  },
  {
    type: 'bigModal',
    title: 'Big Popup',
    image: 'https://static.growlytics.in/dashboard-assets/assets/img/in_app_types/big_modal.png'
  },
  {
    type: 'header',
    title: 'Header',
    image: 'https://static.growlytics.in/dashboard-assets/assets/img/in_app_types/header.png'
  },
  {
    type: 'footer',
    title: 'Footer',
    image: 'https://static.growlytics.in/dashboard-assets/assets/img/in_app_types/footer.png'
  }
];

exports.getInAppStructure = function (type) {
  let structure = null;
  if (type == 'cover') {
    structure = exports.coverMesage;
  } else if (type == 'modal') {
    structure = exports.modalMessage;
  } else if (type == 'header') {
    structure = exports.headerMessage;
  } else if (type == 'footer') {
    structure = exports.footerMessage;
  } else if (type == 'bigModal') {
    structure = exports.bigModalMessage;
  }
  return JSON.parse(JSON.stringify(structure.content));
};
