<template>
  <el-dialog id="defaultEventSettingsPage" title="Select Event For Revenue Tracking" append-to-body :lock-scroll="true" :visible.sync="popupVisible" width="700px">
    <div class="loader" v-if="fetchingData" v-loading="true"></div>

    <template v-else>
      <div slot="header" class="clearfix cardHeader">
        <span>Configure - Revenue Tracking Events</span>
        <div class="headerRightSide">
          <el-button type="success" size="mini" @click="saveRevenueTrackingEvents" :loading="savingRevenueEvents">Save Changes</el-button>
          <el-button class="el-icon-close" circle @click="popupVisible = false" size="mini"></el-button>
        </div>
      </div>

      <div class="cardContent">
        <!-- Select Event -->
        <div class="cardColumn">
          <label>Select Revenue Event</label>
          <el-select class="dropdown" v-model="revenueEventInfo.event" filterable @change="onRevenueEventSelect()" placeholder="Select Revenue Event">
            <el-option v-for="event in revenueEventList" :key="event" :label="event" :value="event"> </el-option>
          </el-select>
        </div>
        <!-- Select Revenue Property -->
        <div class="cardColumn" v-if="revenueEventInfo.event">
          <label>Select Revenue Property</label>
          <el-select class="dropdown" v-model="revenueEventInfo.property" filterable placeholder="Select Revenue Property">
            <el-option v-for="event in revenuePropertyList" :key="event.name" :label="event.name" :value="event.name" :disabled="event.type != 'number'"> </el-option>
          </el-select>
        </div>
      </div>

      <div class="footer">
        <el-button size="small" type="success" @click="saveRevenueTrackingEvents">Save Changes</el-button>
        <el-button size="small" type="info" plain @click="popupVisible = false">Cancel</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" src="./revenueTrackingEventsPopup.scss"></style>

<script>
import revenueTrackingEventsPopupComponent from './revenueTrackingEventsPopupComponent';
export default revenueTrackingEventsPopupComponent;
</script>
