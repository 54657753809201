<template>
  <el-drawer custom-class="journeyActionNodePopup" :visible.sync="dialogVisible" :direction="direction" :before-close="onCancel" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <!-- Dialog Header -->
    <div class="dialogHeader" slot="title">
      {{ title }}
      <div class="rightHeaderSide">
        <el-button type="success" size="small" v-if="!jurneyPublished" :loading="showValidateLoader" @click="onValidateAndSave()"> Save Changes </el-button>
        <el-button type="danger" size="small" v-if="!jurneyPublished" @click="onCancel">Cancel Changes</el-button>

        <template v-if="jurneyPublished && !readonly">
          <el-button type="success" size="small" :loading="showValidateLoader" @click="publishChanges"> Publish Changes </el-button>
          <el-button type="danger" size="small" @click="onCancel">Cancel Changes</el-button>
        </template>
        <template v-if="jurneyPublished && readonly">
          <el-button type="success" size="small" :loading="showValidateLoader" @click="onMakeEditable"> Edit Content </el-button>
          &nbsp;
          <el-button type="danger" size="small" icon="el-icon-close" @click="onCancel" circle></el-button>
        </template>
      </div>
    </div>

    <!-- Dialog Body -->
    <div class="dialogBody">
      <!-- Loader -->
      <div v-show="showPopupLoader" style="width: 100%; height: 100vh" v-loading="true"></div>

      <!-- Elements -->
      <div v-if="campaignId" class="campaignReportContainer">
        <CampaignReportPage :journeyCampaignId="campaignId"></CampaignReportPage>
      </div>
      <template v-else>
        <div class="utmTrackingContainer">
          <UtmTrackingInput :readOnly="readonly" :channelType="channel" ref="utmInput"></UtmTrackingInput>
        </div>
        <EmailBuilder v-if="channel == 'email'" :readOnly="readonly" :allowDynamicEvents="true" ref="HtmlBuilder" :isJourneyCampaign="true"></EmailBuilder>
        <MobilePushBuilder v-if="channel == 'mobilePush'" :readOnly="readonly" :allowDynamicEvents="true" ref="MobilePushBuilder" :isJourneyCampaign="true"></MobilePushBuilder>
        <WebPushBuilder v-if="channel == 'webPush'" :readOnly="readonly" :allowDynamicEvents="true" ref="WebPushBuilder" :isJourneyCampaign="true"></WebPushBuilder>
        <SmsBuilder v-if="channel == 'sms'" :readOnly="readonly" :allowDynamicEvents="true" ref="SmsBuilder" :isJourneyCampaign="true"></SmsBuilder>
        <WhatsAppBuilder v-if="channel == 'whatsApp'" :readOnly="readonly" :allowDynamicEvents="true" ref="WhatsAppBuilder" :isJourneyCampaign="true"></WhatsAppBuilder>
        <InAppBuilder v-if="channel == 'inApp'" :readOnly="readonly" :allowDynamicEvents="true" ref="InAppBuilder" :isJourneyCampaign="true"></InAppBuilder>
        <CustomChannelContentEditor v-if="channel == 'custom'" :readOnly="readonly" :allowDynamicEvents="true" ref="CustomChannelBuilder" :isJourneyCampaign="true"></CustomChannelContentEditor>
      </template>
    </div>
  </el-drawer>
</template>

<script>
import EmailBuilder from '@/components/campaign/contentBuilder/email/editor/emailEditor';
import InAppBuilder from '@/components/campaign/contentBuilder/inAppNotification/editor/inAppEditor';
import MobilePushBuilder from '@/components/campaign/contentBuilder/mobilePush/editor/mobilePushEditor';
import WebPushBuilder from '@/components/campaign/contentBuilder/webPush/editor/webPushEditor';
import SmsBuilder from '@/components/campaign/contentBuilder/sms/editor/smsEditor';
import WhatsAppBuilder from '@/components/campaign/contentBuilder/whatsApp/editor/whatsAppEditor';
import CustomChannelContentEditor from '@/components/campaign/contentBuilder/customChannel/editor/customChannelContentEditor';
import CampaignReportPage from '@/pages/campaignReport/campaignReport';
import UtmTrackingInput from '@/components/campaign/utmTracking/utmTrackingInput';

export default {
  props: {
    jurneyPublished: {
      type: Boolean,
      required: true
    }
  },
  components: {
    CampaignReportPage,
    EmailBuilder,
    MobilePushBuilder,
    WebPushBuilder,
    SmsBuilder,
    WhatsAppBuilder,
    InAppBuilder,
    UtmTrackingInput,
    CustomChannelContentEditor
  },
  data() {
    return {
      nodeId: null,
      campaignId: null,
      title: '',
      campaignName: '',
      channel: '',

      readonly: false,
      dialogVisible: false,

      selectedChannelCode: 'journey',
      showValidateLoader: false,
      direction: 'btt',
      showPopupLoader: true,

      // At publish, for changes, re-use this
      originalContent: null,
      changingPublishedContents: false
    };
  },
  methods: {
    async onValidateAndSave() {
      // Validate input first.
      let isUtmValid = await this.$refs.utmInput.validateForm();
      if (!isUtmValid) return;

      // Validate Input First.
      let isValid = false;
      if (this.channel === 'email') {
        isValid = await this.$refs.HtmlBuilder.validateForm();
      } else if (this.channel === 'mobilePush') {
        isValid = await this.$refs.MobilePushBuilder.validateForm();
      } else if (this.channel === 'webPush') {
        isValid = await this.$refs.WebPushBuilder.validateForm();
      } else if (this.channel === 'sms') {
        isValid = await this.$refs.SmsBuilder.validateForm();
      } else if (this.channel === 'inApp') {
        isValid = await this.$refs.InAppBuilder.validateForm();
      } else if (this.channel === 'whatsApp') {
        isValid = await this.$refs.WhatsAppBuilder.validateForm();
      } else if (this.channel === 'custom') {
        isValid = await this.$refs.CustomChannelBuilder.validateForm();
      }

      if (!isValid) {
        this.warningToast('Please provide all required input fields.');
        this.showValidateLoader = false;
        return;
      } else {
        this.readCampaignNameAndSave();
      }
    },

    async readCampaignNameAndSave() {
      this.$prompt('Enter campaign name', 'Campaign Name', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputValue: this.campaignName ? this.campaignName : '',
        inputPattern: /[.]{0,500}/,
        inputErrorMessage: 'Please provide valid campaign name'
      }).then(async ({ value }) => {
        if (!value) {
          this.readCampaignNameAndSave();
        } else {
          this.campaignName = value;
          this.showValidateLoader = true;

          // Save Changes
          let content = null;
          if (this.channel == 'email') {
            if (this.$refs.HtmlBuilder) {
              content = await this.$refs.HtmlBuilder.readProcessedContentV1();
            }
          } else if (this.channel == 'mobilePush') {
            if (this.$refs.MobilePushBuilder) {
              content = this.$refs.MobilePushBuilder.readProcessedContentV1();
            }
          } else if (this.channel == 'webPush') {
            if (this.$refs.WebPushBuilder) {
              content = this.$refs.WebPushBuilder.readProcessedContentV1();
            }
          } else if (this.channel == 'sms') {
            if (this.$refs.SmsBuilder) {
              content = this.$refs.SmsBuilder.readProcessedContentV1();
            }
          } else if (this.channel == 'inApp') {
            if (this.$refs.InAppBuilder) {
              content = this.$refs.InAppBuilder.readProcessedContentV1();
            }
          } else if (this.channel == 'whatsApp') {
            if (this.$refs.WhatsAppBuilder) {
              content = this.$refs.WhatsAppBuilder.readProcessedContentV1();
            }
          } else if (this.channel === 'custom') {
            if (this.$refs.CustomChannelBuilder) {
              content = this.$refs.CustomChannelBuilder.readProcessedContentV1();
            }
          }

          // Read Utm Tracking Details.
          let utmTracking = this.$refs.utmInput.getContents();
          // Stringify and parse again to detach output from dom, don't remove this.
          if (utmTracking) {
            utmTracking = JSON.parse(JSON.stringify(utmTracking));
          }

          this.$emit('onChange', {
            nodeId: this.nodeId,
            data: {
              content: content,
              campaignName: this.campaignName,
              utmInfo: utmTracking
            }
          });
          this.onCancel();
        }
      });
    },

    onCancel() {
      this.dialogVisible = false;
      requestAnimationFrame(() => {
        Object.assign(this.$data, this.$options.data());
      });
    },

    publishChanges() {
      this.$swal({
        title: 'Make changes to live journey?',
        text: "This journey is live, you won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update live journey.'
      }).then(async () => {
        this.onValidateAndSave();
      });
    },

    showDialog(nodeId, title, channel, originalContent, campaignId) {
      // Reuse for edit.
      this.originalContent = arguments;
      if (this.jurneyPublished) this.readonly = true;

      // Start Rendering
      this.showPopupLoader = true;
      this.nodeId = nodeId;
      this.title = 'Edit - ' + title;
      this.dialogVisible = true;

      this.channel = channel;
      this.campaignName = originalContent ? originalContent.campaignName : null;

      this.renderChildContents(nodeId, title, channel, originalContent, campaignId);
    },

    renderChildContents(nodeId, title, channel, originalContent) {
      setTimeout(() => {
        // Read campaign contents
        let campaignContent = {};
        if (originalContent && originalContent.providerId !== undefined) {
          // Process V1
          campaignContent = {
            providerId: originalContent.providerId,
            content: originalContent.content,
            email_config: originalContent.emailConfig
          };
        } else {
          if (originalContent && originalContent.content) campaignContent = JSON.parse(JSON.stringify(originalContent.content));
        }
        // Set UTM Tracking Info
        let utmInfoForRender = null;
        if (originalContent) {
          utmInfoForRender = originalContent.utmInfo ? originalContent.utmInfo : null;
        }

        requestAnimationFrame(() => {
          this.$refs.utmInput.render(utmInfoForRender);
        });

        requestAnimationFrame(() => {
          this.showPopupLoader = false;
          setTimeout(() => {
            if (this.channel === 'email') {
              this.$refs.HtmlBuilder.renderCampaignContentForJourney(campaignContent);
            } else if (this.channel === 'mobilePush') {
              this.$refs.MobilePushBuilder.renderCampaignContentForJourney(campaignContent);
            } else if (this.channel === 'sms') {
              this.$refs.SmsBuilder.renderCampaignContentForJourney(campaignContent);
            } else if (this.channel === 'webPush') {
              this.$refs.WebPushBuilder.renderCampaignContentForJourney(campaignContent);
            } else if (this.channel === 'inApp') {
              this.$refs.InAppBuilder.renderCampaignContentForJourney(campaignContent);
            } else if (this.channel === 'whatsApp') {
              this.$refs.WhatsAppBuilder.renderCampaignContentForJourney(campaignContent);
            } else if (this.channel === 'custom') {
              this.$refs.CustomChannelBuilder.renderCampaignContentForJourney(campaignContent);
            }
          }, 0);
        });
      }, 300);
    },

    onMakeEditable() {
      this.readonly = false;
      this.renderChildContents(...this.originalContent);
    }
  }
};
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';
.journeyActionNodePopup {
  width: 100%;
  height: 100vh !important;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  // height: 100vh;
  // z-index: 100;
  // overflow: auto;
  // display: flex;
  // flex-direction: column;

  input:disabled,
  textarea:disabled {
    background: white !important;
    color: #303133 !important;
  }

  .wrapperDiv {
    height: 0vh;
    background: black;
    opacity: 0.5;
  }

  .el-drawer__header {
    // background: #;
    padding: 15px 15px;
    background: $primary;
    width: 100%;

    background: #16304c;
    padding-left: 10px;
    color: white;
    line-height: 30px;
    margin-bottom: 0px !important;

    .rightHeaderSide {
      float: right;
      .closeIcon {
        margin-left: 15px;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }

  .dialogBody {
    width: 100% !important;
    overflow: auto;
    background: white;
    flex: 1;
    align-self: stretch;

    .el-form-item__label {
      margin-bottom: 0px;
      padding-right: 20px;
    }

    .utmTrackingContainer {
      border-bottom: 1px solid #ddd;
      background: #f0f3fb;
      padding: 20px 20px 0px 20px;
    }

    .title {
      font-size: 13px;
      margin-bottom: 5px;
    }
    .campaignReportContainer {
      background: #f7f8fa;
      padding: 25px 15px 0px 15px;
    }
  }

  .footerButtons {
    position: fixed;
    bottom: 0;
    z-index: 999;

    width: 100%;

    text-align: center;
    border-top: 1px solid #ddd;
    padding: 20px 0px 20px 0px !important;
    box-shadow: 0px 0 10px rgba(200, 200, 200, 0.8);

    background: white;
    .el-button {
      min-width: 300px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  *:focus {
    outline: 0;
  }
}

.dynamicUserProp {
  padding: 0px !important;
  box-shadow: none;
  border: none;
  width: auto !important;
}

.emojiPopover {
  padding: 0px !important;
  box-shadow: none;
  border: none;
  width: auto !important;
}

.fileUploadDailogBox {
  .hint {
    text-align: center;
    margin-bottom: 20px;
    color: #c1cbe0;
  }

  .fileUploader {
    text-align: center;
    padding-top: 20px;

    .el-progress.el-progress--line {
      display: none;
    }

    .el-upload-list__item-status-label {
      display: none;
    }

    .el-upload-list__item-name {
      display: none;
    }
  }

  .submitBtn {
    margin-top: 20px;
    text-align: center;
  }

  .attachmentDetail {
    margin-top: 40px;
    text-align: left;
    width: 100%;
    border: 1px solid #ddd;

    td {
      border: 1px solid #ddd;
      padding: 5px;
    }

    .fileSize {
      text-align: center;
      width: 15%;
    }

    .removeBtn {
      padding: 5px;
      width: 10%;
      text-align: center;
    }
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    height: 0vh;
  }
  50% {
    height: 100vh;
  }
}
</style>
