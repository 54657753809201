<template>
  <div class="imageUploadDialog">
    <!-- Popup UI -->
    <template v-if="!isImagePage">
      <el-dialog append-to-body="" title="Select Image" custom-class="imageUploadDialog" :visible.sync="visible" width="1400px">
        <div slot="header" class="dialog-header">Upload Image</div>
        <div class="dialogContent">
          <el-upload v-loading="uploadingImage" class="uploadDiv" :show-file-list="false" :before-upload="onFileSelected" :on-success="onFileSelected" drag action="https://jsonplaceholder.typicode.com/posts/">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              Drop file here or <em>click to upload</em>
              <div>jpg/png files with a size less than 10MB</div>
            </div>
          </el-upload>

          <!-- Loader For FEtching image list -->
          <div class="loader" v-if="fetchingImageList" v-loading="true"></div>

          <!-- Image List -->
          <div class="rounded-md border overflow-hidden" v-if="!fetchingImageList">
            <!-- Tab Section -->
            <div class="bg-white rounded-xl">
              <div class="tabContainer border-b">
                <nav class="flex" aria-label="Tabs">
                  <div v-for="(tabName, index) in ['all', 'bookmarked']" :key="index" class="tabItem border-transparent text-gray-700 hover:text-primary-700 text-center py-2.5 px-5 font-semibold" :class="{ selectedTab: tabName == selectedTab }" @click="onTabChange(tabName)">
                    <div class="mb-1 flex align-center justify-center">
                      <div class="text-sm leading-4" style="margin-right: 6px">{{ toPascalCase(tabName) }}</div>
                      <span class="countTag bg-gray-700 text-white w-auto px-2 rounded-full">
                        {{ tabName == 'bookmarked' ? bookmarkedImageCount : totalImageCount }}
                      </span>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div>
              <template v-if="imageList.length > 0">
                <!-- Image List -->
                <el-row class="imageListDiv">
                  <el-col :span="8" v-for="(img, index) in imageList" :key="index">
                    <div class="imageBox flex relative">
                      <div class="absolute z-50 top-1 right-2 cursor-pointer" @click="onToggleImageBookmark(index)">
                        <el-tooltip effect="dark" content="Bookmark Image" placement="bottom-center">
                          <template v-if="img.is_bookmarked"><i class="fa-sharp fa-solid fa-star"></i></template>
                          <template v-else><i class="fa-regular fa-star"></i></template>
                        </el-tooltip>
                      </div>
                      <el-image style="width: 100px; height: 100px" :src="img.url" fit="scale-down"></el-image>
                      <div class="imageMetadata flex-1" :span="16">
                        <div class="imageName font-semibold text-gray-600">
                          {{ img.name }}
                        </div>

                        <div class="imageButtons">
                          <el-button class="mr-2" size="mini" type="primary" @click="onImageSelected(img)">Select</el-button>
                          <el-popconfirm confirmButtonText="Delete" @confirm="deleteImage(img, index)" confirmButtonType="danger" :hideIcon="true" cancelButtonText="No" title="Delete this image?">
                            <el-button slot="reference" type="danger" plain size="mini">Delete</el-button>
                          </el-popconfirm>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>

                <!-- Bottom Pagination -->
                <div class="border-t">
                  <div class="pagination pagination-no-borders" v-if="!fetchingImageList">
                    <!-- <div class="totalCount">{{ total }} Images</div> -->
                    <el-pagination @current-change="onPageChange" :current-page.sync="pageNo" :page-size="pageSize" layout="prev, pager, next" :total="selectedTab == 'bookmarked' ? bookmarkedImageCount : totalImageCount"></el-pagination>
                  </div>
                </div>
              </template>

              <div class="noDataDiv bg-white py-40" v-else>Images not found. Upload or drag images above to get started.</div>
            </div>
          </div>
        </div>
      </el-dialog>
    </template>

    <!-- Image Page UI -->
    <div class="imageAssetsPage" v-else>
      <el-upload v-loading="uploadingImage" class="uploadDiv" :show-file-list="false" :before-upload="onFileSelected" :on-success="onFileSelected" drag action="https://jsonplaceholder.typicode.com/posts/">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          Drop file here or <em>click to upload</em>
          <div>jpg/png files with a size less than 10MB</div>
        </div>
      </el-upload>

      <!-- Loader For Fetching image list -->
      <div class="loader" v-if="fetchingImageList" v-loading="true"></div>

      <!-- Image List -->
      <div class="rounded-md border overflow-hidden" v-if="!fetchingImageList">
        <!-- Tab Section -->
        <div class="bg-white rounded-xl">
          <div class="tabContainer border-b">
            <nav class="flex" aria-label="Tabs">
              <div v-for="(tabName, index) in ['all', 'bookmarked']" :key="index" class="tabItem border-transparent text-gray-700 hover:text-primary-700 text-center py-2.5 px-5 font-semibold" :class="{ selectedTab: tabName == selectedTab }" @click="onTabChange(tabName)">
                <div class="mb-1 flex align-center justify-center">
                  <div class="text-sm leading-4" style="margin-right: 6px">{{ toPascalCase(tabName) }}</div>
                  <span class="countTag bg-gray-700 text-white w-auto px-2 rounded-full">
                    {{ tabName == 'bookmarked' ? bookmarkedImageCount : totalImageCount }}
                  </span>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div>
          <template v-if="imageList.length > 0">
            <!-- Image List -->
            <el-row class="imageListDiv">
              <el-col :span="8" v-for="(img, index) in imageList" :key="index">
                <div class="imageBox flex relative">
                  <div class="absolute z-50 top-1 right-2 cursor-pointer" @click="onToggleImageBookmark(index)">
                    <el-tooltip effect="dark" content="Bookmark Image" placement="bottom-center">
                      <template v-if="img.is_bookmarked"><i class="fa-sharp fa-solid fa-star"></i></template>
                      <template v-else><i class="fa-regular fa-star"></i></template>
                    </el-tooltip>
                  </div>
                  <el-image style="width: 100px; height: 100px" :src="img.url" fit="scale-down"></el-image>
                  <div class="imageMetadata flex-1" :span="16">
                    <div class="imageName font-semibold text-gray-600">
                      {{ img.name }}
                    </div>

                    <div class="imageButtons">
                      <el-button class="mr-2" size="mini" plain type="primary" @click="onCopyImageUrl(img)">Copy Link</el-button>
                      <el-popconfirm confirmButtonText="Delete" @confirm="deleteImage(img, index)" confirmButtonType="danger" :hideIcon="true" cancelButtonText="No" title="Delete this image?">
                        <el-button slot="reference" type="danger" plain size="mini">Delete</el-button>
                      </el-popconfirm>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>

            <!-- Bottom Pagination -->
            <div class="border-t">
              <div class="pagination pagination-no-borders" v-if="!fetchingImageList">
                <!-- <div class="totalCount">{{ total }} Images</div> -->
                <el-pagination @current-change="onPageChange" :current-page.sync="pageNo" :page-size="pageSize" layout="prev, pager, next" :total="selectedTab == 'bookmarked' ? bookmarkedImageCount : totalImageCount"></el-pagination>
              </div>
            </div>
          </template>

          <div class="noDataDiv bg-white py-40" v-else>Images not found. Upload or drag images above to get started.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./imageUpload.scss"></style>

<script>
import imageUploadComponent from './imageUploadComponent';
export default imageUploadComponent;
</script>
