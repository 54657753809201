<template>
  <div class="web-social-proof-box" v-if="proof">
    <!-- Widget Close Button -->
    <img class="grw-box-close-btn" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABDElEQVRoge3ZXQqCQBSG4a8WUdIWbbmBtRq70A8kxBw9fzOc96oukvOg6QwCWZZlLdUD6LyHwDRDf/THTwAjgAG+mG6eYZxnKu4G4DUf4APgITba/u6LGd5nZvDEiCGYB0YcwSwxaghmgVFHME2MGYJpYMwRTBLjhmASGHcEO4MJg2BHMOEQrAQTFsH2YMIj2BamGgRbw1SHYMs9xPDzOcJGrajlmVE/E1etAwO4/PleRU1cWmt/7Ajb5qK27k7VYPbcYsNjSp4TYTFHHnbhMGee2GEwEssOd4zk2skNo7EANMdormLNMBZLcXWM5X5CDeOxKRLHeO7sxDARtqcimGZevQGNvAzNsiyL1xcbE8X3wv0coQAAAABJRU5ErkJggg==" />

    <!-- Layout -->
    <div class="box-layout">
      <div class="box-icon-outer" :style="{ 'background-color': themeColor + '20' }">
        <div v-if="!proof.icon" class="box-icon-inner toast-icon-inner-url toast-icon-inner-url-image">
          <!-- Single User Default Image  -->
          <svg viewBox="-100 -20 640 640" :style="{ fill: themeColor }" xmlns="http://www.w3.org/2000/svg" v-if="proof.type == 'single' && !proof.icon">
            <path d="m210.351562 246.632812c33.882813 0 63.222657-12.152343 87.195313-36.128906 23.972656-23.972656 36.125-53.304687 36.125-87.191406 0-33.875-12.152344-63.210938-36.128906-87.191406-23.976563-23.96875-53.3125-36.121094-87.191407-36.121094-33.886718 0-63.21875 12.152344-87.191406 36.125s-36.128906 53.308594-36.128906 87.1875c0 33.886719 12.15625 63.222656 36.132812 87.195312 23.976563 23.96875 53.3125 36.125 87.1875 36.125zm0 0" />
            <path
              d="m426.128906 393.703125c-.691406-9.976563-2.089844-20.859375-4.148437-32.351563-2.078125-11.578124-4.753907-22.523437-7.957031-32.527343-3.308594-10.339844-7.808594-20.550781-13.371094-30.335938-5.773438-10.15625-12.554688-19-20.164063-26.277343-7.957031-7.613282-17.699219-13.734376-28.964843-18.199219-11.226563-4.441407-23.667969-6.691407-36.976563-6.691407-5.226563 0-10.28125 2.144532-20.042969 8.5-6.007812 3.917969-13.035156 8.449219-20.878906 13.460938-6.707031 4.273438-15.792969 8.277344-27.015625 11.902344-10.949219 3.542968-22.066406 5.339844-33.039063 5.339844-10.972656 0-22.085937-1.796876-33.046874-5.339844-11.210938-3.621094-20.296876-7.625-26.996094-11.898438-7.769532-4.964844-14.800782-9.496094-20.898438-13.46875-9.75-6.355468-14.808594-8.5-20.035156-8.5-13.3125 0-25.75 2.253906-36.972656 6.699219-11.257813 4.457031-21.003906 10.578125-28.96875 18.199219-7.605469 7.28125-14.390625 16.121094-20.15625 26.273437-5.558594 9.785157-10.058594 19.992188-13.371094 30.339844-3.199219 10.003906-5.875 20.945313-7.953125 32.523437-2.058594 11.476563-3.457031 22.363282-4.148437 32.363282-.679688 9.796875-1.023438 19.964844-1.023438 30.234375 0 26.726562 8.496094 48.363281 25.25 64.320312 16.546875 15.746094 38.441406 23.734375 65.066406 23.734375h246.53125c26.625 0 48.511719-7.984375 65.0625-23.734375 16.757813-15.945312 25.253906-37.585937 25.253906-64.324219-.003906-10.316406-.351562-20.492187-1.035156-30.242187zm0 0"
            />
          </svg>

          <!-- All Users Default Image -->
          <svg viewBox="-8 0 115 115" xmlns="http://www.w3.org/2000/svg" :style="{ fill: themeColor }" v-if="proof.type == 'all' && !proof.icon">
            <path
              d="M49.541,38.655c1.617,0,3.158,0.338,4.559,0.946c0.105-2.286,0.893-4.4,2.157-6.15c-0.89-0.186-1.808-0.285-2.748-0.285
                           h-8.906c-0.938,0-1.856,0.098-2.739,0.282c1.347,1.869,2.152,4.15,2.165,6.62C45.666,39.168,47.544,38.655,49.541,38.655z"
            />
            <circle cx="49.054" cy="21.954" r="10.496" />
            <path
              d="M65.539,50.36c5.342,0,9.67-4.33,9.67-9.67c0-5.342-4.328-9.67-9.67-9.67c-5.292,0-9.583,4.251-9.663,9.524
                           c3.049,1.912,5.187,5.146,5.577,8.9C62.695,50.026,64.076,50.36,65.539,50.36z"
            />
            <path
              d="M32.571,31.019c-5.343,0-9.671,4.329-9.671,9.67s4.328,9.669,9.671,9.669c1.892,0,3.651-0.553,5.143-1.492
                           c0.475-3.091,2.132-5.794,4.499-7.634c0.01-0.181,0.027-0.36,0.027-0.543C42.24,35.348,37.91,31.019,32.571,31.019z"
            />
            <path
              d="M71.82,30.813c3.049,1.912,5.187,5.146,5.576,8.901c1.241,0.581,2.623,0.916,4.086,0.916c5.342,0,9.67-4.329,9.67-9.67
                           c0-5.341-4.328-9.67-9.67-9.67C76.191,21.289,71.9,25.541,71.82,30.813z"
            />
            <circle cx="49.541" cy="50.673" r="9.671" />
            <path
              d="M69.643,51.019h-8.144c-0.089,3.258-1.479,6.192-3.679,8.301c6.068,1.806,10.509,7.434,10.509,14.082v3.092
                           c8.04-0.297,12.674-2.573,12.979-2.729l0.646-0.328h0.067V63.401C82.023,56.573,76.469,51.019,69.643,51.019z"
            />
            <path
              d="M85.585,41.289h-8.142c-0.088,3.258-1.479,6.192-3.678,8.301c6.068,1.806,10.508,7.433,10.508,14.081v3.092
                           c8.039-0.296,12.674-2.572,12.979-2.729l0.646-0.327h0.069V53.671C97.967,46.844,92.413,41.289,85.585,41.289z"
            />
            <path
              d="M41.256,59.319c-2.189-2.099-3.575-5.017-3.677-8.254c-0.301-0.022-0.6-0.047-0.908-0.047h-8.203
                           c-6.828,0-12.383,5.555-12.383,12.383v10.037l0.025,0.155l0.691,0.218c5.227,1.633,9.893,2.383,13.944,2.621v-3.031
                           C30.747,66.754,35.186,61.126,41.256,59.319z"
            />
            <path
              d="M53.643,61.003h-8.206c-6.828,0-12.383,5.557-12.383,12.382v10.037l0.026,0.157l0.69,0.216
                           c6.516,2.035,12.177,2.715,16.835,2.715c9.101,0,14.375-2.595,14.701-2.76l0.646-0.328h0.068V73.385
                           C66.023,66.558,60.469,61.003,53.643,61.003z"
            />
            <path
              d="M16.486,40.938c1.463,0,2.844-0.335,4.086-0.916c0.39-3.755,2.527-6.99,5.576-8.902c-0.08-5.271-4.371-9.523-9.662-9.523
                           c-5.343,0-9.671,4.329-9.671,9.671C6.815,36.609,11.143,40.938,16.486,40.938z"
            />
            <path
              d="M24.202,49.899c-2.198-2.109-3.589-5.044-3.677-8.303h-8.143C5.554,41.597,0,47.152,0,53.979v10.037h0.069l0.646,0.327
                           c0.306,0.154,4.939,2.433,12.979,2.728v-3.092C13.694,57.332,18.133,51.704,24.202,49.899z"
            />
            <path
              d="M27.796,30.063c1.16-0.47,2.93-1.047,4.62-1.047c1.967,0,3.891,0.506,5.607,1.469c0.382-0.375,0.732-0.783,1.05-1.22
                           c-1.63-2.141-2.52-4.765-2.52-7.464c0-1.818,0.406-3.622,1.18-5.261c-1.762-1.592-4.01-2.461-6.399-2.461
                           c-4.348,0-8.133,2.943-9.241,7.088C25.341,23.057,27.457,26.361,27.796,30.063z"
            />
            <path
              d="M59.117,28.718c0.336,0.534,0.729,1.037,1.175,1.505c1.588-0.792,3.334-1.208,5.092-1.208
                           c1.729,0,3.386,0.442,4.472,0.812c0.34-4.013,2.767-7.555,6.4-9.35c-1.332-3.805-4.938-6.402-9.021-6.402
                           c-2.64,0-5.14,1.084-6.945,2.992c0.634,1.512,0.955,3.101,0.955,4.73C61.244,24.292,60.51,26.67,59.117,28.718z"
            />
          </svg>
        </div>

        <div v-else :style="{ 'background-image': 'url(' + proof.icon + ') !important' }" class="box-icon-inner"></div>
      </div>
      <div class="box-content">
        <div class="box-message">
          <!-- Header -->
          <div class="web-social-proof-box-header">
            <div class="proof-type">
              <span>{{ proof.header }}</span>
              <!-- <div class="box-time-ago">25 min. ago</div> -->
            </div>
          </div>

          <template v-if="proof.type == 'single'">
            <span class="secondary">{{ proof.userTitle }}</span> from
            <span class="primary" :style="{ color: themeColor }">{{ proof.city }}</span>
            {{ proof.activity }}
            <span class="secondary">{{ proof.time }}</span> ago.
          </template>
          <template v-else-if="proof.type == 'all'">
            <span class="primary" :style="{ color: themeColor }">{{ proof.userCount }}&nbsp;{{ proof.userTitle }}</span>
            {{ proof.activity }}
            <span class="secondary">in last {{ proof.time }}.</span>
          </template>
          <!-- Powered By -->
          <div class="box-powered-by">Powered By <span class="logo">Growlytics</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    proof: {
      default: null
    },
    themeColor: {
      default: '#4d7cfe'
    }
  }
};
</script>
<style lang="scss">
.web-social-proof-box {
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  color: black;
  margin: auto;
  border-radius: 10px;
  cursor: pointer;

  width: 352px;

  -webkit-box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0);

  touch-action: auto;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 10px;
  position: relative;

  .grw-box-close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 13px;
    cursor: pointer;
    padding: 2px;
  }

  .grw-box-close-btn:hover {
    background: #efefef;
    border-radius: 3px;
  }

  .box-layout {
    display: flex;
    flex: 1;

    .box-content {
      display: flex;
      flex: 1;
      line-height: 1.6;
      padding-top: 5px;

      .box-message {
        width: auto;
        word-break: break-word;
        overflow-y: auto;
        text-align: left;
        font-size: 12px;

        .web-social-proof-box-header {
          font-size: 10px;

          .proof-type {
            width: 100%;
            color: #98a6ad;
            font-size: 10px;
            line-height: 13px;

            .img {
              margin-right: 5px;
              margin-top: -3px;
              font-size: 12px;
              color: #67c23a;
            }

            .box-time-ago {
              color: black;
              float: right;
              padding-right: 5px;
            }
          }
        }

        .primary {
          font-weight: 500;
        }

        .secondary {
          font-weight: 500;
        }

        .box-powered-by {
          font-size: 9px;
          font-weight: lighter;
          color: #98a6ad;
          margin-top: 5px;

          .logo {
            font-weight: 500;
            color: #a2acb1;
          }
        }
      }
    }

    .box-icon-outer {
      display: flex;
      width: 80px;
      height: 80px;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 10px;

      .box-icon-inner {
        width: 80px;
        height: auto;
        font-size: 60px;
        line-height: 85px;
        text-align: center;

        background-position: center;
        background-size: cover;
      }
    }
  }
}
</style>
