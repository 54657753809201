<template>
  <table border="0" cellpadding="0" cellspacing="0" class="blockTable" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
    <tr class="rowMarginBottom" v-if="content.margin.top > 0">
      <td style="padding: 0px !important" :height="content.margin.top" align="center" valign="top" :style="{ 'font-size': content.margin.top + 'px', 'line-height': content.margin.top + 'px' }">&nbsp;</td>
    </tr>
    <tr class="rowContent">
      <!-- Margin Left -->
      <td v-if="content.margin.left > 0" :width="content.margin.left" align="center" valign="top" style="padding: 0px !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
      <!-- Content -->
      <td :align="content.alignment" style="padding: 0px !important; text-align: center">
        <div style="width: 100%; vertical-align: middle; text-decoration: none" :style="{ overflow: 'hidden', 'border-radius': content.borderRadius + 'px !important' }">
          <table cellpadding="0" cellspacing="0" border="0" :style="{ 'background-color': content.backgroundColor + ' !important', 'border-radius': content.borderRadius + 'px !important' }" style="width: 90%; margin: auto">
            <tr class="paddingTop" v-if="content.padding.top > 0">
              <td :height="content.padding.top" align="center" valign="top" style="padding: 0px !important; width: 100%" colspan="3" :style="{ 'font-size': content.padding.top + 'px', 'line-height': content.padding.top + 'px' }">&nbsp;</td>
            </tr>
            <tr>
              <!-- Padding Left -->
              <td v-if="content.padding.left > 0" style="padding: 0px !important" :width="content.padding.left" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

              <td class="coupon-code-val" valign="middle" align="center" style="padding: 0px !important; text-align: center" :style="{ color: content.textColor, 'font-size': content.fontSize + 'px', 'background-color': content.backgroundColor + ' !important', 'line-height': content.fontSize + 'px' }">
                {{ couponInfo.dynamic ? 'XXXX-XXXX-XXXX' : couponInfo.manual.code }}
              </td>

              <!-- Padding Right -->
              <td v-if="content.padding.right > 0" style="padding: 0px !important" :width="content.padding.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
            </tr>
            <tr class="paddingBottom" v-if="content.padding.bottom > 0">
              <td style="padding: 0px !important; width: 100%" :height="content.padding.bottom" align="center" valign="top" colspan="3" :style="{ 'font-size': content.padding.bottom + 'px', 'line-height': content.padding.bottom + 'px' }">&nbsp;</td>
            </tr>
          </table>
        </div>
      </td>

      <!-- Margin Right -->
      <td v-if="content.margin.right > 0" style="padding: 0px !important" :width="content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
    </tr>
    <tr class="rowMarginBottom" v-if="content.margin.bottom > 0">
      <td style="padding: 0px !important" :height="content.margin.bottom" align="center" valign="top" :style="{ 'font-size': content.margin.bottom + 'px', 'line-height': content.margin.bottom + 'px' }">&nbsp;</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'dragDropEmailCouponBlock',
  props: {
    content: {
      type: Object,
      required: true
    },
    couponInfo: {
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },

  methods: {},
  created() {}
};
</script>
