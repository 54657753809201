<template>
  <div id="journeyDiagramPage">
    <!-- Joint Diagram -->
    <div id="jointDiagramContainer">
      <div id="jointDiagram" ref="journeyDiagramBox" v-on:drop="onElementDropped" v-on:dragover="allowDrop"></div>

      <!-- Node Statistics Div -->
      <div class="nodeStatsPopOver" v-for="(nodeStats, index) in analyticsDialogDataList" :key="index" :style="{ top: nodeStats.y, left: nodeStats.x }">
        <div class="closeBtn" @click="removeAnalyticsDialog(index)">
          <i class="el-icon-close"></i>
        </div>

        <!-- Entered Stats -->
        <div class="statsRow headerRow">
          <div class="statsLabel headerStatsLabel">Entered</div>
          <div class="statsValue">{{ nodeStats.entered }}</div>
        </div>
        <div class="divider"></div>

        <!-- Exit Stats -->
        <div class="statsRow headerRow">
          <div class="statsLabel">Exited</div>
          <div class="statsValue">{{ nodeStats.exited }}</div>
        </div>
        <div class="statsRow subStatsRow" v-for="(subExitCount, exitType) in nodeStats.subExits" :key="exitType">
          <div class="statsLabel">via {{ exitType }}</div>
          <div class="statsValue">{{ subExitCount }}</div>
        </div>

        <!-- Waiting Stats -->
        <template v-if="nodeStats.blockType == 'flow'">
          <div class="divider"></div>
          <div class="statsRow headerRow">
            <div class="statsLabel">Waiting</div>
            <div class="statsValue">{{ nodeStats.waiting }}</div>
          </div>
        </template>

        <!-- Not Reachable Stats -->
        <template v-if="nodeStats.blockType == 'action' && nodeStats.blockSubType != 'updateUser'">
          <div class="divider"></div>
          <div class="statsRow headerRow">
            <div class="statsLabel">Not Reachable</div>
            <div class="statsValue">{{ nodeStats.notReachable }}</div>
          </div>
        </template>
      </div>
    </div>

    <!-- Right Side - Elements Container -->
    <div class="rightSide" v-if="journeyStatus == 'draft'">
      <el-collapse v-model="elementListAccordianModel[0]">
        <!-- ACTIONS -->
        <el-collapse-item title="Consistency" name="1">
          <template slot="title">ENGAGE</template>
          <el-row class="elementsContainer">
            <template v-for="(action, index) in elementList.actions">
              <el-col :span="12" class="elementItem" :key="index" v-if="action.sub_type != 'facebook' || showFacebookSync()">
                <img :src="action.image" class="img" :element-info="JSON.stringify(action)" draggable="true" v-on:dragstart="onDragStart" />
                <div class="txt">
                  {{ action.name }}
                </div>
              </el-col>
            </template>
          </el-row>
        </el-collapse-item>
      </el-collapse>

      <!-- FLOWS -->
      <el-collapse v-model="elementListAccordianModel[1]">
        <el-collapse-item title="Consistency" name="1">
          <template slot="title">FLOW</template>
          <el-row class="elementsContainer">
            <el-col :span="12" class="elementItem" v-for="(action, index) in elementList.flow" :key="index">
              <img :src="action.image" class="img" :element-info="JSON.stringify(action)" draggable="true" v-on:dragstart="onDragStart" />
              <div class="txt">
                {{ action.name }}
              </div>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>

      <!-- CONDITIONS -->
      <el-collapse v-model="elementListAccordianModel[2]">
        <el-collapse-item title="Consistency" name="1">
          <template slot="title">CONDITION</template>
          <el-row class="elementsContainer">
            <el-col :span="12" class="elementItem" v-for="(action, index) in elementList.conditions" :key="index">
              <img :src="action.image" class="img" :element-info="JSON.stringify(action)" draggable="true" v-on:dragstart="onDragStart" />
              <div class="txt">
                {{ action.name }}
              </div>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>
    </div>

    <!-- Dialogs -->
    <StartNodeDialog ref="startNodeDialog" :jurneyPublished="journeyStatus != 'draft'" @onChange="onNodeDataChanged"></StartNodeDialog>
    <ActionNodeDialog ref="actionNodeDialog" :jurneyPublished="journeyStatus != 'draft'" @onChange="onNodeDataChanged"></ActionNodeDialog>
    <FlowNodeDialog ref="flowNodeDialog" :jurneyPublished="journeyStatus != 'draft'" @onChange="onNodeDataChanged"></FlowNodeDialog>
    <ConditionNodeDialog ref="conditionNodeDialog" :jurneyPublished="journeyStatus != 'draft'" @onChange="onNodeDataChanged"></ConditionNodeDialog>
    <WaitTimeDialog ref="waitTimeDialog" :jurneyPublished="journeyStatus != 'draft'" @onChange="onLinkDataChanged"></WaitTimeDialog>
    <UpdateUserNodePopup ref="updateUserNodeDialog" :jurneyPublished="journeyStatus != 'draft'" @onChange="onNodeDataChanged"></UpdateUserNodePopup>
    <AdNetworkSyncPopup ref="adNetworkNodeDialog" :jurneyPublished="journeyStatus != 'draft'" @onChange="onNodeDataChanged"></AdNetworkSyncPopup>
    <AbSplitNodePopup ref="abSplitNodeDialog" :jurneyPublished="journeyStatus != 'draft'" @onChange="onNodeDataChanged"></AbSplitNodePopup>

    <JourneyTemplates ref="journeyTemplateDialog"></JourneyTemplates>

    <!-- Campaign Goals Dialog -->
  </div>
</template>

<style lang="scss" src="./journeyDiagram.scss"></style>

<script>
import journeyDiagramComponent from './journeyDiagramComponent';
export default journeyDiagramComponent;
</script>
