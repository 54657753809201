const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.fetchCouponList = function (params) {
  return axios.post(baseUrlNew + `coupons`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getDetails = function (id) {
  return axios.get(baseUrlNew + `coupons/${id}`, {
    headers: buildAuthHeaders()
  });
};

exports.createCoupon = function (params) {
  return axios.post(baseUrlNew + 'coupons/create', params, {
    headers: buildAuthHeaders()
  });
};

exports.updateCoupon = function (couponId, params) {
  return axios.put(baseUrlNew + `coupons/${couponId}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.deleteCoupon = function (couponId) {
  return axios.delete(baseUrlNew + `coupons/${couponId}`, {
    headers: buildAuthHeaders()
  });
};
