const axios = require('axios');
const { baseUrlNew, schedulerUrl, buildAuthHeaders } = require('./settings.js');

exports.getAllCollections = function () {
  return axios.get(baseUrlNew + 'productSet/collections', {
    headers: buildAuthHeaders()
  });
};

exports.searchProducts = function (keyword, pageNumber, pageSize) {
  return axios.get(baseUrlNew + 'productSet/products/search', {
    params: { keyword, pageNumber, pageSize },
    headers: buildAuthHeaders()
  });
};

exports.getSearchProductCount = function (keyword) {
  return axios.get(baseUrlNew + 'productSet/products/search/count', {
    params: { keyword },
    headers: buildAuthHeaders()
  });
};

exports.getProductSetData = function (params) {
  return axios.post(schedulerUrl + 'products', params, {
    headers: buildAuthHeaders()
  });
};

exports.getProductDetails = function (productId) {
  return axios.get(schedulerUrl + `single-product/${productId}`, {
    headers: buildAuthHeaders()
  });
};
