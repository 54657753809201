<template>
  <table border="0" cellpadding="0" cellspacing="0" class="blockTable" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
    <tr>
      <td class="mailHeaderBlock" style="padding: 0px !important">
        <!-- Header Type 1 -->
        <template v-if="content.template == 'white-header-1' || content.template == 'dark-header-1'">
          <table class="mail-header-1" cellpadding="0" cellspacing="0" border="0" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
            <tr>
              <!-- Left Side - Product Image -->
              <td class="mailHeadeLogo" align="center" valign="top" width="30%" style="text-align: center">
                <img :src="content.logo" :height="content.logoHeight" style="margin: auto" :style="{ height: content.logoHeight + 'px' }" />
              </td>

              <!-- Seperator For Mobile -->
              <td class="mobileSeperator" style="padding: 0px !important; color: transparent !important" height="20" :style="{ 'font-size': '20px', 'line-height': '2px' }">&nbsp;</td>

              <!-- Right Side - Header Links -->
              <td class="mailHeaderLinks" :align="content.links.alignment ? content.links.alignment : 'center'" valign="middle" width="60%" :style="{ 'text-align': content.links.alignment ? content.links.alignment : 'center' }">
                <template v-for="(link, index) in content.links.list">
                  <span :style="{ color: content.links.color + ' !important' }" :key="index">
                    <a :href="link.url" :key="index" style="text-decoration: none; line-height: 40px" :style="{ color: content.links.color, 'font-weight': content.links.weight, 'font-size': content.links.fontSize + 'px' }">{{ link.text }}</a>
                    <span style="color: transparent !important" v-if="index != content.links.list.length - 1"> &nbsp;&nbsp;&nbsp;&nbsp; </span>
                  </span>
                </template>
              </td>
              <td style="color: transparent !important" width="25" class="hide-on-mobile">&nbsp;</td>
            </tr>
          </table>
        </template>

        <!-- Header Type 2 -->
        <template v-if="content.template == 'white-header-2' || content.template == 'dark-header-2'">
          <table class="mail-header-1" cellpadding="0" cellspacing="0" border="0" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
            <tr>
              <!-- Product Image - TD -->
              <td class="mailHeadeLogo" align="center" valign="top" width="30%" style="text-align: center">
                <img :src="content.logo" :height="content.logoHeight" style="margin: auto" :style="{ height: content.logoHeight + 'px' }" />
              </td>
            </tr>
            <tr>
              <!-- Product Image - TD -->
              <td align="center" valign="top" width="30%" style="text-align: center; color: transparent !important">&nbsp;</td>
            </tr>
            <tr>
              <!--Bottom Links -->
              <td class="mailHeaderLinks" align="right" valign="middle" width="60%" style="text-align: center">
                <template v-for="(link, index) in content.links.list">
                  <span :style="{ color: content.links.color + ' !important' }" :key="index">
                    <a :href="link.url" style="text-decoration: none; font-weight: bold; line-height: 40px" :style="{ color: content.links.color, 'font-weight': content.links.weight, 'font-size': content.links.fontSize + 'px' }">{{ link.text }}</a>
                    <span style="color: transparent !important" v-if="index != content.links.list.length - 1"> &nbsp;&nbsp;&nbsp;&nbsp; </span>
                  </span>
                </template>
              </td>
            </tr>
          </table>
        </template>

        <!-- Header Type 3 -->
        <template v-if="content.template == 'white-header-3' || content.template == 'dark-header-3'">
          <table cellpadding="0" cellspacing="0" border="0" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
            <tr>
              <!-- Product Image - TD -->
              <td class="mailHeadeLogo" align="center" valign="top" width="30%" style="text-align: center">
                <img :src="content.logo" :height="content.logoHeight" style="margin: auto" :style="{ height: content.logoHeight + 'px' }" />
              </td>
            </tr>
          </table>
        </template>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'dragDropEmailHeaderBlock',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },

  methods: {},
  created() {}
};
</script>
