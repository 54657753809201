import eventModule from '../../../services/event';
import companyModule from '@/services/company';
import RevenueTrackingEventPopup from '@/components/revenueTrackingEventsPopup/revenueTrackingEventsPopup';

export default {
  name: 'campaignGoal',
  props: {
    title: {
      type: String,
      default: 'Campaign'
    }
  },
  components: {
    RevenueTrackingEventPopup
  },
  data() {
    let validateNumber = (value, min, max, callback, label) => {
      if (isNaN(value)) {
        callback(new Error(label + ' must be a number.'));
        return false;
      }

      if (value < min) {
        callback(new Error(label + ' can not be less than 0'));
        return false;
      }
      if (value > max) {
        callback(new Error(label + ' can not be greater than ' + max));
        return false;
      }

      callback();
      return true;
    };

    return {
      eventList: {},
      revenueEvent: null,

      readOnly: false,
      data: null,

      formRules: {
        event: [
          {
            required: true,
            message: 'Please select conversion event.',
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: 'Goal Name is required.',
            trigger: 'blur'
          },
          { max: 100, message: 'Can not exceed 100 characters.', trigger: 'blur' }
        ],
        duration: [
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              validateNumber(value, 0, 100, callback, 'Goal name');
            }
          }
        ]
      },

      conversionTimeFormRules: {
        duration: [
          {
            required: true,
            message: 'Valid conversion time is required.',
            trigger: 'blur'
          },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              validateNumber(value, 1, 500, callback, 'Conversion time');
            }
          }
        ]
      }
    };
  },
  mixins: [],
  watch: {
    data: {
      deep: true,
      handler() {
        this.hasUnsavedChanges = true;
      }
    }
  },

  methods: {
    //#region ------- Page Load Eevents

    async fetchEventList() {
      if (this.segmentList != null) return;
      try {
        let result = await eventModule.getEventList();
        this.eventList = result.data.data.eventList;
      } catch (err) {
        this.reportError(err);
        this.errorToast(`Failed to event list. Please contact support.`);
      }
    },

    async fetchRevenueEventSettings() {
      try {
        let result = await companyModule.getDefaultEventSettings('revenue');
        this.revenueEvent = result.data;
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Something's went wrong. Please contact support.`);
      }
    },

    //#endregion -----

    //#region ------- Revenue Tracking

    // show Revenue TrackingPopup
    showRevenueTrackingEventPopup() {
      this.$refs['revenueTrackingEventPopup'].showPopup();
    },

    //#endregion -----

    //#region ------- Additional Goals

    onAddCampaignGoal() {
      if (this.data.goals.length >= 2) {
        this.warningToast('Maximum 2 campaign goals can be set.');
        return;
      }
      this.data.goals.push({
        name: 'Goal' + ' #' + (this.data.goals.length + 1),
        event: null
      });
    },

    onRemoveCampaignGoal(index) {
      this.data.goals.splice(index, 1);
    },

    //#endregion -----

    //#region ------- Create/Update/Delete

    async validateHtmlForm(form) {
      return new Promise((resolve) => {
        form.validate((valid) => {
          resolve(!valid ? false : true);
        });
      });
    },

    async validateForm() {
      // If tracking is disabled, nothing to validate.
      if (!this.data.trackRevenue) {
        return true;
      }

      // Validate Revenue Event
      if (this.data.trackRevenue && !this.revenueEvent) {
        await this.errorToast('Please setup revenue tracking.');
        return false;
      }

      // Validate Goals Form
      for (let i = 0; i < this.data.goals.length; i++) {
        let form = this.$refs['goalsForm-' + i];
        let isFormValid = await this.validateHtmlForm(form[0]);
        if (!isFormValid) return false;
      }

      // Validate Conversion Timeline Form
      let form = this.$refs['conversionTimeForm'];
      let isFormValid = await this.validateHtmlForm(form);
      if (!isFormValid) return false;

      return true;
    },

    renderContent(content, readOnly) {
      if (!content) {
        this.data = {
          trackRevenue: true,
          goals: [],
          conversionTime: { duration: 5, duration_unit: 'days' }
        };
      } else {
        this.data = content;
      }
      this.readOnly = readOnly ? true : false;
    },

    readProcessedContent() {
      return this.data;
    }
  },

  mounted() {
    this.fetchEventList();
    this.fetchRevenueEventSettings();
  }
};
