<template>
  <div class="goalsInfo" v-if="data">
    <!-- Revenue Tracking Card -->
    <el-card class="revenueEvent" shadow="never">
      <div slot="header">
        <div class="headerText">{{ title }} Revenue Tracking</div>
      </div>

      <div>
        <el-radio-group v-model="data.trackRevenue" size="small" v-if="!readOnly">
          <el-radio :label="true" border>Track Revenue For This {{ title }}</el-radio>
          <el-radio :label="false" border>Don't Track Revenue For This {{ title }}</el-radio>
        </el-radio-group>

        <div v-else class="text-sm">
          <i class="fa fa-check text-primary-600" v-if="data.trackRevenue"></i>
          <i class="fa fa-times-circle-0 text-red-600" v-else></i>
          Revenue tracking is {{ data.trackRevenue ? 'enabled' : 'disabled' }} for this {{ title }}
        </div>

        <template v-if="data.trackRevenue">
          <div v-if="!revenueEvent">
            <div class="errorMessage">
              <i class="el-icon-warning"></i>
              Revenue tracking setup is pending!
            </div>
            <el-button style="margin-left 22px;" type="primary" size="big" @click="showRevenueTrackingEventPopup">Setup Revenue Tracking</el-button>
          </div>

          <div v-else>
            <el-row>
              <el-col :span="4">
                <div class="label">Revenue Event</div>
                <div class="value">{{ revenueEvent.event }}</div>
              </el-col>
              <el-col :span="9">
                <div class="label">Revenue Amount Property</div>
                <div class="value">{{ revenueEvent.property }}</div>
              </el-col>
            </el-row>
          </div>

          <el-form class="conversionDeadline" label-position="top" :model="data.conversionTime" ref="conversionTimeForm" :rules="conversionTimeFormRules">
            <el-row>
              <el-col :span="24">
                <el-form-item v-if="!readOnly" label="Conversion Deadline" prop="duration" class="discountTypeRadio">
                  <el-input size="small" :disabled="readOnly" type="number" placeholder="Enter Conversion Time" v-model="data.conversionTime.duration" class="input-with-select" style="width: 250px" autocomplete="new-password">
                    <el-select :disabled="readOnly" v-model="data.conversionTime.duration_unit" style="width: 90px" slot="append">
                      <el-option label="Hours" value="hours"></el-option>
                      <el-option label="Days" value="days"></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>

                <div v-else class="text-sm mb-1">
                  <div class="text-gray-600 text-xs mb-1">Conversion Deadline</div>
                  <div class="border border-gray-100 w-40 py-1 px-2 rounded">{{ data.conversionTime.duration }} {{ data.conversionTime.duration_unit }}</div>
                </div>

                <div class="text-xs text-gray-400">Conversion will only considered if customer converts within {{ data.conversionTime.duration + ' ' + data.conversionTime.duration_unit }} of opening/clicking {{ title }} message.</div>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </div>
    </el-card>

    <!-- Additional Goals Card - Hidden For Now -->
    <el-card class="additionalGoal mt-4 hidden" shadow="never">
      <div slot="header">
        <span class="headerText">Additional {{ title }} Goals</span>
      </div>

      <div v-if="!readOnly" class="p-4">
        <div v-for="(goal, index) in data.goals" :key="index">
          <el-form class="goalItem" :model="goal" :ref="'goalsForm-' + index" :rules="formRules">
            <el-row :gutter="25">
              <el-col :span="24" class="title"> Goal {{ index + 1 }} </el-col>
              <el-col :span="8">
                <el-form-item label="Goal Name" prop="name">
                  <el-input v-model="goal.name" max="100" placeholder="Enter Goal Name"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Goal Event Name" prop="event">
                  <el-select searchable class="dropdown" v-model="goal.event" filterable placeholder="Select Goal Event">
                    <el-option v-for="(properyList, eventName) in eventList" :key="eventName" :label="eventName" :value="eventName" :disabled="revenueEvent.event && eventName == revenueEvent.event"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8" style="position: relative">
                <el-button style="position: absolute; right: 15px; top: -22px" size="small" type="danger" icon="el-icon-delete" plain @click="onRemoveCampaignGoal(index)">Remove Goal</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" plain @click="onAddCampaignGoal">Add {{ title }} Goal</el-button>
      </div>

      <!-- REadonly Goals List -->
      <div v-else>
        <div v-if="data.goals.length == 0">
          <div v-for="(goal, index) in data.goals" :key="index" class="my-4 mx-4">
            {{ goal.name }}
            <div class="text-gray-600 text-xs mb-1">{{ goal.event }}</div>
          </div>
        </div>
        <div v-else class="py-4 px-3 w-full text-gray-400 text-sm">No additional goals specified for this {{ title }}.</div>
      </div>
    </el-card>
  </div>
</template>

<style lang="scss" src="./campaignGoalsPopup.scss"></style>

<script>
import campaignGoalsPopupComponent from './campaignGoalsPopupComponent';
export default campaignGoalsPopupComponent;
</script>
