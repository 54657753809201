const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.getOverview = (params, type) => {
  return axios.post(baseUrlNew + `campaign-report/overview/${type}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getOverviewDaily = (params, metric) => {
  return axios.post(baseUrlNew + `campaign-report/overview/graph/${metric}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.sendOverviewCSVReport = function (params) {
  return axios.post(baseUrlNew + `campaign-report/overview/export`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getConversionGoals = (campaignId, params) => {
  return axios.post(baseUrlNew + `campaign-report/goals/${campaignId}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.fetchCampaignRevenueList = function (campaignId, params) {
  return axios.post(baseUrlNew + `campaign-report/revenue/${campaignId}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getUTMConversionsList = function (campaignId, params) {
  return axios.post(baseUrlNew + `campaign-report/goals/revenue/${campaignId}`, params, {
    headers: buildAuthHeaders()
  });
};
