const axios = require('axios');
const { schedulerUrl, buildAuthHeaders } = require('./settings.js');

exports.getChannelBalance = function (channel) {
  return axios.get(schedulerUrl + `channel/util/balance/${channel}`, {
    headers: buildAuthHeaders()
  });
};

exports.getWhatsAppTemplateList = function (params) {
  return axios.post(schedulerUrl + `channel/util/whatsapp/templates`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getWhatsAppTemplateListV2 = function (params) {
  return axios.post(schedulerUrl + `channel/util/whatsapp/templatesv2`, params, {
    headers: buildAuthHeaders()
  });
};
