import ShortLinkPopup from '@/components/campaign/shortLink/shortLink';
import SendTestSMSPopup from '@/components/campaign/contentBuilder/sms/sendTestSmsPopup/sendTestSmsPopup';
import { Picker } from 'emoji-mart-vue';
import { fetchChannelProviderList } from '../../../../../services/campaign';
import DynamicUserProperty from '../../../../dynamicUserFilterComponent/dynamicUserFilter';

export default {
  name: 'smsEditor',
  components: {
    Picker,
    DynamicUserProperty,
    ShortLinkPopup,
    SendTestSMSPopup
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    allowDynamicEvents: {
      type: Boolean,
      required: false,
      default: true
    },
    isJourneyCampaign: {
      required: true,
      type: Boolean
    }
  },
  watch: {
    'smsForm.message': {
      deep: true,
      handler() {
        this.onSMSContentChange();
      }
    }
  },
  data() {
    return {
      contentAlreadyRendered: false,

      smsDynmicUserPopver: false,
      smsEmojiPiker: false,

      smsProviderList: [],
      TestingSelectedProvider: '',

      channelConfig: {
        templateId: null // Thirdparty temp
      },
      tmeplateIdErrorText: null,
      smsPreviewText: '',

      // Content Builder Variables
      smsForm: {
        message: 'Sms Content Goes Here',
        isTrasltional: true,
        selectedProvider: '',
        shortLinks: []
      },

      // Form validation
      smsFormRules: {
        message: [
          {
            required: true,
            message: 'Please input notification message',
            trigger: 'blur'
          }
        ],
        selectedProvider: [
          {
            required: true,
            message: 'Please select sms provider.',
            trigger: 'blur'
          }
        ]
      },

      // Preview variable
      showSmsPreview: false
    };
  },

  methods: {
    fetchSmsProviders() {
      let params = {
        channel: 'sms',
        readArchived: this.readOnly
      };
      fetchChannelProviderList(params, this)
        .then((result) => {
          if (result.data.success) {
            this.smsProviderList = result.data.data;
            if (result.data.data.length <= 0) {
              this.warningToast('Sms provider is not configured.');
            }
          }
        })
        .catch((e) => {
          this.reportError(e);
          this.errorToast('Something went wrong');
        });
    },

    setDynamicProp(text) {
      // Fetch Current Position
      let position = document.getElementById('smsTextInput').selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      this.smsForm.message = insert(this.smsForm.message, position, text);
      this.smsEmojiPiker = false;
    },

    smsEmojiSelected(emoji) {
      // Fetch Current Position
      let position = document.getElementById('smsTextInput').selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      this.smsForm.message = insert(this.smsForm.message, position, emoji.native);
      this.smsEmojiPiker = false;
    },

    showShortLinkPopup() {
      this.$refs.shortLinkPopup.showPopup();
    },

    onAddShortLink(data) {
      this.smsForm.shortLinks.push(data);
    },

    onDeleteShortLink(index) {
      let link = this.smsForm.shortLinks[index];

      // Check if the code already exists in message.
      let indexOf = this.smsForm.message.indexOf(link.code);
      if (indexOf >= 0) {
        this.errorToast('Short link is already being used and can not be deleted.');
        return;
      }

      this.smsForm.shortLinks.splice(index, 1);
    },

    onSMSContentChange() {
      this.smsPreviewText = this.smsForm.message;
      for (let i = 0; i < this.smsForm.shortLinks.length; i++) {
        let snippet = this.smsForm.shortLinks[i].code;
        this.smsPreviewText = this.smsPreviewText.replace(snippet, 'https://i.gltcs.in/XXXXXXXXXXX');
      }
    },

    /**************************** Start: Sms Test Methods *********************************/

    async showSendTestSmsPopup() {
      let isValid = await this.validateForm();
      if (!isValid) return;

      let smsContent = this.readProcessedContentV1();
      this.$refs['SendTestSMSPopup'].showPopup(smsContent);

      this.showPushNotificationTestPopup = true;
    },

    /**************************** End: SMS Test Methods *********************************/

    async validateForm() {
      // Validate template id.
      this.tmeplateIdErrorText = null;
      if (!this.channelConfig.templateId) {
        this.tmeplateIdErrorText = 'Template ID is required.';
        this.errorToast('Template ID is required.');
        return false;
      }

      return new Promise((resolve) => {
        this.$refs['smsInputForm'].validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    readProcessedContentV1() {
      return {
        providerId: this.smsForm.selectedProvider,
        channelConfig: JSON.stringify(this.channelConfig),
        content: {
          sms_message: this.smsForm.message,
          sms_is_transactional: this.smsForm.isTrasltional,
          short_links: JSON.stringify(this.smsForm.shortLinks)
        }
      };
    },

    renderCampaignContent(data) {
      if (!this.contentAlreadyRendered) {
        this.contentAlreadyRendered = true;
        this.smsForm.selectedProvider = data.providerId ? data.providerId : null;

        if (data.content) {
          this.smsForm.message = data.content.sms_message ? data.content.sms_message : this.smsForm.message;
          this.smsForm.shortLinks = data.content.short_links ? JSON.parse(data.content.short_links) : this.smsForm.shortLinks;
          if (data.content.sms_is_transactional != null && data.content.sms_is_transactional !== undefined) {
            this.smsForm.isTrasltional = data.content.sms_is_transactional ? true : false;
          } else if (data.content.isTrasltional != null && data.content.isTrasltional !== undefined) {
            this.smsForm.isTrasltional = this.smsForm.isTrasltional ? true : false;
          }
          this.onSMSContentChange();
        }

        if (data.channelConfig) {
          this.channelConfig = this.overrideJson(this.channelConfig, JSON.parse(data.channelConfig));
        }
        this.onSMSContentChange();
      }
    },

    renderCampaignContentForJourney(data) {
      if (data.content) {
        let newContent = {};
        newContent.sms_message = data.content.smsMessage ? data.content.smsMessage : data.content.sms_message;
        newContent.sms_is_transactional = data.content.isTransactional ? data.content.isTransactional : data.content.sms_is_transactional;
        newContent.short_links = data.content.shortLinks ? data.content.shortLinks : data.content.short_links;
        data.content = newContent;
      }
      this.renderCampaignContent(data);
    }
  },
  mounted() {
    this.onSMSContentChange();
    this.fetchSmsProviders();
  }
};
