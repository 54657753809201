import colorPicker from '@caohenghu/vue-colorpicker';

export default {
  components: {
    colorPicker
  },
  props: {
    inputData: Object
  },
  data() {
    return {};
  },
  methods: {
    show() {},

    setColor(obj, property, args) {
      let color = args[0];
      obj[property] = this.RGBAToHexA(color.rgba.r, color.rgba.g, color.rgba.b, color.rgba.a).toUpperCase();
    },

    close() {}
  }
};
