<template>
  <el-dialog append-to-body="" title="Generate Short Link" custom-class="shortLinkDialog" :visible.sync="visible" width="600px">
    <div class="dialogContent">
      <!-- Form -->
      <div v-if="currentSection == 'generate'" class="gnerateLinkSection">
        <el-form :model="form" status-icon label-position="top" :rules="rules" ref="form" label-width="120px">
          <el-form-item label="Enter Original URL" prop="originalUrl">
            <el-input id="shortLinkInput" type="textarea" v-model="form.originalUrl" autocomplete="off" :autosize="{ minRows: 3, maxRows: 3 }"></el-input>
            <el-popover v-model="smsDynmicUserPopver" popper-class="dynamicUserProp" trigger="click">
              <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
              <el-button type="info" class="shortLinkUserIconBtn" slot="reference">
                <i class="el-icon-user-solid" aria-hidden="true"></i>
              </el-button>
            </el-popover>
            <div class="hint">You don't need to put UTM parameters in URL. Growlytics will automtically put UTM parameters you specify at campaign level.</div>
          </el-form-item>

          <template v-if="(channel = 'whatsApp')">
            <el-form-item label="" prop="originalUrl">
              <el-checkbox v-model="form.onlyCode">Only generate short code, without domain name in url.</el-checkbox>
              <div class="hint" style="margin-top: -3px">If you are generating short code for whatsapp buttons, WhatsApp does not allow full urls. In this case, you can use this option to only generate short code without domain name in url.</div>
            </el-form-item>
          </template>
        </el-form>
      </div>

      <!-- Success -->
      <div v-else class="successSection">
        <div class="successTitle">Short Link Created</div>
        <el-input ref="generatedLink" class="linkCopyElementHidden" v-model="generatedLink"></el-input>

        <div class="generatedUrl" :closable="false">{{ generatedLink }}</div>
        <el-alert class="additionalNote" type="info" :closable="false" title="Copy-Paste this link snippet inside your message. Growlytics will automatically generate and put short link inside your message."> </el-alert>
      </div>
    </div>

    <template #footer>
      <template v-if="currentSection == 'generate'">
        <el-button type="primary" @click="onGenerateShortLink()" :loading="generatingUrl">Create Short Link</el-button>
        <el-button type="danger" plain @click="toggleVisiblity">Cancel</el-button>
      </template>
      <div class="w-full text-right" v-else>
        <el-button type="primary" class="continueButton" @click="onCopyAndContinue"> Copy & Continue</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" src="./shortLink.scss"></style>

<script>
import shortLinkComponent from './shortLinkComponent';
export default shortLinkComponent;
</script>
