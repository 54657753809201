<template>
  <table class="blockSection" :id="'block_' + block.id" v-if="block" :style="getCalculatedContainerStyles()" :width="isSubBlock ? '100%' : mailOptions.mailMaxWidth" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; border: none">
    <tr>
      <td style="width: 100%; padding: 0px" width="100%">
        <!-- Text Block -->
        <div v-if="block.type == 'text'" border="0" cellpadding="0" cellspacing="0" class="blockTable textSection" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <DragDropEmailTextBlock :content="block.content"></DragDropEmailTextBlock>
        </div>

        <!-- Image Section -->
        <table v-if="block.type == 'image'" border="0" cellpadding="0" cellspacing="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
            <!-- Content -->
            <td style="padding: 0px">
              <table cellpadding="0" cellspacing="0" border="0" width="100%" style="height: auto; border-spacing: 0; border-collapse: collapse; border: none; width: 100%">
                <td style="padding: 0px; line-height: 0px" :style="{ 'text-align': block.content.alignment }">
                  <a v-if="block.content.link" :href="block.content.link" style="display: block; width: 100%; line-height: 0px">
                    <div v-if="!block.content.src" class="imagePreview"><i class="fa fa-picture-o"></i></div>
                    <img
                      v-else
                      :src="block.content.src"
                      :mailsrc="block.content.src"
                      :width="block.content.width + '%'"
                      style="display: inline-block"
                      :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name : 'Image'"
                      class="imgRender"
                      onerror="this.onerror=null;this.src='https://static.growlytics.in/drag-drop-editor-assets/image-preview.svg';"
                      :style="{
                        width: block.content.width + '%',
                        'border-radius': block.content.radius + 'px'
                      }"
                    />
                  </a>
                  <template v-else>
                    <template v-if="!block.content.src">
                      <div class="imagePreview">
                        <i class="fa fa-picture-o"></i>
                        <div class="noImgDescription">No Image Mentioned Yet</div>
                      </div>
                    </template>
                    <img
                      v-else
                      :src="block.content.src"
                      :mailsrc="block.content.src"
                      class="imgRender"
                      :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name : 'Image'"
                      onerror="this.onerror=null;this.src='https://static.growlytics.in/drag-drop-editor-assets/image-preview.svg';"
                      :width="block.content.width + '%'"
                      style="display: inline-block"
                      :style="{
                        width: block.content.width + '%',
                        'border-radius': block.content.radius + 'px'
                      }"
                    />
                  </template>
                </td>
              </table>
            </td>
            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :width="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>

        <!-- Button Section -->
        <ButtonComponent ref="buttonRef" :block="block" :content="block.content" v-if="block.type == 'button'"></ButtonComponent>

        <!-- Divider Section -->
        <table v-if="block.type == 'divider'" border="0" cellpadding="0" cellspacing="0" class="blockTable buttonTable" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
            <!-- Content -->
            <td style="padding: 0px" align="center">
              <table cellpadding="0" cellspacing="0" border="0" width="100%" style="height: auto; border-spacing: 0; border-collapse: collapse; border: none; width: 100%">
                <td style="padding: 0px">
                  <hr
                    :width="block.content.width + '%'"
                    :style="{
                      margin: 'auto',
                      height: block.content.height + 'px',
                      color: block.content.color,
                      background: block.content.color,
                      width: block.content.width + '%',
                      border: 'none'
                    }"
                  />
                </td>
              </table>
            </td>
            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :height="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>

        <!-- Custom HTML Section -->
        <table v-if="block.type == 'custom-html'" border="0" cellpadding="0" cellspacing="0" class="blockTable buttonTable" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
            <!-- Content -->
            <td style="padding: 0px" align="center" v-html="block.content.html"></td>
            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :height="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>

        <!-- Social-Icons Section -->
        <table v-if="block.type == 'social-icons'" border="0" cellpadding="0" cellspacing="0" class="blockTable buttonTable" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <!-- MarginTop -->
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

            <td style="padding: 0px" :style="{ 'text-align': block.content.alignment }" valign="middle">
              <table cellpadding="0" cellspacing="0" border="0" style="height: auto; width: auto; border-spacing: 0; border-collapse: collapse; border: none; margin: auto; width: 100%">
                <tr>
                  <td v-for="(icon, iconIndex) in block.content.iconList" :key="iconIndex" style="display: inline-block">
                    <table cellpadding="0" cellspacing="0" border="0" style="height: auto; width: auto; border-spacing: 0; border-collapse: collapse; border: none; margin: auto; width: 100%">
                      <tr>
                        <td style="padding: 0px; color: transparent !important" v-if="block.content.iconSpacing > 0" :width="block.content.iconSpacing" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
                        <td style="padding: 0px">
                          <a :href="icon.url">
                            <img :src="icon.image" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name : 'Image'" :height="block.content.imageHeight" :style="{ height: block.content.imageHeight + 'px', 'border-radius': block.content.borderRadius + 'px' }" />
                          </a>
                        </td>
                        <td style="padding: 0px; color: transparent !important" v-if="block.content.iconSpacing > 0" :width="block.content.iconSpacing" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>

            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <!-- MarginBottom -->
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :width="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>

        <!-- Cart Section -->
        <table class="cartSection" v-if="block.type == 'cart'" border="0" cellpadding="0" cellspacing="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <div class="blockPreviewIcon to-be-removed">Cart Preview</div>

          <!-- MarginTop -->
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>

          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

            <td>
              <table>
                <tbody class="cartTable __cartTableReplacementIdentifierInBackend__">
                  <!-- First Cart Item -->
                  <tr class="cart-item-to-be-removed cartItem">
                    <td width="250" style="padding: 10px; width: 250px">
                      <img width="100%" style="width: 100%" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name : 'Image'" src="https://static.growlytics.in/dashboard-assets/assets/img/email_editor/headphones.jpeg" />
                    </td>
                    <td style="padding: 0px">
                      <table style="width: 100%">
                        <tr>
                          <td class="remove-in-mobile" width="25" style="padding: 0px; width: 25px; color: transparent !important">&nbsp;</td>
                          <td style="padding: 0px">
                            <div class="cartItemName" style="font-size: 20px; line-height: 35px">Cart - Product Name 1</div>
                            <div class="productInfo" style="font-size: 16px; line-height: 35px">
                              <span class="cartItemPrice">₹ 1,200</span>
                              <span class="mobileSeperator">&nbsp;|&nbsp;</span>
                              <span class="cartItemQuantity">Qty: 3</span>
                            </div>
                            <div class="productNotes" style="font-size: 12px; line-height: 20px; color: #8a8989" v-if="$store.state.token.companyPlatformType != 'shopify'">Cart item notes goes here.</div>
                          </td>
                          <td class="remove-in-mobile" width="20" style="padding: 0px; width: 20px; color: transparent !important">&nbsp;</td>
                        </tr>
                        <tr class="remove-in-mobile">
                          <td width="25" style="padding: 0px; width: 25px; line-height: 7px; color: transparent !important" v-if="$store.state.token.companyPlatformType != 'shopify'">&nbsp;</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <!-- Cart Seperator -->
                  <tr class="cart-item-to-be-removed">
                    <td width="30%" style="padding: 0px; width: 30%; color: transparent !important">&nbsp;</td>
                  </tr>
                  <!-- Second Cart Item -->
                  <tr class="cart-item-to-be-removed cartItem">
                    <td width="250" style="padding: 10px; width: 250px">
                      <img width="100%" style="width: 100%" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name : 'Image'" src="https://static.growlytics.in/dashboard-assets/assets/img/email_editor/shoes.jpeg" />
                    </td>
                    <td style="padding: 0px">
                      <table style="width: 100%">
                        <tr>
                          <td class="remove-in-mobile" width="25" style="padding: 0px; width: 25px; color: transparent !important">&nbsp;</td>
                          <td style="padding: 0px">
                            <div class="cartItemName" style="font-size: 20px; line-height: 35px">Cart - Product Name 2</div>
                            <div class="productInfo" style="font-size: 16px; line-height: 35px">
                              <span class="cartItemPrice">₹ 3,200</span>
                              <span class="mobileSeperator">&nbsp;|&nbsp;</span>
                              <span class="cartItemQuantity">Qty: 1</span>
                            </div>
                            <div class="productNotes" style="font-size: 12px; line-height: 20px; color: #8a8989" v-if="$store.state.token.companyPlatformType != 'shopify'">Cart item notes goes here.</div>
                          </td>
                          <td class="remove-in-mobile" width="20" style="padding: 0px; width: 20px; color: transparent !important">&nbsp;</td>
                        </tr>
                        <tr class="remove-in-mobile">
                          <td width="25" style="padding: 0px; width: 25px; line-height: 7px; color: transparent !important" v-if="$store.state.token.companyPlatformType != 'shopify'">&nbsp;</td>
                          <!-- <td width="25" style="padding: 0px; width: 25px; line-height: 35px;" v-if="$store.state.token.companyPlatformType == 'shopify'">&nbsp;</td> -->
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <!-- Cart Seperator -->
                  <tr class="cart-item-to-be-removed">
                    <td height="30" style="padding: 0px; height: 30px; color: transparent !important">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" style="padding: 0px" class="---growlytics-cart-button-link---">
                      <ButtonComponent :content="block.content.button"></ButtonComponent>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>

          <!-- MarginBottom -->
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :width="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>

        <!-- Product Sets Section -->
        <table :id="'prd-set-' + block.id" v-if="block.type == 'product' && !fetchingBlockContent" class="productSetsSection _-_product-Sets-Replacement_-_" border="0" cellpadding="0" cellspacing="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <div class="blockPreviewIcon to-be-removed">Products Preview</div>

          <!-- MarginTop -->
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

            <!-- Products Table -->
            <td align="center" valign="middle" style="padding: 0px">
              <!-- Add product info json, to be used in backend. -->
              <span class="productSetInfo" style="display: none">{{ { content: block.content, style: productSetSizing } }}</span>
              <table cellpadding="0" cellspacing="0" border="0" class="productSetTable" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
                <tr v-for="(row, productSetRowIndex) in productSetGrid" :key="productSetRowIndex" class="product-set-item-to-be-removed">
                  <td v-for="(product, productSetColIndex) in row" class="product-col" :width="productSetSizing.width" :key="productSetColIndex" valign="top" align="center" :style="{ width: productSetSizing.width + 'px', 'max-width': productSetSizing.width + 'px' }">
                    <!-- Render Product Block -->
                    <a :href="product.url" _target="blank">
                      <table cellpadding="0" cellspacing="0" border="0" class="productSetTable" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
                        <!-- Product column Padding Top -->
                        <tr>
                          <td align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '13px', 'line-height': '13px' }">&nbsp;</td>
                        </tr>
                        <!-- Product Image -->
                        <tr>
                          <td align="center" valign="top" style="padding: 0px; text-align: center">
                            <a :href="product.url" _target="blank">
                              <img class="prodImg" :alt="brandInfo && brandInfo.brand_name ? brandInfo.brand_name : 'Image'" style="margin: auto" :height="productSetSizing.imageHeight" :src="product.image" :style="{ height: productSetSizing.imageHeight + 'px' }" />
                            </a>
                          </td>
                        </tr>
                        <!-- Product Name -->
                        <tr v-if="productSetSizing.productName.marginTop > 0">
                          <td :height="productSetSizing.productName.marginTop" align="center" valign="top" style="padding: 0px; text-align: center; color: transparent !important" :style="{ 'font-size': productSetSizing.productName.marginTop + 'px', 'line-height': productSetSizing.productName.marginTop + 'px' }">&nbsp;</td>
                        </tr>
                        <tr>
                          <td align="center" valign="top" style="padding: 0px; font-weight: bold; text-align: center">
                            <a :href="product.url" _target="blank" :style="{ color: block.content.productNameColor, 'font-size': productSetSizing.productName.fontSize + 'px' }">
                              {{ product.name }}
                            </a>
                          </td>
                        </tr>
                        <!-- Product Price -->
                        <tr v-if="block.content.showPrice && productSetSizing.productPrice.marginTop > 0">
                          <td :height="productSetSizing.productPrice.marginTop" align="center" valign="top" style="padding: 0px; text-align: center" :style="{ 'font-size': productSetSizing.productPrice.marginTop + 'px', 'line-height': productSetSizing.productName.marginTop + 'px' }">&nbsp;</td>
                        </tr>
                        <tr v-if="block.content.showPrice">
                          <td align="center" valign="top" style="padding: 0px; text-align: center">
                            <a :href="product.url" _target="blank" :style="{ color: block.content.productPriceColor, 'font-size': productSetSizing.productPrice.fontSize + 'px' }">
                              <span v-if="product.original_higher_price && product.original_higher_price > product.price" style="text-decoration: line-through"> {{ brandInfo ? brandInfo.currency : '$' }}{{ product.original_higher_price }} </span>
                              {{ brandInfo ? brandInfo.currency : '$' }}{{ product.price }}
                            </a>
                          </td>
                        </tr>

                        <!-- Product Buy Now Button -->
                        <tr v-if="block.content.button.visible && productSetSizing.productPrice.marginTop > 0">
                          <td :height="productSetSizing.productPrice.marginTop" align="center" valign="top" style="padding: 0px; text-align: center; color: transparent !important" :style="{ 'font-size': productSetSizing.productPrice.marginTop + 'px', 'line-height': productSetSizing.productName.marginTop + 'px' }">&nbsp;</td>
                        </tr>
                        <!-- Product Buy Now Button -->
                        <tr v-if="block.content.button.visible">
                          <td valign="middle" align="center" style="text-align: center">
                            <ButtonComponent :key="productSetRowIndex + productSetColIndex" :content="block.content.button"></ButtonComponent>
                          </td>
                        </tr>

                        <!-- Product column Padding Bottom -->
                        <tr>
                          <td align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '13px', 'line-height': '13px' }">&nbsp;</td>
                        </tr>
                      </table>
                    </a>
                  </td>
                </tr>
              </table>
            </td>

            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <!-- MarginBottom -->
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :width="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>
        <div class="loader to-be-removed" v-if="block.type == 'product' && fetchingBlockContent" v-loading="true"></div>

        <!-- Single Product -->
        <table :id="'prd-single-' + block.id" v-if="block.type == 'single-product' && !fetchingBlockContent" class="singleProductSection _-_product-Single-Replacement_-_" border="0" cellpadding="0" cellspacing="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <div class="blockPreviewIcon to-be-removed">Product Preview</div>

          <!-- MarginTop -->
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

            <!-- Single Product Table -->
            <td align="center" valign="middle" style="padding: 0px" v-if="singleProduct">
              <!-- Add product info json, to be used in backend. -->
              <span class="singleProductInfo" style="display: none">{{ { content: block.content, product: block.content.productId } }}</span>
              <table cellpadding="0" cellspacing="0" border="0" class="productInfoTable" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
                <tr v-if="singleProduct" class="product-to-be-removed">
                  <!-- Image Left Side Padding -->
                  <td class="product-row-gap" width="20" align="center" valign="top" style="padding: 0px" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

                  <!-- Product Image - TD -->
                  <td class="product-col" align="center" valign="top" height="250" width="35%" :background="singleProduct.image" :style="{ background: `url(${singleProduct.image}) center / contain no-repeat #000000;` }" style="background-size: contain; background-repeat: no-repeat; background-position: center"></td>
                  <!-- Image Right Side Padding -->
                  <td class="product-row-gap" width="20" align="center" valign="top" style="padding: 0px" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
                  <td class="product-col">
                    <!-- Right side prdouct info table -->
                    <table cellpadding="0" cellspacing="0" border="0" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
                      <!-- Right side Padding Top -->
                      <tr>
                        <td align="center" valign="top" style="padding: 0px" :style="{ 'font-size': '13px', 'line-height': '13px' }">&nbsp;</td>
                      </tr>
                      <!-- Product Name -->
                      <tr>
                        <td align="left" valign="top" style="padding: 0px; font-weight: bold; font-size: 16px" :style="{ color: block.content.productNameColor }">
                          {{ singleProduct.name }}
                        </td>
                      </tr>

                      <!-- Product Description -->
                      <tr v-if="singleProduct.description">
                        <td height="10" align="left" valign="top" style="padding: 0px; font-size: 10px; line-height: 10px">&nbsp;</td>
                      </tr>
                      <tr v-if="singleProduct.description">
                        <td align="left" valign="top" style="padding: 0px; font-size: 13px" :style="{ color: block.content.productDescriptionColor }">
                          <span v-html="singleProduct.description"></span>
                        </td>
                      </tr>

                      <!-- Product Price -->
                      <tr v-if="block.content.showPrice">
                        <td height="15" align="left" valign="top" style="padding: 0px; font-size: 15px; line-height: 15px">&nbsp;</td>
                      </tr>
                      <tr v-if="block.content.showPrice">
                        <td align="left" valign="top" style="padding: 0px; font-size: 14px" :style="{ color: block.content.productPriceColor }">
                          <span v-if="singleProduct.original_higher_price && singleProduct.original_higher_price > singleProduct.price" style="text-decoration: line-through"> {{ brandInfo ? brandInfo.currency : '$' }}{{ singleProduct.original_higher_price }} </span>
                          {{ brandInfo ? brandInfo.currency : '$' }}{{ singleProduct.price }}
                        </td>
                      </tr>

                      <!-- Product Buy Now Button -->
                      <tr v-if="block.content.button.visible">
                        <td align="left" valign="top" style="padding: 0px">
                          <ButtonComponent :content="block.content.button" :block="{ product: singleProduct }"></ButtonComponent>
                        </td>
                      </tr>

                      <!-- Product column Padding Bottom -->
                      <tr>
                        <td align="center" valign="top" style="padding: 0px; font-size: 13px; line-height: 13px">&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>

            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <!-- MarginBottom -->
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :width="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>
        <div class="loader to-be-removed" v-if="block.type == 'single-product' && fetchingBlockContent" v-loading="true"></div>

        <!-- Email Header -->
        <table :id="'eheader-block-' + block.id" v-if="block.type == 'email-header' && !fetchingBlockContent" class="" border="0" cellpadding="0" cellspacing="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <!-- MarginTop -->
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

            <!-- Email Header Table -->
            <td align="center" valign="middle" style="padding: 0px">
              <DragDropEmailHeaderBlock :content="block.content"></DragDropEmailHeaderBlock>
            </td>

            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <!-- MarginBottom -->
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :width="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>

        <!-- Email Footer -->
        <table :id="'efooter-block-' + block.id" v-if="block.type == 'email-footer' && !fetchingBlockContent" class="" border="0" cellpadding="0" cellspacing="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <!-- MarginTop -->
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

            <!-- Email Header Table -->
            <td align="center" valign="middle" style="padding: 0px">
              <DragDropEmailFooterBlock :brandInfo="brandInfo" :content="block.content"></DragDropEmailFooterBlock>
            </td>

            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <!-- MarginBottom -->
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :width="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>

        <!-- Discount -->
        <table :id="'discount-block-' + block.id" v-if="block.type == 'discount' && !fetchingBlockContent" class="discountSection _-_dicsount-coupon-Replacement_-_" border="0" cellpadding="0" cellspacing="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <div class="blockPreviewIcon to-be-removed">Discount Preview</div>

          <!-- MarginTop -->
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

            <!-- Discount Table -->
            <td align="center" valign="middle" style="padding: 0px">
              <!-- Add coupon info json, to be used in backend. -->
              <span class="couponInfo" style="display: none">{{ block.content.couponInfo }}</span>
              <span class="couponTrackingInfo" style="display: none" v-if="block.content.couponInfo.dynamic && block.content.couponInfo.id">
                {{ '{' + '{coupon[' + block.content.couponInfo.id + '][Coupon Code]}' + '}' }}
              </span>
              <table cellpadding="0" cellspacing="0" border="0" class="discountTable" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
                <!-- Coupon Title -->
                <tr>
                  <td align="center" valign="top" style="width: 100%">
                    <DragDropEmailTextBlock :content="block.content.title"></DragDropEmailTextBlock>
                  </td>
                </tr>

                <!-- Coupon Code Box -->
                <tr>
                  <td align="center" valign="top" style="width: 100%">
                    <DragDropEmailCouponBlock :couponInfo="block.content.couponInfo" :content="block.content.coupon"></DragDropEmailCouponBlock>
                  </td>
                </tr>

                <!-- Shop Now Button -->
                <tr>
                  <td align="center" valign="top" style="width: 100%; text-align: center">
                    <ButtonComponent :content="block.content.button"></ButtonComponent>
                  </td>
                </tr>

                <!-- Coupon Expiry Description -->
                <tr>
                  <td class="expiry-text" align="center" valign="top" style="width: 100; text-align: center">
                    <DragDropEmailMinimalTextBlock :content="block.content.expiryText"></DragDropEmailMinimalTextBlock>
                  </td>
                </tr>
              </table>
            </td>

            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <!-- MarginBottom -->
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :width="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>
        <div class="loader to-be-removed" v-if="block.type == 'single-product' && fetchingBlockContent" v-loading="true"></div>

        <!-- Product Review -->
        <table :id="'prd-review-' + block.id" v-if="block.type == 'product-review' && !fetchingBlockContent" class="singleProductSection _-_product-review-Replacement_-_" border="0" cellpadding="0" cellspacing="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <div class="blockPreviewIcon to-be-removed">Rating Preview</div>

          <!-- MarginTop -->
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

            <!-- Preview Table -->
            <td align="center" valign="middle" style="padding: 0px">
              <!-- Add product info json, to be used in backend. -->
              <span class="productReviewInfo" style="display: none">{{ { content: block.content, orderId: block.content.orderId } }}</span>
              <table cellpadding="0" cellspacing="0" border="0" class="productInfoTable productReviewTable" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
                <tr class="review-to-be-removed">
                  <!-- Image Left Side Padding -->
                  <td class="product-row-gap" width="20" align="center" valign="top" style="padding: 0px" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

                  <!-- Product Image - TD -->
                  <td class="product-col" align="center" valign="top" height="250" width="35%" style="background: url(https://static.growlytics.in/dashboard-assets/assets/img/email_editor/single-product-image-preview.png) center / contain no-repeat; background-size: contain; background-repeat: no-repeat; background-position: center"></td>
                  <!-- Image Right Side Padding -->
                  <td class="product-row-gap" width="20" align="center" valign="top" style="padding: 0px" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
                  <td class="product-col">
                    <!-- Right side prdouct info table -->
                    <table cellpadding="0" cellspacing="0" border="0" style="height: auto; width: 100%; border-spacing: 0; border-collapse: collapse; border: none">
                      <!-- Right side Padding Top -->
                      <tr>
                        <td align="center" valign="top" style="padding: 0px" :style="{ 'font-size': '13px', 'line-height': '13px' }">&nbsp;</td>
                      </tr>
                      <!-- Product Name -->
                      <tr>
                        <td align="left" valign="top" style="padding: 0px; font-weight: bold; padding-bottom: 10px; font-size: 16px" :style="{ color: block.content.productNameColor }">Product Name</td>
                      </tr>

                      <!-- Product Description -->
                      <tr>
                        <td align="left" valign="top" style="padding: 0px; font-size: 13px" :style="{ color: block.content.productDescriptionColor }">
                          <span>Product description for preview. Lorem Ipsum is simply dummy text of the printing and typesetting industry. There are many variations of passages of Lorem Ipsum available.</span>
                        </td>
                      </tr>

                      <!-- Product Buy Now Button -->
                      <tr v-if="block.content.button.visible">
                        <td align="left" valign="top" style="padding-top: 20px">
                          <ButtonComponent :content="block.content.button" :block="{ product: block.content.button }"></ButtonComponent>
                        </td>
                      </tr>

                      <!-- Product column Padding Bottom -->
                      <tr>
                        <td align="center" valign="top" style="padding: 0px; font-size: 13px; line-height: 13px">&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>

            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <!-- MarginBottom -->
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :width="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>

        <!-- Email Header -->
        <table :id="'ehero-block-' + block.id" v-if="block.type == 'email-hero-block' && !fetchingBlockContent" class="" border="0" cellpadding="0" cellspacing="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
          <!-- MarginTop -->
          <tr class="rowMarginTop" v-if="block.content.margin.top > 0">
            <td :height="block.content.margin.top" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': block.content.margin.top + 'px', 'line-height': block.content.margin.top + 'px' }">&nbsp;</td>
          </tr>
          <tr class="rowContent">
            <!-- Margin Left -->
            <td v-if="block.content.margin.left > 0" :width="block.content.margin.left" align="center" valign="top" style="padding: 0px; color: transparent !important" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>

            <!-- Email Header Table -->
            <td align="center" valign="middle" style="padding: 0px">
              <DragDropEmailHeroBlock :content="block.content"></DragDropEmailHeroBlock>
            </td>

            <!-- Margin Right -->
            <td v-if="block.content.margin.right > 0" style="padding: 0px; color: transparent !important" :width="block.content.margin.right" align="center" valign="top" :style="{ 'font-size': '1px', 'line-height': '1px' }">&nbsp;</td>
          </tr>
          <!-- MarginBottom -->
          <tr class="rowMarginBottom" v-if="block.content.margin.bottom > 0">
            <td style="padding: 0px; color: transparent !important" :width="block.content.margin.bottom" align="center" valign="top" :style="{ 'font-size': block.content.margin.bottom + 'px', 'line-height': block.content.margin.bottom + 'px' }">&nbsp;</td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</template>

<style lang="scss">
.loader {
  background: white;
  border: 1px solid #ececec;
  height: 30vh;
  padding-left: 20px;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
}
</style>

<script>
import dragDropBlockComponent from './dragDropBlockComponent';
export default dragDropBlockComponent;
</script>
