// import campaignModule from '../../../../services/campaign';
import DynamicUserProperty from '@/components/dynamicUserFilterComponent/dynamicUserFilter';

export default {
  props: {
    isJourneyCampaign: {
      type: Boolean,
      required: true
    },
    channel: {
      type: String,
      required: false
    },

    allowDynamicEvents: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: { DynamicUserProperty },
  data() {
    return {
      visible: false,
      rules: {
        originalUrl: [{ required: true, message: 'Please enter url to shorten.', trigger: 'blur' }]
      },
      form: {
        originalUrl: null,
        onlyCode: false
      },

      // for dynamic attribute
      smsDynmicUserPopver: false,

      generatingUrl: false,
      currentSection: 'generate',
      generatedLink: null
    };
  },
  methods: {
    showPopup() {
      this.visible = true;

      this.form.originalUrl = null;
      this.currentSection = 'generate';
    },

    setDynamicProp(text) {
      // Fetch Current Position
      let position = document.getElementById('shortLinkInput').selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      this.form.originalUrl = insert(this.form.originalUrl, position, text);
      this.smsDynmicUserPopver = false;
    },

    async onGenerateShortLink() {
      // Validate form first.
      this.$refs['form'].validate(async (valid) => {
        if (!valid) return;

        // Generate Link
        this.generatingUrl = true;
        try {
          setTimeout(() => {
            // Generate random number/id
            let randomId = new Date().getTime();
            this.generatedLink = `#[shortlink-${randomId}]#`;
            this.$emit('newlink', { code: this.generatedLink, originalUrl: this.form.originalUrl, onlyCode: this.form.onlyCode });
            this.currentSection = 'success';
            this.generatingUrl = false;
          }, 750);
        } catch (err) {
          this.errorToast('Failed to generate short link. Something went wrong.');
          this.generatingUrl = false;
          this.reportError(err);
          console.error('Failed to generate short link', err);
        }
      });
    },

    copyLinkToClipboard() {
      let element = this.$refs.generatedLink;
      element.select();
      document.execCommand('copy');
      this.successToast('Short link copied.');
    },

    onCopyAndContinue() {
      this.copyLinkToClipboard();
      this.toggleVisiblity();
    },

    toggleVisiblity() {
      this.visible = !this.visible;
    }
  }
};
