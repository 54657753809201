import { fetchChannelProviderList } from '@/services/campaign';
import { getFacebookAudienceList } from '@/services/channelIntegration';
import BeatLoader from '@/components/loaders/BeatLoader.vue';
import CreateFbAudiencePopup from '@/components/createFacebookAudiencePopup/createFacebookAudiencePopup';
import * as _ from 'lodash';

export default {
  props: {
    jurneyPublished: {
      type: Boolean,
      required: true
    }
  },
  components: {
    BeatLoader,
    CreateFbAudiencePopup
  },
  data() {
    return {
      loading: true,
      fetchingAudienceList: false,

      nodeId: null,
      dialogVisible: false,
      direction: 'btt',

      providerList: null,
      audienceList: null,

      form: {
        provider: null,
        action: 'add',
        target: null
      },

      formRules: {
        provider: [
          {
            required: true,
            message: 'Please select facebook ad account.',
            trigger: 'blur'
          }
        ],
        target: [
          {
            required: true,
            message: 'Please select facebook audience.',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    async validateForm() {
      console.log('Run validation for facebook.');
      return new Promise((resolve) => {
        this.$refs['adNetworkForm'].validate((valid) => {
          resolve(!valid ? false : true);
        });
      });
    },

    async onValidateAndSave() {
      // Validate Form.
      let isValid = await this.validateForm();
      if (!isValid) return;

      let contents = this.form;

      this.$emit('onChange', {
        nodeId: this.nodeId,
        data: {
          campaignName: this.form.action == 'add' ? 'Add To Audience' : 'Remove From Audience',
          data: contents
        }
      });

      this.onCancel();
    },

    onCancel() {
      Object.assign(this.$data, this.$options.data());
    },

    publishChanges() {
      this.$swal({
        title: 'Make changes to live journey?',
        text: "This journey is live, you won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update live journey.'
      }).then(async () => {
        this.onValidateAndSave();
      });
    },

    async showDialog(nodeId, flowType, existingData) {
      this.nodeId = nodeId;

      this.fetchFBAccountList();

      if (existingData) {
        this.form = existingData.data;
        if (this.form.provider) {
          this.fetchFBAudienceList();
        }
      }

      this.dialogVisible = true;
    },

    /*********** Methods for mangging form data *************/

    async onShowAudienceDialog() {
      let providerMetadata = _.find(this.providerList, (provider) => {
        return provider.id == this.form.provider;
      });
      this.$refs.createFbAudiencePopup.showDialog(this.form.provider, providerMetadata);
    },

    async onAudienceCreateSuccess(data) {
      this.form.target = data.id;
      await this.fetchFBAudienceList();
    },

    async fetchFBAccountList() {
      try {
        this.loading = true;
        let params = {
          channel: 'social',
          provider_type: 'facebook',
          readArchived: this.readOnly
        };
        let result = await fetchChannelProviderList(params, this);
        if (result.data.success) {
          this.providerList = result.data.data;
          if (result.data.data.length <= 0) {
            this.warningToast('No Facebook account is configured yet.');
          }
        }
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong');
      } finally {
        this.loading = false;
      }
    },

    async fetchFBAudienceList() {
      try {
        this.fetchingAudienceList = true;
        let result = await getFacebookAudienceList(this.form.provider);
        this.audienceList = result.data;
        this.fetchingAudienceList = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to fetch Facebook Audience List!');
      }
    }
  }
};
