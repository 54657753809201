const axios = require('axios');
const { schedulerUrl, buildAuthHeaders } = require('./settings.js');

exports.getCampaignMessageLog = function (campaignId) {
  return axios.get(schedulerUrl + 'campaign/logs/' + campaignId, {
    headers: buildAuthHeaders()
  });
};

exports.sendTestEmail = function (params) {
  return axios.post(schedulerUrl + 'provider/test/email', params, {
    headers: buildAuthHeaders()
  });
};

exports.sendTestmobilePushForValidation = function (params) {
  return axios.post(schedulerUrl + 'provider/test/fcm', params, {
    headers: buildAuthHeaders()
  });
};

exports.testPushNotificationContent = function (params) {
  return axios.post(schedulerUrl + 'campaign/preview/mobilePush', params, {
    headers: buildAuthHeaders()
  });
};

exports.sendWebPushCampaignPreview = function (params) {
  return axios.post(schedulerUrl + 'campaign/preview/webPush', params, {
    headers: buildAuthHeaders()
  });
};

exports.sendSmsCampaignPreview = function (params) {
  return axios.post(schedulerUrl + 'campaign/preview/sms', params, {
    headers: buildAuthHeaders()
  });
};

exports.sendTestWhatsAppMessage = function (params) {
  return axios.post(schedulerUrl + 'campaign/preview/whatsApp', params, {
    headers: buildAuthHeaders()
  });
};

exports.sendEmailCampaignPreview = function (params) {
  return axios.post(schedulerUrl + 'campaign/preview/email', params, {
    headers: buildAuthHeaders()
  });
};

exports.sendInAppCampaignPreview = function (params) {
  return axios.post(schedulerUrl + 'campaign/preview/inApp', params, {
    headers: buildAuthHeaders()
  });
};

exports.sendCustomApiCampaignPreview = function (params) {
  return axios.post(schedulerUrl + 'campaign/preview/custom-api', params, {
    headers: buildAuthHeaders()
  });
};
