import productsModule from '@/services/productSets';
// import eventModule from '@/services/event';
const _ = require('lodash');

export default {
  name: 'ProductSelectionPopup',
  components: {},
  data() {
    return {
      popupVisible: false,

      fetchingData: true,
      pageNo: 1,
      pageSize: 50,
      totalProducts: 0,
      searchKeyword: '',
      productList: [],
      collectionList: [],

      onlySingleSelectMode: false,
      selectedProducts: []
    };
  },

  methods: {
    //#region ----------------------------- Start: Revenue Tracking Events --------------------

    // Fetch products from server.
    async fetchProducts(skipPaginationCheck) {
      try {
        this.fetchingData = true;

        // Read Pagination Data First
        if (!skipPaginationCheck) {
          this.pageNo = 1;
          let result = await productsModule.getSearchProductCount(this.searchKeyword ? this.searchKeyword : null);
          this.totalProducts = result.data;
        }

        // Read Product List
        let result = await productsModule.searchProducts(this.searchKeyword ? this.searchKeyword : null, this.pageNo, this.pageSize);
        for (let i = 0; i < result.data.length; i++) {
          result.data[i].selected = false;

          let listProductItem = _.find(this.selectedProducts, (currentProduct) => {
            return currentProduct.id == result.data[i].id;
          });
          if (listProductItem) result.data[i].selected = true;
        }
        this.productList = result.data;

        this.fetchingData = false;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Something went wrong.');
        this.fetchingData = false;
      }
    },

    toggleProductSelection(index) {
      // If only single product allow, clear previous selects.
      if (this.onlySingleSelectMode) {
        this.selectedProducts = [];
        for (let i = 0; i < this.productList.length; i++) {
          this.productList[i].selected = false;
        }
      }

      let product = this.productList[index];
      if (product.selected) {
        product.selected = false;
        this.selectedProducts = _.filter(this.selectedProducts, (currentProduct) => {
          return currentProduct.id != product.id;
        });
      } else {
        product.selected = true;
        this.selectedProducts.push(product);
      }
    },

    removeSelectedProduct(product) {
      this.selectedProducts = _.filter(this.selectedProducts, (currentProduct) => {
        return currentProduct.id != product.id;
      });

      let listProductItem = _.find(this.productList, (currentProduct) => {
        return currentProduct.id == product.id;
      });
      listProductItem.selected = false;
    },

    onPageChange() {
      this.fetchProducts(true);
    },

    onSearch: _.debounce(function () {
      this.fetchProducts();
    }, 700),

    //#endregion ----------------------------- End: Revenue Tracking Events --------------------

    async showPopup(onlySingleSelectMode) {
      this.onlySingleSelectMode = onlySingleSelectMode ? onlySingleSelectMode : false;
      this.popupVisible = true;
      this.selectedProducts = [];
      this.fetchProducts();
    },

    async onSubmit() {
      this.popupVisible = false;
      if (this.onlySingleSelectMode) {
        this.$emit('onAddSelectedProducts', this.selectedProducts.length > 0 ? this.selectedProducts[0] : null);
      } else {
        this.$emit('onAddSelectedProducts', this.selectedProducts);
      }
    }
  },

  mounted() {
    // setTimeout(() => {
    //   this.popupVisible = true;
    //   this.fetchProducts();
    // }, 1000);
  }
};
