import JointUtil from './jointElements';
import StartNodeDialog from './../journeyStartNodePopup';
import ActionNodeDialog from './../journeyActionNodePopup';
import FlowNodeDialog from './../journeyFlowNodePopup';
import UpdateUserNodePopup from './../journeyUpdateUserNodePopup/journeyUpdateUserNodePopup';
import AdNetworkSyncPopup from './../journeyAdNetworkSyncPopup/journeyAdNetworkSyncPopup';
import ConditionNodeDialog from './../journeyConditionNodePopup';
import AbSplitNodePopup from './../journeyAbSplitNodePopup';
import WaitTimeDialog from './../journeyWaitTimePopup';
import * as _ from 'lodash';
let joint = JointUtil.jointObj;
import CampaignMixin from '../../../mixins/campaignMixin';
import JourneyTemplates from '@/components/journeyTemplates/journeyTemplates';

export default {
  name: 'jointDiagram',
  props: {
    initialData: { type: Object },
    journeyStatus: { type: String, required: true }
  },
  components: {
    StartNodeDialog,
    ActionNodeDialog,
    FlowNodeDialog,
    ConditionNodeDialog,
    WaitTimeDialog,
    JourneyTemplates,
    UpdateUserNodePopup,
    AdNetworkSyncPopup,
    AbSplitNodePopup
  },
  mixins: [CampaignMixin],
  data() {
    return {
      readonly: false,
      elementList: {
        actions: [
          // Action Elements
          {
            name: 'Email',
            nodeSubText: 'Send Email',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/email.png',
            blockType: 'action',
            sub_type: 'email'
          },
          {
            name: 'Mobile Push',
            nodeSubText: 'Send Mobile Push',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/mobilePush.png',

            blockType: 'action',
            sub_type: 'mobilePush'
          },
          {
            name: 'Web Push',
            nodeSubText: 'Send Web Push',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/webPush.png',

            blockType: 'action',
            sub_type: 'webPush'
          },
          {
            name: 'In-App',
            nodeSubText: 'Show Mobile In-app',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/inApp.png',

            blockType: 'action',
            sub_type: 'inApp'
          },
          {
            name: 'SMS',
            nodeSubText: 'Send SMS',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/sms.png',

            blockType: 'action',
            sub_type: 'sms'
          },
          {
            name: 'WhatsApp',
            nodeSubText: 'Send WhatsApp Message',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/whatsApp.png',

            blockType: 'action',
            sub_type: 'whatsApp'
          },
          {
            name: 'Facebook',
            nodeSubText: 'Sync With Facebook',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/facebook.png',

            blockType: 'action',
            sub_type: 'facebook'
          },
          {
            name: 'Set User Attribute',
            nodeSubText: 'Set User Attribute',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/update-user-attribute.png',

            blockType: 'action',
            sub_type: 'updateUser'
          },
          {
            name: 'Custom Connector',
            nodeSubText: 'Send Custom Connector',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/api-webhook.png',

            blockType: 'action',
            sub_type: 'custom'
          }
        ],
        flow: [
          // Flow Elements
          {
            name: 'Wait For Time',
            nodeSubText: 'Wait For Time',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/waitForTime.png',
            blockType: 'flow',
            sub_type: 'wait_time'
          },
          {
            name: 'Wait For Event',
            nodeSubText: 'Wait For Event',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/waitForEvent.png',
            blockType: 'flow',
            sub_type: 'wait_event'
          },
          {
            name: 'A/B Test',
            nodeSubText: 'A/B Test',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/ab-split.png',
            blockType: 'split',
            sub_type: 'split'
          }
        ],
        conditions: [
          // CONDITION Elements
          {
            name: 'Past Behaviour',
            nodeSubText: 'Check Past Behaviour',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/pastBehaviour.png',
            blockType: 'condition',
            sub_type: 'past_behaviour'
          },
          {
            name: 'Is In Segment',
            nodeSubText: 'Is in segment',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/segment.png',
            blockType: 'condition',
            sub_type: 'is_in_segment'
          },
          {
            name: 'Is Reachable',
            nodeSubText: 'Is reachable',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/isReachable.png',
            blockType: 'condition',
            sub_type: 'is_user_reachable'
          }
        ]
      },
      elementListAccordianModel: ['1', '1', '1'],

      showDialogs: {
        flow: false,
        start: false,
        action: false
      },
      analyticsDialogDataList: []
    };
  },

  methods: {
    /************ Start: Drag Drop Events *************/

    onDragStart(ev) {
      let info = ev.target.getAttribute('element-info');
      ev.dataTransfer.setData('info', info);
    },

    allowDrop(ev) {
      ev.preventDefault();
    },

    onElementDropped(ev) {
      try {
        ev.preventDefault();
        var data = ev.dataTransfer.getData('info');
        data = JSON.parse(data);

        let x = ev.clientX - document.getElementById('journeyDiagramPage').offsetLeft - 100;
        let y = ev.clientY - document.getElementById('journeyDiagramPage').offsetTop - 50;
        y += document.getElementById('jointDiagramContainer').scrollTop;
        x += document.getElementById('jointDiagramContainer').scrollLeft;
        if (data && data.blockType == 'action') {
          JointUtil.CreateSendMessageNode(data, this.graph, x, y);
        } else if (data && data.blockType == 'flow') {
          JointUtil.CreateFlowNode(data, this.graph, x, y);
        } else if (data && data.blockType == 'condition') {
          JointUtil.CreateConditionNode(data, this.graph, x, y);
        } else if (data && data.blockType == 'split') {
          JointUtil.CreateAbSplitNode(data, this.graph, x, y);
        }
      } catch (err) {
        console.log('Drop Failed', err);
      }
    },

    /************ End: Drag Drop Events *************/

    /******** Start: Node Content Events *************/

    showLinkEditor(linkModel) {
      let linkId = linkModel.id;
      let linkData = linkModel.get('grwLinkData');
      linkData = linkData === undefined ? null : linkData;
      console.log('Show here', linkId, linkData);
      this.$refs.waitTimeDialog.showDialog(linkId, linkData);
    },

    onLinkDataChanged(linkData) {
      let link = this.graph.getCell(linkData.nodeId);
      link.set('grwLinkData', linkData.data);
      console.log('modified link data', link.get('grwLinkData'));
      link.label(0, { attrs: { label: { text: linkData.data.timer.displayText } } });

      // If journey published, save journey contents and campaign contents as well.
      if (this.journeyStatus != 'draft') {
        this.$emit('changeLinkContentOfPublishedJourney', {
          sourceNodeGraphId: link.source().id,
          targetNodeGraphId: link.target().id,
          linkData: linkData.data
        });
      }
    },

    showNodeEditor(nodeModel) {
      let nodeId = nodeModel.id;
      let nodeType = nodeModel.get('blockType');
      let nodeTitle = nodeModel.get('nodeSubText');
      let nodeData = nodeModel.get('grwNodeData');
      nodeData = nodeData === undefined ? null : nodeData;

      let nodeLabel = nodeModel.get('message') == 'Click to edit' ? '' : nodeModel.get('message');
      if (nodeType == 'start') {
        this.$refs.startNodeDialog.showDialog(nodeId, nodeData, nodeLabel);
      } else if (nodeType == 'action') {
        let subType = nodeModel.get('sub_type');
        if (subType == 'updateUser') {
          this.$refs.updateUserNodeDialog.showDialog(nodeId, subType, nodeData, nodeLabel);
        } else if (subType == 'facebook' || subType == 'google') {
          this.$refs.adNetworkNodeDialog.showDialog(nodeId, subType, nodeData, nodeLabel);
        } else {
          let campaignId = nodeModel.get('campaign_id');
          this.$refs.actionNodeDialog.showDialog(nodeId, nodeTitle, subType, nodeData, campaignId);
        }
      } else if (nodeType == 'flow') {
        let flowType = nodeModel.get('sub_type');
        this.$refs.flowNodeDialog.showDialog(nodeId, flowType, nodeData, nodeLabel);
      } else if (nodeType == 'split') {
        let flowType = nodeModel.get('sub_type');
        this.$refs.abSplitNodeDialog.showDialog(nodeId, flowType, nodeData, nodeLabel);
      } else if (nodeType == 'condition') {
        let flowType = nodeModel.get('sub_type');
        this.$refs.conditionNodeDialog.showDialog(nodeId, flowType, nodeData, nodeLabel);
      }
    },

    onNodeDataChanged(data) {
      // console.log('node data', data);
      let node = this.graph.getCell(data.nodeId);
      if (!node) {
        console.error('No node found to modify.');
        return;
      }

      // Build New Title
      let newTitle = '';
      let newSubTitleText = null;
      let nodeType = node.get('blockType');
      let nodeSubType = node.get('sub_type');

      if (nodeType == 'start') {
        nodeSubType = data.data.triggerType;
        if (nodeSubType == 'entry-action') {
          newTitle = `On ${data.data.actionEvent.eventName}`;
          newSubTitleText = 'Custom Event';
        } else if (nodeSubType == 'entry-segment') {
          let entryType = data.data.segment.entryType;
          if (entryType == 'entry') {
            newSubTitleText = 'Enters Segment';
            newTitle = 'Customer Enters Segment';
          } else if (entryType == 'already-and-entry') {
            newSubTitleText = 'Enters/Already In Segment';
            newTitle = 'Enters/Already In Segment';
          } else {
            newSubTitleText = 'Exits Segment';
            newTitle = 'Customer Exits Segment';
          }
        } else if (nodeSubType == 'entry-onetime') {
          newTitle = `Run Journey One Time`;
          newSubTitleText = 'Run OneTime';
        } else if (nodeSubType == 'entry-repeat') {
          let repeatDisplayText = '';
          if (data.data.repeat.type == 'day') repeatDisplayText = 'Daily';
          if (data.data.repeat.type == 'week') repeatDisplayText = 'Weekly';
          if (data.data.repeat.type == 'month') repeatDisplayText = 'Monthly';
          newTitle = `Repeat Journey - ` + repeatDisplayText;
          newSubTitleText = 'Repeat Journey';
        }
      } else if (nodeType == 'action') {
        newTitle = data.data.campaignName;
      } else if (nodeType == 'flow') {
        if (nodeSubType == 'wait_time') {
          let waitTimeType = data.data.waitTimeType ? data.data.waitTimeType : 'normal';
          if (waitTimeType == 'week') newTitle = 'Wait for weekly slots';
          else if (waitTimeType == 'month') newTitle = 'Wait for monthly slots';
          else newTitle = 'Wait for ' + data.data.waitDuration + ' ' + data.data.durationType;
        } else if (nodeSubType == 'wait_event') {
          // Modify sub title as well
          newTitle = 'Within ' + data.data.waitDuration + ' ' + data.data.durationType;
          newTitle += ', ' + data.data.eventName;
        }
      } else if (nodeType == 'split') {
        newTitle = 'Split In ' + data.data.length + ' Paths';
        JointUtil.abSplitModifyPorts(this.graph, node, data.data);
      } else if (nodeType == 'condition') {
        if (nodeSubType == 'is_in_segment') {
          newTitle = data.data.name;
        } else if (nodeSubType == 'is_user_reachable') {
          newTitle = 'on ' + this.campaignChannelDisplayNames[data.data] + '?';
        } else if (nodeSubType == 'past_behaviour') {
          newTitle = data.nodeLabel;
        }
      }

      // Set New Title
      if (newTitle) {
        let newString = newTitle.substring(0, 25);
        if (newTitle.length > 25) {
          newString += '...';
        }
        node.set('message', newTitle);
        node.attr(`.name/text`, newString);
      }

      if (newSubTitleText) {
        node.set('sub_type', nodeSubType);
        node.set('nodeSubText', 'Entry - ' + newSubTitleText);
        node.attr(`.label/text`, 'Entry - ' + newSubTitleText);
      }

      node.set('grwNodeData', data.data);
      node.attr(`.invalidInput/visibility`, 'hidden');

      // If journey published, save journey contents and campaign contents as well.
      if (this.journeyStatus != 'draft') {
        this.$emit('changeStepContentOfPublishedJourney', {
          campaignId: node.get('campaign_id'),
          nodeId: node.get('db_id'),
          nodeData: node.get('grwNodeData')
        });
      } else {
        // Save Changes On Node.
        this.$emit('saveDraftAtNodechange', {});
      }
    },

    showAnalyticsForNode(cellModel) {
      // Return if already added
      let existingEntry = _.find(this.analyticsDialogDataList, (analyticsEntry) => {
        return analyticsEntry.id == cellModel.id;
      });
      if (existingEntry) return;

      // nodeExits
      let stats = cellModel.attributes.stats;
      let { x, y } = cellModel.attributes.position;
      y = y + 70;
      x = x + 'px';
      y = y + 'px';

      // Build analytics structure based on cell type.
      let structure = {
        x: x,
        y: y,
        blockType: cellModel.attributes.blockType,
        blockSubType: cellModel.attributes.sub_type,
        entered: stats ? stats.entered : 0,
        exited: stats ? stats.exited : 0,
        waiting: stats ? stats.waiting : 0,
        notReachable: stats ? stats.notReachable : 0,
        subExits: {}
      };

      if (cellModel.attributes.blockType == 'start') {
        structure.subExits.Performed = stats ? stats.triggered : 0;
      } else if (cellModel.attributes.blockType == 'condition') {
        structure.subExits.Yes = stats ? stats.yes : 0;
        structure.subExits.No = stats ? stats.no : 0;
      } else if (cellModel.attributes.blockType == 'flow') {
        structure.subExits.Timeout = stats ? stats.timeout : 0;
        if (cellModel.attributes.sub_type == 'wait_event') {
          structure.subExits.Performed = stats ? stats.triggered : 0;
        }
      } else if (cellModel.attributes.blockType == 'action') {
        let exitNodeLinks = cellModel.attributes.exitDbLinks;

        for (let i = 0; i < exitNodeLinks.length; i++) {
          if (exitNodeLinks[i].relation_type == 'sent') {
            structure.subExits.Sent = stats ? stats.sent : 0;
          } else if (exitNodeLinks[i].relation_type == 'opened') {
            structure.subExits.Opened = stats ? stats.opened : 0;
          } else if (exitNodeLinks[i].relation_type == 'notOpened') {
            structure.subExits['Not Opened'] = stats ? stats.notOpened : 0;
          } else if (exitNodeLinks[i].relation_type == 'clicked') {
            structure.subExits.Clicked = stats ? stats.clicked : 0;
          } else if (exitNodeLinks[i].relation_type == 'notClicked') {
            structure.subExits['Not Clicked'] = stats ? stats.notClicked : 0;
          } else if (exitNodeLinks[i].relation_type == 'afterUpdate') {
            structure.subExits['Update Success'] = stats ? stats.afterUpdate : 0;
          }
        }
      } else if (cellModel.attributes.blockType == 'split') {
        for (let i = 0; i < cellModel.attributes.grwNodeData.length; i++) {
          let path = cellModel.attributes.grwNodeData[i];
          structure.subExits[path.name] = stats ? stats[path.id] : 0;
        }
      }

      structure.subExits['Exit Criteria'] = stats ? stats.exitCriteria : 0;

      structure.id = cellModel.id;
      this.analyticsDialogDataList.push(structure);
      console.log('Show: ', structure);
    },

    removeAnalyticsDialog(index) {
      this.analyticsDialogDataList.splice(index, 1);
    },

    /************ End: Node Content Events ************/

    /************ Start: Init Events ************/

    initPaper(journeyDataToRender) {
      let journeyData = this.initialData;
      if (journeyDataToRender) journeyData = journeyDataToRender;

      const namespace = JointUtil.shapesList; // e.g. { standard: { Rectangle: RectangleElementClass }}
      // const paper = new joint.dia.Paper({ cellViewNamespace: namespace });

      this.graph = new joint.dia.Graph({}, { cellNamespace: namespace });
      this.graph.vueComponent = this;
      let graph = this.graph;
      if (journeyData) {
        journeyData = this.formatJourneyDataForDisplay(journeyData);
        this.graph.fromJSON(journeyData);
      }

      // Init Paper
      this.paper = new joint.dia.Paper({
        el: document.getElementById('jointDiagram'),
        cellViewNamespace: namespace,
        model: this.graph,
        gridSize: 10,

        interactive: this.journeyStatus == 'draft' ? true : false,
        linkPinning: false,
        multiLinks: true,
        defaultLink: new joint.shapes.standard.Link(JointUtil.defaultLinkParams),
        defaultRouter: {
          name: 'manhattan',
          args: {
            excludeEnds: ['source'],
            padding: 20,
            startDirections: ['top', 'bottom'],
            endDirections: ['top', 'left'],
            step: 5
          }
        },
        defaultConnector: {
          name: 'rounded'
        },

        validateConnection: function (cellViewS, magnetS, cellViewT, magnetT, end, linkView) {
          return JointUtil.onLinkValidate(cellViewS, magnetS, cellViewT, magnetT, end, linkView);
        },

        // Enable marking available cells & magnets
        markAvailable: true
      });

      // On Click
      this.paper.on('element:pointerclick', function (cellView, evt) {
        JointUtil.onMouseClick(cellView, evt, graph);
      });

      // On Click
      this.paper.on('link:pointerclick', function (cellView, evt) {
        JointUtil.onLinkMouseClick(cellView, evt, graph);
      });

      // On Element Mouse Enter
      this.paper.on('cell:mouseenter', function (cellView, evt) {
        JointUtil.onElementMouseEnter(cellView, evt, graph);
      });

      // On Element Mouse LEave
      this.paper.on('cell:mouseleave', function (cellView, evt) {
        JointUtil.onElementMouseLeave(cellView, evt, graph);
      });

      // On Link Mouse Enter
      this.paper.on('link:mouseenter', function (linkView, evt) {
        JointUtil.onLinkMouseEnter(linkView, evt, graph);
      });

      // On Mouse LEave
      this.paper.on('link:mouseleave', function (linkView, evt) {
        JointUtil.onLinkMouseLeave(linkView, evt, graph);
      });

      this.paper.on('link:connect', function (linkView, evt, targetCellView, magnet, arrowhead) {
        JointUtil.onLinkAdded(graph, linkView, evt, targetCellView, magnet, arrowhead);
      });

      // Load if existing data found
      if (!journeyData) {
        JointUtil.CreateStartNode(
          {
            nodeSubText: 'Entry - Custom Event',
            message: 'Click to edit',
            image: 'https://static.growlytics.in/dashboard-assets/assets/img/journey/enterJourney.png',
            blockType: 'start',
            sub_type: 'start'
          },
          this.graph
        );
      }

      // Iterate and show all stats if journey is published.
      if (this.journeyStatus != 'draft') {
        setTimeout(() => {
          let elementList = this.graph.getElements();
          for (let i = 0; i < elementList.length; i++) {
            let element = elementList[i];
            this.showAnalyticsForNode(element, element.get('stats'));
            break;
          }
        }, 500);
      }
    },

    formatJourneyDataForDisplay(journeyData) {
      for (let i = 0; i < journeyData.cells.length; i++) {
        let cell = journeyData.cells[i];

        if (this.journeyStatus == 'draft') {
          delete cell.isPublished;
          delete cell.db_id;
          delete cell.stats;
          delete cell.campaign_id;
          delete cell.exitDbLinks;
        }

        journeyData.cells[i] = cell;
      }
      return journeyData;
    },

    // ************ End: Init Events ***********************

    // ************ Start: Parent Events ***********************

    validate() {
      let nodeList = this.graph.getElements();

      // Validate data of each node
      let invalidNodes = [];
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].attr(`.invalidInput/visibility`, 'hidden');
        if (nodeList[i].get('grwNodeData') === undefined || nodeList[i].get('grwNodeData') == null) {
          let title = nodeList[i].get('nodeSubText');
          let msg = `<li class="mb-2">${title} - Please provide valid information for ${title} step.</li>`;
          invalidNodes.push(msg);
          // Show error icon on the node
          nodeList[i].attr(`.invalidInput/visibility`, 'visible');
        } else {
          // If data present for node, make sure provider id is there.
          // Special case for the journeys created from templates.
          let node = nodeList[i];
          let nodeType = node.attributes.blockType;
          let nodeSubType = node.attributes.sub_type;
          if (nodeType == 'action') {
            let nodeData = node.attributes.grwNodeData;
            if (['updateUser', 'facebook', 'google'].indexOf(nodeSubType) >= 0) {
              console.log('');
            } else {
              if (!nodeData.content || !nodeData.content.providerId) {
                let nodeTitle = nodeList[i].get('nodeSubText');
                let msg = `<li style="margin-left: 10px"><div class="hdr">${nodeTitle} [${nodeData.campaignName}]</div>Message delivery provider is required for this step.</li>`;
                invalidNodes.push(msg);
                // Show error icon on the node
                nodeList[i].attr(`.invalidInput/visibility`, 'visible');
              }
            }
          }
        }
      }
      if (invalidNodes.length > 0) {
        let html = '<div class="px-3"><ol>' + invalidNodes.join(' ') + '</ol><div>';
        this.$alert(html, 'Changes Needed For Publish', {
          confirmButtonText: 'Okay',
          customClass: 'errorPopup',
          dangerouslyUseHTMLString: true
        });
        return false;
      }

      // Remote Nodes Not Allowed
      for (let i = 0; i < nodeList.length; i++) {
        let node = nodeList[i];
        if (this.graph.getPredecessors(node).length == 0 && node.get('blockType') != 'start') {
          let msg = 'All steps must be part of journey. Please remove un-necessary steps or include them within journey.';
          this.$alert(msg, 'All steps must be connected', {
            confirmButtonText: 'Okay'
          });
          return false;
        }
      }

      // Atleast one action node must be persent in journey
      let actionNode = _.find(nodeList, (nd) => {
        return nd.get('blockType') == 'action';
      });
      if (!actionNode) {
        let msg = 'Atleast one action/engagement step must be provided in journey.';
        this.$alert(msg, 'Action/Engage step is missing in journey ', {
          confirmButtonText: 'Okay'
        });
        return false;
      }

      return true;
    },

    getJson() {
      let json = this.graph.toJSON();
      return json;
    }

    // ************ End: Parent Events ***********************
  },

  async mounted() {
    this.readonly = this.journeyStatus != 'draft';
    this.initPaper();
  }
};
