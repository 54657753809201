<template>
  <div class="styleBuilder">
    <el-row :gutter="20">
      <!-- Text Color -->
      <el-col :span="12">
        <label class="label"> Text Color </label>
        <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
          <div :style="{ background: inputData.textColor }">
            <color-picker :color="inputData.textColor" theme="dark" @changeColor="setColor(inputData, 'textColor', arguments)" />
          </div>
          <div class="colorButton" slot="reference">
            <span class="colorPreview" :style="{ 'background-color': inputData.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
            <span class="colorValue">{{ inputData.textColor }}</span>
          </div>
        </el-popover>
      </el-col>

      <!-- Font Weight -->
      <el-col :span="12" class="dropdownContainer">
        <label class="label"> Font Weight </label>
        <el-select v-model="inputData.fontWeight" size="small">
          <el-option value="normal" label="Normal"></el-option>
          <el-option value="bold" label="Bold"></el-option>
        </el-select>
      </el-col>

      <!-- Font Size -->
      <el-col :span="24" class="sliderContainer">
        <label class="label"> Text Size </label>
        <el-slider :min="7" :max="70" v-model="inputData.fontSize"> </el-slider>
      </el-col>

      <!-- Text Margin -->
      <el-col :span="24" class="optionItem paddingContainer" v-if="inputData.margin">
        <label class="label"> Text Margin (Spacing Outside) </label>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
            <label class="label bottom">Top</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
            <label class="label bottom">Bottom</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.left" :min="0" :max="300" size="mini"></el-input-number>
            <label class="label bottom">Left</label>
          </el-col>
          <el-col :span="6">
            <el-input-number controls-position="right" v-model="inputData.margin.right" :min="0" :max="300" size="mini"></el-input-number>
            <label class="label bottom">Right</label>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" src="../styleBuilder.scss"></style>

<script>
import minimalTextStyleBuilderComponent from './minimalTextStyleBuilderComponent';
export default minimalTextStyleBuilderComponent;
</script>
