<template>
  <div v-html="outputHtml"></div>
</template>

<script>
import * as Eta from 'eta';
import * as _ from 'lodash';
export default {
  name: 'dragDropButtonBlock',
  props: {
    block: {
      type: Object,
      required: false
    },
    content: {
      type: Object,
      required: true
    }
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.render();
      }
    }
  },
  computed: {},
  components: {},
  data() {
    return {
      html: `
        <table border="0" cellpadding="0" cellspacing="0" class="blockTable buttonTable" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">

        <% if (it.content.margin.top > 0) { %>
          <tr class="rowMarginBottom">
            <td style="padding: 0px !important; ; color: transparent !important; font-size: <%= it.content.margin.top %>px; line-height: <%= it.content.margin.top %>px;" height="<%= it.content.margin.top %>" align="center" valign="top">&nbsp;</td>
          </tr>
        <% } %>

        <tr class="rowContent">
          <!-- Margin Left -->
          <% if (it.content.margin.left > 0) { %>
            <td width="<%= it.content.margin.left %>" align="center" valign="top" style="padding: 0px !important; ; color: transparent !important; font-size: 1px; line-height: 1px;">&nbsp;</td>
          <% } %>
          <!-- Content -->
          <td style="padding: 0px !important; text-align: <%= it.content.alignment %>;">
            <a href="<%= it.buttonLink %>" _target="blank" style="display: inline-block; vertical-align: middle; text-decoration: none; overflow: hidden; background-color: <%= it.content.buttonColor %>; border-radius: <%= it.content.radius %>px !important; border: 1px solid <%= it.content.buttonColor %>;">
              <table cellpadding="0" cellspacing="0" border="0" style="background-color: <%= it.content.buttonColor %>; border-radius: <%= it.content.radius %>px !important;">

                <% if (it.content.insidePadding.top > 0) { %>
                  <tr class="paddingTop">
                    <td height="<%= it.content.insidePadding.top %>" align="center" valign="top" colspan="3" style="padding: 0px !important; ; color: transparent !important; width: 100%; font-size: <%= it.content.insidePadding.top %>px; line-height: <%= it.content.insidePadding.top %>px">&nbsp;</td>
                  </tr>
                <% } %>

                <tr>
                  <!-- Padding Left -->
                  <% if (it.content.insidePadding.left > 0) { %>
                  <td style="padding: 0px !important; color: transparent !important" width="<%= it.content.insidePadding.left %>" align="center" valign="top" :style="font-size: '1px'; line-height: 1px;">&nbsp;</td>
                  <% } %>

                  <td valign="middle" align="center" style="padding: 0px !important; color: <%= it.content.textColor %>; font-size: <%= it.content.fontSize %>px; line-height: <%= it.content.fontSize %>px">
                    <a href="<%= it.buttonLink %>" _target="blank" style="display: inline-block; vertical-align: middle; text-decoration: none; color: <%= it.content.textColor %>; overflow: hidden; background-color: <%= it.content.buttonColor %>; border-radius: <%= it.content.radius %> px !important; border: 1px solid <%= it.content.buttonColor %>">
                      <%= it.content.text %>
                    </a>
                  </td>

                  <!-- Padding Right -->
                  <% if (it.content.insidePadding.right > 0) { %>
                    <td style="padding: 0px !important; color: transparent !important" width="<%= it.content.insidePadding.right %>" align="center" valign="top" style="font-size: 1px; line-height: 1px;">&nbsp;</td>
                  <% } %>
                </tr>

                <!-- Padding Bottom -->
                <% if (it.content.insidePadding.bottom > 0) { %>
                  <tr class="paddingBottom">
                    <td height="<%= it.content.insidePadding.bottom %>" align="center" valign="top" colspan="3" style="padding: 0px !important; ; color: transparent !important; width: 100%; font-size: <%= it.content.insidePadding.bottom %>px; line-height: <%= it.content.insidePadding.bottom %>px">&nbsp;</td>
                  </tr>
                <% } %>
              </table>
            </a>
          </td>

          <!-- Margin Right -->
          <% if (it.content.margin.right > 0) { %>
            <td width="<%= it.content.margin.right %>" align="center" valign="top" style="padding: 0px !important; ; color: transparent !important; font-size: 1px; line-height: 1px;">&nbsp;</td>
          <% } %>
        </tr>

        <!-- Margin Bottom -->
        <% if (it.content.margin.bottom > 0) { %>
          <tr class="rowMarginBottom">
            <td height="<%= it.content.margin.bottom %>" align="center" valign="top" style="padding: 0px !important; ; color: transparent !important; font-size: <%= it.content.margin.bottom %>px; line-height: <%= it.content.margin.bottom %>px;">&nbsp;</td>
          </tr>
        <% } %>
      </table>
      `,
      outputHtml: null
    };
  },

  methods: {
    generateButtonLink: function () {
      if (this.content.link) return this.content.link;
      else if (this.block && this.block.product && this.block.product.url) return this.block.product.url;
      return '';
    },
    render: _.debounce(function () {
      this.renderWithoutDebounce();
    }, 100),

    renderWithoutDebounce() {
      this.outputHtml = this.generateHtml();
    },

    generateHtml() {
      let output = Eta.render(this.html, { block: this.block, content: this.content, buttonLink: this.generateButtonLink() });

      // HREF urls to be Html Decoded first.
      var urlDecodeRegex = /<a(.*?)href="(.*?)"/g;
      let match = null;
      let strToReplace = [];
      while ((match = urlDecodeRegex.exec(output))) {
        let originalUrl = match[2];
        //Decode url
        var elem = document.createElement('textarea');
        elem.innerHTML = originalUrl;
        originalUrl = elem.value;
        strToReplace.push({
          find: match[0],
          replace: `<a ${match[1]} href="${decodeURI(originalUrl)}"`
        });
      }

      for (let i = 0; i < strToReplace.length; i++) {
        output = output.replace(strToReplace[i].find, strToReplace[i].replace);
      }
      return output;
    }
  },

  mounted() {
    this.outputHtml = Eta.render(this.html, { block: this.block, content: this.content, buttonLink: this.generateButtonLink() });
  }
};
</script>
