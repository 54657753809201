<template>
  <div id="mobilePushEditorPage" nativeOnScroll="handleScroll">
    <el-container style="margin-right: 50px">
      <el-row class="mobilePushEditor" :gutter="20">
        <!-- Left Side Form -->
        <el-col class="leftSideForm" :span="15" v-bind:class="{ readOnly: readOnly === true }">
          <!-- Form  -->
          <el-form ref="mobilePushForm" v-bind:class="{ readOnly: readOnly === true }" label-position="top" :model="mobilePush">
            <!-- Notification Title -->
            <el-form-item label="Title" prop="title">
              <el-input id="titleMPushInput" :disabled="readOnly" v-model="mobilePush.title"></el-input>
              <el-popover v-model="titleEmojiPiker" popper-class="customTooltip" style="width: 355px" trigger="click" v-if="!readOnly">
                <picker type="hidden" :showPreview="false" :perLine="20" :exclude="['flags']" @select="onTitleEmojiSelected" :native="true" set="emojione" />
                <el-button type="text" info class="emojiButton" slot="reference" circle>
                  <i class="fa fa-smile-o" aria-hidden="true"></i>
                </el-button>
              </el-popover>

              <el-popover v-model="titleDynmicUserPopver" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'title'" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                <el-button type="text" info class="userIconBtn" slot="reference" circle>
                  <i class="el-icon-user-solid" aria-hidden="true"></i>
                </el-button>
              </el-popover>
            </el-form-item>

            <!-- Ntoification Message -->
            <el-form-item label="Message" prop="message">
              <el-input id="messageMPushInput" :disabled="readOnly" v-model="mobilePush.message"></el-input>
              <el-popover v-model="messageEmojiPiker" popper-class="customTooltip" style="width: 355px" trigger="click" v-if="!readOnly">
                <picker :showPreview="false" @select="onMessageEmojiSelected" :native="true" set="emojione" />
                <el-button type="text" info class="emojiButton" slot="reference" circle>
                  <i class="fa fa-smile-o" aria-hidden="true"></i>
                </el-button>
              </el-popover>

              <el-popover v-model="messageDynmicUserPopver" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'message'" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                <el-button type="text" info class="userIconBtn" slot="reference" circle>
                  <i class="el-icon-user-solid" aria-hidden="true"></i>
                </el-button>
              </el-popover>
            </el-form-item>

            <div class="border rounded-md overflow-hidden bg-white">
              <div class="px-3 border-b py-2 text-base bg-gray-50">Media Type</div>
              <!-- Media Type Input -->
              <div class="p-3 bg-white">
                <el-radio-group size="small" v-model="mobilePush.media.type" :disabled="readOnly">
                  <el-radio-button label="none">None</el-radio-button>
                  <el-radio-button label="image">Image</el-radio-button>
                  <el-radio-button label="crousal">Crousal</el-radio-button>
                </el-radio-group>
              </div>

              <!-- Notification Image -->
              <div class="px-3 pb-1" v-if="mobilePush.media.type == 'image'">
                <el-form-item label="Notification Image Url">
                  <el-input id="imageMPushInput" :disabled="readOnly" v-model="mobilePush.media.image" placeholder="Enter Image Url"></el-input>
                  <el-popover v-model="imageDynamicPopover" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                    <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'image'" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                    <el-button type="text" info class="userIconBtn" slot="reference" circle>
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>

                  <div class="hint">Recommended size: 1080px x 540px. Aspect ratio (width:height): 2:1. Actual rendering depends on screen size. JPG, JPEG, PNG formats only. GIF format also available but for iOS 10+ only. Less than 1 MB in size. URL must begin with HTTPS.</div>
                </el-form-item>
              </div>
            </div>

            <el-row>
              <!-- Notification Background Color -->
              <el-col :span="16">
                <div class="flex">
                  <!-- Enable Background Color -->
                  <div>
                    <el-form-item label="Notification Background Color">
                      <el-switch v-if="!readOnly" v-model="mobilePush.backgroundColor.enabled" active-color="#13ce66"> </el-switch>
                      &nbsp;
                      <div v-if="!readOnly" style="display: inline-block">
                        {{ mobilePush.backgroundColor.enabled ? 'Yes' : 'No' }}
                      </div>
                    </el-form-item>
                  </div>

                  <!-- Background Color -->
                  <div class="ml-10" v-if="mobilePush.backgroundColor.enabled">
                    <el-form-item label="Notification Background Color">
                      <el-popover :disabled="readOnly" popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                        <div :style="{ background: mobilePush.backgroundColor.color }">
                          <color-picker :color="mobilePush.backgroundColor.color" theme="light" @changeColor="setColor(mobilePush.backgroundColor, 'color', arguments)" />
                        </div>
                        <div class="colorButton w-40 uppercase" slot="reference">
                          <span class="colorPreview" v-if="mobilePush.backgroundColor.color" :style="{ 'background-color': mobilePush.backgroundColor.color + ' !important', border: 'none', color: 'white !important' }"></span>
                          <span class="colorValue">{{ mobilePush.backgroundColor.color ? mobilePush.backgroundColor.color : 'Transparent' }}</span>
                        </div>
                      </el-popover>
                    </el-form-item>
                  </div>
                </div>
              </el-col>
            </el-row>

            <!-- Android/iOS Options -->
            <div class="advancedOptionsContainer mt-3">
              <div class="rounded-md border overflow-hidden mb-4 bg-white" shadow="never" :key="os" v-for="(option, os) in options">
                <!-- Options Header -->
                <div class="py-2 px-2 bg-gray-100 border-b" @mouseover="setPreviewMode(os)">
                  <i v-if="os == 'android'" class="fa-brands fa-android"></i>
                  <i v-else class="fa-brands fa-apple"></i>
                  &nbsp;
                  {{ option.title }} Options
                </div>

                <div class="py-2 px-3" @mouseover="setPreviewMode(os)">
                  <el-row :gutter="20">
                    <!-- Subtitle Text -->
                    <el-col :span="16">
                      <el-form ref="advanceOptionsForm" label-position="top" :model="option">
                        <!-- Summary Text -->
                        <el-form-item label="Subtitle Text" prop="summaryText">
                          <el-input :disabled="readOnly" v-model="option.summaryText" placeholder="Summary Text"></el-input>
                          <div class="hint">Appears besides the app name</div>
                        </el-form-item>
                      </el-form>
                    </el-col>

                    <!-- iOS - Notificaiton Priority -->
                    <el-col :span="8" v-if="os == 'ios'">
                      <el-form-item label="Notification Priority">
                        <el-select :disabled="readOnly" v-model="option.notificationPriority" placeholder="Select Priority">
                          <el-option label="Passive" value="passive"> </el-option>
                          <el-option label="Active" value="active"> </el-option>
                          <el-option label="Time Sensitive" value="time-sensitive"> </el-option>
                          <el-option label="Critical" value="critical"> </el-option>
                        </el-select>
                        <div class="hint"></div>
                      </el-form-item>
                    </el-col>

                    <!-- Android - Notification Channel -->
                    <el-col :span="8" v-if="os == 'android'">
                      <el-form-item class="notificationChannel" label="Android Notification Channel" prop="channel">
                        <el-tooltip placement="right" :disabled="readOnly">
                          <el-select :disabled="readOnly" v-model="option.channel" placeholder="Select Channel">
                            <el-option v-for="item in androidChannelList" :key="item" :label="item" :value="item"> </el-option>
                          </el-select>
                        </el-tooltip>
                      </el-form-item>
                      <!-- <span v-if="!readOnly" class="hintLink">
                        <router-link target="_blank" to="/channels/mobilePush">You can add channel form here</router-link>
                      </span> -->
                    </el-col>
                  </el-row>

                  <!-- Click Action -->
                  <el-row>
                    <el-col :span="8">
                      <el-form-item class="" label="Click Action">
                        <el-select :disabled="readOnly" v-model="option.click.action">
                          <el-option label="Open App" value="open-app"></el-option>
                          <el-option label="Open Deeplink URL" value="deeplink"></el-option>
                          <el-option label="Open Web URL" value="web-url"></el-option>
                          <el-option label="Open App Screen" value="open-screen"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="16">
                      <!-- Deep Link URL -->
                      <el-form-item v-if="option.click.action == 'deeplink'" label="Deeplink URL">
                        <el-input :disabled="readOnly" v-model="option.click.deepLinkUrl" placeholder="Enter Deeplink URL"></el-input>
                        <div class="hint">Deeplink can be any URI (eg. myapp://productdetails/itemabc). URL can be HTTP or HTTPS (eg. https://www.growlytics.in)</div>
                      </el-form-item>
                      <!-- Web View URL -->
                      <el-form-item v-if="option.click.action == 'web-url'" label="Web URL">
                        <el-input :disabled="readOnly" v-model="option.click.webUrl" placeholder="Enter Web URL"></el-input>
                      </el-form-item>
                      <!-- Screen Name -->
                      <el-form-item v-if="option.click.action == 'open-screen'" label="App Screen Name">
                        <el-input :disabled="readOnly" v-model="option.click.screenName" placeholder="App Screen Name"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <!-- Use Buttons -->
                  <div class="pb-2">
                    Use Notification Buttons &nbsp;&nbsp;&nbsp;&nbsp;
                    <el-switch v-if="!readOnly" v-model="option.useButtons" active-color="#13ce66"> </el-switch>
                  </div>

                  <!--  Android - Notification Buttons List -->
                  <div v-if="os == 'android' && option.useButtons">
                    <div class="border bg-gray-50 rounded mb-2" v-for="(button, buttonIndex) in option.buttons" :key="buttonIndex" :gutter="20">
                      <div class="px-3 py-2 border-b">
                        <span class="text-sm">Button #{{ buttonIndex + 1 }}</span>
                        <el-button v-if="!readOnly" class="float-right text-sm" size="mini" type="danger" circle icon="el-icon-delete" plain @click="removeButton(os, buttonIndex)"></el-button>
                      </div>

                      <!-- Button Container -->
                      <div class="px-3 py-2 bg-gray-50">
                        <el-row :gutter="20" class="border-0">
                          <!-- Label -->
                          <el-col :span="os == 'ios' ? 12 : 12">
                            <div class="formLabel">Button Text</div>
                            <el-input size="small" :disabled="readOnly" v-model="button.label" placeholder="Enter Button Text"></el-input>
                            <!-- <div class="text-xs text-red-500 pt-1" v-if="inputErrors.label">{{ inputErrors.label }}</div> -->
                          </el-col>

                          <!-- Action Type -->
                          <el-col :span="10" v-if="os == 'ios'">
                            <div class="formLabel">Click Action</div>
                            <el-select size="small" :disabled="readOnly" v-model="button.action">
                              <el-option label="Open App" value="open-app"></el-option>
                              <el-option label="Deeplink URL" value="deeplink"></el-option>
                              <el-option label="Dismiss Notification" value="dismiss"></el-option>
                            </el-select>
                          </el-col>
                        </el-row>

                        <!-- Deeplink URL -->
                        <div class="mt-3 overflow-hidden" v-if="button.action == 'deeplink'">
                          <div class="formLabel">Deeplink Or URL</div>
                          <div class="position-relative">
                            <el-input :id="'button-deeplink--' + os + '-' + buttonIndex + 'MPushInput'" size="small" class="w-40" :disabled="readOnly" v-model="button.deeplinkUrl" placeholder="Deeplink or URL"></el-input>
                            <el-popover v-model="button.deeplinkPopover" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                              <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'button-deeplink--' + os + '-' + buttonIndex" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                              <el-button type="text" info class="userIconBtn" slot="reference" circle>
                                <i class="el-icon-user-solid" aria-hidden="true"></i>
                              </el-button>
                            </el-popover>
                            <!-- <div class="text-xs text-red-500 pt-1" v-if="inputErrors.deeplinkUrl">{{ inputErrors.deeplinkUrl }}</div> -->
                            <div class="hint">Deeplink can be any URI (eg. myapp://productdetails/itemabc). URL can be HTTP or HTTPS (eg. https://www.growlytics.in)</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Add button -->
                    <div class="addBtnContainer">
                      <el-button type="primary" size="mini" class="addBtn" v-if="option.buttons.length < (os == 'ios' ? 2 : 3) && !readOnly" plain @click="addButton(os, option.buttons)">Add Notification Button</el-button>
                    </div>
                  </div>

                  <!-- iOS - Button - Mention Category For Buttons -->
                  <div v-if="os == 'ios' && option.useButtons">
                    <el-form-item label="Notification Category - Specified In App For Buttons">
                      <el-input :disabled="readOnly" v-model="option.category" placeholder="Enter Notification Category"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>

            <!-- Custom Key-Value Pairs -->
            <div class="keyValuePairsCard">
              <el-card class="box-card keyValuePairsCard" shadow="never" :class="{ disabled: !keyValuePairEnabled }">
                <div slot="header" class="clearfix">
                  <span>Custom Key-Value Pairs</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <el-switch v-if="!readOnly" v-model="keyValuePairEnabled" active-color="#13ce66"> </el-switch>
                  &nbsp;
                  <div v-if="!readOnly" style="display: inline-block">
                    {{ keyValuePairEnabled ? 'On' : 'Off' }}
                  </div>
                </div>

                <div class="body" v-if="keyValuePairEnabled">
                  <el-row v-show="keyValuePairList.length > 0" v-for="(pair, index) in keyValuePairList" :key="index">
                    <el-col class="inputCol" :span="24">
                      <el-form :inline="true" ref="keyValuePairListForm" class="demo-form-inline" label-position="top" :model="pair">
                        <!-- // key  -->
                        <el-form-item prop="key">
                          <el-input :disabled="readOnly" size="mini" v-model="pair.key" placeholder="Enter Key"></el-input>
                        </el-form-item>
                        =
                        <!-- // Value  -->
                        <el-form-item prop="value">
                          <el-input :id="'key_value_pair -' + index + 'MPushInput'" :disabled="readOnly" size="mini" v-model="pair.value" placeholder="Enter Value"></el-input>
                          <el-popover v-model="keyValueDynamicPopover[index]" popper-class="dynamicUserProp" trigger="click" v-if="!readOnly">
                            <DynamicUserProperty :isJourneyCampaign="isJourneyCampaign" ref="dynamicUserPropertyComponent" :allowDynamicEvents="allowDynamicEvents" v-bind:inputType="'key_value_pair -' + index" @setDynamicPropText="setDynamicProp"></DynamicUserProperty>
                            <el-button type="text" info class="userIconBtn" slot="reference" circle>
                              <i class="el-icon-user-solid" aria-hidden="true"></i>
                            </el-button>
                          </el-popover>
                        </el-form-item>
                        <div class="deleteBtn">
                          <el-button v-if="!readOnly" :disabled="readOnly" type="text" class="deleteBtn" icon="el-icon-delete" @click="keyValuePairList.splice(index, 1)"></el-button>
                        </div>
                        &nbsp;
                      </el-form>
                    </el-col>
                  </el-row>
                  <el-button v-if="!readOnly && keyValuePairList.length < 5" :disabled="readOnly" class="addBtn" @click="addKeyValuePair('keyValuePairForm')" plain>Add New Pair</el-button>
                </div>
              </el-card>
            </div>
          </el-form>
        </el-col>

        <!-- Right Side Preview -->
        <el-col :span="9">
          <div ref="mobilePreview" class="rightSidePreview">
            <div class="w-full text-center mb-3">
              <el-button class="btnSendSms center" size="mini" type="primary" @click="showSendTestNotificationDilog()"> Send Test Notification </el-button>
            </div>

            <div class="androidIosSwitch">
              <el-switch v-model="isIosPreviewMode" active-text="iPhone" inactive-text="Android"> </el-switch>
            </div>

            <!-- Mobile Preview -->
            <div class="mobileDiv">
              <!-- Android Notification -->
              <div class="notification androidNotification" v-if="!isIosPreviewMode">
                <div class="notificationHeader">
                  <div class="smallAppIcon" :style="{ background: options.android.color }"></div>
                  <div class="content">
                    <span class="appName">App Name</span>
                    <span v-if="options.android.enabled"
                      >&middot;
                      {{ options.android.summaryText }}
                    </span>
                  </div>
                  <div class="timing">&middot; now</div>
                </div>
                <div class="body">
                  <div class="content">
                    <div class="title">{{ mobilePush.title }}</div>
                    <div class="message">{{ mobilePush.message }}</div>
                  </div>
                  <div class="bigAppIcon">
                    <img src="https://static.growlytics.in/dashboard-assets/assets/img/android-mockup-icon.jpg" />
                  </div>
                </div>
                <div
                  class="notifImage"
                  v-if="mobilePush.image"
                  :style="{
                    'background-image': 'url(' + mobilePush.image + ')'
                  }"
                ></div>
                <!-- Buttons -->
                <div class="buttonsContainer" v-if="options.android.useButtons && options.android.buttons.length > 0">
                  <div class="button" :style="{ color: options.android.color }" :key="index" v-for="(button, index) in options.android.buttons">
                    {{ button.label }}
                  </div>
                </div>
              </div>

              <!-- IOS Notification -->
              <template v-else>
                <div class="notification">
                  <div class="iosNotification">
                    <div class="notificationHeader">
                      <div class="smallAppIcon">
                        <i class="fa fa-apple"></i>
                      </div>
                      <div class="appName">App Name</div>
                    </div>
                    <div
                      class="notifImage"
                      v-if="mobilePush.image"
                      :style="{
                        'background-image': 'url(' + mobilePush.image + ')'
                      }"
                    ></div>
                    <div class="body">
                      <div class="content">
                        <div class="title">{{ mobilePush.title }}</div>
                        <div class="message">{{ mobilePush.message }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- IOS - Buttons Not Applicable Due to Category Restrictions. -->
                  <!-- <div class="iosButtonsContainer mt-2 bg-white" v-if="options.ios.useButtons && options.ios.buttons.length > 0">
                    <div class="text-center text-sm py-2 border-b" :key="index" v-for="(button, index) in options.ios.buttons">
                      {{ button.label }}
                    </div>
                  </div> -->
                </div>
              </template>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- Push Notification Test dialog  -->
      <el-dialog append-to-body class="mobilePushTestDailog" :visible.sync="testPushDialog.visible" width="1000px" center title="Send a test Notification ">
        <el-form ref="pushNotificationTestForm" @submit.native.prevent>
          <!-- // Query Search  -->
          <el-form-item required>
            <!-- Header -->
            <div class="subHeader">Only users having push notification token will be available in search.</div>

            <!-- // User To Search -->
            <div class="inputDetailOfUser">
              <el-autocomplete v-model="testPushDialog.inputOfUser" class="selectAttribute" :fetch-suggestions="fetchUserListForPushContentTest" placeholder="Search User" @select="updateSelectedUserList">
                <el-select v-model="userAttributeToSearchBy" slot="prepend" placeholder="Select user property to search by">
                  <el-option label="Email" value="email"></el-option>
                  <el-option label="Mobile" value="mobile"></el-option>
                  <el-option label="Name" value="name"></el-option>
                  <el-option label="Growlytics ID" value="id"></el-option>
                </el-select>
              </el-autocomplete>
            </div>
          </el-form-item>

          <!-- Errors -->
          <div class="rounded-md bg-red-50 px-3 py-3" style="border: 1px solid red" v-if="testPushDialog.errorMessage">
            <div class="text-red-500 mb-2 text-lg font-semibold">{{ testPushDialog.errorMessage }}</div>
            <div>
              <div class="bg-red-500 text-white px-2 py-1 inline-block rounded-full mr-2" v-for="(error, errorIndex) in testPushDialog.errorList" :key="errorIndex">{{ error.statusCode }} {{ error.statusMessage }}</div>
            </div>
          </div>

          <!-- // Show User List  -->
          <div class="userListDiv" v-if="testPushDialog.selectedUserList.length > 0">
            <el-table :data="testPushDialog.selectedUserList">
              <el-table-column prop="email" label="Email">
                <template slot-scope="scope">
                  <span v-if="!scope.row.email">--</span>
                  <span v-else>{{ scope.row.email }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="Name">
                <template slot-scope="scope">
                  <span v-if="!scope.row.name">--</span>
                  <span v-else>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="id" label="Id"> </el-table-column>
              <el-table-column prop="mobile" label="Mobile">
                <template slot-scope="scope">
                  <span v-if="!scope.row.mobile">--</span>
                  <span v-else>{{ scope.row.mobile }}</span>
                </template>
              </el-table-column>
              <el-table-column width="50" label="">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="deleteRow(scope.$index, testPushDialog.selectedUserList)" type="text" size="small">
                    <el-button type="text" class="deleteBtn" icon="el-icon-delete"></el-button>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- // Test Button  -->
          <div class="sendTestBtn" v-if="testPushDialog.selectedUserList.length > 0">
            <el-button class="sendTestNotificationBtn" :loading="sendingTestNotification" type="success" size="mini" @click="testPushNotificationContent()">Send Test Notification</el-button>
          </div>
        </el-form>
      </el-dialog>
    </el-container>
  </div>
</template>

<style lang="scss" src="./mobilePushEditor.scss"></style>

<script>
import MobilePushEditorComponent from './mobilePushEditorComponent';
export default MobilePushEditorComponent;
</script>
