const moment = require('moment');
const _ = require('lodash');
import eventModule from '@/services/event';

export default {
  props: {
    graph: Object,
    name: String,
    durationGroup: String,
    duration: String,
    hidePagination: {
      required: false,
      type: Boolean,
      default: false
    },
    chartHeight: {
      required: false,
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      lineChartOptions: null,
      startTime: null,
      endTime: null,
      groupByDuration: null,

      chartObj: null,

      // Graph Variables
      pageNo: 1,
      pageSize: 10,
      graphData: null,
      categories: null,
      datasets: null
    };
  },
  watch: {
    watchProperty() {
      this.drawTimeLineGraph(this.graph);
    }
  },
  computed: {
    watchProperty() {
      return `${this.durationGroup}|${this.duration}`;
    }
  },
  methods: {
    prepareDataSets(graph) {
      this.graphData = graph.data;

      if (this.graphData === null) {
        this.lineChartOptions = null;
        return;
      }

      if (graph.durationType == 'exact') {
        this.startTime = moment(graph.startTime);
        this.endTime = moment(graph.endTime);
      } else if (graph.durationType == 'latest') {
        this.timingFilter = graph.duration;
        this.startTime = moment().subtract(this.timingFilter, 'minutes');
        this.endTime = moment();
      } else {
        throw new Error('Invalid duration type.');
      }

      this.groupByDuration = graph.durationGroup;

      for (var axisLabel in this.graphData) {
        this.graphData[axisLabel].forEach((xy) => {
          let dt = moment.utc(xy.x).format('YYYY-MM-DD HH:mm');
          xy.x = moment(dt).startOf(this.groupByDuration + 's');
        });
      }
      // console.log("drawing graph", this.groupByDuration);
      // Put empty values for missing data sets
      // console.log("starti time considered:", this.startTime.toString());
      let currentTime = this.startTime.clone().startOf(this.groupByDuration);
      let datasets = {};
      while (currentTime <= this.endTime) {
        let endTime = currentTime.clone().add(1, this.groupByDuration + 's');
        // Iterate each series and original value or zero value.
        Object.keys(this.graphData).forEach((propertyName) => {
          // If empty dataset does not exist for current property, add one
          if (!datasets[propertyName]) {
            datasets[propertyName] = {
              name: propertyName,
              data: []
            };
          }

          // check if entry exists with current time.
          let entry = _.find(this.graphData[propertyName], (xy) => {
            if (propertyName == 'Electronics') {
              // console.log(propertyName, xy.x.toString(), currentTime.toString(), endTime.toString(), moment(xy.x) >= currentTime && moment(xy.x) < endTime, xy.y);
            }
            return moment(xy.x) >= currentTime && moment(xy.x) < endTime;
          });
          // console.log(this.graphData);

          // let time = Date.UTC(currentTime.year(), currentTime.month(), currentTime.date());
          let time = parseInt(currentTime.clone().format('x'));
          if (entry == null) {
            datasets[propertyName].data.push([time, 0]);
          } else {
            datasets[propertyName].data.push([time, parseInt(entry.y)]);
          }
        });

        currentTime = endTime;
      }

      // Convert data set from key value to array
      let temp = [];
      for (var key in datasets) {
        temp.push(datasets[key]);
      }
      this.datasets = temp;
    },

    onPageChange() {
      if (this.graphData === null) {
        this.barChartOptions = null;
        return;
      }

      let start = (this.pageNo - 1) * this.pageSize;
      let end = start + 10;
      let datasets = JSON.parse(JSON.stringify(this.datasets.slice(start, end)));

      // Decide date formate based on duration selected
      let format = '%e %b';
      if (this.groupByDuration.startsWith('month')) {
        format = '%b';
      } else if (this.groupByDuration.startsWith('hour')) {
        format = '%e %I%p';
      } else if (this.groupByDuration.startsWith('minute')) {
        format = '%H:%M %P';
      }

      let chartOptions = {
        chart: {
          type: 'line',
          height: this.chartHeight
        },
        title: {
          text: null
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: `{value:${format}}`
          }
        },
        yAxis: {
          min: 0
        },
        tooltip: {
          headerFormat: '<b>{point.y} {series.name}</b><br>',
          pointFormat: '{point.x:%e %b, %I:%M %P} '
        },

        series: datasets
      };

      if (!this.lineChartOptions) {
        this.lineChartOptions = chartOptions;
      } else {
        let chart = this.$refs.lineGraph.chart;
        while (chart.series.length > 0) {
          chart.series[0].remove(false);
        }
        for (let i = 0; i < datasets.length; i++) {
          chart.addSeries(datasets[i], false);
        }
        chart.redraw();
      }
    },

    drawTimeLineGraph(graph) {
      try {
        this.prepareDataSets(graph);

        this.pageNo = 1;
        this.onPageChange();
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something is wrong, please contact support.');
      }
    },

    getDataAsTable() {
      this.tableData = [];

      // Add proeprties column
      this.tableColumns = [
        {
          headerName: 'Event/Attribute',
          prop: 'name',
          width: '200',
          fiexed: true
        }
      ];

      // Iterate and build rows for property list
      Object.keys(this.graph.data).forEach((propertyName) => {
        this.tableData.push({
          name: propertyName
        });
      });

      // Generate list of date columns from start time to end time
      let currentTime = this.startTime.clone().startOf(this.groupByDuration);
      while (currentTime <= this.endTime) {
        let endTime = currentTime.clone().add(1, this.groupByDuration + 's');

        // Create column for given date
        let headerName = currentTime.clone().format('MMM DD, hh A');
        this.tableColumns.push({
          headerName: headerName,
          prop: headerName,
          width: 150,
          fiexed: false
        });

        // For given date, add value for all table rows.
        this.tableData.forEach((row) => {
          let propertyName = row.name;

          //Read count of events for current property and time
          let count = 0;
          let entry = _.find(this.graph.data[propertyName], (xy) => {
            xy.x = moment(xy.x).format('YYYY-MM-DDTHH:mm:ss');
            return xy.x == currentTime.format('YYYY-MM-DDTHH:mm:ss');
          });

          if (entry != null) count = entry.y;

          // Add column value for current row
          row[headerName] = count;
        });
        currentTime = endTime;
      }

      return {
        columns: this.tableColumns,
        data: this.tableData
      };
    },

    async exportCSV() {
      try {
        let tableData = this.getDataAsTable();
        let result = await eventModule.exportEventDynamicTimelineGraphData(tableData);

        if (result.data.success) {
          window.open(result.data.url, '_blank');
        } else {
          this.errorToast('Failed to export report. Please contact support!');
          this.reportError('FAILED TO EXPORT Time Line Graph Report:' + JSON.stringify(result.data));
        }
      } catch (err) {
        this.errorToast('Failed to export report. Please contact support!');
        this.reportError(err);
      }
    }
  },

  mounted() {
    this.drawTimeLineGraph(this.graph);
  }
};
