<template>
  <el-dialog custom-class="productSelectionPopup" :append-to-body="true" title="Select Products From Catalog" width="1200px" :visible.sync="popupVisible" direction="btt">
    <el-row :gutter="20" class="popupGrid">
      <el-col :span="onlySingleSelectMode ? 24 : 16" class="productListColumn">
        <el-card>
          <div slot="header" class="productListHeader">
            Product Catalog ({{ totalProducts }})
            <!-- <el-select size="mini" placeholder="Filter By Collection"></el-select> -->
            <el-input class="searchBox" v-model="searchKeyword" prefix-icon="el-icon-search" @input="onSearch" placeholder="Search Product" size="mini">Search Product</el-input>
          </div>

          <div class="loader" v-if="fetchingData" v-loading="true"></div>
          <el-row v-else :gutter="10">
            <el-col :span="onlySingleSelectMode ? 8 : 12" v-for="(product, index) in productList" :key="product.id">
              <div class="product-item" @click="toggleProductSelection(index)">
                <!-- Product Select Radio Box -->
                <div class="productSelector" v-if="!product.selected"></div>
                <div class="productSelected" v-else>
                  <i class="el-icon-check"></i>
                </div>

                <!-- Product Image -->
                <div class="productImage" :style="{ 'background-image': `url(${product.image})` }"></div>

                <!-- Product Name & Pricing -->
                <div class="productRightSide">
                  <div class="productName">{{ product.name ? product.name.substring(0, 85) + (product.name.length > 100 ? '...' : '') : product.name }}</div>
                  <div class="productPrice">INR {{ product.price }}</div>
                </div>
              </div>
            </el-col>
            <el-col :span="24" v-if="totalProducts > pageSize">
              <div class="pagination">
                <div class="totalCount">Total {{ totalProducts }} Products</div>
                <el-pagination @current-change="onPageChange()" :current-page.sync="pageNo" :page-size="pageSize" layout="prev, pager, next" :total="totalProducts"></el-pagination>
              </div>
            </el-col>
          </el-row>
        </el-card>
        <div class="productListHeader"></div>
      </el-col>
      <el-col :span="8" class="rightSide" v-if="!onlySingleSelectMode">
        <el-card>
          <div slot="header" class="productListHeader">Selected Products ({{ selectedProducts.length }})</div>

          <div class="selectedProductList">
            <div class="product-item" v-for="product in selectedProducts" :key="product.id">
              <!-- Product Select Radio Box -->
              <div class="productRemove" @click="removeSelectedProduct(product)">
                <i class="el-icon-delete"></i>
              </div>

              <!-- Product Image -->
              <div class="productImage" :style="{ 'background-image': `url(${product.image})` }"></div>

              <!-- Product Name & Pricing -->
              <div class="productRightSide">
                <div class="productName">{{ product.name ? product.name.substring(0, 85) + (product.name.length > 100 ? '...' : '') : product.name }}</div>
                <div class="productPrice">INR {{ product.price }}</div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">Cancel</el-button>
      <el-button size="small" type="primary" @click="onSubmit">Add Products</el-button>
    </span>
  </el-dialog>
</template>

<style lang="scss" src="./productSelectionPopup.scss"></style>

<script>
import productSelectionPopupComponent from './productSelectionPopupComponent';
export default productSelectionPopupComponent;
</script>
