import { Picker } from 'emoji-mart-vue';
import _ from 'lodash';
import customerModule from '../../../../../services/customer';
import campaignPreviewService from '@/services/campaignMessagePreview';
import DynamicUserProperty from '@/components/dynamicUserFilterComponent/dynamicUserFilter';
import ImageManager from '@/components/campaign/contentBuilder/imageUpload/imageUpload';

import campaignService from '@/services/campaign';

export default {
  name: 'webPushEditor',
  components: {
    Picker,
    DynamicUserProperty,
    ImageManager
  },
  props: {
    providerId: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    allowDynamicEvents: {
      type: Boolean,
      required: false,
      default: true
    },
    isJourneyCampaign: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      contentAlreadyRendered: false,

      // dynamicuserFilter popover  variables
      titleDynmicUserPopver: false,
      messageDynmicUserPopver: false,
      deepLinkDynmicUserPopver: false,
      imageDynmicUserPopver: false,

      // emoji popover variables
      titleEmojiPiker: false,
      messageEmojiPiker: false,

      // WebPush Priview variable
      selectWebPushPriview: 'chromeWindows10',
      priviewMode: [
        {
          value: 'chromeWindows10',
          key: 'Chrome Windows 10'
        },
        {
          value: 'chromeMac',
          key: 'Chrome Mac'
        },
        {
          value: 'chromeAndroid',
          key: 'Chrome Android'
        },
        {
          value: 'fireFoxMac',
          key: 'FireFox Mac'
        },
        {
          value: 'fireFoxWindow',
          key: 'FireFox Window'
        }
      ],

      // Content Builder Variables
      webPush: {
        providerId: null,
        title: 'Notification Title Goes Here 😋',
        titleSfghjk: 'asdfghbjnkm',
        message: 'Notification Message Goes Here 😂',
        image: 'https://images.unsplash.com/photo-1573117425812-87a41504bc34',
        deepLinkUrl: '',
        icon: 'https://app.growlytics.in/static/img/logo/logo_dark.png'
      },
      sendingTestNotification: false,

      // testing Dailog variables
      userAttributeToSearchBy: 'email',
      selectedUserList: [],
      queryString: '',
      inputOfUser: '',

      //webPush Form validation
      webPushFormRules: {
        title: [
          {
            required: true,
            message: 'Please input notification title',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 300,
            message: 'Length should be 3 to 300',
            trigger: 'blur'
          }
        ],
        message: [
          {
            required: true,
            message: 'Please input notification message',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 1000,
            message: 'Length should be 3 to 1000',
            trigger: 'blur'
          }
        ],
        icon: [
          {
            required: true,
            message: 'Please input icon image url',
            trigger: 'blur'
          },
          {
            max: 5000,
            message: 'Maximum 5000 characters allowed.',
            trigger: 'blur'
          }
        ],
        deepLinkUrl: [
          {
            max: 5000,
            message: 'Maximum 5000 characters allowed.',
            trigger: 'blur'
          }
        ],
        image: [
          {
            max: 5000,
            message: 'Maximum 5000 characters allowed.',
            trigger: 'blur'
          }
        ]
      },

      // Push Notification Test Variables
      showWebPushNotificationTestPopup: false
    };
  },

  methods: {
    setDynamicProp(text, inputType) {
      // Fetch Current Position
      let elementId = inputType + 'WPushInput';
      let position = document.getElementById(elementId).selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      if (inputType == 'title') {
        this.webPush.title = insert(this.webPush.title, position, text);
        this.titleDynmicUserPopver = false;
      } else if (inputType == 'message') {
        this.webPush.message = insert(this.webPush.message, position, text);
        this.messageDynmicUserPopver = false;
      } else if (inputType == 'deepLinkUrl') {
        this.webPush.deepLinkUrl = insert(this.webPush.deepLinkUrl, position, text);
        this.deepLinkDynmicUserPopver = false;
      } else if (inputType == 'image') {
        this.webPush.image = insert(this.webPush.image, position, text);
        this.imageDynmicUserPopver = false;
      }
    },

    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    onTitleEmojiSelected(emoji) {
      // Fetch Current Position
      let position = document.getElementById('titleWPushInput').selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      this.webPush.title = insert(this.webPush.title, position, emoji.native);
      this.titleEmojiPiker = false;
    },

    onMessageEmojiSelected(emoji) {
      // Fetch Current Position
      let position = document.getElementById('messageWPushInput').selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      this.webPush.message = insert(this.webPush.message, position, emoji.native);
      this.messageEmojiPiker = false;
    },

    onWebPushImageUpload(obj, prop, img) {
      obj[prop] = img.url;
    },

    /**************************** Start: Push Notification Test Methods *********************************/

    async testPushNotificationContent() {
      if (!this.webPush.providerId) {
        this.errorToast('Web Push provider not configured');
        return;
      }
      try {
        this.sendingTestNotification = true;
        let processedData = this.readProcessedContentV1();
        let params = {
          customerList: this.selectedUserList,
          pushContent: processedData.content
        };
        let result = await campaignPreviewService.sendWebPushCampaignPreview(params);

        if (result.data.success) {
          this.successToast(`Push notification sent.`);
          this.showWebPushNotificationTestPopup = false;
        }
        this.sendingTestNotification = false;
      } catch (e) {
        console.error(e);
        this.errorToast(`Something went wrong.`);
        this.sendingTestNotification = false;
      }
    },

    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },

    fetchUserListForPushContentTest(queryString, cb) {
      let params = {
        queryString: queryString,
        customerProp: this.userAttributeToSearchBy,
        pushType: 'web'
      };

      let results = [];
      customerModule
        .fetchUserSuggestionListForPushTest(params, this)
        .then((result) => {
          if (result.data.success) {
            results = queryString ? result.data.data.filter(this.createFilter(queryString)) : result.data.data;
          }
          cb(results);
        })
        .catch((e) => {
          console.error(e);
          this.errorToast(`Something went wrong. Please try again later.`);
          cb(results);
        });
    },

    updateSelectedUserList(item) {
      let user = _.find(this.selectedUserList, (u) => {
        return u.value == item.value;
      });
      if (!user) {
        this.selectedUserList.push(item);
      }
      this.inputOfUser = '';
    },

    async showWebPushContentTestForm() {
      let isValidData = await this.validateForm();
      if (isValidData) {
        this.showWebPushNotificationTestPopup = true;
      }
    },

    async showSendTestNotificationDilog() {
      await this.showWebPushContentTestForm();
    },

    /**************************** End: Push Notification Test Methods *********************************/

    async validateForm() {
      return new Promise((resolve) => {
        // Validate provider first.
        if (this.webPush.providerId == null) {
          this.warningToast('Webpush provider integration is not configured yet.');
          resolve(false);
          return;
        }

        this.$refs['webPushForm'].validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    readProcessedContentV1() {
      return {
        providerId: this.webPush.providerId,
        content: {
          webPushTitle: this.webPush.title,
          web_push_title: this.webPush.title,
          web_push_message: this.webPush.message,
          web_push_image: this.webPush.image,
          web_push_icon: this.webPush.icon,
          web_push_deeplink_url: this.webPush.deepLinkUrl
        }
      };
    },

    renderCampaignContent(data) {
      if (!this.contentAlreadyRendered) {
        this.contentAlreadyRendered = true;

        this.fetchWebPushVapidProviderDetails();

        this.webPush.providerId = data.providerId;

        if (!data.content) return null;

        // WebPush Notification Data
        this.webPush.title = data.content.web_push_title;
        this.webPush.message = data.content.web_push_message;
        this.webPush.image = data.content.web_push_image;
        this.webPush.icon = data.content.web_push_icon;
        this.webPush.deepLinkUrl = data.content.web_push_deeplink_url;
      }
    },

    renderCampaignContentForJourney(data) {
      if (data.content) {
        let newContent = {};
        newContent.web_push_title = data.content.webPushTitle ? data.content.webPushTitle : data.content.web_push_title;
        newContent.web_push_message = data.content.webPushMessage ? data.content.webPushMessage : data.content.web_push_message;
        newContent.web_push_image = data.content.webPushImage ? data.content.webPushImage : data.content.web_push_image;
        newContent.web_push_icon = data.content.webPushIcon ? data.content.webPushIcon : data.content.web_push_icon;
        newContent.web_push_deeplink_url = data.content.webPushDeeplinkUrl ? data.content.webPushDeeplinkUrl : data.content.web_push_deeplink_url;
        data.content = newContent;
      }
      this.renderCampaignContent(data);
    },

    async fetchWebPushVapidProviderDetails() {
      try {
        let params = {
          channel: 'webPush',
          readArchived: this.readOnly
        };
        let result = await campaignService.fetchChannelProviderList(params, this);

        if (result.data.success) {
          if (result.data.data.length == 0) {
            this.warningToast('Web push provider not configured.');
          } else {
            this.webPush.providerId = result.data.data[0].id;
          }
        }
      } catch (e) {
        console.error(e);
        this.errorToast('Something went wrong');
      }
    }
  }
};
