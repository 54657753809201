import channelIntegrationService from '@/services/channelIntegration';

export default {
  name: 'segmentRemarketingPopup',
  props: {
    channel: {
      type: String,
      requried: true
    }
  },

  data() {
    return {
      visible: false,
      savingData: false,
      providerInfo: null,

      form: {
        name: '',
        description: null
      },

      // Form validation
      formRules: {
        name: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur'
          }
        ]
      }
    };
  },

  methods: {
    showDialog(providerId, providerInfo) {
      this.form = {
        name: '',
        description: null,
        providerId: providerId
      };

      this.providerInfo = providerInfo;

      this.visible = true;
    },

    async validateForm() {
      return new Promise((resolve) => {
        this.$refs['fbAudienceCreate'].validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    async onCreate() {
      try {
        // Validate Form.
        let isValid = await this.validateForm();
        if (!isValid) return;

        this.savingData = true;

        // Create
        let response = await channelIntegrationService.createFacebookAudience(this.form);
        this.successToast('Facebook Audience Created.');
        console.log('Create response', response.data);

        this.visible = false;
        this.$emit('success', { id: response.data.id });
      } catch (err) {
        this.reportError(err);
        this.errorToast(`Something's wrong. Please contact support.`);
      } finally {
        this.savingData = false;
      }
    }
  },

  mounted() {}
};
