// let width = 220;
// let height = 60;
let portBaseStructure = {
  group: 'sent',
  label: {
    position: {
      args: {
        y: 140
      }
    }
  },
  attrs: {
    '.joint-port-body': { y: 140 },
    text: {
      text: 'On Sent'
    }
  }
};

let commonPortList = [
  {
    group: 'send-in',
    label: {
      position: {
        args: {
          y: 12
        }
      }
    },
    attrs: {
      text: {
        text: ''
      }
    }
  }
];

let buildList = function (portInfo, startPosition) {
  let emailActionPortList = JSON.parse(JSON.stringify(commonPortList));
  for (let i = 0; i < portInfo.length; i++) {
    // Build Port
    let port = JSON.parse(JSON.stringify(portBaseStructure));
    port.group = portInfo[i].group;
    port.label.position.args.y = startPosition;
    port.attrs.text.text = portInfo[i].title;
    port.attrs['.joint-port-body'].y = startPosition - 10;

    startPosition += 20;
    emailActionPortList.push(port);
  }
  return emailActionPortList;
};

// Build Email Action Ports
let emailActionPortInfo = [
  { group: 'sent', title: 'After Send' },
  { group: 'opened', title: 'On Opened' },
  { group: 'notOpened', title: `Din't Open` },
  { group: 'clicked', title: 'On Clicked' },
  { group: 'notClicked', title: `Din't Click` }
];
exports.emailActionPortList = buildList(emailActionPortInfo, -30);

// Build Email Action Ports
let updateUserActionPortInfo = [{ group: 'afterUpdate', title: 'After Update' }];
let adNetworkActionPortInfo = [{ group: 'afterUpdate', title: 'After Update' }];
exports.updateUserActionPortList = buildList(updateUserActionPortInfo, 10);
exports.adNetworkActionPortList = buildList(adNetworkActionPortInfo, 10);

// Non Emails list;
let restActionPortInfo = [
  { group: 'sent', title: 'After Send' },
  { group: 'clicked', title: 'On Clicked' },
  { group: 'notClicked', title: `Din't Click` }
];
exports.restActionPortList = buildList(restActionPortInfo, -10);

// Start Node
let info = [{ group: 'triggered', title: 'Triggered' }];
exports.startNodePortList = buildList(info, 10);

// Wait Time Node
info = [{ group: 'timeout', title: 'After Wait' }];
exports.waitTimeNodePortList = buildList(info, 10);

// Wait Event Node
info = [
  { group: 'triggered', title: 'Triggered' },
  { group: 'timeout', title: 'Timeout' }
];
exports.waitEventNodePortList = buildList(info, 0);

// Yes/No Ports
info = [
  { group: 'yes', title: 'Yes' },
  { group: 'no', title: 'No' }
];
exports.yesNoNodePortList = buildList(info, 0);

// Ports For a/b split
exports.buildAbSplitPortList = function (branchNames) {
  let portList = [];
  for (let i = 0; i < branchNames.length; i++) {
    portList.push({ group: branchNames[i].id, title: branchNames[i].name + ` (${branchNames[i].percentage}%)` });
  }
  let yPosition = (branchNames.length - 2) * -10;
  return buildList(portList, yPosition);
};
