import customerServices from '../../services/customer';
import eventModule from '../../services/event';
import couponModule from '../../services/coupon';

export default {
  name: 'dynamicUserFilter',
  props: {
    inputType: String,
    showUnsubscribe: {
      type: Boolean,
      required: false,
      default: false
    },
    allowDynamicEvents: {
      type: Boolean,
      required: false,
      default: true
    },
    isJourneyCampaign: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      selectedDynamicProp: [],
      dynamicGrwProperty: [
        {
          value: 'user',
          label: 'User Property',
          children: []
        },
        {
          value: 'event',
          label: 'Events',
          children: []
        },
        {
          value: 'coupon',
          label: 'Coupons',
          children: []
        },
        {
          value: 'cart',
          label: 'Cart',
          children: [
            {
              value: 'platform_cart_id',
              label: 'Cart Id'
            },
            {
              value: 'cart_amount',
              label: 'Cart Amount'
            },
            {
              value: 'platform_cart_abandoned_url',
              label: 'Cart Abandoned URL'
            },
            {
              value: 'platform_cart_abandoned_url_path',
              label: 'Cart Abandoned URL Path'
            },
            {
              value: 'cart_item_count',
              label: 'Cart Products Count'
            },
            {
              value: 'cart_image',
              label: 'Cart Image'
            },
            {
              value: 'cart_product_name',
              label: 'Cart Product Name'
            },
            {
              value: 'product_summary_for_whatsapp',
              label: 'Product Summary For WhatsApp'
            }
          ]
        }
      ],
      userPropertyList: [],
      eventList: [],
      couponList: [],
      eventMetadata: {}
    };
  },

  methods: {
    onSelectProp() {
      let str = null;
      if (this.selectedDynamicProp[0] == 'unsubscribe') {
        str = 'unsubscribe';
      } else {
        let rootType = this.selectedDynamicProp[0];
        let rootValue = this.selectedDynamicProp[1];
        str = `{{${rootType}[${rootValue}]`;

        if (this.selectedDynamicProp.length > 2) {
          str += '[' + this.selectedDynamicProp[2] + ']';
        }

        str += "|default:''}}";
      }
      this.$emit('setDynamicPropText', str, this.inputType);
      this.selectedDynamicProp = [];
    },

    fetchEventList() {
      eventModule
        .getEventList(this)
        .then((result) => {
          this.eventMetadata = result.data.data.eventList;
          this.eventList = Object.keys(result.data.data.eventList);
          for (let i = 0; i < this.eventList.length; i++) {
            let temp = {
              value: this.eventList[i],
              label: this.eventList[i],
              children: []
            };
            let eventProperty = this.eventMetadata[this.eventList[i]];
            for (let j = 0; j < eventProperty.length; j++) {
              temp.children.push({
                value: eventProperty[j].name,
                label: eventProperty[j].name
              });
            }
            this.dynamicGrwProperty[1].children.push(temp);
          }
        })
        .catch((err) => {
          this.reportError(err);
        });
    },

    fetchUserPropertyList() {
      customerServices
        .fetchUserPropertyList(null, this)
        .then((result) => {
          if (result.data.success) {
            this.userPropertyList = result.data.data;
            for (let k = 0; k < this.userPropertyList.length; k++) {
              let temp = {
                value: this.userPropertyList[k].name,
                label: this.userPropertyList[k].name
              };
              this.dynamicGrwProperty[0].children.push(temp);
            }
          }
        })
        .catch((error) => {
          this.reportError(error);
        });
    },

    async fetchCouponList() {
      try {
        let result = await couponModule.fetchCouponList({}, this);
        this.couponList = result.data.list;
        this.dynamicGrwProperty[2].children = [];
        for (let k = 0; k < this.couponList.length; k++) {
          let temp = {
            value: this.couponList[k].name,
            label: this.couponList[k].name,
            children: [
              { label: 'Coupon Code', value: 'Coupon Code' },
              { label: 'Expiry Time', value: 'Expiry Time' }
            ]
          };
          this.dynamicGrwProperty[2].children.push(temp);
        }
      } catch (error) {
        this.reportError(error);
      }
    }
  },

  mounted() {
    // Add Unsubscribe link if provided.
    if (this.showUnsubscribe) {
      this.dynamicGrwProperty.push({
        value: 'unsubscribe',
        label: 'Unsubscribe Link'
      });
    }

    if (this.allowDynamicEvents) {
      this.fetchEventList();
    } else {
      // Remove from event list
      this.dynamicGrwProperty.splice(1, 1);
    }
    this.fetchUserPropertyList();
    this.fetchCouponList();
  }
};
