const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.getChannelIntegrationStauts = () => {
  return axios.get(baseUrlNew + 'channel/status', {
    headers: buildAuthHeaders()
  });
};

exports.getFacebookAudienceList = (providerId) => {
  return axios.get(baseUrlNew + `channel/facebook/${providerId}`, {
    headers: buildAuthHeaders()
  });
};

exports.createFacebookAudience = (params) => {
  return axios.post(baseUrlNew + `channel/facebook`, params, {
    headers: buildAuthHeaders()
  });
};
