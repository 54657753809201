<template>
  <div class="dragDropEditor">
    <!-- Left Side -->
    <el-tabs v-model="selectedLeftSideTab" class="leftSide" type="border-card">
      <!-- Mail Options Tab -->
      <el-tab-pane class="generalTab settingsTab" label="General" name="general">
        <el-collapse v-model="selectedMailOption" accordion>
          <!-- Gemeral Mail Options Options -->
          <el-collapse-item class="blockOptionsContainer" title="Mail General Options" name="general">
            <el-row :gutter="20" class="blockOptionsContainer rowOptions px-2">
              <!-- Row - Background Color -->
              <el-col :span="12" class="optionItem">
                <label class="label"> Mail Background color </label>
                <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                  <div :style="{ background: mailOptions.background }">
                    <color-picker :color="mailOptions.background" theme="light" @changeColor="setColor(mailOptions, 'background', arguments)" />
                  </div>
                  <div class="colorButton" slot="reference">
                    <span class="colorPreview" v-if="mailOptions.background" :style="{ 'background-color': mailOptions.background + ' !important', border: 'none', color: 'white !important' }"></span>
                    <span class="colorValue">{{ mailOptions.background ? mailOptions.background : 'Transparent' }}</span>
                  </div>
                </el-popover>
              </el-col>
              <el-col :span="12" class="optionItem">
                <label class="label">Email Width</label>
                <el-radio-group v-model="mailOptions.widthInfo.type">
                  <el-radio-button label="full">100%</el-radio-button>
                  <el-radio-button label="custom">Custom</el-radio-button>
                </el-radio-group>
              </el-col>
              <!-- Email Width Option -->
              <el-col :span="24" class="sliderContainer">
                <label class="label"> Email Width ({{ mailOptions.widthInfo.width }}px) </label>
                <el-slider class="slider" :min="550" :max="1280" :step="10" v-model="mailOptions.widthInfo.width"> </el-slider>
              </el-col>
            </el-row>
          </el-collapse-item>
          <!--  Mail Options - Font Settings -->
          <el-collapse-item class="blockOptionsContainer emailFontSettingsOptionsContainer" title="Font Styling" name="font-styling">
            <div class="overflow-hidden rounded mb-3 shadow-sm" style="border: 1px solid #f1f1f1" v-for="(value, key) in mailOptions.textStyle" :key="key">
              <div class="py-1 px-2 min-w-full bg-gray-50" style="border-bottom: 1px solid #f1f1f1">{{ value.label }}</div>
              <div class="p-2 bg-gray-50">
                <EmailFontStyleBuilder :isJourneyCampaign="isJourneyCampaign" ref="emailFontStyleBuilder" :inputData.sync="mailOptions.textStyle[key]"></EmailFontStyleBuilder>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>

      <!-- Row Settings Tab -->
      <el-tab-pane class="settingsTab" label="Settings" name="settings" v-if="selectedBlock">
        <el-collapse v-model="selectedLeftSiteSettingsScope" accordion @change="onBlockSettingCollapseSectionChange">
          <!-- Row Options -->
          <el-collapse-item class="blockOptionsContainer" title="Background & Spacing" name="row">
            <el-row :gutter="20" class="rowOptions px-2">
              <!-- Row- Image Selector -->
              <el-col :span="24" class="optionItem">
                <label class="label"> Row Background Image </label>
                <div class="imagePreview" v-if="selectedRow.content.rowBackgroundImage"></div>
                <div class="imageSelector">
                  <ImageManager @onSelect="onImageUpload(selectedRow, 'rowBackgroundImage', ...arguments)" ref="rowImageManager"></ImageManager>
                  <el-image style="width: 100%; height: 150px" :src="selectedRow.content.rowBackgroundImage" fit="contain">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <el-row class="imageButtons" :gutter="10">
                    <el-col :span="selectedRow.content.rowBackgroundImage ? 12 : 24">
                      <el-button size="small" type="primary" plain @click="showImageuploader('rowImageManager')">
                        <template v-if="selectedRow.content.rowBackgroundImage">Change</template>
                        <template v-else>Select</template>
                        Image
                      </el-button>
                    </el-col>
                    <el-col :span="12" v-if="selectedRow.content.rowBackgroundImage">
                      <el-button size="small" type="danger" plain @click="selectedRow.content.rowBackgroundImage = null"> Remove Image </el-button>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <!-- Row - Background Color -->
              <el-col :span="12" class="optionItem">
                <label class="label"> Row Background Color </label>

                <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                  <div :style="{ background: selectedRow.content.rowBackgroundColor }">
                    <color-picker :color="selectedRow.content.rowBackgroundColor" theme="light" @changeColor="setColor(selectedRow.content, 'rowBackgroundColor', arguments)" />
                  </div>
                  <div class="colorButton" slot="reference">
                    <span class="colorPreview" v-if="selectedRow.content.rowBackgroundColor" :style="{ 'background-color': selectedRow.content.rowBackgroundColor + ' !important', border: 'none', color: 'white !important' }"></span>
                    <span class="colorValue">{{ selectedRow.content.rowBackgroundColor ? selectedRow.content.rowBackgroundColor : 'Transparent' }}</span>
                  </div>
                </el-popover>
              </el-col>

              <!-- Row - Device Visiblity -->
              <el-col :span="12" class="optionItem">
                <label class="label"> Device Visiblity </label>
                <el-select v-model="selectedRow.content.deviceVisiblity">
                  <el-option label="All Devices" value="all"></el-option>
                  <el-option label="Mobile Only" value="mobile"></el-option>
                  <el-option label="Desktop Only" value="desktop"></el-option>
                </el-select>
              </el-col>

              <!-- Row - Padding -->
              <el-col :span="24" class="optionItem paddingContainer" v-if="selectedBlock.content.margin">
                <label class="label"> Block Padding </label>
                <el-row :gutter="10">
                  <el-col :span="6">
                    <el-input-number controls-position="right" v-model="selectedBlock.content.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
                    <label class="label bottom">Top</label>
                  </el-col>
                  <el-col :span="6">
                    <el-input-number controls-position="right" v-model="selectedBlock.content.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
                    <label class="label bottom">Bottom</label>
                  </el-col>
                  <el-col :span="6">
                    <el-input-number controls-position="right" v-model="selectedBlock.content.margin.left" :min="0" :max="300" size="mini"></el-input-number>
                    <label class="label bottom">Left</label>
                  </el-col>
                  <el-col :span="6">
                    <el-input-number controls-position="right" v-model="selectedBlock.content.margin.right" :min="0" :max="300" size="mini"></el-input-number>
                    <label class="label bottom">Right</label>
                  </el-col>
                </el-row>
              </el-col>

              <!-- Columns - Split On Mobile.  -->
              <el-col :span="12" class="pt-2" v-if="selectedRow && selectedRow.type.startsWith('columns-')">
                <label class="label"> Keep Columns Mobile Responsive </label>
                <el-radio-group size="small" style="display: block; width: 100%" v-model="selectedRow.content.dontKeepMobileResponsive">
                  <el-radio-button :label="false">Yes</el-radio-button>
                  <el-radio-button :label="true">No</el-radio-button>
                </el-radio-group>
              </el-col>

              <!-- Columns - Column Vertical Align Mobile.  -->
              <el-col :span="12" class="pt-2" v-if="selectedRow && selectedRow.type.startsWith('columns-')">
                <label class="label"> Grid Column Vertical Align </label>
                <el-radio-group size="small" style="display: block; width: 100%" v-model="selectedRow.content.columnContentVerticalAlign">
                  <el-radio-button label="top">Top</el-radio-button>
                  <el-radio-button label="middle">Center</el-radio-button>
                  <el-radio-button label="bottom">Bottom</el-radio-button>
                </el-radio-group>
              </el-col>
            </el-row>
          </el-collapse-item>

          <!-- Product Sets Options -->
          <template v-if="selectedBlock.type == 'product'">
            <!-- Product Set Styling -->
            <el-collapse-item class="blockOptionsContainer productFilters" title="Product Styling" name="block">
              <el-row :gutter="20" class="rowOptions">
                <el-col :span="12" class="optionItem">
                  <label class="label"> Total Rows </label>
                  <el-select size="small" v-model="selectedBlock.content.rows" @change="changeBlockConents(true)">
                    <el-option v-for="(rows, index) in [1, 2, 3]" :key="index" :label="rows" :value="rows"> </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12" class="optionItem">
                  <label class="label"> Columns Per Row </label>
                  <el-select size="small" v-model="selectedBlock.content.cols" @change="changeBlockConents(true)">
                    <el-option v-for="(cols, index) in [1, 2, 3]" :key="index" :label="cols" :value="cols"> </el-option>
                  </el-select>
                </el-col>
                <!-- Button Colors -->
                <el-col :span="12">
                  <label class="label"> Product Name Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.productNameColor }">
                      <color-picker :color="selectedBlock.content.productNameColor" theme="light" @changeColor="setColor(selectedBlock.content, 'productNameColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.productNameColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.productNameColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Button Color -->
                <el-col :span="12">
                  <label class="label"> Product Price Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.productPriceColor }">
                      <color-picker :color="selectedBlock.content.productPriceColor" theme="light" @changeColor="setColor(selectedBlock.content, 'productPriceColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.productPriceColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.productPriceColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <el-col :span="12" class="optionItem">
                  <label class="label">Show Product Price</label>
                  <el-radio-group v-model="selectedBlock.content.showPrice" style="width: 100%">
                    <el-radio-button :label="true">Yes</el-radio-button>
                    <el-radio-button :label="false">No</el-radio-button>
                  </el-radio-group>
                </el-col>
                <el-col :span="12" class="optionItem">
                  <label class="label">Show Product Button</label>
                  <el-radio-group v-model="selectedBlock.content.button.visible">
                    <el-radio-button :label="true">Yes</el-radio-button>
                    <el-radio-button :label="false">No</el-radio-button>
                  </el-radio-group>
                </el-col>
                <!-- Button Text -->
                <el-col :span="24">
                  <label class="label"> Button Text </label>
                  <el-input v-model="selectedBlock.content.button.text" type="text"></el-input>
                </el-col>
                <!-- Button Colors -->
                <el-col :span="12">
                  <label class="label"> Button Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.textColor }">
                      <color-picker :color="selectedBlock.content.button.textColor" theme="light" @changeColor="setColor(selectedBlock.content.button, 'textColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.textColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Button Color -->
                <el-col :span="12">
                  <label class="label"> Button Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.buttonColor }">
                      <color-picker :color="selectedBlock.content.button.buttonColor" theme="light" @changeColor="setColor(selectedBlock.content.button, 'buttonColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.buttonColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.buttonColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Font Size -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Button Font Size </label>
                  <el-slider class="slider" :min="10" :max="20" v-model="selectedBlock.content.button.fontSize"> </el-slider>
                </el-col>
                <!-- Border Radius -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Button Radius </label>
                  <el-slider class="slider" :min="0" :max="50" v-model="selectedBlock.content.button.radius"> </el-slider>
                </el-col>
                <!-- Button - Text Padding -->
                <el-col :span="24" class="paddingContainer">
                  <label class="label"> Button Text Padding</label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.top" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.bottom" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.left" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.right" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-collapse-item>

            <!-- Product Filters -->
            <el-collapse-item class="blockOptionsContainer collectionFilters" title="Product Filters" name="filters">
              <el-row :gutter="20" class="rowOptions">
                <!-- All Products / Specific Collections -->
                <el-col :span="24">
                  <label class="label"> Product Source </label>
                  <el-select v-model="selectedBlock.content.productInfo.filterType" @change="changeBlockConents()">
                    <el-option label="All Products" value="all"></el-option>
                    <el-option label="Select Products Manually" value="manual"></el-option>
                    <el-option label="Recent Products Viewed By Visitor" value="recent"></el-option>
                  </el-select>
                </el-col>

                <el-col :span="24" class="optionItem" style="margin-top: 5px"></el-col>

                <!-- Show collections to include/exlude -->
                <template v-if="selectedBlock.content.productInfo.filterType == 'all'">
                  <!-- Collections To Include -->
                  <el-col :span="24" class="optionItem">
                    <label class="label"> Limit To Collections </label>
                    <el-select filterable v-model="selectedCollectionToInclude" @change="onIncludeCollection" placeholder="Select Collection To Include">
                      <el-option v-for="collection in productCollectionList" :key="collection.id" :label="collection.name" :value="collection"> </el-option>
                    </el-select>
                    <div class="collectionList" v-if="selectedBlock.content.productInfo.include.length != 0">
                      <el-row class="collectionItem" v-for="(coll, index) in selectedBlock.content.productInfo.include" :key="index">
                        <el-col :span="23">
                          {{ coll.name }}
                        </el-col>
                        <el-col :span="1">
                          <i class="el-icon-delete deleteBtn" @click="onRemoveIncludedCollection(index)"></i>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </template>
                <template v-else-if="selectedBlock.content.productInfo.filterType == 'manual'">
                  <!-- Show Products to include -->
                  <el-col :span="24" class="optionItem">
                    <label class="label"> Search & Add Products</label>
                    <el-select v-model="selectedProductToAdd" filterable remote placeholder="Search Product To Add" :remote-method="fetchProductsWithKeyword" :loading="productSearchLoader" @change="onAddProductToList">
                      <el-option v-for="(product, index) in productSearchedList" :key="index" :label="product.name" :value="product"> </el-option>
                    </el-select>
                    <div class="collectionList" v-if="selectedBlock.content.productInfo.products.length != 0">
                      <!-- Icon List - Iterate -->
                      <draggable v-model="selectedBlock.content.productInfo.products" group="manualProductList" @start="imageListDrag = true" @end="imageListDrag = false" v-bind="dragOptions" handle=".manualProductDragContainer" @change="changeBlockConents(false)">
                        <transition-group type="transition" :name="!imageListDrag ? 'flip-list' : null">
                          <el-row class="collectionItem" v-for="(prod, indexForManualProd) in selectedBlock.content.productInfo.products" :key="indexForManualProd">
                            <el-col :span="1">
                              <i class="socialIconImage cursor-pointer fa fa-exchange fa-rotate-90 manualProductDragContainer" aria-hidden="true"></i>
                            </el-col>
                            <el-col :span="22">
                              {{ prod.name }}
                            </el-col>
                            <el-col :span="1">
                              <i class="el-icon-delete deleteBtn" @click="onRemoveProductFromList(indexForManualProd)"></i>
                            </el-col>
                          </el-row>
                        </transition-group>
                      </draggable>
                    </div>
                  </el-col>
                </template>
                <template v-else>
                  <div style="color: #8f9fae; font-size: 12px; padding: 80px 10px 1px 10px">List of products which particular visitor has visited will be shown.</div>
                </template>
              </el-row>
            </el-collapse-item>

            <!-- Product Ordering -->
            <el-collapse-item class="blockOptionsContainer productFilters" title="Product Display Order" name="ordering">
              <el-row :gutter="20" class="rowOptions" v-if="selectedBlock.content.productInfo.filterType != 'recent'">
                <el-col :span="24" class="optionItem">
                  <label class="label"> Product Display Ordering </label>
                  <el-select size="small" v-model="selectedBlock.content.productInfo.sort" @change="changeBlockConents()">
                    <el-option v-for="(priority, key) in productDisplayPriorityList" :key="key" :label="priority.title" :value="key"> </el-option>
                  </el-select>
                  <div class="hint">{{ productDisplayPriorityList[selectedBlock.content.productInfo.sort].description }}</div>
                </el-col>
                <el-col :span="24" class="optionItem">
                  <label class="label"> Data Point For Ordering </label>
                  <el-select size="small" v-model="selectedBlock.content.productInfo.event" @change="changeBlockConents()">
                    <el-option v-for="(dataPoint, key) in productDisplayPriorityEvents" :key="key" :label="dataPoint.title" :value="key"> </el-option>
                  </el-select>
                  <div class="hint">{{ productDisplayPriorityEvents[selectedBlock.content.productInfo.event].description }}</div>
                </el-col>
              </el-row>
              <div v-else>
                <div style="color: #8f9fae; font-size: 14px; padding: 20px 10px 1px 10px">Display order is not applicable for recent products viewed by customers.</div>
              </div>
            </el-collapse-item>
          </template>

          <!-- Single Product Options -->
          <template v-else-if="selectedBlock.type == 'single-product'">
            <!-- Single Product -->
            <el-collapse-item class="blockOptionsContainer productFilters" title="Single Product Settings" name="block">
              <el-row :gutter="20" class="rowOptions">
                <!-- Product Select -->
                <el-col :span="24">
                  <label class="label"> Product To Display (Mention Id of Product)</label>
                  <div class="singleProductSelect">
                    <div class="productSelectInput">
                      <el-input id="inputSingleProductId" size="small" @change="changeBlockConents(false)" v-model="selectedBlock.content.productInfo.id"></el-input>
                      <el-popover v-model="productSelectorPopver" popper-class="dynamicUserProp productSelectorIcon" trigger="click">
                        <DynamicUserProperty ref="dynamicUserPropertyComponent" v-bind:inputType="'title'" :isJourneyCampaign="isJourneyCampaign" @setDynamicPropText="addSingleProductLinkDynmicUserPopver"></DynamicUserProperty>
                        <el-button type="text" info class="textBoxUserIconBtn" slot="reference" circle style="width: auto !important">
                          <i class="el-icon-user-solid" aria-hidden="true"></i>
                        </el-button>
                      </el-popover>
                    </div>
                    <el-button size="small" type="primary" plain @click="showProductSelector(false)">Select Product</el-button>
                  </div>
                </el-col>

                <!-- Product Name Color -->
                <el-col :span="12">
                  <label class="label"> Product Name Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.productNameColor }">
                      <color-picker :color="selectedBlock.content.productNameColor" theme="light" @changeColor="setColor(selectedBlock.content, 'productNameColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.productNameColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.productNameColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- productDescriptionColor Color -->
                <el-col :span="12">
                  <label class="label"> Product Description Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.productDescriptionColor }">
                      <color-picker :color="selectedBlock.content.productDescriptionColor" theme="light" @changeColor="setColor(selectedBlock.content, 'productDescriptionColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.productDescriptionColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.productDescriptionColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <el-col :span="12" class="optionItem">
                  <label class="label">Show Product Price</label>
                  <el-radio-group v-model="selectedBlock.content.showPrice" style="width: 100%">
                    <el-radio-button :label="true">Yes</el-radio-button>
                    <el-radio-button :label="false">No</el-radio-button>
                  </el-radio-group>
                </el-col>
                <el-col :span="12">
                  <label class="label"> Product Price Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.productPriceColor }">
                      <color-picker :color="selectedBlock.content.productPriceColor" theme="light" @changeColor="setColor(selectedBlock.content, 'productPriceColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.productPriceColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.productPriceColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </el-collapse-item>

            <!-- Single Product Button -->
            <el-collapse-item class="blockOptionsContainer productFilters" title="Product Button Settings" name="product-button">
              <el-row :gutter="20" class="rowOptions">
                <!-- Button Text -->
                <el-col :span="12">
                  <label class="label"> Button Text </label>
                  <el-input v-model="selectedBlock.content.button.text" type="text"></el-input>
                </el-col>
                <!-- Button Visiblity -->
                <el-col :span="12" class="optionItem">
                  <label class="label">Show Product Button</label>
                  <el-radio-group v-model="selectedBlock.content.button.visible" style="width: 100%">
                    <el-radio-button :label="true">Yes</el-radio-button>
                    <el-radio-button :label="false">No</el-radio-button>
                  </el-radio-group>
                </el-col>
                <!-- Button Colors -->
                <el-col :span="12">
                  <label class="label"> Button Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.textColor }">
                      <color-picker :color="selectedBlock.content.button.textColor" theme="light" @changeColor="setColor(selectedBlock.content.button, 'textColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.textColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Button Color -->
                <el-col :span="12">
                  <label class="label"> Button Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.buttonColor }">
                      <color-picker :color="selectedBlock.content.button.buttonColor" theme="light" @changeColor="setColor(selectedBlock.content.button, 'buttonColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.buttonColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.buttonColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Font Size -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Button Font Size </label>
                  <el-slider class="slider" :min="10" :max="20" v-model="selectedBlock.content.button.fontSize"> </el-slider>
                </el-col>
                <!-- Border Radius -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Button Radius </label>
                  <el-slider class="slider" :min="0" :max="50" v-model="selectedBlock.content.button.radius"> </el-slider>
                </el-col>
                <!-- Button - Text Padding -->
                <el-col :span="24" class="paddingContainer">
                  <label class="label"> Button Text Padding</label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.top" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.bottom" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.left" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.right" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-collapse-item>
          </template>

          <!-- Discount Options -->
          <template v-else-if="selectedBlock.type == 'discount'">
            <el-collapse-item class="blockOptionsContainer couponOptionsContainer" title="Set Coupon Content" name="coupon-code">
              <el-row :gutter="20" class="rowOptions">
                <el-col :col="24">
                  <label class="label"> Coupon Type </label>
                  <el-radio-group v-model="selectedBlock.content.couponInfo.dynamic" @change="onSelectedCouponTypeChange">
                    <el-radio :label="true">Growlytics Generated Coupon</el-radio>
                    <el-radio :label="false">Outside Generated Coupon</el-radio>
                  </el-radio-group>
                </el-col>
                <!-- Dynamic Coupon CODE -->
                <el-col :span="24" v-if="selectedBlock.content.couponInfo.dynamic">
                  <label class="label"> Select Dynamic Coupon </label>
                  <el-select v-model="selectedBlock.content.couponInfo.id" v-if="couponList != null">
                    <el-option v-for="(coupon, index) in couponList" :key="index" :label="coupon.name" :value="coupon.id"></el-option>
                  </el-select>
                </el-col>

                <!-- Manual Coupon -->
                <template v-else>
                  <el-col :span="12">
                    <label class="label"> Enter COUPON CODE </label>
                    <el-input v-model="selectedBlock.content.couponInfo.manual.code" type="text" min="1" max="200"></el-input>
                  </el-col>
                  <el-col :span="12">
                    <label class="label"> Select Dynamic Coupon </label>
                    <el-input v-model="selectedBlock.content.couponInfo.manual.expiryText" @input="onSelectedCouponExpiryTimeTextChange" type="text" min="1" max="200"></el-input>
                  </el-col>
                </template>

                <!-- Button Click Link -->
                <el-col :span="24">
                  <label class="label"> Button Click Link </label>
                  <el-input v-model="selectedBlock.content.button.link" type="text"></el-input>
                </el-col>
              </el-row>
            </el-collapse-item>

            <!-- Coupon Title Styling -->
            <el-collapse-item class="blockOptionsContainer couponOptionsContainer" title="Coupon Title Text Style" name="discountTitleText">
              <div class="p-3 w-full">
                <TextStyleBuilder :customFontList="customFontsList" :isJourneyCampaign="isJourneyCampaign" ref="discountTitleText" :inputData.sync="selectedBlock.content.title"></TextStyleBuilder>
              </div>
            </el-collapse-item>

            <!-- Coupon Code Text Style -->
            <el-collapse-item class="blockOptionsContainer couponOptionsContainer" title="Coupon Code Text Style" name="discountCouponText">
              <div class="p-3 w-full">
                <CouponStyleBuilder :customFontList="customFontsList" ref="discountCouponText" :inputData.sync="selectedBlock.content.coupon"></CouponStyleBuilder>
              </div>
            </el-collapse-item>

            <!-- Coupon Expiry Text Style -->
            <el-collapse-item class="blockOptionsContainer couponOptionsContainer" title="Expiry Text Style" name="discountExpiryText">
              <div class="p-3 w-full">
                <MinimalTextStyleBuilder ref="discountExpiryText" :inputData.sync="selectedBlock.content.expiryText"></MinimalTextStyleBuilder>
              </div>
            </el-collapse-item>

            <!-- CTA button Style -->
            <el-collapse-item class="blockOptionsContainer couponOptionsContainer" title="CTA Button Style" name="dicsountCouponButton">
              <div class="p-3 w-full">
                <ButtonStyleBuilder ref="dicsountCouponButton" :inputData.sync="selectedBlock.content.button"></ButtonStyleBuilder>
              </div>
            </el-collapse-item>
          </template>

          <!-- Product Review Options -->
          <template v-else-if="selectedBlock.type == 'product-review'">
            <!-- Single Product -->
            <el-collapse-item class="blockOptionsContainer productFilters" title="Review Block Settings" name="block">
              <el-row :gutter="20" class="rowOptions">
                <!-- Product Select -->
                <el-col :span="24">
                  <label class="label"> Order Id (For Which Review Will Be Collected)</label>
                  <div class="singleProductSelect">
                    <div class="productSelectInput">
                      <el-input id="inputProductReviewOrderId" size="small" v-model="selectedBlock.content.orderId"></el-input>
                      <el-popover v-model="productReviewPopover" popper-class="dynamicUserProp productSelectorIcon" trigger="click">
                        <DynamicUserProperty ref="dynamicUserPropertyComponent" v-bind:inputType="'title'" :isJourneyCampaign="isJourneyCampaign" @setDynamicPropText="addProductReviewLinkDynmicUserPopver"></DynamicUserProperty>
                        <el-button type="text" info class="textBoxUserIconBtn" slot="reference" circle style="width: auto !important">
                          <i class="el-icon-user-solid" aria-hidden="true"></i>
                        </el-button>
                      </el-popover>
                    </div>
                  </div>
                </el-col>

                <!-- Product Name Color -->
                <el-col :span="12">
                  <label class="label"> Product Name Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.productNameColor }">
                      <color-picker :color="selectedBlock.content.productNameColor" theme="light" @changeColor="setColor(selectedBlock.content, 'productNameColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.productNameColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.productNameColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- productDescriptionColor Color -->
                <el-col :span="12">
                  <label class="label"> Product Description Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.productDescriptionColor }">
                      <color-picker :color="selectedBlock.content.productDescriptionColor" theme="light" @changeColor="setColor(selectedBlock.content, 'productDescriptionColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.productDescriptionColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.productDescriptionColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </el-collapse-item>

            <!-- Single Product Button -->
            <el-collapse-item class="blockOptionsContainer productFilters" title="Review Button Settings" name="product-button">
              <el-row :gutter="20" class="rowOptions">
                <!-- Button Text -->
                <el-col :span="12">
                  <label class="label"> Button Text </label>
                  <el-input v-model="selectedBlock.content.button.text" type="text"></el-input>
                </el-col>
                <!-- Button Colors -->
                <el-col :span="12">
                  <label class="label"> Button Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.textColor }">
                      <color-picker :color="selectedBlock.content.button.textColor" theme="light" @changeColor="setColor(selectedBlock.content.button, 'textColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.textColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Button Color -->
                <el-col :span="12">
                  <label class="label"> Button Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.buttonColor }">
                      <color-picker :color="selectedBlock.content.button.buttonColor" theme="light" @changeColor="setColor(selectedBlock.content.button, 'buttonColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.buttonColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.buttonColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Font Size -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Button Font Size </label>
                  <el-slider class="slider" :min="10" :max="20" v-model="selectedBlock.content.button.fontSize"> </el-slider>
                </el-col>
                <!-- Border Radius -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Button Radius </label>
                  <el-slider class="slider" :min="0" :max="50" v-model="selectedBlock.content.button.radius"> </el-slider>
                </el-col>
                <!-- Button - Text Padding -->
                <el-col :span="24" class="paddingContainer">
                  <label class="label"> Button Text Padding</label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.top" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.bottom" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.left" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.right" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-collapse-item>
          </template>

          <!-- Custom HTML Options -->
          <template v-else-if="selectedBlock.type == 'custom-html'">
            <el-collapse-item class="blockOptionsContainer" title="Custom HTML" name="custom-html">
              <el-row :gutter="20" class="rowOptions">
                <el-col :col="24" class="py-1">
                  <label class="label"> Enter Custom HTML </label>
                  <el-input type="textarea" v-model="selectedBlock.content.html" :rows="8"> </el-input>
                  <div class="text-gray-400 text-xs mt-2">This html will be rendered inside &lt;td&gt; block. Use this block carefully, incostintent html content may break entire mail HTML structure.</div>
                </el-col>
              </el-row>
            </el-collapse-item>
          </template>

          <!-- Social Icons Options -->
          <template v-else-if="selectedBlock.type == 'social-icons'">
            <!-- Icons List Styling -->
            <el-collapse-item class="blockOptionsContainer" title="Icons Style" name="icon-styling">
              <el-row :gutter="20">
                <!-- Social Icon Height -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Icon Height </label>
                  <el-slider class="slider" :min="20" :max="200" v-model="selectedBlock.content.imageHeight"> </el-slider>
                </el-col>
                <!-- Space Between Icons -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Space Between Icons </label>
                  <el-slider class="slider" :min="0" :max="50" v-model="selectedBlock.content.iconSpacing"> </el-slider>
                </el-col>
                <!-- Border Radius -->
                <!-- <el-col :span="12" class="sliderContainer">
                  <label class="label"> Icon Border Radius </label>
                  <el-slider class="slider" :min="0" :max="50" v-model="selectedBlock.content.borderRadius"> </el-slider>
                </el-col> -->
                <!-- Alignment -->
                <el-col :span="12" class="alignmentButton">
                  <label class="label"> Alignment </label>
                  <el-radio-group size="small" style="display: block; width: 100%" v-model="selectedBlock.content.alignment">
                    <el-radio-button label="left">
                      <i class="fa fa-align-left" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="center">
                      <i class="fa fa-align-center" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="right">
                      <i class="fa fa-align-right" aria-hidden="true"></i>
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
                <!-- Icon Margin -->
                <el-col :span="24" class="optionItem paddingContainer">
                  <label class="label"> Section Padding </label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.left" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.right" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-collapse-item>

            <!-- Icons List Section -->
            <el-collapse-item class="blockOptionsContainer" title="Icons List" name="block">
              <div class="socialIconsContainer">
                <div class="socialIconsHeader">
                  <el-dropdown class="addFieldButton" size="small" @command="onAddSocialIcons">
                    <el-button size="mini" type="primary"> Add Icon<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="prop" v-for="(prop, index) in imageListBlockSettings.predefined" :key="index">{{ prop.title }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>

                <!-- Icon List -->
                <div class="socialIconsList">
                  <!-- Icon List - Iterate -->
                  <draggable v-model="selectedBlock.content.iconList" group="socialIconsList" @start="imageListDrag = true" @end="imageListDrag = false" v-bind="dragOptions" handle=".fieldDragContainer">
                    <transition-group type="transition" :name="!imageListDrag ? 'flip-list' : null">
                      <el-row class="socialIcons" v-for="(field, imageIconFieldIndex) in selectedBlock.content.iconList" :key="imageIconFieldIndex">
                        <el-col :span="2" class="iconActionImageContainer">
                          <i class="socialIconImage fa fa-exchange fa-rotate-90 fieldDragContainer" aria-hidden="true"></i>
                          <i class="socialIconImage el-icon-delete-solid" @click="selectedBlock.content.iconList.splice(imageIconFieldIndex, 1)" aria-hidden="true"></i>
                        </el-col>
                        <el-col :span="22">
                          <div class="socialIconsTitle">
                            {{ field.title }}
                          </div>
                          <div class="socialIconsInputContainer">
                            <el-input placeholder="Please input" size="mini" v-model="field.url">
                              <template slot="prepend">Click Link</template>
                            </el-input>
                          </div>
                          <div class="socialIconsInputContainer">
                            <el-input placeholder="Please input" size="mini" v-model="field.image">
                              <template slot="prepend">Icon Image </template>
                              <template slot="append">
                                <i class="el-icon-upload" @click="showSocialIconUploader('socialIcon-' + imageIconFieldIndex)"></i>
                              </template>
                            </el-input>
                            <ImageManager @onSelect="onUploadSocialIcon(imageIconFieldIndex, ...arguments)" :ref="'socialIcon-' + imageIconFieldIndex"></ImageManager>
                          </div>
                        </el-col>
                      </el-row>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </el-collapse-item>
          </template>

          <!-- Cart Options -->
          <template v-else-if="selectedBlock.type == 'cart'">
            <!-- Icons List Styling -->
            <el-collapse-item class="blockOptionsContainer" title="Cart Style" name="block">
              <el-row :gutter="20">
                <!-- Cart Margin -->
                <el-col :span="24" class="optionItem paddingContainer">
                  <label class="label"> Cart Section Padding </label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.left" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.right" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-collapse-item>
            <el-collapse-item class="buttonOptions blockOptionsContainer" title="Cart Button Style" name="cart-button">
              <el-row :gutter="20">
                <!-- Button Text -->
                <el-col :span="24">
                  <label class="label"> Button Text </label>
                  <el-input v-model="selectedBlock.content.button.text" type="text"></el-input>
                </el-col>
                <!-- Button Colors -->
                <el-col :span="12">
                  <label class="label"> Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.textColor }">
                      <color-picker :color="selectedBlock.content.button.textColor" theme="light" @changeColor="setColor(selectedBlock.content.button, 'textColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.textColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Button Color -->
                <el-col :span="12">
                  <label class="label"> Button Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.buttonColor }">
                      <color-picker :color="selectedBlock.content.button.buttonColor" theme="light" @changeColor="setColor(selectedBlock.content.button, 'buttonColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.buttonColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.buttonColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Font Size -->
                <el-col :span="24">
                  <label class="label"> Text Size </label>
                  <el-slider :min="12" :max="70" v-model="selectedBlock.content.button.fontSize"> </el-slider>
                </el-col>
                <!-- Alignment -->
                <el-col :span="12" class="alignmentButton">
                  <label class="label"> Alignment </label>
                  <el-radio-group style="display: block; width: 100%" v-model="selectedBlock.content.button.alignment">
                    <el-radio-button label="left">
                      <i class="fa fa-align-left" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="center">
                      <i class="fa fa-align-center" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="right">
                      <i class="fa fa-align-right" aria-hidden="true"></i>
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
                <!-- Border Radius -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Button Radius </label>
                  <el-slider class="slider" :min="0" :max="50" v-model="selectedBlock.content.button.radius"> </el-slider>
                </el-col>
                <!-- Button - Text Padding -->
                <el-col :span="24" class="paddingContainer">
                  <label class="label"> Text Padding</label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.top" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.bottom" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.left" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.insidePadding.right" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-collapse-item>
          </template>

          <!-- Email Header Options -->
          <template v-else-if="selectedBlock.type == 'email-header'">
            <el-collapse-item class="blockOptionsContainer emailHeaderOptionsContainer" title="Email Header Logo" name="block">
              <el-row class="imageOptions" :gutter="20">
                <!-- Image Selector -->
                <el-col class="optionItem" :span="24">
                  <!-- Image Selector -->
                  <div class="imagePreview" v-if="selectedBlock.content.logo"></div>
                  <div class="imageSelector">
                    <!-- Image Upload Option -->
                    <el-button size="small" type="info" plain @click="showImageuploader('blockImageManager')"> Upload New Logo Image </el-button>
                    <ImageManager @onSelect="onImageUpload(selectedBlock, 'logo', ...arguments)" ref="blockImageManager"></ImageManager>

                    <!-- Image Preview -->
                    <el-image style="width: 100%; height: 150px; margin-top: 10px" :src="selectedBlock.content.logo" fit="contain">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </el-col>

                <!-- Logo - Click Url -->
                <el-col :span="24" class="optionItem" style="position: relative; margin-top: 20px">
                  <label class="label"> Logo Click URL - Opened when logo clicked </label>
                  <el-input id="inputImageClickLink" v-model="selectedBlock.content.logoClickUrl" type="text"></el-input>
                  <el-popover v-model="imageLinkDynmicUserPopver" popper-class="dynamicUserProp" trigger="click">
                    <DynamicUserProperty ref="dynamicUserPropertyComponent" :isJourneyCampaign="isJourneyCampaign" v-bind:inputType="'title'" @setDynamicPropText="addImageLinkDynmicUserPopver"></DynamicUserProperty>
                    <el-button type="text" info class="textBoxUserIconBtn" slot="reference" circle>
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-col>

                <!-- Header Logo Height -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Logo Height </label>
                  <el-slider :min="30" :max="100" v-model="selectedBlock.content.logoHeight"> </el-slider>
                </el-col>
              </el-row>
            </el-collapse-item>
            <el-collapse-item class="blockOptionsContainer emailHeaderOptionsContainer" title="Email Header Links" name="email-header-links" v-if="selectedBlock.content.links">
              <el-row :gutter="20" class="px-2 mt-1">
                <!-- Header Link Text Color -->
                <el-col :span="12">
                  <label class="label pt-4"> Link Text Font Weight </label>
                  <el-select v-model="selectedBlock.content.links.weight" class="w-full">
                    <el-option value="normal" label="Normal"></el-option>
                    <el-option value="bold" label="Bold"></el-option>
                    <el-option value="300" label="Light"></el-option>
                  </el-select>
                </el-col>

                <!-- Header Link Alignment -->
                <el-col :span="12" v-if="selectedBlock.content.links.alignment">
                  <label class="label pt-4"> Alignment </label>
                  <el-radio-group style="display: block" v-model="selectedBlock.content.links.alignment">
                    <el-radio-button label="left">
                      <i class="fa fa-align-left" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="center">
                      <i class="fa fa-align-center" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="right">
                      <i class="fa fa-align-right" aria-hidden="true"></i>
                    </el-radio-button>
                  </el-radio-group>
                </el-col>

                <!-- Header Link Text Color -->
                <el-col :span="12">
                  <label class="label pt-4"> Link Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.links.color }">
                      <color-picker :color="selectedBlock.content.links.color" theme="light" @changeColor="setColor(selectedBlock.content.links, 'color', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.links.color + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.links.color }}</span>
                    </div>
                  </el-popover>
                </el-col>

                <!-- Link Text Font Size -->
                <el-col :span="12" class="pt-4">
                  <label class="label"> Link Text Size </label>
                  <el-slider class="mt-0" :min="10" :max="30" v-model="selectedBlock.content.links.fontSize"> </el-slider>
                </el-col>

                <!-- Links List -->
                <el-col :span="24" class="pt-4 emailHeaderFooterLinkListContainer">
                  <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                    <!-- Header -->
                    <div class="min-w-full divide-y divide-gray-200 bg-gray-50 px-2 py-2">
                      <span class="text-sm leading-7">Social Links</span>

                      <el-button type="primary" size="small" class="float-right" @click="selectedBlock.content.links.list.push({ url: '', text: '' })">Add New Link</el-button>
                    </div>

                    <draggable v-model="selectedBlock.content.links.list" group="headerLinksListDragger" @start="imageListDrag = true" @end="imageListDrag = false" v-bind="dragOptions" handle=".fieldDragContainer">
                      <transition-group type="transition" :name="!imageListDrag ? 'flip-list' : null">
                        <div class="bg-white headerLinksListDragger" v-for="(field, fieldIndex) in selectedBlock.content.links.list" :key="fieldIndex">
                          <div class="pl-1 pr-3 py-3 border-t whitespace-nowrap text-sm font-medium text-gray-900">
                            <el-row class="emailFooterIcon" :gutter="10">
                              <el-col :span="2" class="iconActionImageContainer">
                                <i class="fa fa-exchange fa-rotate-90 fieldDragContainer" aria-hidden="true"></i>
                                <i class="el-icon-delete-solid" @click="selectedBlock.content.links.list.splice(fieldIndex, 1)" aria-hidden="true"></i>
                              </el-col>
                              <el-col :span="7">
                                <label>Link Text</label>
                                <el-input placeholder="Please input" size="mini" v-model="field.text"> </el-input>
                              </el-col>
                              <el-col :span="15">
                                <label>Link URL</label>
                                <el-input placeholder="Please input" size="mini" v-model="field.url"> </el-input>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </el-col>
              </el-row>
            </el-collapse-item>
          </template>

          <!-- Email Footer Options -->
          <template v-else-if="selectedBlock.type == 'email-footer'">
            <el-collapse-item class="blockOptionsContainer emailFooterOptionsContainer" title="Footer Logo" name="block">
              <el-row class="imageOptions mt-2" :gutter="20">
                <!-- Header Logo Height -->
                <el-col :span="12" class="optionItem">
                  <label class="label">Show Logo In Footer</label>
                  <el-radio-group size="small" v-model="selectedBlock.content.logo.enabled">
                    <el-radio-button :label="true">Yes</el-radio-button>
                    <el-radio-button :label="false">No</el-radio-button>
                  </el-radio-group>
                </el-col>

                <!-- Header Logo Height -->
                <el-col :span="12" class="sliderContainer" style="margin-top: 0px !important">
                  <label class="label"> Logo Height </label>
                  <el-slider :min="30" :max="100" v-model="selectedBlock.content.logo.height"> </el-slider>
                </el-col>

                <!-- Image Selector -->
                <el-col class="optionItem pt-2" :span="24">
                  <!-- Image Selector -->
                  <div class="imagePreview" v-if="selectedBlock.content.logo.image"></div>
                  <div class="imageSelector">
                    <!-- Image Upload Option -->
                    <el-button size="small" type="info" plain @click="showImageuploader('blockImageManager')"> Upload New Logo Image </el-button>
                    <ImageManager @onSelect="onUploadFooterLogo" ref="blockImageManager"></ImageManager>

                    <!-- Image Preview -->
                    <el-image style="width: 100%; height: 150px; margin-top: 10px" :src="selectedBlock.content.logo.image" fit="contain">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </el-col>

                <!-- Logo - Click Url -->
                <el-col :span="24" class="optionItem" style="position: relative; margin-top: 20px">
                  <label class="label"> Logo Click URL - Opened when logo clicked </label>
                  <el-input id="inputImageClickLink" v-model="selectedBlock.content.logo.url" type="text"></el-input>
                  <el-popover v-model="imageLinkDynmicUserPopver" popper-class="dynamicUserProp" trigger="click">
                    <DynamicUserProperty ref="dynamicUserPropertyComponent" :isJourneyCampaign="isJourneyCampaign" v-bind:inputType="'title'" @setDynamicPropText="addImageLinkDynmicUserPopver"></DynamicUserProperty>
                    <el-button type="text" info class="textBoxUserIconBtn" slot="reference" circle>
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-col>
              </el-row>
            </el-collapse-item>

            <!-- Footer Address -->
            <el-collapse-item class="blockOptionsContainer couponOptionsContainer" title="Footer Address" name="emailFooterAddressText">
              <div class="p-1 w-full">
                <TextStyleBuilder :customFontList="customFontsList" :editorHeight="280" ref="emailFooterAddressText" :isJourneyCampaign="isJourneyCampaign" :inputData.sync="selectedBlock.content.address"></TextStyleBuilder>
              </div>
            </el-collapse-item>

            <!-- Unsubscribe Text  -->
            <el-collapse-item class="blockOptionsContainer couponOptionsContainer" title="Footer Unsubscribe Text" name="emailFooterUnsubscribeText">
              <div class="p-1 w-full">
                <TextStyleBuilder :customFontList="customFontsList" :editorHeight="280" ref="emailFooterUnsubscribeText" :isJourneyCampaign="isJourneyCampaign" :inputData.sync="selectedBlock.content.unsubscribe"></TextStyleBuilder>
              </div>
            </el-collapse-item>

            <!-- Footer Social Icons -->
            <el-collapse-item class="blockOptionsContainer emailFooterOptionsContainer" title="Footer Social Icons" name="email-footer-social">
              <el-row>
                <!-- Header Logo Height -->
                <el-col :span="12" class="optionItem">
                  <label class="label">Show Social Icons In Footer</label>
                  <el-radio-group v-model="selectedBlock.content.socialLinks.enabled">
                    <el-radio-button :label="true">Yes</el-radio-button>
                    <el-radio-button :label="false">No</el-radio-button>
                  </el-radio-group>
                </el-col>

                <!-- Social Icon Color -->
                <el-col :span="12" class="px-2">
                  <label class="label"> Social Icons Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.socialLinks.iconColor }">
                      <color-picker :color="selectedBlock.content.socialLinks.iconColor" theme="light" @changeColor="onEmailFooterSocialIconColorchange(arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.socialLinks.iconColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.socialLinks.iconColor }}</span>
                    </div>
                  </el-popover>
                </el-col>

                <!-- Header Logo Height -->
                <el-col :span="24" class="sliderContainer px-1 py-1" style="margin-top: 0px !important">
                  <label class="label"> Social Icons Height </label>
                  <el-slider :min="10" :max="70" v-model="selectedBlock.content.socialLinks.height"> </el-slider>
                </el-col>

                <!-- Social Icons List -->
                <el-col :span="24" class="emailHeaderFooterLinkListContainer">
                  <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                    <!-- Header -->
                    <div class="min-w-full divide-y divide-gray-200 bg-gray-50 px-2 py-2">
                      <span class="text-sm leading-7">Social Links</span>
                      <el-dropdown class="float-right" @command="onEmailFooterSocialIconAdd">
                        <el-button type="primary" size="mini"> Add Social Icon <i class="el-icon-arrow-down el-icon--right"></i> </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="iconType" class="capitalize" v-for="(iconType, iconSelectorIndex) in ['facebook', 'twitter', 'instagram', 'linkedin', 'pinterest', 'youtube', 'google', 'whatsapp']" :key="iconSelectorIndex">{{ iconType }}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>

                    <draggable v-model="selectedBlock.content.socialLinks.list" group="footerLinksListDragger" @start="imageListDrag = true" @end="imageListDrag = false" v-bind="dragOptions" handle=".fieldDragContainer">
                      <transition-group type="transition" :name="!imageListDrag ? 'flip-list' : null">
                        <div class="bg-white footerLinksListDragger" v-for="(field, socialIconIndex) in selectedBlock.content.socialLinks.list" :key="socialIconIndex">
                          <div class="pl-1 pr-3 py-3 border-t whitespace-nowrap text-sm font-medium text-gray-900" :class="socialIconIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                            <el-row class="emailFooterIcon" :gutter="10">
                              <el-col :span="2" class="iconActionImageContainer">
                                <i class="fa fa-exchange fa-rotate-90 fieldDragContainer" aria-hidden="true"></i>
                                <i class="el-icon-delete-solid" @click="selectedBlock.content.socialLinks.list.splice(socialIconIndex, 1)" aria-hidden="true"></i>
                              </el-col>
                              <el-col :span="7">
                                <label>Icon Type</label>
                                <div class="capitalize border px-2 py-1 text-gray-600 font-normal text-xs rounded bg-gray-50">{{ field.icon }}</div>
                              </el-col>
                              <el-col :span="15">
                                <label>Click URL</label>
                                <el-input placeholder="Please input" size="mini" v-model="field.url"> </el-input>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </el-col>
              </el-row>
            </el-collapse-item>

            <!-- Footer App Store Icons -->
            <el-collapse-item class="blockOptionsContainer emailFooterOptionsContainer" title="Footer App Store Icons" name="email-footer-app">
              <el-row>
                <!-- Header Logo Height -->
                <el-col :span="12" class="optionItem">
                  <label class="label">Show App Store Icons In Footer</label>
                  <el-radio-group v-model="selectedBlock.content.appStoreLinks.enabled">
                    <el-radio-button :label="true">Yes</el-radio-button>
                    <el-radio-button :label="false">No</el-radio-button>
                  </el-radio-group>
                </el-col>

                <!-- Header Logo Height -->
                <el-col :span="12" class="sliderContainer px-1 py-1" style="margin-top: 0px !important">
                  <label class="label"> App Store Icons Height </label>
                  <el-slider :min="10" :max="70" v-model="selectedBlock.content.appStoreLinks.height"> </el-slider>
                </el-col>

                <!-- Header Logo Height -->
                <el-col :span="24" class="emailHeaderFooterLinkListContainer px-1 py-2" style="margin-top: 0px !important" v-for="(store, storeListIndex) in selectedBlock.content.appStoreLinks.list" :key="storeListIndex">
                  <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                    <!-- Header -->
                    <div class="min-w-full divide-y divide-gray-200 bg-gray-50 px-2 py-2 border-b">
                      <span class="text-sm leading-7">{{ store.title }}</span>
                      <el-switch v-model="store.enabled" class="float-right"></el-switch>
                    </div>

                    <div class="bg-white py-3 px-2">
                      <el-row class="emailFooterIcon" :gutter="10">
                        <el-col :span="12">
                          <label>Image URL</label>
                          <el-input placeholder="Please input" size="mini" v-model="store.image"> </el-input>
                        </el-col>
                        <el-col :span="12">
                          <label>Click URL</label>
                          <el-input placeholder="Please input" size="mini" v-model="store.url"> </el-input>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-collapse-item>
          </template>

          <!-- Block Options -->
          <el-collapse-item class="blockOptionsContainer" v-else-if="!selectedBlock.type.startsWith('columns')" :title="selectedBlock.name + ' Settings'" name="block">
            <!-- Image Options -->
            <div class="imageOptions" v-if="selectedBlock.type == 'image'">
              <el-row :gutter="20">
                <!-- Image Selector -->
                <el-col class="optionItem" :span="24">
                  <label class="label">
                    <el-radio-group v-model="selectedBlock.content.srcMode" size="mini">
                      <el-radio style="margin-right: 3px !important" label="upload" border>Upload Image</el-radio>
                      <el-radio label="url" border>Use Web URL</el-radio>
                    </el-radio-group>
                  </label>

                  <!-- Image Selector -->
                  <div class="imagePreview" v-if="selectedBlock.src"></div>
                  <div class="imageSelector">
                    <!-- Image Upload Option -->
                    <template v-if="selectedBlock.content.srcMode == 'upload'">
                      <el-button size="small" type="info" plain @click="showImageuploader('blockImageManager')"> Upload New Image </el-button>
                      <ImageManager @onSelect="onImageUpload(selectedBlock, 'src', ...arguments)" ref="blockImageManager"></ImageManager>
                    </template>
                    <!-- Image URL Input -->
                    <div v-else style="position: relative; width: 100%">
                      <label class="label"> Image URL </label>
                      <el-input id="inputImageLink" v-model="selectedBlock.content.src" type="text"></el-input>
                      <el-popover v-model="imageDynmicUserPopver" popper-class="dynamicUserProp" trigger="click">
                        <DynamicUserProperty ref="dynamicUserPropertyComponent" :isJourneyCampaign="isJourneyCampaign" v-bind:inputType="'title'" @setDynamicPropText="addImageDynmicUserPopver"></DynamicUserProperty>
                        <el-button type="text" info class="textBoxUserIconBtn" slot="reference" circle style="width: auto !important">
                          <i class="el-icon-user-solid" aria-hidden="true"></i>
                        </el-button>
                      </el-popover>
                    </div>
                    <!-- Image Preview -->
                    <el-image style="width: 100%; height: 150px; margin-top: 10px" :src="selectedBlock.content.src" fit="contain">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </el-col>
                <!-- Image - Click Url -->
                <el-col :span="24" class="optionItem" style="position: relative; margin-top: 20px">
                  <label class="label"> Image Click URL - Opened when image clicked </label>
                  <el-input id="inputImageClickLink" v-model="selectedBlock.content.link" type="text"></el-input>
                  <el-popover v-model="imageLinkDynmicUserPopver" popper-class="dynamicUserProp" trigger="click">
                    <DynamicUserProperty ref="dynamicUserPropertyComponent" :isJourneyCampaign="isJourneyCampaign" v-bind:inputType="'title'" @setDynamicPropText="addImageLinkDynmicUserPopver"></DynamicUserProperty>
                    <el-button type="text" info class="textBoxUserIconBtn" slot="reference" circle>
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-col>
                <!-- Image - Alignment -->
                <el-col :span="12" class="optionItem radioButtonContainer">
                  <label class="label"> Alignment </label>
                  <el-radio-group size="mini" class="radioButtonGroup" style="display: block" v-model="selectedBlock.content.alignment">
                    <el-radio-button label="left">
                      <i class="fa fa-align-left" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="center">
                      <i class="fa fa-align-center" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="right">
                      <i class="fa fa-align-right" aria-hidden="true"></i>
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
                <!-- Image - Width -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label"> Image width </label>
                  <el-slider class="slider" :min="5" :max="100" v-model="selectedBlock.content.width"> </el-slider>
                </el-col>
                <!-- Image - Border Radius -->
                <el-col :span="24" class="optionItem sliderContainer">
                  <label class="label"> Image Radius </label>
                  <el-slider class="slider" :min="0" :max="500" v-model="selectedBlock.content.radius"> </el-slider>
                </el-col>
              </el-row>
            </div>

            <!-- Text Options -->
            <div class="textOptions blockOptionsContainer" v-if="selectedBlock.type == 'text'">
              <!-- Ck Editor -->
              <div class="ckEditorContainer">
                <el-popover popper-class="dynamicUserProp" v-model="ckEditorDynmicPopover" trigger="click">
                  <DynamicUserProperty ref="dynamicUserPropertyComponent" :isJourneyCampaign="isJourneyCampaign" v-bind:showUnsubscribe="true" v-bind:inputType="'subject'" @setDynamicPropText="addDynamicPropToTextBlock"></DynamicUserProperty>
                  <el-button type="text" info class="userIconBtn" slot="reference" circle>
                    <i class="el-icon-user-solid" aria-hidden="true"></i>
                  </el-button>
                </el-popover>
                <MceTextEditor ref="mceEditor" :customFontList="customFontsList" @onBlockEditorHtmlChange="onBlockEditorHtmlChange" :inputValue="selectedBlock.content.html" />
              </div>
            </div>

            <!-- Button Options -->
            <div class="buttonOptions blockOptionsContainer" v-if="selectedBlock.type == 'button'">
              <el-row :gutter="20">
                <!-- Button Text -->
                <el-col :span="24">
                  <label class="label"> Button Text </label>
                  <el-input v-model="selectedBlock.content.text" type="text"></el-input>
                </el-col>
                <!-- Click Url -->
                <el-col :span="24" style="position: relative">
                  <label class="label"> Click Link [Opened when clicked] </label>
                  <el-input id="inputButtonClickLink" v-model="selectedBlock.content.link" type="text"></el-input>
                  <el-popover v-model="buttonLinkDynmicUserPopver" popper-class="dynamicUserProp" trigger="click">
                    <DynamicUserProperty v-bind:inputType="'title'" :isJourneyCampaign="isJourneyCampaign" @setDynamicPropText="addButtonLinkDynmicUserPopver"></DynamicUserProperty>
                    <el-button type="text" info class="textBoxUserIconBtn" style="top: 30px" slot="reference" circle>
                      <i class="el-icon-user-solid" aria-hidden="true"></i>
                    </el-button>
                  </el-popover>
                </el-col>
                <!-- Button Colors -->
                <el-col :span="12">
                  <label class="label"> Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.textColor }">
                      <color-picker :color="selectedBlock.content.textColor" theme="light" @changeColor="setColor(selectedBlock.content, 'textColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.textColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Button Color -->
                <el-col :span="12">
                  <label class="label"> Button Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.buttonColor }">
                      <color-picker :color="selectedBlock.content.buttonColor" theme="light" @changeColor="setColor(selectedBlock.content, 'buttonColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.buttonColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.buttonColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Font Size -->
                <el-col :span="24">
                  <label class="label"> Text Size </label>
                  <el-slider :min="12" :max="70" v-model="selectedBlock.content.fontSize"> </el-slider>
                </el-col>
                <!-- Alignment -->
                <el-col :span="12" class="alignmentButton">
                  <label class="label"> Alignment </label>
                  <el-radio-group style="display: block; width: 100%" v-model="selectedBlock.content.alignment">
                    <el-radio-button label="left">
                      <i class="fa fa-align-left" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="center">
                      <i class="fa fa-align-center" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="right">
                      <i class="fa fa-align-right" aria-hidden="true"></i>
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
                <!-- Border Radius -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Button Radius </label>
                  <el-slider class="slider" :min="0" :max="50" v-model="selectedBlock.content.radius"> </el-slider>
                </el-col>
                <!-- Button - Text Padding -->
                <el-col :span="24" class="paddingContainer">
                  <label class="label"> Text Padding</label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.top" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.bottom" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.left" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.right" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>

            <!-- Divider Options -->
            <div class="buttonOptions blockOptionsContainer" v-if="selectedBlock.type == 'divider'">
              <el-row :gutter="20">
                <!-- Divider Height -->
                <el-col :span="24">
                  <label class="label"> Divider Height </label>
                  <el-slider :min="1" :max="50" v-model="selectedBlock.content.height"> </el-slider>
                </el-col>
                <!-- Divider Width -->
                <el-col :span="24">
                  <label class="label"> Divider Width </label>
                  <el-slider :min="10" :max="100" v-model="selectedBlock.content.width"> </el-slider>
                </el-col>
                <!-- Divider Color -->
                <el-col :span="12">
                  <label class="label"> Divider Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.color }">
                      <color-picker :color="selectedBlock.content.color" theme="light" @changeColor="setColor(selectedBlock.content, 'color', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.color + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.color }}</span>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
    </el-tabs>

    <!-- Right Side -->
    <div class="rightSide">
      <div class="mainEditor mailBody" :style="{ background: mailOptions.background }">
        <!-- Drop When No Blocks Found -->
        <div class="blockList" v-if="blockList.length > 0">
          <!-- Add Block At Top -->
          <div class="additionoBlocksDivnalAddBlock">
            <div class="description">
              <el-button type="info" plain @click="showBlockSelector(0)">Add Block</el-button>
            </div>
          </div>

          <!-- Block List - Iterate -->
          <draggable v-model="blockList" group="blockList" @start="drag = true" @end="drag = false" v-bind="dragOptions" handle=".dragContainer">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <div class="blockContainer" v-for="(block, index) in blockList" :key="index" @click="onBlockSelect(block, block)">
                <!-- Render Block -->
                <div
                  :id="'email-block-' + block.id"
                  class="block"
                  :class="{
                    'selected-block': selectedBlock && block.id == selectedBlock.id
                  }"
                >
                  <!-- Block - Device visiblity info -->
                  <div class="to-be-removed deviceVisiblityPreview" v-if="block && block.content && block.content.deviceVisiblity != 'all'">
                    {{ block.content.deviceVisiblity == 'mobile' ? 'Mobile Only' : 'Desktop Only' }}
                  </div>

                  <!-- Block - Add Buttons  -->
                  <div class="to-be-removed addButtonContainer addButtonContainerTop blockAddButtonContainer">
                    <div class="liner"></div>
                    <el-button size="mini" type="primary" icon="el-icon-plus" circle class="addButton" @click="showBlockSelector(index)"> </el-button>
                  </div>
                  <div class="to-be-removed addButtonContainer addButtonContainerBottom blockAddButtonContainer">
                    <div class="liner"></div>
                    <el-button size="mini" type="primary" icon="el-icon-plus" circle class="addButton" @click="showBlockSelector(index + 1)"> </el-button>
                  </div>

                  <!--  Move Button -->
                  <el-tooltip class="dragContainer to-be-removed" effect="dark" content="Drag Block Up/Down" placement="left-start">
                    <el-button class="dragContainer to-be-removed" size="mini" type="primary" circle>
                      <i class="fa fa-arrows" aria-hidden="true"></i>
                    </el-button>
                  </el-tooltip>

                  <!--  Delete Button -->
                  <el-tooltip class="deleteButton to-be-removed" effect="dark" content="Delete Block" placement="left-start">
                    <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="removeBlock(index)"></el-button>
                  </el-tooltip>

                  <!-- Copy  Button -->
                  <el-tooltip class="copyButton to-be-removed" effect="dark" content="Copy Block" placement="top">
                    <el-button class="" size="mini" type="primary" icon="el-icon-document-copy" circle @click="copyBlock(index)"></el-button>
                  </el-tooltip>

                  <el-tooltip class="saveBlockButton to-be-removed" effect="dark" content="Save Block" placement="bottom">
                    <el-button class="" size="mini" type="info" icon="el-icon-star-on" circle @click="saveBlockForLaterUse(index)"></el-button>
                  </el-tooltip>

                  <!-- If Columns -->
                  <table
                    :id="'block_' + block.id"
                    v-if="block.type.startsWith('columns')"
                    class="gridSection bock-image-background"
                    cellpadding="0"
                    cellspacing="0"
                    border="0"
                    :style="{
                      'background-color': block.content.rowBackgroundColor ? block.content.rowBackgroundColor : 'transparent',
                      'background-image': block.content.rowBackgroundImage ? 'url(' + block.content.rowBackgroundImage + ')' : 'none',
                      'background-size': 'cover',
                      'background-position': 'center top',
                      'background-repeat': 'no-repeat',
                      width: mailOptions.mailMaxWidth,
                      'max-width': '750px'
                    }"
                    :width="mailOptions.mailMaxWidth"
                    style="border-spacing: 0; border-collapse: collapse"
                  >
                    <tr>
                      <td style="padding: 0px !important">
                        <table class="columnsBlock" border="0" cellspacing="0" cellpadding="0" style="width: 100%; border-spacing: 0; border-collapse: collapse" width="100%">
                          <tr class="section-row">
                            <td
                              class="section-column"
                              v-for="(blockColumnRows, columnIndex) in block.content.columns"
                              :key="columnIndex"
                              :style="{
                                width: columnIndex == 0 && block.content.leftWidth ? block.content.leftWidth + '%' : 'auto',
                                'vertical-align': block.content.columnContentVerticalAlign ? block.content.columnContentVerticalAlign : 'top'
                              }"
                              style="padding: 0px !important"
                            >
                              <!-- Sub Blocks -->
                              <draggable v-model="block.content.columns[columnIndex]" group="subBlock" @start="drag = true" @end="drag = false" v-bind="dragOptions" handle=".column-dragContainer">
                                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                  <div class="section-column-row" v-for="(subBlock, columnRowIndex) in block.content.columns[columnIndex]" :key="columnRowIndex" @click.stop="onBlockSelect(subBlock, block)" :class="{ 'selected-block': selectedBlock && subBlock.id == selectedBlock.id }">
                                    <!-- Move Sub Block Button -->
                                    <el-tooltip class="column-dragContainer to-be-removed" effect="dark" content="Drag Up/Down" placement="top">
                                      <el-button size="mini" type="primary" circle>
                                        <i class="fa fa-arrows" aria-hidden="true"></i>
                                      </el-button>
                                    </el-tooltip>

                                    <!-- Delete Sub Block Button -->
                                    <el-tooltip class="column-deleteButton to-be-removed" effect="dark" content="Delete" placement="top">
                                      <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="removeBlockFromColumn(index, columnIndex, columnRowIndex)"></el-button>
                                    </el-tooltip>

                                    <!-- Sub Block Copy Button -->
                                    <el-tooltip class="column-copyButton to-be-removed" effect="dark" content="Create Copy" placement="top">
                                      <el-button class="" size="mini" type="primary" icon="el-icon-document-copy" circle @click="copyBlockToColumn(index, columnIndex, columnRowIndex)"></el-button>
                                    </el-tooltip>

                                    <!-- Sub-Block - Add Buttons  -->
                                    <div class="to-be-removed addButtonContainer addButtonContainerTop">
                                      <div class="liner"></div>
                                      <el-button size="mini" type="primary" icon="el-icon-plus" circle class="addButton" @click="showBlockSelector(index, columnIndex, columnRowIndex)"> </el-button>
                                    </div>
                                    <div class="to-be-removed addButtonContainer addButtonContainerBottom">
                                      <div class="liner"></div>
                                      <el-button size="mini" type="primary" icon="el-icon-plus" circle class="addButton" @click="showBlockSelector(index, columnIndex, columnRowIndex + 1)"> </el-button>
                                    </div>

                                    <!-- Single Row Sub Block -->
                                    <DragDropBlock :isSubBlock="true" :mailOptions="mailOptions" :block="subBlock" :brandInfo="brandInfo" :ref="'block-' + subBlock.id" @click.stop="onBlockSelect(subBlock, block)"></DragDropBlock>

                                    <!-- Bottom - Sub Dropper -->
                                    <div class="dropHint" v-if="subBlock.showDroppers">
                                      <div class="dropHintText dropHintTextBottom">Drop Here</div>
                                    </div>
                                  </div>
                                </transition-group>
                              </draggable>

                              <!-- No Sub Divs -->
                              <div class="noSubBlocksDiv to-be-removed" v-if="block.content.columns[columnIndex].length == 0">
                                <div class="description">
                                  <el-button type="info" plain @click="showBlockSelector(index, columnIndex, 0)">Add Block</el-button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>

                  <!-- Non Column Block -->
                  <DragDropBlock v-else :isSubBlock="false" :mailOptions="mailOptions" :block="block" :brandInfo="brandInfo" :ref="'block-' + block.id"></DragDropBlock>
                </div>
                <!-- </drop> -->
              </div>
            </transition-group>
          </draggable>

          <!-- Add Block At Bottom -->
          <div class="additionoBlocksDivnalAddBlock">
            <div class="description">
              <el-button type="info" plain @click="showBlockSelector(blockList.length)">Add Block</el-button>
            </div>
          </div>
        </div>

        <!-- Add Block -->
        <div class="noBlocksDiv" v-if="blockList.length == 0">
          <div class="description">
            <el-button type="info" plain @click="showBlockSelector(0)">Add First Block</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Drag Drop Block Selector Popup -->
    <DragDropBlockSelector ref="blockSelector" v-on:blockSelected="addBlock"></DragDropBlockSelector>

    <ProductSelectionPopup ref="productSelectionPopup" v-on:onAddSelectedProducts="onProductSelectedFromPopup"></ProductSelectionPopup>
  </div>
</template>

<style lang="scss" src="./dragDropEditor.scss"></style>

<script>
import dragDropEditorComponent from './dragDropEditorComponent';
export default dragDropEditorComponent;
</script>
