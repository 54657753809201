const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.createSegment = function (params) {
  return axios.post(baseUrlNew + `segment`, params, {
    headers: buildAuthHeaders()
  });
};

exports.updateSegment = function (segmentId, params) {
  return axios.put(baseUrlNew + `segment/${segmentId}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.deleteSegment = function (segmentId) {
  return axios.delete(baseUrlNew + `segment/${segmentId}`, {
    headers: buildAuthHeaders()
  });
};

exports.cloneSegment = function (segmentId) {
  return axios.post(
    baseUrlNew + `segment/${segmentId}/clone`,
    {},
    {
      headers: buildAuthHeaders()
    }
  );
};

exports.exportSegmentUsersV1 = function (params) {
  let path = `segment/export`;
  return axios.post(baseUrlNew + path, params, {
    headers: buildAuthHeaders()
  });
};

exports.getSegmentList = function (getArchived) {
  let path = `segment`;
  if (getArchived) path += '?archived=1';
  return axios.get(baseUrlNew + path, {
    headers: buildAuthHeaders()
  });
};

exports.getSegmentsForListPage = function (params) {
  let path = `segment/for-list-page`;
  return axios.post(baseUrlNew + path, params, {
    headers: buildAuthHeaders()
  });
};

exports.getSegmentSize = function (segmentId) {
  return axios.get(baseUrlNew + `segment/${segmentId}/size`, {
    headers: buildAuthHeaders()
  });
};

exports.getSegmentReachablity = function (segmentId) {
  return axios.get(baseUrlNew + `segment/${segmentId}/reachablity`, {
    headers: buildAuthHeaders()
  });
};

exports.getSegmentDetail = function (segmentId) {
  return axios.get(baseUrlNew + `segment/${segmentId}`, {
    headers: buildAuthHeaders()
  });
};
