export default {
  props: {
    data: Object
  },
  data() {
    return {};
  },
  methods: {},

  created() {}
};
